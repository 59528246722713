import React from "react";
import * as Material from "@mui/material";

import CustomConsole from "../../CustomConsole";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { Button } from "@mui/base";
import { toast } from "react-toastify";
import { Clear } from "@mui/icons-material";

function RefundDialog(props) {
  CustomConsole(props);
  const [count, setCount] = React.useState(1);
  const [refundQty, setRefundQty] = React.useState(0);
  const [countRefundQty, setCountRefundQty] = React.useState(1);

  React.useEffect(() => {
    setRefundQty(
      props.singleSelectedStockData.new_containers_to_provide -
        (props.singleSelectedStockData.ongoing_ordered_containers -
          props.singleSelectedStockData.empty_containers_submitting)
    );
  }, [props]);

  const hadleSubmitRefund = () => {
    props.hadleSubmitRefund({
      capacity: props.singleSelectedStockData.container_capacity,
      material: props.singleSelectedStockData.container_material,
      quantity: countRefundQty,
      is_crate: props.singleSelectedStockData.is_crate,
    });
  };
  const handleCloseRefundDialog = () => {
    props.handleCloseRefundDialog();
    setCount("");
  };
  const handleMinusQuantity = () => {
    if (countRefundQty > 1) {
      setCountRefundQty(countRefundQty - 1);
    } else {
      toast.error("Can't Decrease less than one", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handlePlusQuantity = (event) => {
    if (countRefundQty < refundQty) {
      setCountRefundQty(countRefundQty + 1);
    } else {
      toast.error("Can't Increase More than Container Qty", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <Material.Dialog open={props.openRefundDialog}>
      <Material.DialogContent>
        <Material.Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Clear color="error" onClick={handleCloseRefundDialog} />
        </Material.Box>
        <Material.Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
          <Material.CardContent>
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
              }}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Containers Qty: {refundQty}
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Material: {props.singleSelectedStockData.container_material}
              </Material.Typography>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Capacity: {props.singleSelectedStockData.container_capacity}
              </Material.Typography>
            </Material.Box>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "1rem",
                height: "30px",
                border: "1px solid blue",
              }}
            >
              {/* <Material.TextField
                label="Enter Quantity"
                size="small"
                autoFocus
                value={count}
                onChange={handleChangeQuantity}
                InputLabelProps={{ shrink: true }}
              /> */}
              <Material.Button
                sx={{ width: "10px", bgcolor: "#068fff" }}
                variant="contained"
                onClick={handleMinusQuantity}
              >
                -
              </Material.Button>
              <Material.Button>{countRefundQty}</Material.Button>
              <Material.Button
                sx={{ width: "10px", bgcolor: "#068fff" }}
                variant="contained"
                onClick={handlePlusQuantity}
              >
                +
              </Material.Button>
            </Material.Box>
          </Material.CardContent>
        </Material.Card>
      </Material.DialogContent>
      <Material.DialogActions>
        <Material.Button variant="contained" onClick={hadleSubmitRefund}>
          Refund
        </Material.Button>
      </Material.DialogActions>
    </Material.Dialog>
  );
}

export default RefundDialog;
