import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { employeeType } from "../../../Components/AppMeta/appMetaConfig";
import { url } from "../apis";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import CustomConsole from "../../../CustomConsole";
import { PayThroughWallet } from "./adminOrderPayAction";

export const UpdateUserDetails = (
  fullname,
  phone,
  email,
  roleId,
  twntyLtrDiscount,
  userId,
  distributorType,
  customPrice
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const reqObj = {
    email: email,
    five_ltr_discount: "0",
    fivehnd_ml_discount: "0",
    fullname: fullname,
    one_ltr_discount: "0",
    phone: phone,
    role_id: roleId,
    supplier_type: distributorType,
    twenty_ltr_discount: twntyLtrDiscount,
    two_ltr_discount: "0",
    twohnd_ml_discount: "0",
    custom_twentyltr_price: parseFloat(customPrice),
  };
  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/profile/${userId}`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_USER_DETAILS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Error in updating details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const UpdateUserRoleId = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SCM/superAdmin/roles/editRolesForUser`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_USER_ROLEID,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Error in updating details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const DeleteUser = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/admin/delete_user/${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DELETE_USER,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error("Error in deleting user", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetProfileDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/profile/${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response.data);
        dispatch({
          type: actionType.GET_PROFILE_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetSupplierDriverDetails = (supplierId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/distributor/employees?employee_type=${employeeType.DRIVER}&supplier_id=${supplierId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIER_DRIVER_DETAILS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GET_ASSIGN_DELIVERY_AGENT,
          payload: response.data,
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      // set Empty
      dispatch({
        type: actionType.GET_ASSIGN_DELIVERY_AGENT,
        payload: {},
      });
      CustomConsole(error);
    }
  };
};
export const GetUserStockDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/getUserStockDetails?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USERSTOCK_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetDepositDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/getDepositDetails?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DEPOSIT_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const UpdateGstDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  CustomConsole(reqObj);
  const user_id = reqObj.userId;
  delete reqObj.userId;
  return async (dispatch) => {
    try {
      let response = await api.put(`SCM/consumer_profile/${user_id}`, reqObj);

      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_GST_DETAILS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console("-----error0----");
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};
export const GetSubscriptionOrderDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/getSubscribedOrderDetails?user_id=${userId}`
      );
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUBSCRIPTION_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

export const GetUserAddressAdmin = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/addresses?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetFilteredUserAddressAdmin = (params) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const filteredReqObj = Object.fromEntries(
    Object.entries(params || {})?.filter(([key, value]) => value !== "")
  );

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/addresses?${new URLSearchParams(filteredReqObj)}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const GetUserAddressAdminById = ({ address_id, user_id }) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/addresses?address_id=${address_id}&user_id=${user_id}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetAccountsDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`SCM/accounts?user_id=${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ACCOUNTS_DETAILS_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const EnableUserAdmin = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/admin/enable_user/${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ENABLE_USER_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const DisableUserAdmin = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/admin/disable_user/${userId}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DISABLE_USER_ADMIN,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const EditUserAddress = (data, addressId, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SCM/addresses/${userId}/${addressId}`,
        data
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
        theme: "colored",
      });
    }
  };
};
export const DeleteUserAddress = (userId, addressId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/addresses/${userId}/${addressId}`, {
        status: "false",
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DELETE_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
        theme: "colored",
      });
    }
  };
};

export const AddUserAddressAdmin = (data, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/addresses?user_id=${userId}`, data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_USER_ADDRESS_ADMIN,
          payload: response.data,
        });
        toast.dark("Added Sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const UpdateSupplierDetails = (reqObj, userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      CustomConsole(reqObj);
      let response = await api.put(`/SCM/company_profile/${userId}`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_SUPPLIER_DETAILS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error("Error in adding Stock", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const UpdateUserStock = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(data);
  return async (dispatch) => {
    try {
      CustomConsole(data);
      let response = await api.put(`/OM/updateUserStockDetails`, data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_USER_STOCK,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//function for adding crate stock details
export const UpdateCrateUserStock = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(data);
  return async (dispatch) => {
    try {
      CustomConsole(data);
      let response = await api.put(`/OM/stock/addCrate`, data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_CRATE_USER_STOCK,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//function for adding crate stock details
export const DepositUserStock = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      CustomConsole(reqObj);
      let response = await api.post(
        `/OM/products/deposit/order/create`,
        reqObj
      );
      CustomConsole("-----------response-------------");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.DEPOSIT_USER_STOCK,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          dispatch(
            PayThroughWallet({
              transaction_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              amount: response.data.data.amount,
              order_id: response.data.data.order_id,
              payment_method: "WATERWALLET",
              payment_type: "PREPAID",
              user_id: reqObj.user_id,
            })
          );
        }, 1000);
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const ReturnCansOrder = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/CreateWithdrawalOrders`, data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.RETURN_CANS_ORDER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        CustomConsole("ELSE: Response in  Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const ReduceUserStock = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/reduceToProvideStock`, data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REDUCE_USER_STOCK,
          payload: response.data,
        });
      } else {
        CustomConsole("ELSE: Response in Action Error");
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetAssignedWfsDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/TM/admin/getAssignedWfsDetails?user_id=${userId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ASSIGNED_WFS,
          payload: response.data,
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      // toast.error("Error getting assigned WFS details", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  };
};

export const GetCansRollBack = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/getDistributorRollbackData?user_id=${userId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_USER_CANS_ROLLBACK,
          payload: response.data,
        });
      } else {
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const PutCansRollBack = (reqobj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqobj);
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/admin/distributorRollbackEnable`,
        reqobj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_CANS_ROLLBACK,
          payload: {
            data: response.data,
            is_enabled: reqobj.is_revert_back_enabled,
          },
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const CansRollBackForOrder = (reqobj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqobj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/distributor/rollbackCans`, reqobj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANS_ROLLBACK_ORDER,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (response.data.error === true) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const DisableAssignWfsSwitch = (wfsId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/admin/disableAssignedWfs`, {
        wfs_id: wfsId,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DISABLE_WFS_SWITCH,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const RequestReplacementCan = (reqobj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/admin/replaceCans/createRequest`,
        reqobj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REQUEST_REPLACEMENT_CAN,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          type: "success",
        });
      } else {
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
};

export const GetWalletDetails = (userId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("----GetWalletDetails--------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `PM/payments/accounts/wallet?user_id=${userId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_WALLET_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

export const AddMoneyToWallet = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("----GetWalletDetails--------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/admin/waterWallet/addMoneyToWallet`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.ADD_WALLET_MONEY,
          payload: response.data,
        });
        if (response.data.error) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            type: "error",
          });
        } else {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            type: "success",
          });
        }
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error("Error in adding to wallet", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const PostReduceWaterWallet = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("----GetWalletDetails--------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/PM/admin/waterWallet/reduceWallet`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.REDUCE_WATER_WALLET_MONEY,
          payload: response.data,
        });
        if (response.data.error) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            type: "error",
          });
        } else {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            type: "success",
          });
        }
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error(
        "Not authorized to reduce amount from wallet Or Error in reduceing amount in water wallet",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};

export const InfluencerEnableDisable = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("Influence disable and enable");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/admin/referralCode/enableInfluencer`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.INFULENCER_ENABLE_DISABLE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error("Error in adding to wallet", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const MakeUserInfluencer = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("Influence Request");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/consumer/referralCode/requestInfluencer`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.MAKE_USER_INFLUENCER,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error("Error in adding to wallet", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetReferralCode = (userId) => {
  let api = useAxios();
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  const reqObj = {
    user_id: userId,
  };
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/consumer/referralCode/createReferralId`,
        reqObj
      );
      CustomConsole("---promocode api----");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REFERRAL_CODES,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const UpdateReferralCode = (reqObj) => {
  let api = useAxios();
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/SSM/admin/referralCode/editReferralCode`,
        reqObj
      );
      CustomConsole("---promocode api----");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_REFERRAL_CODE,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const DeliverySlotsEnableDisableSwitchAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/admin/deliverySlots/enableDisableDiverlySlot`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DELIVERY_SLOTS_ENABLED_DISABLED,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const AddWaterDrops = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("----GetWalletDetails--------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/admin/waterDropWallet/addDropsToWallet`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.ADD_WATER_DROPS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error("Error in adding to wallet", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const RemoveWaterDrops = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  
  CustomConsole("----RemoveWaterDrops--------");
  let api = useAxios(); // Ensure this function is available and properly imported
  
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/admin/waterDropWallet/removeDropsFromWallet`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.REMOVE_WATER_DROPS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.error("Error in removing from wallet", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetBillingAddress = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole(reqObj);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/address/billingAddress?phone=${reqObj.phone}&user_id=${reqObj.userId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_BILLING_ADDRESS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      CustomConsole(error);
      dispatch({
        type: actionType.GET_BILLING_ADDRESS,
        payload: { data: [] },
      });
      // toast.error(error.response.data, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  };
};
export const GetUserBasedName = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole(props);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/get_active_users`, { params: props });
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response);
        dispatch({
          type: actionType.GET_USERS_NAME,
          payload: response.data,
        });
        // toast.success(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      } else if (response.status === httpStatus.BAD_REQUEST) {
        dispatch({
          type: actionType.GET_USERS_NAME,
          payload: { msg: "No Data", userDetails: [] },
        });
      } else {
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        CustomConsole("----error-----");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_USERS_NAME,
        payload: { msg: "No Data", userDetails: [] },
      });
      CustomConsole("Error: " + JSON.stringify(error));
      // toast.error("Error in getting Users", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  };
};
export const TansferDistributorCanCreateOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/admin/transferCans/createOrder`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.TRANSFER_CANS_DISTRIBUTOR_CREATE_ORDER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Error in updating details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const ClearUpdateUserDetailsData = () => {
  return {
    type: actionType.CLEAR_UPDATE_USER_DATA,
  };
};

export const PostRefundConsumerStock = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/refund/deposit`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REFUND_CONSUMER_STOCK,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "colored",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
        theme: "colored",
      });
    }
  };
};
