import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Divider,
  ToggleButton,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  ButtonGroup,
  Grid,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Autocomplete from "@mui/material/Autocomplete";
import CustomConsole from "../../CustomConsole";
import { CSVLink } from "react-csv";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDepositAndUsersWalletSummary,
  GetNewPaymentSummary,
  GetNewSaleProductSummary,
  GetNewSaleSummary,
} from "../Redux/Actions/NewDashboardAction";
import moment from "moment";
import { DownloadForOffline, Padding } from "@mui/icons-material";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import DateRangeFilterDialog from "./DateRangeFilterDialog";
import { AvailablePincodes } from "../Redux/Actions/pincodesAction";
import PaymentSummaryDashboard from "./PaymentSummaryDashboard";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

function SalesSummaryDashboardNew() {
  const dispatch = useDispatch();
  const NewDashboardData = useSelector((state) => state.newDashboardDetails);
  const [cityNameValue, setCityNameValue] = React.useState("");
  const [customDatesMenu, setCustomDatesMenu] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState("INDIA");
  const [warehouseFilteredState, setWarehouseFilteredState] =
    React.useState("");
  const [warehouseFilterCity, setWareFilterhouseCity] = React.useState("");
  const [districts, setDistricts] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = useState();
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [productSummary, setProductSummary] = useState({});
  const [depositsPrice, setDepositsPrice] = React.useState({});
  const [openDateRangeFilterDialog, setOpenDateRangeFilterDialog] =
    React.useState(false);
  const [
    salesTwantyLiterB2bPrepaidTotalAmountQty,
    setSalesTwantyLiterB2bPrepaidTotalAmountQty,
  ] = React.useState({});
  const [
    salesOtherProductsB2bPrepaidTotalAmount,
    setSalesOtherProductsB2bPrepaidTotalAmount,
  ] = React.useState(0);
  const [
    salesOtherProductsB2bPrepaidTotalQUantityty,
    setSalesOtherProductsB2bPrepaidTotalQUantityty,
  ] = React.useState(0);
  //b2b Post Paid
  const [
    salesTwantyLiterB2bPOSTpaidTotalAmountQty,
    setSalesTwantyLiterB2bPOSTpaidTotalAmountQty,
  ] = React.useState({});
  const [
    salesOtherProductsB2bPOSTpaidTotalQUantityty,
    setSalesOtherProductsB2bPOSTpaidTotalQUantityty,
  ] = React.useState(0);
  const [
    salesOtherProductsB2bPOSTpaidTotalAmount,
    setSalesOtherProductsB2bPOSTpaidTotalAmount,
  ] = React.useState(0);
  //Total B2c
  const [
    salesTwantyLiterB2CTotalAmountQty,
    setSalesTwantyLiterB2CTotalAmountQty,
  ] = React.useState({});
  const [
    salesOtherProductsB2CTotalQUantityty,
    setSalesOtherProductsB2CTotalQUantityty,
  ] = React.useState(0);
  const [
    salesOtherProductsB2CTotalAmount,
    setSalesOtherProductsB2CTotalAmount,
  ] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(
    moment().add(-1, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment().add(-1, "days").format("YYYY-MM-DD")
  );

  const [alignment, setAlignment] = React.useState("Yestraday");
  // Get the current year
  const currentYear = moment().year();
  const nextYear = moment().add(1, "year").year();
  const pastYear = moment().subtract(1, "year").year();
  // Calculate the start and end dates for each quarter
  const quarters = [];
  for (let quarter = 1; quarter <= 4; quarter++) {
    const startDate = moment(`${currentYear}-01-01`)
      .add((quarter - 1) * 3, "months")
      .startOf("month");
    const endDate = moment(startDate).endOf("quarter");
    quarters.push({
      quarter: quarter,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  }
  React.useEffect(() => {
    handleSearchDate();
  }, [fromDate, toDate, warehouseFilteredState, warehouseFilterCity]);
  React.useEffect(() => {
    getCountries();
    dispatch(GetDepositAndUsersWalletSummary());
  }, []);
  React.useEffect(() => {
    if (selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getStates({ country: selectedCountry });
    }
  }, [selectedCountry]);
  React.useEffect(() => {
    if (warehouseFilteredState && selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getDistricts({
        country: selectedCountry,
        state: warehouseFilteredState,
      });
    }
  }, [warehouseFilteredState]);

  //   React.useEffect(() => {
  //     // dispatch(
  //     //   GetNewSaleSummary({
  //     //     from_date: moment().add(-1, "days").format("YYYY-MM-DD"),
  //     //     to_date: toDate,
  //     //     city: warehouseFilterCity,
  //     //   })
  //     // );
  //     setAlignment("Yestraday");
  //     handleAlignment();
  //   }, []);

  React.useEffect(() => {
    if (
      NewDashboardData?.product_Summary !== undefined &&
      NewDashboardData?.product_Summary !== null &&
      NewDashboardData?.product_Summary.length > 0
    ) {
      let totalDepositPrice = 0;
      let totalExtraCharges = 0;
      let orderPrice = 0;
      NewDashboardData?.product_Summary?.map((product) => {
        totalDepositPrice += product.total?.deposit_price || 0;
        totalExtraCharges += product.total?.delivery_charges || 0;
        orderPrice += product.total?.price || 0;
      });
      setProductSummary({ totalDepositPrice, totalExtraCharges, orderPrice });
    }
    if (
      NewDashboardData?.deposits_summary !== undefined &&
      NewDashboardData?.deposits_summary !== null &&
      NewDashboardData?.deposits_summary.length > 0
    ) {
      let depositsPaidTotalPrice = 0;
      let depositsFreeTotalPrice = 0;
      for (const data of NewDashboardData?.deposits_summary) {
        console.log(data);
        depositsPaidTotalPrice += parseInt(data?.paid_amount) || 0;
        depositsFreeTotalPrice += parseInt(data?.unpaid_amount) || 0;
      }
      // NewDashboardData?.deposits_summary?.reduce((price) => {
      //   console.log(price);
      //   depositsPaidTotalPrice += price?.paid_amount || 0;
      //   depositsFreeTotalPrice += price?.unpaid_amount || 0;
      // });
      setDepositsPrice({ depositsPaidTotalPrice, depositsFreeTotalPrice });
    }
  }, [NewDashboardData]);

  React.useEffect(() => {
    CustomConsole("--------NewDashboardData------------");
    CustomConsole(NewDashboardData);
    let localProducts = [];

    if (
      NewDashboardData?.sales_twenty_ltr_summary !== undefined &&
      NewDashboardData?.sales_twenty_ltr_summary !== null
    ) {
      // localProducts.push({ data: orderDetails });
      CustomConsole("-----------localProducts-------------------");
      CustomConsole(localProducts);
      localProducts.push({
        capacity: "20_Liter",
        material: "PET",
        prepaid_b2b_quantity:
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2b_twenty_ltr_qty !== null &&
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2b_twenty_ltr_qty !== undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary
                  ?.prepaid_b2b_twenty_ltr_qty
              )
            : 0,
        prepaid_b2b_Amount: parseFloat(
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2b_amount
            ? NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2b_amount
            : 0
        ),
        postpaid_b2c_quantity:
          NewDashboardData?.sales_twenty_ltr_summary
            ?.postpaid_twenty_ltr_qty !== undefined &&
          NewDashboardData?.sales_twenty_ltr_summary
            ?.postpaid_twenty_ltr_qty !== null
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary
                  ?.postpaid_twenty_ltr_qty
              )
            : 0,
        postpaid_b2b_Amount: parseFloat(
          NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount
            ? NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount
            : 0
        ),
        b2c_quantity: parseFloat(
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_twenty_ltr_qty
            ? NewDashboardData?.sales_twenty_ltr_summary
                ?.prepaid_b2c_twenty_ltr_qty
            : 0
        ),
        b2c_amount: parseFloat(
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_amount
            ? NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_amount
            : 0
        ),
        totalQuantity:
          NewDashboardData.sales_twenty_ltr_summary &&
          NewDashboardData.sales_twenty_ltr_summary
            .prepaid_b2c_twenty_ltr_qty !== undefined &&
          NewDashboardData.sales_twenty_ltr_summary
            .prepaid_b2c_twenty_ltr_qty !== null &&
          NewDashboardData.sales_twenty_ltr_summary.postpaid_twenty_ltr_qty !==
            undefined &&
          NewDashboardData.sales_twenty_ltr_summary.postpaid_twenty_ltr_qty !==
            null &&
          NewDashboardData.sales_twenty_ltr_summary
            .prepaid_b2b_twenty_ltr_qty !== undefined &&
          NewDashboardData.sales_twenty_ltr_summary
            .prepaid_b2b_twenty_ltr_qty !== null
            ? parseFloat(
                parseFloat(
                  parseFloat(
                    NewDashboardData.sales_twenty_ltr_summary
                      .prepaid_b2c_twenty_ltr_qty
                  ) +
                    parseFloat(
                      NewDashboardData.sales_twenty_ltr_summary
                        .postpaid_twenty_ltr_qty
                    ) +
                    parseFloat(
                      NewDashboardData.sales_twenty_ltr_summary
                        .prepaid_b2b_twenty_ltr_qty
                    )
                ).toFixed(2)
              )
            : 0,
        totalAmount:
          NewDashboardData.sales_twenty_ltr_summary &&
          NewDashboardData.sales_twenty_ltr_summary.prepaid_b2b_amount !==
            undefined &&
          NewDashboardData.sales_twenty_ltr_summary.prepaid_b2b_amount !==
            null &&
          NewDashboardData.sales_twenty_ltr_summary.postpaid_amount !==
            undefined &&
          NewDashboardData.sales_twenty_ltr_summary.postpaid_amount !== null &&
          NewDashboardData.sales_twenty_ltr_summary.prepaid_b2c_amount !==
            undefined &&
          NewDashboardData.sales_twenty_ltr_summary.prepaid_b2c_amount !== null
            ? parseFloat(
                parseFloat(
                  parseFloat(
                    NewDashboardData.sales_twenty_ltr_summary.prepaid_b2b_amount
                  ) +
                    parseFloat(
                      NewDashboardData.sales_twenty_ltr_summary.postpaid_amount
                    ) +
                    parseFloat(
                      NewDashboardData.sales_twenty_ltr_summary
                        .prepaid_b2c_amount
                    )
                ).toFixed(2)
              )
            : 0,
      });
    }
    if (
      NewDashboardData?.sales_other_products !== undefined &&
      NewDashboardData?.sales_other_products !== null &&
      NewDashboardData?.sales_other_products?.length > 0
    ) {
      for (const data of NewDashboardData.sales_other_products) {
        localProducts.push({
          capacity: data.product_capacity,
          material: data.product_material,
          prepaid_b2b_quantity: parseFloat(data.prepaid_b2b_total_quantity),
          prepaid_b2b_Amount: parseFloat(
            data.prepaid_b2b_water_price_charges
              ? data.prepaid_b2b_water_price_charges
              : 0
          ),
          postpaid_b2c_quantity: parseFloat(
            data.postpaid_b2b_total_quantity
              ? data.postpaid_b2b_total_quantity
              : 0
          ),
          postpaid_b2b_Amount: parseFloat(
            data.postpaid_b2b_water_price_charges
              ? data.postpaid_b2b_water_price_charges
              : 0
          ),
          b2c_quantity: parseFloat(
            data.prepaid_b2c_total_quantity
              ? data.prepaid_b2c_total_quantity
              : 0
          ),

          b2c_amount: parseFloat(
            data.prepaid_b2c_water_price_charges
              ? data.prepaid_b2c_water_price_charges
              : 0
          ),
          totalQuantity: parseFloat(
            parseFloat(
              parseFloat(data.prepaid_b2b_total_quantity) +
                parseFloat(data.postpaid_b2b_total_quantity) +
                parseFloat(data.prepaid_b2c_total_quantity)
            ).toFixed(2)
          ),
          totalAmount: parseFloat(
            parseFloat(
              parseFloat(data.prepaid_b2b_water_price_charges) +
                parseFloat(data.postpaid_b2b_water_price_charges) +
                parseFloat(data.prepaid_b2c_water_price_charges)
            ).toFixed(2)
          ),
        });
      }
    }
    //Total 20 Liters
    if (
      NewDashboardData?.sales_twenty_ltr_summary !== undefined &&
      NewDashboardData?.sales_twenty_ltr_summary !== null
    ) {
      setSalesTwantyLiterB2bPrepaidTotalAmountQty({
        prepaid_b2b_quantity:
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2b_twenty_ltr_qty !== null &&
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2b_twenty_ltr_qty !== undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary
                  ?.prepaid_b2b_twenty_ltr_qty
              )
            : 0,
        prepaid_b2b_Amount:
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2b_amount !==
            null &&
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2b_amount !==
            undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2b_amount
                  ? NewDashboardData?.sales_twenty_ltr_summary
                      ?.prepaid_b2b_amount
                  : 0
              )
            : 0,
      });
      setSalesTwantyLiterB2bPOSTpaidTotalAmountQty({
        postpaid_b2b_quantity:
          NewDashboardData?.sales_twenty_ltr_summary
            ?.postpaid_twenty_ltr_qty !== null &&
          NewDashboardData?.sales_twenty_ltr_summary
            ?.postpaid_twenty_ltr_qty !== undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary
                  ?.postpaid_twenty_ltr_qty
                  ? NewDashboardData?.sales_twenty_ltr_summary
                      ?.postpaid_twenty_ltr_qty
                  : 0
              )
            : 0,
        postpaid_b2b_Amount:
          NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount !==
            null &&
          NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount !==
            undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount
                  ? NewDashboardData?.sales_twenty_ltr_summary?.postpaid_amount
                  : 0
              )
            : 0,
      });
      setSalesTwantyLiterB2CTotalAmountQty({
        b2c_quantity:
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2c_twenty_ltr_qty !== null &&
          NewDashboardData?.sales_twenty_ltr_summary
            ?.prepaid_b2c_twenty_ltr_qty !== undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary
                  ?.prepaid_b2c_twenty_ltr_qty
                  ? NewDashboardData?.sales_twenty_ltr_summary
                      ?.prepaid_b2c_twenty_ltr_qty
                  : 0
              )
            : 0,
        b2c_Amount:
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_amount !==
            null &&
          NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_amount !==
            undefined
            ? parseFloat(
                NewDashboardData?.sales_twenty_ltr_summary?.prepaid_b2c_amount
                  ? NewDashboardData?.sales_twenty_ltr_summary
                      ?.prepaid_b2c_amount
                  : 0
              )
            : 0,
      });
    }

    let totalPrepaidB2BQty = 0;
    let totalPrepaidB2BPrice = 0;
    let totalPrepaidB2CQty = 0;
    let totalPrepaidB2CPrice = 0;
    let totalPostpaidB2BQty = 0;
    let totalPostpaidB2BPrice = 0;
    // Total Other Products
    CustomConsole("---------sales_other_products-----------------");
    CustomConsole(NewDashboardData.sales_other_products);
    if (
      NewDashboardData.sales_other_products !== undefined &&
      NewDashboardData.sales_other_products !== null &&
      NewDashboardData.sales_other_products.length > 0
    ) {
      for (const data of NewDashboardData.sales_other_products) {
        totalPrepaidB2BQty +=
          data.prepaid_b2b_total_quantity !== undefined &&
          data.prepaid_b2b_total_quantity !== null
            ? parseFloat(
                data.prepaid_b2b_total_quantity
                  ? data.prepaid_b2b_total_quantity
                  : 0
              )
            : 0;
        totalPrepaidB2BPrice +=
          data.prepaid_b2b_water_price_charges !== undefined &&
          data.prepaid_b2b_water_price_charges !== null
            ? parseFloat(
                data.prepaid_b2b_water_price_charges
                  ? data.prepaid_b2b_water_price_charges
                  : 0
              )
            : 0;
        totalPrepaidB2CQty +=
          data.prepaid_b2c_total_quantity !== undefined &&
          data.prepaid_b2c_total_quantity !== null
            ? parseFloat(
                data.prepaid_b2c_total_quantity
                  ? data.prepaid_b2c_total_quantity
                  : 0
              )
            : 0;
        totalPrepaidB2CPrice +=
          data.prepaid_b2c_water_price_charges !== undefined &&
          data.prepaid_b2c_water_price_charges !== null
            ? parseFloat(
                data.prepaid_b2c_water_price_charges
                  ? data.prepaid_b2c_water_price_charges
                  : 0
              )
            : 0;
        totalPostpaidB2BQty +=
          data.postpaid_b2b_total_quantity !== undefined &&
          data.postpaid_b2b_total_quantity !== null
            ? parseFloat(
                data.postpaid_b2b_total_quantity
                  ? data.postpaid_b2b_total_quantity
                  : 0
              )
            : 0;
        totalPostpaidB2BPrice +=
          data.postpaid_b2b_water_price_charges !== undefined &&
          data.postpaid_b2b_water_price_charges !== null
            ? parseFloat(
                data.postpaid_b2b_water_price_charges
                  ? data.postpaid_b2b_water_price_charges
                  : 0
              )
            : 0;
      }
      CustomConsole("totalPrepaidB2BQty: ", totalPrepaidB2BQty);
      CustomConsole("totalPrepaidB2BPrice: ", totalPrepaidB2BPrice);
      CustomConsole("totalPrepaidB2CQty: ", totalPrepaidB2CQty);
      CustomConsole("totalPrepaidB2CPrice: ", totalPrepaidB2CPrice);
      CustomConsole("totalPostpaidB2BQty: ", totalPostpaidB2BQty);
      CustomConsole("totalPostpaidB2BPrice: ", totalPostpaidB2BPrice);

      setSalesOtherProductsB2bPrepaidTotalQUantityty(() =>
        parseFloat(totalPrepaidB2BQty ? totalPrepaidB2BQty : 0).toFixed(2)
      );
      setSalesOtherProductsB2bPrepaidTotalAmount(() =>
        parseFloat(totalPrepaidB2BPrice ? totalPrepaidB2BPrice : 0).toFixed(2)
      );
      setSalesOtherProductsB2bPOSTpaidTotalQUantityty(() =>
        parseFloat(totalPostpaidB2BQty ? totalPostpaidB2BQty : 0).toFixed(2)
      );
      setSalesOtherProductsB2bPOSTpaidTotalAmount(() =>
        parseFloat(totalPostpaidB2BPrice ? totalPostpaidB2BPrice : 0).toFixed(2)
      );
      setSalesOtherProductsB2CTotalQUantityty(() =>
        parseFloat(totalPrepaidB2CQty ? totalPrepaidB2CQty : 0).toFixed(2)
      );
      setSalesOtherProductsB2CTotalAmount(() =>
        parseFloat(totalPrepaidB2CPrice ? totalPrepaidB2CPrice : 0).toFixed(2)
      );
    }

    setProducts(localProducts);
  }, [NewDashboardData]);

  const handleSearchDate = () => {
    // dispatch(
    //   GetNewSaleSummary({
    //     from_date: fromDate,
    //     to_date: toDate,
    //     city: warehouseFilterCity,
    //     state: warehouseFilteredState,
    //   })
    // );
    dispatch(
      GetNewPaymentSummary({
        from_date: fromDate,
        to_date: toDate,
      })
    );
    dispatch(
      GetNewSaleProductSummary({
        from_date: fromDate,
        to_date: toDate,
        city: warehouseFilterCity || undefined,
        state: warehouseFilteredState || undefined,
      })
    );
    dispatch(GetDepositAndUsersWalletSummary());
  };

  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setCountries(all_available_countries?.data);
    // setAllAvailableCountries(all_available_countries?.data);
  };
  const getStates = async (params) => {
    const all_states = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "state_name",
    });
    setStates(all_states?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "district",
    });
    setDistricts(all_Districts?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  const handleAlignment = (event) => {
    CustomConsole("---------newAlignment--------------");
    CustomConsole(event);
    CustomConsole(alignment);
    NewDashboardData.sales_other_products = [];
    setSalesOtherProductsB2CTotalAmount(0);
    setSalesOtherProductsB2CTotalQUantityty(0);
    setSalesOtherProductsB2bPOSTpaidTotalAmount(0);
    setSalesOtherProductsB2bPOSTpaidTotalQUantityty(0);
    setSalesOtherProductsB2bPrepaidTotalQUantityty(0);
    setSalesOtherProductsB2bPrepaidTotalAmount(0);
    setAlignment(event.target.value);
    if (event.target.value === "Yestraday") {
      setFromDate(() => moment().add(-1, "days").format("YYYY-MM-DD"));
      setToDate(moment().add(-1, "days").format("YYYY-MM-DD"));
    } else if (event.target.value === "Week") {
      setFromDate(() => moment().add(-7, "days").format("YYYY-MM-DD"));
      setToDate(moment().add(-1, "days").format("YYYY-MM-DD"));
    } else if (event.target.value === "Month") {
      setFromDate(() => moment().add(-31, "days").format("YYYY-MM-DD"));
      setToDate(moment().add(-1, "days").format("YYYY-MM-DD"));
    } else if (event.target.value === "Year") {
      setFromDate(() => moment().add(-366, "days").format("YYYY-MM-DD"));
      setToDate(moment().add(-1, "days").format("YYYY-MM-DD"));
    } else if (event.target.value === "Q1") {
      setToDate(quarters[0].endDate);
      setFromDate(quarters[0].startDate);
      //   setFromDate(() => moment().quarter(1).format("YYYY-MM-DD"));
    } else if (event.target.value === "Q2") {
      setToDate(quarters[1].endDate);
      setFromDate(quarters[1].startDate);
    } else if (event.target.value === "Q3") {
      setToDate(quarters[2].endDate);
      setFromDate(quarters[2].startDate);
    } else if (event.target.value === "Q4") {
      setToDate(quarters[3].endDate);
      setFromDate(quarters[3].startDate);
    } else if (event.target.value === "pastFinYear") {
      setFromDate(() =>
        moment(moment(`${pastYear}-04-01`)).format("YYYY-MM-DD")
      );
      setToDate(() =>
        moment(moment(`${currentYear}-03-31`)).format("YYYY-MM-DD")
      );
    } else if (event.target.value === "currentFinYear") {
      setFromDate(() =>
        moment(moment(`${currentYear}-04-01`)).format("YYYY-MM-DD")
      );
      setToDate(() => moment(moment(`${nextYear}-03-31`)).format("YYYY-MM-DD"));
    }
  };

  const handleChangeCityNames = (event) => {
    setCityNameValue(event.target.value);
  };

  const cityNames = ["CHENNAI", "BANGLORE"];
  const customDatesValues = [
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    <Button variant="contained">Date Range</Button>,
  ];
  const handleGetDateRange = (props) => {
    setToDate(props.toDate);
    setFromDate(props.fromDate);
    setOpenDateRangeFilterDialog(false);
  };
  const handleChangecustomDatesMenu = (event) => {
    if (event.target.value === "DateRange") {
      setCustomDatesMenu(event.target.value);
      setOpenDateRangeFilterDialog(true);
      setCustomDatesMenu("");
    } else if (event.target.value === "Q1") {
      setCustomDatesMenu(event.target.value);
      setFromDate("2024-01-01");
      setToDate("2024-03-30");
    } else if (event.target.value === "Q2") {
      setCustomDatesMenu(event.target.value);
      setFromDate("2024-04-01");
      setToDate("2024-06-30");
    } else if (event.target.value === "Q3") {
      setCustomDatesMenu(event.target.value);
      setFromDate("2024-07-01");
      setToDate("2024-09-30");
    } else if (event.target.value === "Q4") {
      setCustomDatesMenu(event.target.value);
      setFromDate("2024-10-01");
      setToDate("2024-12-31");
    }
  };

  const handleDownload = () => {
    // const processedData = preprocessData(productSummaryData);
    // debugger;
    let data = NewDashboardData?.product_Summary?.map((item) => {
      let order_qty = [
        item?.product_name,
        "Order Qty",
        item?.b2c_prepaid?.quantity || 0,
        item?.b2b_prepaid?.quantity || 0,
        item?.b2b_postpaid?.quantity || 0,
        item?.total?.quantity || 0,
        "--",
      ];
      let price = [
        item?.product_name,
        "Price",
        item?.b2c_prepaid?.price || 0,
        item?.b2b_prepaid?.price || 0,
        item?.b2b_postpaid?.price || 0,
        item?.total?.price || 0,
        item?.total?.price > 0
          ? parseFloat(item?.total?.price || 0) /
            parseFloat(item?.total?.quantity || 0)
          : 0,
      ];
      let deposit_qty = [
        item?.product_name,
        "Deposit Qty",
        item?.b2c_prepaid?.deposit_qty || 0,
        item?.b2b_prepaid?.deposit_qty || 0,
        item?.b2b_postpaid?.deposit_qty || 0,
        item?.total?.deposit_qty || 0,
        "--",
      ];
      let deposit_price = [
        item?.product_name,
        "Deposit Price",
        item?.b2c_prepaid?.deposit_price || 0,
        item?.b2b_prepaid?.deposit_price || 0,
        item?.b2b_postpaid?.deposit_price || 0,
        item?.total?.deposit_price || 0,
        "--",
      ];
      let discount = [
        item?.product_name,
        "Discount",
        item?.b2c_prepaid?.discount || 0,
        item?.b2b_prepaid?.discount || 0,
        item?.b2b_postpaid?.discount || 0,
        item?.total?.discount || 0,
        "--",
      ];
      let charges = [
        item?.product_name,
        "Extra Charges",
        item?.b2c_prepaid?.delivery_charges || 0,
        item?.b2b_prepaid?.delivery_charges || 0,
        item?.b2b_postpaid?.delivery_charges || 0,
        item?.total?.delivery_charges || 0,
        "--",
      ];
      let total = [
        item?.product_name,
        "Total",
        (
          parseFloat(item?.b2c_prepaid?.price) +
          parseFloat(item?.b2c_prepaid?.deposit_price) +
          parseFloat(item?.b2c_prepaid?.discount) +
          parseFloat(item?.b2c_prepaid?.delivery_charges)
        ).toFixed(2),
        (
          parseFloat(item?.b2b_prepaid?.price || 0) +
          parseFloat(item?.b2b_prepaid?.deposit_price || 0) +
          parseFloat(item?.b2b_prepaid?.discount || 0) +
          parseFloat(item?.b2b_prepaid?.delivery_charges || 0)
        ).toFixed(2),
        (
          parseFloat(item?.b2b_postpaid?.price || 0) +
          parseFloat(item?.b2b_postpaid?.deposit_price || 0) +
          parseFloat(item?.b2b_postpaid?.discount || 0) +
          parseFloat(item?.b2b_postpaid?.delivery_charges || 0)
        ).toFixed(2),
        (
          parseFloat(item?.total?.price || 0) +
          parseFloat(item?.total?.deposit_price || 0) +
          parseFloat(item?.total?.discount || 0) +
          parseFloat(item?.total?.delivery_charges || 0)
        ).toFixed(2),
        "--",
      ];
      return [
        order_qty,
        price,
        deposit_qty,
        deposit_price,
        discount,
        charges,
        total,
      ];
    });
    data.unshift([
      [
        "Products",
        "Units",
        "B2C Prepaid",
        "B2B prepaid",
        "Postpaid",
        "Total",
        "Avg.Price",
      ],
    ]);
    debugger;
    // const worksheet = XLSX.utils.json_to_sheet(processedData);
    // var worksheet = XLSX.utils.aoa_to_sheet(data);
    // worksheet["!merges"] = [
    //   { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } }, // Merges from A3 to B3 (0-based index)
    // ];
    // Flattening the nested arrays
    var flattenedData = data.flat();

    // Create the worksheet
    var worksheet = XLSX.utils.aoa_to_sheet(flattenedData);

    // Define the merge ranges
    var centerStyle = {
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };
    worksheet["!merges"] = [
      { s: { r: 1, c: 0 }, e: { r: 7, c: 0 } }, // Merge "20L-PET" in rows 1 to 6
      { s: { r: 8, c: 0 }, e: { r: 14, c: 0 } }, // Merge "300 ML-GLASS" in rows 7 to 12
    ];
    // Apply style to the merged cells
    for (let merge of worksheet["!merges"]) {
      let cell = XLSX.utils.encode_cell(merge.s);
      worksheet[cell].s = centerStyle;
    }

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "salesSummary.xlsx");
  };

  return (
    <Box>
      {/* Top Bar for Filter & Search Options */}
      <Paper elevation={12} sx={{ padding: "5px" }}>
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {
            <Autocomplete
              sx={{ width: "200px", fontSize: "16px" }}
              value={selectedCountry}
              // options={countries?.map((item, index) => {
              //   return { name: item.name, code: item.code, id: item.id };
              // })}
              options={countries || []}
              defaultValue={selectedCountry}
              autoHighlight
              getOptionLabel={(nameSearch) => `${selectedCountry} `}
              // defaultValue={countries[0]?.name}
              onChange={(_, newValue) => {
                setSelectedCountry(newValue?.country || ""); // Set the selected  as the value
              }}
              renderOption={(props, item) => {
                return (
                  <MenuItem
                    onClick={() => setSelectedCountry(item.country)}
                    key={item}
                    value={item.country}
                  >
                    {item.country}
                  </MenuItem>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={"Country"}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "country", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          }
          <Autocomplete
            sx={{ width: "200px", fontSize: "16px" }}
            // key={warehouseFilteredState}
            // id="country-select-demo"
            // sx={{ width: 210 }}
            value={warehouseFilteredState}
            // options={countries?.map((item, index) => {
            //   return { name: item.name, code: item.code, id: item.id };
            // })}
            options={states || []}
            defaultValue={warehouseFilteredState}
            autoHighlight
            getOptionLabel={(nameSearch) =>
              !nameSearch
                ? ""
                : typeof nameSearch === "object"
                ? nameSearch?.state_name?.toUpperCase()
                : nameSearch
            }
            // defaultValue={countries[0]?.name}
            onChange={(_, newValue) => {
              setWarehouseFilteredState(newValue?.state_name || null); // Set the selected  as the value
            }}
            renderOption={(props, item) => {
              return (
                <MenuItem
                  onClick={() => setWarehouseFilteredState(item.state_name)}
                  key={item}
                  value={item.state_name?.toUpperCase()}
                >
                  {item.state_name?.toUpperCase()}
                </MenuItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                onCh
                {...params}
                size="small"
                label={"State"}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "state_name", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: "200px", fontSize: "16px" }}
            id="country-select-demo"
            // sx={{ width: "max-content" }}
            options={
              districts?.map((item, index) => {
                return { name: item.district };
              }) || []
            }
            // defaultValue={{ name: "Chennai", code: "TN05", id: 1 }}
            autoHighlight
            getOptionLabel={(nameSearch) => `${nameSearch?.name || ""} `}
            onChange={(_, newValue) => {
              setWareFilterhouseCity(newValue?.name || null); // Set the selected  as the value
            }}
            renderOption={(props, nameSearch) => (
              <Box
                sx={{ fontSize: "16px" }}
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {nameSearch.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                autoComplete={false}
                aria-autocomplete="none"
                {...params}
                size="small"
                label={"City"}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
          <FormControl sx={{ width: "200px", fontSize: "16px" }} size="small">
            <InputLabel>Select date range</InputLabel>
            <Select
              value={alignment}
              label="Select date range"
              onChange={(event) => handleAlignment(event)}
            >
              <MenuItem value={"Yestraday"}>Yesterday </MenuItem>
              <MenuItem value={"Week"}>Week</MenuItem>
              <MenuItem value={"Month"}>Month</MenuItem>
              <MenuItem value={"Year"}>Year</MenuItem>
              <MenuItem value={"pastFinYear"}>Past Financial Year</MenuItem>
              <MenuItem value={"currentFinYear"}>
                Current Financial Year
              </MenuItem>
              <MenuItem value={"Q1"}>JAN-MAR (Q1)</MenuItem>
              <MenuItem value={"Q2"}>APR-JUN (Q2)</MenuItem>
              <MenuItem value={"Q3"}>JUL-AUG (Q3)</MenuItem>
              <MenuItem value={"Q4"}>SEP-DEC (Q4)</MenuItem>
              <MenuItem value={"Custom Date"}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDateRangeFilterDialog(true)}
                >
                  Custom Date
                </Button>
              </MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            <DownloadForOffline
              sx={{ mt: "12px", fontSize: "32px" }}
              onClick={handleDownload}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            // flexDirection: { xs: "column", sm: "column", md: "column", lg: "column" },
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <ToggleButtonGroup
            sx={{ display: { xs: "none", sm: "block" } }}
            color="primary"
            value={alignment}
            exclusive
            onChange={(event) => handleAlignment(event)}
          >
            <ToggleButton value="Yestraday">Yesterday</ToggleButton>
            <ToggleButton value="Week">Week</ToggleButton>
            <ToggleButton value="Month">Month</ToggleButton>
            <ToggleButton value="Year">Year</ToggleButton>
            <ToggleButton
              value="Custom Date"
              onClick={() => setOpenDateRangeFilterDialog(true)}
            >
              Custom Date
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography
            sx={{
              textAlign: "center",
              ml: { xs: "0px", md: "10px" },
            }}
          >
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              Selected Date Range:{" "}
            </Typography>
            {fromDate} - {toDate}
          </Typography>
        </Box>
      </Paper>
      <Paper elevation={12} sx={{ marginTop: "20px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                pl: "10px",
                pr: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  border: "2px solid black",
                  borderBottom: "0px solid black",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  PRODUCT SUMMARY
                </Typography>
              </Box>
              <TableContainer sx={{ border: "1.4px solid black" }}>
                <Table
                  id="products_summary_table"
                  size="small"
                  sx={{ overflow: "auto" }}
                >
                  <TableBody>
                    {/* <TableCell align="center"> */}

                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        border: "1.4px solid black",
                        bgcolor: "#D5F5E3",
                      }}
                      align="center"
                    >
                      {/* Products Header Cell */}
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        Products
                      </TableCell>
                      {/* Units Header Cell */}
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        Units
                      </TableCell>
                      {/* B2C Prepaid Header cell */}
                      <TableCell
                        align="start"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        B2C Prepaid
                      </TableCell>{" "}
                      {/* B2B Prepaid Header cell */}
                      <TableCell
                        align="start"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        B2B prepaid
                      </TableCell>
                      {/* Postpaid Header cell */}
                      <TableCell
                        align="start"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        Postpaid
                      </TableCell>
                      {/* Total Header cell */}
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        Total
                      </TableCell>{" "}
                      {/* Avarage Price Header cell */}
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                          border: "1.4px solid black",
                        }}
                      >
                        Avg.Price
                      </TableCell>
                    </TableRow>
                    {NewDashboardData.product_Summary !== undefined &&
                    NewDashboardData.product_Summary !== null &&
                    NewDashboardData.product_Summary.length > 0 ? (
                      <>
                        {NewDashboardData.product_Summary?.map((data) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                border: "1.4px solid black",
                                bgcolor: "#EAFAF1",
                              }}
                            >
                              {/* Product Names Body Cell */}
                              <TableCell
                                align="center"
                                sx={{
                                  borderBottom: "3px solid black",
                                  borderRight: "0px solid black",
                                  borderLeft: "0px solid black",
                                }}
                              >
                                {data.product_name}
                              </TableCell>
                              {/* Units Body Cell */}
                              <TableCell
                                align="end"
                                style={{
                                  minWidth: "120px",
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Order Qty</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Price</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Deposit Qty</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Deposit Price</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Discount</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>Extra Charges</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: "30px",
                                    border: "1.5px solid black",
                                    borderRight: "0px solid black",
                                  }}
                                >
                                  <TableRow sx={{ fontWeight: "bold" }}>
                                    Total
                                  </TableRow>
                                </Box>
                              </TableCell>
                              {/* B2C Prepaid Body Cell */}
                              <TableCell
                                align="center"
                                style={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.quantity
                                      ? data.b2c_prepaid?.quantity
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.price
                                      ? data.b2c_prepaid?.price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.deposit_qty
                                      ? data.b2c_prepaid?.deposit_qty
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.deposit_price
                                      ? data.b2c_prepaid?.deposit_price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.discount
                                      ? data.b2c_prepaid?.discount
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2c_prepaid?.delivery_charges
                                      ? data.b2c_prepaid?.delivery_charges
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: { md: "30px", xs: "5px" },
                                    border: "1.5px solid black",
                                    borderLeft: "0px solid black",
                                    borderRight: "0px solid black",
                                  }}
                                >
                                  <TableRow>
                                    &#x20B9;{" "}
                                    {parseFloat(
                                      data.b2c_prepaid?.price +
                                        data.b2c_prepaid?.deposit_price +
                                        data.b2c_prepaid?.discount +
                                        data.b2c_prepaid?.delivery_charges
                                    ).toFixed(2)}
                                  </TableRow>
                                </Box>
                              </TableCell>
                              {/* B2B Prepaid Body Cell */}
                              <TableCell
                                align="center"
                                style={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.quantity
                                      ? data.b2b_prepaid?.quantity
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.price
                                      ? data.b2b_prepaid?.price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.deposit_qty
                                      ? data.b2b_prepaid?.deposit_qty
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.deposit_price
                                      ? data.b2b_prepaid?.deposit_price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.discount
                                      ? data.b2b_prepaid?.discount
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_prepaid?.delivery_charges
                                      ? data.b2b_prepaid?.delivery_charges
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: { md: "30px", xs: "5px" },
                                    border: "1.5px solid black",
                                    borderRight: "0px solid black",
                                    borderLeft: "0px solid black",
                                  }}
                                >
                                  <TableRow>
                                    &#x20B9;
                                    {parseFloat(
                                      data.b2b_prepaid?.price +
                                        data.b2b_prepaid?.deposit_price +
                                        data.b2b_prepaid?.discount +
                                        data.b2b_prepaid?.delivery_charges
                                    ).toFixed(2)}
                                  </TableRow>
                                </Box>
                              </TableCell>
                              {/* Postpaid Body Cell */}
                              <TableCell
                                align="center"
                                style={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.quantity
                                      ? data.b2b_postpaid?.quantity
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.price
                                      ? data.b2b_postpaid?.price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.deposit_qty
                                      ? data.b2b_postpaid?.deposit_qty
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.deposit_price
                                      ? data.b2b_postpaid?.deposit_price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.discount
                                      ? data.b2b_postpaid?.discount
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "30px" }}>
                                  <TableRow>
                                    {data.b2b_postpaid?.delivery_charges
                                      ? data.b2b_postpaid?.delivery_charges
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: { md: "30px", xs: "5px" },
                                    border: "1.5px solid black",
                                    borderRight: "0px solid black",
                                    borderLeft: "0px solid black",
                                  }}
                                >
                                  <TableRow>
                                    &#x20B9;{" "}
                                    {parseFloat(
                                      data.b2b_postpaid?.price +
                                        data.b2b_postpaid?.deposit_price +
                                        data.b2b_postpaid?.discount +
                                        data.b2b_postpaid?.delivery_charges
                                    ).toFixed(2)}
                                  </TableRow>
                                </Box>
                              </TableCell>
                              {/* Total Body Cell */}
                              <TableCell
                                align="center"
                                style={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.quantity
                                      ? data.total?.quantity
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.price ? data.total?.price : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.deposit_qty
                                      ? data.total?.deposit_qty
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.deposit_price
                                      ? data.total?.deposit_price
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.discount
                                      ? data.total?.discount
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {data.total?.delivery_charges
                                      ? data.total?.delivery_charges
                                      : 0}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: { md: "30px", xs: "5px" },
                                    border: "1.5px solid black",
                                    borderRight: "0px solid black",
                                    borderLeft: "0px solid black",
                                  }}
                                >
                                  <TableRow>
                                    {" "}
                                    &#x20B9;
                                    {parseFloat(
                                      data.total?.price +
                                        data.total?.deposit_price +
                                        data.total?.discount +
                                        data.total?.delivery_charges
                                    ).toFixed(2)}
                                  </TableRow>
                                </Box>
                              </TableCell>
                              {/* Avarage Price Body Cell */}
                              <TableCell
                                align="center"
                                style={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  border: "1.4px solid black",
                                  padding: 0,
                                }}
                              >
                                {" "}
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow> --</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>
                                    {parseFloat(
                                      data.total?.price > 0
                                        ? data.total.price /
                                            data.total?.quantity
                                        : 0
                                    ).toFixed(2)}
                                  </TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>--</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>--</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>--</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box sx={{ pl: "10px" }}>
                                  <TableRow>--</TableRow>
                                </Box>
                                <Divider
                                  sx={{
                                    height: `${1}px`, // Adjust the thickness as needed
                                    borderStyle: "dashed",
                                    border: "1px solid black",
                                  }}
                                />
                                <Box
                                  sx={{
                                    pl: "10px",
                                    border: "1.5px solid black",
                                    borderLeft: "0px solid black",
                                  }}
                                >
                                  <TableRow>--</TableRow>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ color: "red" }}>
                          No Data Found
                        </Typography>
                      </Box>
                    )}
                    {/* Orders Details */}
                    <TableRow sx={{ border: "1.4px solid black" }}>
                      <Box sx={{ height: "20px" }}></Box>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#A3E4D7" }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        sx={{ border: "1.4px solid black" }}
                      >
                        Orders
                      </TableCell>
                      <TableCell sx={{ border: "1.4px solid black" }}>
                        {NewDashboardData?.orderSummary?.b2c_prepaid_orders
                          ? NewDashboardData?.orderSummary?.b2c_prepaid_orders
                          : 0}
                      </TableCell>
                      <TableCell sx={{ border: "1.4px solid black" }}>
                        {NewDashboardData?.orderSummary?.b2b_prepaid_orders
                          ? NewDashboardData?.orderSummary?.b2b_prepaid_orders
                          : 0}
                      </TableCell>
                      <TableCell sx={{ border: "1.4px solid black" }}>
                        {NewDashboardData?.orderSummary?.b2b_postpaid_orders
                          ? NewDashboardData?.orderSummary?.b2b_postpaid_orders
                          : 0}
                      </TableCell>
                      <TableCell sx={{ border: "1.4px solid black" }}>
                        {parseInt(
                          NewDashboardData?.orderSummary?.b2c_prepaid_orders +
                            NewDashboardData?.orderSummary?.b2b_prepaid_orders +
                            NewDashboardData?.orderSummary?.b2b_postpaid_orders
                        )
                          ? NewDashboardData?.orderSummary?.b2c_prepaid_orders +
                            NewDashboardData?.orderSummary?.b2b_prepaid_orders +
                            NewDashboardData?.orderSummary?.b2b_postpaid_orders
                          : 0}
                      </TableCell>
                      <TableCell sx={{ bgcolor: "#A3E4D7" }}></TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        border: "1.4px solid black",
                        padding: 0,
                        bgcolor: "#EAFAF1",
                      }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        Order Price(&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {productSummary.orderPrice
                          ? productSummary.orderPrice
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        Total Prepaid Amount (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {NewDashboardData?.orderSummary?.total_prepaid_amount
                          ? NewDashboardData?.orderSummary?.total_prepaid_amount
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        B2B Paid Amount (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {NewDashboardData?.orderSummary?.b2b_paid_amount
                          ? NewDashboardData?.orderSummary?.b2b_paid_amount
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black", color: "red" }}
                        align="center"
                        colSpan={5}
                      >
                        B2B Pending Amount (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black", color: "red" }}
                      >
                        {NewDashboardData?.orderSummary?.b2b_pending_amount
                          ? NewDashboardData?.orderSummary?.b2b_pending_amount
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        Total Deposit Amount (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {productSummary.totalDepositPrice
                          ? productSummary.totalDepositPrice
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        Total Extra Charges (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {productSummary.totalExtraCharges
                          ? productSummary.totalExtraCharges
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1.4px solid black", bgcolor: "#EAFAF1" }}
                    >
                      <TableCell
                        sx={{ border: "1.4px solid black" }}
                        align="center"
                        colSpan={5}
                      >
                        Total Price (&#x20B9;)
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        sx={{ border: "1.4px solid black" }}
                      >
                        {parseInt(
                          productSummary.totalExtraCharges +
                            productSummary.orderPrice +
                            productSummary.totalDepositPrice
                        )
                          ? productSummary.totalExtraCharges +
                            productSummary.orderPrice +
                            productSummary.totalDepositPrice
                          : 0}
                      </TableCell>
                    </TableRow>
                    {/* </TableCell> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          {/* Deposit Details */}

          <Grid item md={6} xs={12}>
            <PaymentSummaryDashboard toDate={toDate} fromDate={fromDate} />
          </Grid>
          {/* Users Summary */}
          <Grid item md={6} xs={12}>
            <Table>
              <TableContainer
                sx={{
                  border: "1.4px solid black",
                  // width: { md: "100%", xs: "320px" },
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    border: "1.4px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    USERS SUMMARY
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1.4px solid black",
                    bgcolor: "#EBF5FB ",
                    pr: "30px",
                    pl: "30px",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      B2C Users
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {NewDashboardData?.users_summary?.b2c_users
                        ? NewDashboardData?.users_summary?.b2c_users
                        : 0}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1.4px solid black",
                    bgcolor: "#EBF5FB ",
                    pr: "30px",
                    pl: "30px",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      B2B Users
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {NewDashboardData?.users_summary?.b2b_users
                        ? NewDashboardData?.users_summary?.b2b_users
                        : 0}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1.4px solid black",
                    bgcolor: "#EBF5FB ",
                    pr: "30px",
                    pl: "30px",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Today Registered Users
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {NewDashboardData?.users_summary?.today_registered_users
                        ? NewDashboardData?.users_summary
                            ?.today_registered_users
                        : 0}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1.4px solid black",
                    bgcolor: "#EBF5FB ",
                    pr: "30px",
                    pl: "30px",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Yesterday Registered Users
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {NewDashboardData?.users_summary
                        ?.yesterday_registered_users
                        ? NewDashboardData?.users_summary
                            ?.yesterday_registered_users
                        : 0}
                    </Typography>
                  </Box>
                </Box>
              </TableContainer>
              {/* Wallet Summary */}
              <TableContainer
                sx={{ border: "2px solid black", borderTop: "0px solid black" }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    borderBottom: "1.4px solid black",
                    // borderTop: "0px solid black",
                    bgcolor: "#AED6F1",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    WALLET SUMMARY
                  </Typography>
                </Box>
                <TableRow sx={{ bgcolor: "#F7F9F9" }}>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      borderRight: "1.4px solid black",
                      // borderBottom: "0px solid black",
                      // borderLeft: "0px solid black",
                    }}
                  >
                    Current Wallet Balance
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      minWidth: "200px",
                    }}
                  >
                    {NewDashboardData?.wallet_balance
                      ? NewDashboardData?.wallet_balance
                      : 0}
                  </TableCell>
                </TableRow>
              </TableContainer>
            </Table>
          </Grid>
          <Grid item md={12} xs={12} sx={{ ml: "10px", mr: "10px" }}>
            <TableContainer sx={{ border: "1.4px solid black" }}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  border: "1.4px solid black",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  CURRENT DEPOSITS
                </Typography>
              </Box>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#D0B4DA" }}>
                    <TableCell
                      sx={{ borderLeft: "1.4px solid black" }}
                    ></TableCell>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{
                        padding: 0,
                        border: "1.4px solid black",
                        fontWeight: "bold",
                        borderTop: "0px solid black",
                      }}
                    >
                      Paid
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{
                        padding: 0,
                        border: "1.4px solid black",
                        fontWeight: "bold",
                        borderTop: "0px solid black",
                      }}
                    >
                      Free
                    </TableCell>
                    <TableCell
                      sx={{ borderRight: "1.4px solid black" }}
                    ></TableCell>
                    {/* <TableCell
                      sx={{ borderRight: "1.4px solid black" }}
                    ></TableCell> */}
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#D0B4DA" }}>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        borderLeft: "1.4px solid black",
                        paddingBottom: "10px",
                      }}
                    >
                      Products
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        border: "1.4px solid black",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        border: "1.4px solid black",
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        border: "1.4px solid black",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        border: "1.4px solid black",
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        // border: "1.4px solid black",
                        borderRight: "1.4px solid black",
                      }}
                    >
                      Physical Quantity
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "20px",
                        // paddingRight: "20px",
                        // border: "1.4px solid black",
                        borderRight: "1.4px solid black",
                        paddingBottom: "10px",
                      }}
                    >
                      Total Quantity
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        padding: 0,
                        // paddingLeft: "20px",
                        // paddingRight: "20px",
                        borderRight: "1.4px solid black",
                        paddingBottom: "10px",
                      }}
                    >
                      Total Price
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {NewDashboardData?.deposits_summary !== undefined &&
                  NewDashboardData?.deposits_summary !== null &&
                  NewDashboardData?.deposits_summary.length > 0 ? (
                    <>
                      {NewDashboardData?.deposits_summary?.map((data) => {
                        return (
                          <TableRow sx={{ bgcolor: "#F2E4F7" }}>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                border: "1.4px solid black",
                              }}
                            >
                              {data.container_capacity +
                                "-" +
                                data.container_material}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(data.paid_quantity)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(data.paid_amount)}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(data.unpaid_quantity)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(data.unpaid_amount)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(data.physical_quantity)}
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "20px",
                                // paddingRight: "20px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseInt(
                                data.paid_quantity + data.unpaid_quantity
                              )
                                ? parseInt(data.paid_quantity) +
                                  parseInt(data.unpaid_quantity)
                                : 0}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                padding: 0,
                                // paddingLeft: "20px",
                                // paddingRight: "20px",
                                border: "1.4px solid black",
                              }}
                            >
                              {parseFloat(
                                parseFloat(data.paid_amount) +
                                  parseFloat(data.unpaid_amount)
                              ).toFixed(2)}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                      <TableRow sx={{ bgcolor: "#D0B4DA" }}>
                        <TableCell
                          sx={{
                            borderLeft: "1.4px solid black",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          Total
                        </TableCell>
                        <TableCell
                          // colSpan={2}
                          align="center"
                          sx={{
                            padding: 0,
                            border: "1.4px solid black",
                            fontWeight: "bold",
                            borderBottom: "0px solid black",
                          }}
                        >
                          --
                        </TableCell>
                        <TableCell
                          // colSpan={2}
                          align="center"
                          sx={{
                            padding: 0,
                            border: "1.4px solid black",
                            fontWeight: "bold",
                            borderTop: "0px solid black",
                            borderBottom: "0px solid black",
                          }}
                        >
                          &#x20B9;
                          {parseFloat(
                            depositsPrice.depositsPaidTotalPrice
                          ).toFixed(2) || 0}
                        </TableCell>
                        <TableCell
                          sx={{ borderRight: "1.4px solid black" }}
                          align="center"
                        >
                          --
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1.4px solid black",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          &#x20B9;{" "}
                          {parseFloat(
                            depositsPrice.depositsFreeTotalPrice
                          ).toFixed(2) || 0}
                        </TableCell>
                        <TableCell
                          sx={{ borderRight: "1.4px solid black" }}
                          align="center"
                        >
                          --
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography sx={{ color: "red" }}>
                        No Data Found
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Paper>

      {openDateRangeFilterDialog && (
        <DateRangeFilterDialog
          openDateRangeFilterDialog={openDateRangeFilterDialog}
          handleCloseDateRange={() => setOpenDateRangeFilterDialog(false)}
          handleGetDateRange={handleGetDateRange}
          setToDate={setToDate}
          setFromDate={setFromDate}
          toDate={toDate}
          fromDate={fromDate}
        />
      )}
    </Box>
  );
}

export default SalesSummaryDashboardNew;
