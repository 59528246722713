import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CreateWfsBatchSku } from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import { toast } from "react-toastify";
import CustomConsole from "../../CustomConsole";

const WfsCreateBatchDialog = ({
  open,
  onClose,
  type,
  handelUpdateBatch,
  quantity,
  setQuantity,
}) => {
  const wfsStockDetails = useSelector((state) => state.userDetailsUpdate);
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [batchDetails, setBatchDetails] = React.useState({
    name: "",
    material: "PET",
    capacity: "20_Liters",
    quantity: "",
    line_id: "",
    wfs_id: wfsStockDetails.assignedWfsAddress.wfs_id,
  });
  //function for handling input changes
  const handleInputChange = (e) => {
    let { name, value, type, checked } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setBatchDetails((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //function for handling input changes
  const handleLineInputChange = (e) => {
    let { name, value, type, checked } = e.target;
    setBatchDetails((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //function for handling product selection change
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.name === event.target.value
    );
    if (selectedDetails) {
      setBatchDetails((prev) => ({
        ...prev,
        name: selectedDetails.product_details.name,
        material: selectedDetails.product_details.material,
        capacity: selectedDetails.product_details.capacity,
      }));
    }
  };

  //function for handling onsubmit
  const handleSubmit = () => {
    if (type === "add") {
      dispatch(CreateWfsBatchSku(batchDetails));
      onClose();
    } else {
      if (quantity > 0) {
        handelUpdateBatch(quantity);
        onClose();
      } else {
        toast.error("Quantity cannot be zero", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  // Function for handling input change
  const handleEditInputChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setQuantity(inputValue);
    }
  };

  CustomConsole(wfsBatchDetails);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Batch Details</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2} sx={{ paddingTop: "0.5rem" }}>
          {type === "add" && (
            <>
              <Grid item xs={12}>
                <FormControl sx={{ minWidth: "100%" }} size="small">
                  <InputLabel id="demo-select-small">Product*</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={batchDetails.name}
                    label="Capacity"
                    onChange={handleChangeSelectedProduct}
                  >
                    {skuContainerDetails.getAvailableProducts.map((data) => (
                      <MenuItem
                        value={data.product_details.name}
                        key={data.product_details.id}
                      >
                        <div>
                          <span>
                            {" "}
                            <strong>{data.product_details.name}</strong>
                          </span>
                          <br />
                          <small>
                            Capacity:
                            {data.product_details.capacity}/
                          </small>
                          <small>
                            Material:
                            {data.product_details.material}
                          </small>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {wfsBatchDetails?.lineDetailsError === false ? (
                  <TextField
                    select
                    size="small"
                    label="Select Line ID"
                    sx={{ width: "100%" }}
                    name="line_id"
                    helperText="Please select your line ID"
                    onChange={handleLineInputChange}
                  >
                    {wfsBatchDetails?.lineBatchDetails?.map((option) => (
                      <MenuItem value={option.line_id}>
                        {option.line_id}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  size="small"
                  value={batchDetails.quantity}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
          {type === "edit" && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  size="small"
                  value={quantity}
                  onChange={handleEditInputChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={onClose}
            color="error"
            variant="contained"
            size="small"
          >
            close
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            {type} Batch
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WfsCreateBatchDialog;
