import React from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetWithdrawallistOrderid } from "../Redux/Actions/AdminWithdrawalListAction";
import CustomConsole from "../../CustomConsole";
import { returnCanOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import SupplierOrdersEnterOtpDialog from "../../Components/DialoBoxes/SupplierOrdersEnterOtpDialog";
import SupplierQRorCanDamageDialog from "../../Components/DialoBoxes/SupplierQRorCanDamageDialog";
import ReturnCanCameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanCameraDialog";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import SupplierReturnCanFilter from "../../Components/DialogBoxes/SupplierDialogBox/SupplierReturnCanFilter";
import {
  GetSupplierDeliveryAgentDetails,
  GetSupplierReturnCanOrders,
  InscanQRBarcode,
  PostSupplierAssignDeliveryAgentReturnOrReplacement,
  PutLinkInScanQrBarCode,
  PutSupplierReturnCanVerifyOtp,
  PutSupplierReturnInScanCans,
  SubmitReturnInScanAction,
} from "../Redux/Actions/supliersAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ArrowBack,
  Call,
  CheckCircle,
  DocumentScanner,
  HighlightOff,
  LocalShipping,
  Place,
  Refresh,
} from "@mui/icons-material";
import ReturnCanCameraCanSwapScanIvalidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanCameraCanSwapScanIvalidCansDialog";
import ReturnCanApprovedRejectedCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanApprovedRejectedCanSwapDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { toast } from "react-toastify";
import moment from "moment";

function ReturnCanSingleOrder() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  CustomConsole(orderId);
  const adminWithdrawallistData = useSelector(
    (state) => state.withdrawalOrderlistAdmin
  );
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);
  CustomConsole(adminWithdrawallistData);
  const [withdrawalOrderDetails, setWithdrawalOrderDetails] = React.useState(
    []
  );
  const [returnOrderDetails, setReturnOrderDetails] = React.useState([]);

  const [returnCanOrderId, setReturnCanOrderId] = React.useState("");

  const [openQrorCanDamageDialog, setOpenQrorCanDamageDialog] =
    React.useState(false);
  const [damageOrFineCans, setDamageOrFineCans] = React.useState("IsDamages");
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);

  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [link, setLink] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [returnInOutScanBtn, setReturnInOutScanBtn] = React.useState(false);
  const [returnSubmitOtpBtn, setReturnSubmitOtpBtnBtn] = React.useState(false);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [returnOrderId, setReturnOrderId] = React.useState("");
  const [selectCanFilledOrNot, setSelectCanFilledOrNot] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [scanCount, setScanCount] = React.useState(0);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [openCamera, setOpenCamera] = React.useState(false);
  const [apiData, setApiData] = React.useState({});
  const [openReturnCanCameraDialog, setOpenReturnCanCameraDialog] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageBackWard, setPageBackWard] = React.useState(false);
  const [pageForward, setPageForward] = React.useState(false);
  const [pageStatuss, setPageStatuss] = React.useState(false);
  const [openReturnCanFilter, setOpenReturnCanFilter] = React.useState(false);
  const [returnCanView, setReturnCanView] = React.useState("form");
  const [pageSize, setPageSize] = React.useState(25);
  const [deliveryAgent, setDeliveryAgent] = React.useState("");
  const [returnCanOrderData, setReturnCanOrderData] = React.useState([]);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [enterOtpOpenDialog, setEnterOtpOpenDialog] = React.useState(false);
  const [physicalDamageCheckBox, setPhysicalDamageCheckBox] =
    React.useState(true);
  const [physicalDamageQty, setPhysicalDamageQty] = React.useState(0);
  const [qrBarcodeDamageCheckBox, setQrBarcodeDamageCheckBox] =
    React.useState(true);
  const [qrBarcodeDamageQty, setQrBarcodeDamageQty] = React.useState(0);
  const [retunReceivingReq, setRetunReceivingReq] = React.useState({
    empty: 0,
    filled: 0,
    filled_expired: 0,
  });
  const [userDeliverLocation, setUserDeliverLocation] = React.useState({
    latitude: "",
    longitude: "",
  });

  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);

  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [
    openReturnApprovedRejectedDialog,
    setOpenReturnApprovedRejectedDialog,
  ] = React.useState(false);
  const [adminRole, setAdminRoleId] = React.useState("");
  const [returnAddress, setReturnAddress] = React.useState([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  CustomConsole(withdrawalOrderDetails.return_can_order_id);
  React.useEffect(() => {
    dispatch(GetWithdrawallistOrderid(orderId));
  }, []);
  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  React.useEffect(() => {
    // CustomConsole(adminWithdrawallistData)
    if (Object.keys(adminWithdrawallistData.withdrawalOrderid).length > 0) {
      adminWithdrawallistData.withdrawalOrderid.map((data) => {
        CustomConsole(data);
        setWithdrawalOrderDetails(data);
      });
    } else {
      setWithdrawalOrderDetails([]);
    }
  }, [adminWithdrawallistData]);

  const orderStatusFun = (order_status) => {
    CustomConsole(order_status);
    switch (order_status) {
      case returnCanOrderStatus.CONSUMER_ACCEPTED:
        return "CONSUMER ACCEPTED";
      case returnCanOrderStatus.APPROVED:
        return "APPROVED";
      case returnCanOrderStatus.REFUND:
        return "REFUND";
      case returnCanOrderStatus.CONSUMER_REJECTED:
        return "CONSUMER REJECTED";
      case returnCanOrderStatus.DELIVERED:
        return "DELIVERED";
      case returnCanOrderStatus.REJECTED:
        return "REJECTED";
      case returnCanOrderStatus.PENDING:
        return "PENDING";
      default:
        return "";
    }
  };
  const handleButtonData = (props) => {
    CustomConsole("_______handleButtonData_______");

    if (
      withdrawalOrderDetails.is_submit_started === false &&
      withdrawalOrderDetails.is_submit_finished === false
    ) {
      CustomConsole("------EnterOtp------------");
      setEnterOtpOpenDialog(true);
    }
    // else if (
    //   withdrawalOrderDetails.is_submit_started === true &&
    //   withdrawalOrderDetails.is_submit_finished === false
    // ) {
    //   CustomConsole("------Inscan------------");
    //   setOpenQrorCanDamageDialog(true);
    //   // setOpenCamera(true);
    // }
  };
  React.useEffect(() => {}, []);
  React.useEffect(() => {
    setUserDeliverLocation({
      latitude: withdrawalOrderDetails.latitude,
      longitude: withdrawalOrderDetails.longitude,
    });
    const OrderDetails = [
      {
        type: "Consumer Name",
        payload: withdrawalOrderDetails.name,
      },
      {
        type: "Contact Phone",
        payload: withdrawalOrderDetails.contact_phone,
      },
      { type: "Order Id", payload: withdrawalOrderDetails.return_can_order_id },
      {
        type: "Container Quantity",
        payload: withdrawalOrderDetails.container_qty,
      },
      {
        type: "Product",
        payload:
          withdrawalOrderDetails.container_capacity +
          "-" +
          withdrawalOrderDetails.container_material,
      },
      {
        type: "Status",
        payload: orderStatusFun(withdrawalOrderDetails.order_status),
      },
      {
        type: "Booking At",
        payload: moment
          .utc(withdrawalOrderDetails.created_at)
          .tz("Asia/Kolkata")
          .add(5, "hours")
          .add(30, "minutes")
          .format("DD-MM-YYYY hh:mm"),
      },
      { type: "Refund Amount", payload: withdrawalOrderDetails.refund_amount },
      {
        type: "Reject Reason",
        payload:
          withdrawalOrderDetails.reject_reason !== ""
            ? withdrawalOrderDetails.reject_reason
            : "--",
      },
      {
        type: "Delivery Agent Name",
        payload:
          withdrawalOrderDetails.drivers_name !== ""
            ? withdrawalOrderDetails.drivers_name
            : "--",
      },
      {
        type: "Delivery Agent Phone",
        payload:
          withdrawalOrderDetails.drivers_phone !== ""
            ? withdrawalOrderDetails.drivers_phone
            : "--",
      },
    ];
    const ConsumerAddress = [
      {
        type: "Line 1",
        payload:
          withdrawalOrderDetails.consumer_address !== undefined &&
          withdrawalOrderDetails.consumer_address !== null &&
          withdrawalOrderDetails.consumer_address.address_line1 != undefined &&
          withdrawalOrderDetails.consumer_address.address_line1 != ""
            ? withdrawalOrderDetails.consumer_address.address_line1
            : "--",
      },
      {
        type: "Line 2",
        payload:
          withdrawalOrderDetails.consumer_address !== undefined &&
          withdrawalOrderDetails.consumer_address !== null &&
          withdrawalOrderDetails.consumer_address.address_line2 != undefined &&
          withdrawalOrderDetails.consumer_address.address_line2 != ""
            ? withdrawalOrderDetails.consumer_address.address_line2
            : "--",
      },
      {
        type: "Service Area",
        payload:
          withdrawalOrderDetails.consumer_address !== undefined &&
          withdrawalOrderDetails.consumer_address !== null &&
          withdrawalOrderDetails.consumer_address.service_area != undefined &&
          withdrawalOrderDetails.consumer_address.service_area != ""
            ? withdrawalOrderDetails.consumer_address.service_area
            : "--",
      },
      {
        type: "Pincode",
        payload:
          withdrawalOrderDetails.consumer_address !== undefined &&
          withdrawalOrderDetails.consumer_address !== null &&
          withdrawalOrderDetails.consumer_address.pincode != undefined &&
          withdrawalOrderDetails.consumer_address.pincode != ""
            ? withdrawalOrderDetails.consumer_address.pincode
            : "--",
      },
    ];
    // const RetunCanData = [
    //   { type: "Order Id", payload: withdrawalOrderDetails.return_can_order_id },
    //   { type: "Consumer Name", payload: withdrawalOrderDetails.name },
    //   {
    //     type: "Consumer Number",
    //     payload: withdrawalOrderDetails.consumer_phone
    //       ? withdrawalOrderDetails.consumer_phone
    //       : "--",
    //   },
    //   {
    //     type: "Status",
    //     payload: orderStatusFun(withdrawalOrderDetails.order_status),
    //   },
    //   {
    //     type: "Container Quantity",
    //     payload: withdrawalOrderDetails.container_qty,
    //   },
    //   //   {
    //   //     type: props.isConsumer === true ? "Supplier Name" : "WFS Name",
    //   //     payload: withdrawalOrderDetails.supplier_name,
    //   //   },

    //   {
    //     type: "Service Area",
    //     payload:
    //       withdrawalOrderDetails.consumer_address !== undefined &&
    //       withdrawalOrderDetails.consumer_address !== null &&
    //       withdrawalOrderDetails.consumer_address.service_area != undefined
    //         ? withdrawalOrderDetails.consumer_address.service_area
    //         : "--",
    //   },
    //   {
    //     type: "Pincode",
    //     payload:
    //       withdrawalOrderDetails.consumer_address !== undefined &&
    //       withdrawalOrderDetails.consumer_address !== null &&
    //       withdrawalOrderDetails.consumer_address.pincode != undefined
    //         ? withdrawalOrderDetails.consumer_address.pincode
    //         : "--",
    //   },
    //   {
    //     type: "Address",
    //     payload:
    //       withdrawalOrderDetails.consumer_address !== undefined &&
    //       withdrawalOrderDetails.consumer_address !== null &&
    //       withdrawalOrderDetails.consumer_address.address_line1 != undefined &&
    //       withdrawalOrderDetails.consumer_address.address_line2
    //         ? withdrawalOrderDetails.consumer_address.address_line1 +
    //           "," +
    //           withdrawalOrderDetails.consumer_address.address_line2
    //         : "--",
    //   },

    //   { type: "Capacity", payload: withdrawalOrderDetails.container_capacity },
    //   { type: "Material", payload: withdrawalOrderDetails.container_material },
    //   { type: "Created At", payload: withdrawalOrderDetails.created_at },
    //   { type: "Updated At", payload: withdrawalOrderDetails.updated_at },
    //   { type: "Refund Amount", payload: withdrawalOrderDetails.refund_amount },
    //   {
    //     type: "Reject Reason",
    //     payload:
    //       withdrawalOrderDetails.reject_reason !== ""
    //         ? withdrawalOrderDetails.reject_reason
    //         : "--",
    //   },
    //   {
    //     type: "Deposited Valid Cans",
    //     payload: withdrawalOrderDetails.deposited_valid_cans,
    //   },

    //   {
    //     type: "UnDeposited Valid Cans",
    //     payload: withdrawalOrderDetails.undeposited_valid_cans,
    //   },
    //   {
    //     type: "Physically Damage Quantity",
    //     payload: withdrawalOrderDetails.physically_damage_can_qty,
    //   },
    //   {
    //     type: "Qr Bar Code Damage Can Quantity",
    //     payload: withdrawalOrderDetails.qr_bar_code_damage_can_qty,
    //   },
    //   {
    //     type: "Delivery Agent Name",
    //     payload:
    //       withdrawalOrderDetails.drivers_name !== ""
    //         ? withdrawalOrderDetails.drivers_name
    //         : "--",
    //   },
    //   {
    //     type: "Delivery Agent Phone",
    //     payload:
    //       withdrawalOrderDetails.drivers_phone !== ""
    //         ? withdrawalOrderDetails.drivers_phone
    //         : "--",
    //   },
    // ];
    // CustomConsole(RetunCanData);
    setReturnOrderDetails(OrderDetails);
    setReturnAddress(ConsumerAddress);
    // setReturnCanOrderData(RetunCanData);
  }, [withdrawalOrderDetails]);
  React.useEffect(() => {
    dispatch(
      GetQrBarcodeSwapList({
        status: 200,
        page_number: 1,
        page_size: 100,
        order_id: withdrawalOrderDetails.return_can_order_id,
      })
    );
  }, []);
  React.useEffect(() => {}, [physicalDamageCheckBox]);
  const handleSubmitOtp = (props) => {
    CustomConsole("---handleReturnSubmitotp--");
    CustomConsole(props);
    CustomConsole(returnCanOrderId);
    setEnterOtpOpenDialog(false);
    const reqObj = {
      return_can_otp: enterOtpTextfied,
    };

    CustomConsole(reqObj);
    dispatch(
      PutSupplierReturnCanVerifyOtp(
        withdrawalOrderDetails.return_can_order_id,
        reqObj
      )
    );
    setReturnSubmitOtpBtnBtn(true);
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(orderId));
    }, 500);
  };
  const handleCloseDialog = () => {
    CustomConsole("---- handleCloseDialog -----");
    setEnterOtpOpenDialog(false);
  };
  const handleSubmitQrorCanDamage = (props) => {
    CustomConsole("---- handleSubmitQrorCanDamage -----");
    CustomConsole(props);
    setApiData(props);
    CustomConsole(returnCanOrderId);
    CustomConsole(scannedQrCodeDetails);
    // setOpenQrorCanDamageDialog(false);
    // setOpenCamera(true);
    // setOpenReturnCanCameraDialog(true);
    if (
      props.damage_type === 101 ||
      props.damage_type === 102 ||
      props.damage_type === 103 ||
      props.is_can_damaged === false
    ) {
      setOpenCamera(true);
      setOpenReturnCanCameraDialog(true);
      setOpenQrorCanDamageDialog(false);
    } else if (props.damage_type === 104 || props.damage_type === 105) {
      CustomConsole("---- 104 & 105 -----");
      const reqParams = {
        damage_type: props.damage_type,
        is_damaged: props.is_can_damaged,
        is_filled_can: props.is_can_filled,
        order_id: withdrawalOrderDetails.return_can_order_id,
        qr_barcode_id2: "",
      };
      CustomConsole(reqParams);
      dispatch(PutSupplierReturnInScanCans(reqParams));
      // setReturnInOutScanBtn(true);
      setOpenQrorCanDamageDialog(false);
      setTimeout(() => {
        dispatch(GetWithdrawallistOrderid(orderId));
      }, 300);
    }
  };
  const handleCloseQrorCanDamage = (props) => {
    CustomConsole("---- handleCloseQrorCanDamage -----");
    CustomConsole(props);
    setOpenQrorCanDamageDialog(false);
  };
  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenReturnCanCameraDialog(false);
  };
  const refreshOrderDetails = () => {
    // dispatch(GetWithdrawallistOrderid(orderId));
    window.location.reload(true);
  };
  const handleBack = () => {
    CustomConsole("handleBack");
    // if (ADMIN_ROLES.BW_DISTRIBUTER === adminRole) {
    //   const url = `/dashboard/orders`;
    //   history.push(url);
    // } else if (ADMIN_ROLES.BW_DELIVERYAGENT === adminRole) {
    //   const url = `/dashboard/deliveries`;
    //   history.push(url);
    // }
    window.history.back();
  };
  const handleOpenAssignDeliveryAgentDialog = (props) => {
    CustomConsole(props);
    CustomConsole("---handleOpenAssign-------");
    dispatch(GetSupplierDeliveryAgentDetails());
    setReturnOrderId(props);
    setOpenAssignDeliveryAgentDialog(true);
    // CustomConsole(replacementOrderId);
  };
  const handleCloses = () => {
    setOpenAssignDeliveryAgentDialog(false);
  };
  const handleAssignDeliveryAgent = (props) => {
    CustomConsole(props);
    const reqObj = {
      delivery_agent_id: props.driver_id,
      order_id: withdrawalOrderDetails.return_can_order_id,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierAssignDeliveryAgentReturnOrReplacement(reqObj));
    setOpenAssignDeliveryAgentDialog(false);
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(orderId));
    }, 500);
  };

  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };

  const handleScanInValidCans = () => {
    setOpenScanInValidCsnsDialog(true);
  };

  const handleReturnApprovedOrRejectedCans = () => {
    setOpenApprovedRejectedCans(true);
  };

  const handleCloseReturnApprovedRejectedDialog = () => {
    setOpenReturnApprovedRejectedDialog(false);
  };
  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
  };
  const handleLinkEmptyCans = (props) => {
    CustomConsole("------------handleLinkEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedEmpty,
      order_id: withdrawalOrderDetails.return_can_order_id,
    };
    dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedEmptyCans = (props) => {
    CustomConsole("------------handleLinkApprovedEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedFilledids,
      order_id: withdrawalOrderDetails.return_can_order_id,
    };
    dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    const approvedEmpty = props.approved_empty.join(" ");
    CustomConsole(approvedEmpty);
    for (const data of props.approved_empty) {
      CustomConsole(data);
      dispatch(
        PutSupplierReturnInScanCans({
          damage_type: apiData.damage_type || undefined,
          is_damaged: apiData.is_can_damaged,
          is_filled_can: apiData.is_can_filled,
          order_id: withdrawalOrderDetails.return_can_order_id,
          qr_barcode_id1: data,
          qr_barcode_id2: "",
        })
      );
    }
    setTimeout(() => {
      dispatch(GetWithdrawallistOrderid(orderId));
    }, 1000);

    //@ToDo: Check the API for Linking the filled cans and proceed further
  };
  const handleSubmitReturn = () => {
    const reqObj = {
      // order_id: withdrawalOrderDetails.return_can_order_id,
      empty_qty: parseInt(retunReceivingReq.empty),
      // filled_qty: parseInt(retunReceivingReq.filled),
      expired_qty: parseInt(retunReceivingReq.filled_expired),
      physically_damaged: {
        recieved: physicalDamageCheckBox,
        qty: parseInt(physicalDamageQty),
      },
      qr_barcode_damaged: {
        recieved: qrBarcodeDamageCheckBox,
        qty: parseInt(qrBarcodeDamageQty),
      },
    };
    CustomConsole("---reqObj---------");
    CustomConsole(reqObj);
    const totalSubmitting =
      reqObj.empty_qty +
      // reqObj.filled_qty +
      reqObj.expired_qty +
      physicalDamageQty +
      qrBarcodeDamageQty;
    CustomConsole(totalSubmitting);

    if (totalSubmitting === withdrawalOrderDetails.container_qty) {
      dispatch(
        SubmitReturnInScanAction(
          withdrawalOrderDetails.return_can_order_id,
          reqObj
        )
      );
      setTimeout(() => {
        dispatch(GetWithdrawallistOrderid(orderId));
      }, 1000);
    } else {
      toast.error(
        `Ordered Quantity And Returning Quantity is Mismatching! Or Can't Submit Less Than Zero Every Field `,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
  const handlePhysicalDamageChecked = (event) => {
    CustomConsole("----checked-----PD------");
    CustomConsole(event.target.checked);
    setPhysicalDamageCheckBox(event.target.checked);
  };
  const handleQrBarcodeDamageChecked = (event) => {
    CustomConsole("----checked-----QrBarCode------");
    CustomConsole(event.target.checked);
    setQrBarcodeDamageCheckBox(event.target.checked);
  };
  const handleGMapButton = () => {
    CustomConsole(userDeliverLocation);
    if (
      userDeliverLocation.latitude !== undefined &&
      userDeliverLocation.latitude !== null &&
      userDeliverLocation.longitude !== undefined &&
      userDeliverLocation.longitude !== null &&
      parseFloat(userDeliverLocation.latitude) > 0 &&
      parseFloat(userDeliverLocation.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${userDeliverLocation.latitude},${userDeliverLocation.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.contact_phone !== "") {
      CustomConsole(props.contact_phone);
      const telUrl = `tel:${props.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <Box sx={{ marginTop: "50px", ml: "5px" }}>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          xs={12}
          component={Paper}
          elevation={20}
          // sx={{ pr: "10px", pt: "10px" }}
        >
          <Box>
            {/* ORDER DETAILS */}
            <Box
              sx={{
                bgcolor: "#D6EAF8",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleBack}
                  sx={{
                    // backgroundColor: "#34f7b3",
                    color: "black",
                    borderRadius: "20px",
                  }}
                >
                  <ArrowBack />
                </Button>
              </Box>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px", mt: "3px" }}
              >
                ORDER DETAILS
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={refreshOrderDetails}
                  sx={{
                    // backgroundColor: "#d8f59f",
                    color: "black",
                    borderRadius: "100px",
                  }}
                >
                  <Refresh />
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handlePhone(withdrawalOrderDetails)}
                  sx={{
                    color: "white",
                    borderRadius: "50px",
                    bgcolor: "#239B56",
                  }}
                >
                  <Call />
                </Button>
              </Box>
            </Box>

            <Divider
              sx={{
                height: `${1}px`, // Adjust the thickness as needed
                borderStyle: "dashed",
                border: "1px solid black",
              }}
            />
            <Box sx={{ bgcolor: "#F4F6F7", p: "10px" }}>
              {returnOrderDetails.map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "start",
                        color: "#717D7E",
                        fontWeight: "bold",
                      }}
                    >
                      {data.type}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        textAlign: "end",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {data.payload}
                    </Typography>
                  </Box>
                );
              })}
              <Box>
                {/* CONSUMER ADDRESS */}
                <Box
                  sx={{
                    bgcolor: "#D6EAF8",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Box>
                    <Button
                      disabled={
                        parseInt(withdrawalOrderDetails.order_status) !== 200 ||
                        ADMIN_ROLES.BW_DELIVERYAGENT === adminRole
                      }
                      variant="outlined"
                      size="small"
                      onClick={handleOpenAssignDeliveryAgentDialog}
                      sx={{
                        // backgroundColor: "#34f7b3",
                        color: "black",
                        borderRadius: "20px",
                      }}
                    >
                      Assign
                      <LocalShipping />
                    </Button>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      mt: "3px",
                      mr: "40px",
                    }}
                  >
                    ADDRESS
                  </Typography>
                  <Box>
                    <Button
                      disabled={
                        parseInt(withdrawalOrderDetails.order_status) !== 200
                      }
                      variant="outlined"
                      size="small"
                      onClick={handleGMapButton}
                      sx={{
                        color: "blue",
                        borderRadius: "50px",
                      }}
                    >
                      <Place sx={{ color: "red" }} /> Maps
                    </Button>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: "#F4F6F7",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>
                    {withdrawalOrderDetails.consumer_address !== undefined &&
                    withdrawalOrderDetails.consumer_address !== null &&
                    withdrawalOrderDetails.consumer_address.address_line1 !=
                      undefined &&
                    withdrawalOrderDetails.consumer_address.address_line1 != ""
                      ? withdrawalOrderDetails.consumer_address.address_line1
                      : "--"}
                    ,
                  </Typography>
                  <Typography>
                    {withdrawalOrderDetails.consumer_address !== undefined &&
                    withdrawalOrderDetails.consumer_address !== null &&
                    withdrawalOrderDetails.consumer_address.address_line2 !=
                      undefined &&
                    withdrawalOrderDetails.consumer_address.address_line2 != ""
                      ? withdrawalOrderDetails.consumer_address.address_line2
                      : "--"}
                    ,
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      {withdrawalOrderDetails.consumer_address !== undefined &&
                      withdrawalOrderDetails.consumer_address !== null &&
                      withdrawalOrderDetails.consumer_address.service_area !=
                        undefined &&
                      withdrawalOrderDetails.consumer_address.service_area != ""
                        ? withdrawalOrderDetails.consumer_address.service_area
                        : "--"}{" "}
                      -
                    </Typography>
                    <Typography>
                      {withdrawalOrderDetails.consumer_address !== undefined &&
                      withdrawalOrderDetails.consumer_address !== null &&
                      withdrawalOrderDetails.consumer_address.pincode !=
                        undefined &&
                      withdrawalOrderDetails.consumer_address.pincode != ""
                        ? withdrawalOrderDetails.consumer_address.pincode
                        : "--"}
                    </Typography>
                  </Box>
                </Box>
                {withdrawalOrderDetails.is_submit_started === false &&
                withdrawalOrderDetails.is_submit_finished === false ? (
                  <Button
                    variant="contained"
                    onClick={handleButtonData}
                    sx={{
                      background: appColors.commonBtnColor.background,
                      color: "white",
                      borderRadius: "10px",
                      margin: "10px",
                    }}
                  >
                    ENTER OTP
                  </Button>
                ) : (
                  ""
                )}

                {/* RECEIVING FROM CONSUMER CANS  */}
                {withdrawalOrderDetails.is_submit_started === true &&
                withdrawalOrderDetails.is_submit_finished === false &&
                parseInt(withdrawalOrderDetails.order_status) === 200 ? (
                  <Box>
                    <Box sx={{ bgcolor: "#D6EAF8" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        RECEIVING DETAILS
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        height: `${1}px`, // Adjust the thickness as needed
                        borderStyle: "dashed",
                        border: "1px solid black",
                      }}
                    />
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ m: 1, color: "red" }}>Note:</Typography>
                      <Checkbox defaultChecked disabled />
                      <Typography sx={{ m: 1 }}>Receiving</Typography>
                    </Box>
                    {/* Empty RECEIVING */}
                    <Box
                      sx={{
                        bgcolor: "#F4F6F7",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: { md: "row", xs: "column" },
                        // justifyContent: "space-around",
                        pt: "10px",
                        gap: { xs: "10px", md: "20px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Empty : -&nbsp;&nbsp;
                        </Typography>
                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              empty: parseInt(retunReceivingReq.empty + 1),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              empty: parseInt(
                                retunReceivingReq.empty > 0
                                  ? retunReceivingReq.empty - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              empty:
                                event.target.value >= 0 &&
                                event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.empty),
                            }))
                          }
                          inputValue={parseInt(retunReceivingReq.empty)}
                          // disabled={false}
                        />
                      </Box>
                      {/* Filled RECEIVING */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Filled : -&nbsp;&nbsp;
                        </Typography>
                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled: parseInt(retunReceivingReq.filled + 1),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled: parseInt(
                                retunReceivingReq.filled > 0
                                  ? retunReceivingReq.filled - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled: parseInt(
                                event.target.value >= 0 &&
                                  event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.filled)
                              ),
                            }))
                          }
                          inputValue={retunReceivingReq.filled}
                          // disabled={false}
                        />
                      </Box> */}
                      {/* Filled Expired RECEIVING */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Expired :-&nbsp;&nbsp;
                        </Typography>

                        <OrderButtonSku
                          handelAddQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled_expired: parseInt(
                                retunReceivingReq.filled_expired + 1
                              ),
                            }))
                          }
                          handelMinusQuantity={() =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled_expired: parseInt(
                                retunReceivingReq.filled_expired > 0
                                  ? retunReceivingReq.filled_expired - 1
                                  : 0
                              ),
                            }))
                          }
                          handelQuantityChange={(event) =>
                            setRetunReceivingReq((prev) => ({
                              ...prev,
                              filled_expired: parseInt(
                                event.target.value >= 0 &&
                                  event.target.value <= 999
                                  ? parseInt(event.target.value)
                                  : parseInt(prev.filled_expired)
                              ),
                            }))
                          }
                          inputValue={retunReceivingReq.filled_expired}
                          // disabled={false}
                        />
                      </Box>
                      {/* Qr Barcode Damage RECEIVING */}
                      {withdrawalOrderDetails.is_qr === true ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography sx={{ textAlign: "start" }}>
                            Qr Barcode Damage &nbsp;&nbsp;
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <Checkbox
                              defaultChecked
                              value={qrBarcodeDamageCheckBox}
                              onChange={handleQrBarcodeDamageChecked}
                              {...label}
                            />
                            <OrderButtonSku
                              handelAddQuantity={() =>
                                setQrBarcodeDamageQty(
                                  parseInt(qrBarcodeDamageQty + 1)
                                )
                              }
                              handelMinusQuantity={() =>
                                setQrBarcodeDamageQty(
                                  parseInt(
                                    qrBarcodeDamageQty > 0
                                      ? qrBarcodeDamageQty - 1
                                      : 0
                                  )
                                )
                              }
                              handelQuantityChange={(event) =>
                                setQrBarcodeDamageQty(
                                  parseInt(
                                    event.target.value >= 0 &&
                                      event.target.value <= 999
                                      ? parseInt(event.target.value)
                                      : parseInt(qrBarcodeDamageQty)
                                  )
                                )
                              }
                              inputValue={qrBarcodeDamageQty}
                              // disabled={false}
                            />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ textAlign: "start" }}>
                          Physical Damage&nbsp;&nbsp;
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Checkbox
                            defaultChecked
                            value={physicalDamageCheckBox}
                            onChange={handlePhysicalDamageChecked}
                            {...label}
                          />
                          <OrderButtonSku
                            handelAddQuantity={() =>
                              setPhysicalDamageQty(
                                parseInt(physicalDamageQty + 1)
                              )
                            }
                            handelMinusQuantity={() =>
                              setPhysicalDamageQty(
                                parseInt(
                                  physicalDamageQty > 0
                                    ? physicalDamageQty - 1
                                    : 0
                                )
                              )
                            }
                            handelQuantityChange={(event) =>
                              setPhysicalDamageQty(
                                parseInt(
                                  event.target.value >= 0 &&
                                    event.target.value <= 999
                                    ? parseInt(event.target.value)
                                    : parseInt(physicalDamageQty)
                                )
                              )
                            }
                            inputValue={physicalDamageQty}
                            // disabled={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button variant="contained" onClick={handleSubmitReturn}>
                        submit
                      </Button>
                    </Box>
                  </Box>
                ) : withdrawalOrderDetails.is_submit_finished === false &&
                  parseInt(withdrawalOrderDetails.order_status) === 700 ? (
                  <Box>
                    <Typography sx={{ fontWeight: "bold", color: "green" }}>
                      Admin Rejected Order
                    </Typography>
                  </Box>
                ) : (
                  // <Box>
                  //   <Typography sx={{ fontWeight: "bold", color: "green" }}>
                  //     successfully Completed Order
                  //   </Typography>
                  // </Box>
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Box>
        {returnCanOrderData.map((data) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  textAlign: "start",
                  color: "blue",
                  fontWeight: "bold",
                }}
              >
                {data.type}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  textAlign: "end",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {data.payload}
              </Typography>
            </Box>
          );
        })}
        {(withdrawalOrderDetails.delivery_agent_id === undefined ||
          withdrawalOrderDetails.delivery_agent_id === null ||
          parseInt(withdrawalOrderDetails.delivery_agent_id) === 0) &&
        parseInt(withdrawalOrderDetails.order_status) === 200 ? (
          <Box sx={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                background: appColors.commonBtnColor.background,
                color: "white",
                borderRadius: "10px",
              }}
              onClick={handleOpenAssignDeliveryAgentDialog}
            >
              Assign Delivery Agent
            </Button>
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Box>
            <Button
              variant="contained"
              onClick={handleButtonData}
              sx={{
                background: appColors.commonBtnColor.background,
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              {withdrawalOrderDetails.is_submit_started === false &&
              withdrawalOrderDetails.is_submit_finished === false
                ? "Enter OTP"
                : withdrawalOrderDetails.is_submit_started === true &&
                  withdrawalOrderDetails.is_submit_finished === false &&
                  parseInt(withdrawalOrderDetails.order_status) === 200
                ? "in-scan"
                : ""}
            </Button>
          </Box>
          <Box>
            {" "}
            <Button
              variant="contained"
              onClick={handleScanInValidCans}
              sx={{ backgroundColor: "blue", color: "white", margin: "10px" }}
            >
              <DocumentScanner /> in-valid cans
            </Button>
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={handleReturnApprovedOrRejectedCans}
          sx={{
            backgroundColor: "#2C3E50",
            color: "white",
            margin: "10px",
            borderRadius: "10px",
            ":hover": {
              backgroundColor: "#73AFEB",
              color: "black",
            },
          }}
        >
          Approved
          <CheckCircle
            fontSize="20px"
            sx={{ bgcolor: "green", color: "white" }}
          />
          Cans
        </Button>
      </Box> */}

      {openApprovedRejectedCans &&
      withdrawalOrderDetails.return_can_order_id &&
      withdrawalOrderDetails.return_can_order_id.length ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          order_id={withdrawalOrderDetails.return_can_order_id}
          handleClose={handleCloseApprovedRejectedDialog}
          handleLink={handleLinkApprovedQrs}
        />
      ) : null}
      {enterOtpOpenDialog === true ? (
        <SupplierOrdersEnterOtpDialog
          isDialogOpen={enterOtpOpenDialog}
          handleClose={handleCloseDialog}
          handleSubmitOtp={handleSubmitOtp}
          enterOtpTextfied={enterOtpTextfied}
          setEnterOtpTextfied={setEnterOtpTextfied}
          setSelectCanFilledOrNot={setSelectCanFilledOrNot}
          selectCanFilledOrNot={selectCanFilledOrNot}
          setOtpValidationError={setOtpValidationError}
          otpValidationError={otpValidationError}
        />
      ) : (
        ""
      )}
      {openQrorCanDamageDialog === true ? (
        <SupplierQRorCanDamageDialog
          openQrorCanDamageDialog={openQrorCanDamageDialog}
          handleSubmit={handleSubmitQrorCanDamage}
          handleClose={handleCloseQrorCanDamage}
        />
      ) : (
        ""
      )}
      <ReturnCanCameraDialog
        openCamera={openCamera}
        openReturnCanCameraDialog={openReturnCanCameraDialog}
        returnCanOrderId={withdrawalOrderDetails.return_can_order_id}
        setOpenCamera={setOpenCamera}
        setOpenReturnCanCameraDialog={setOpenReturnCanCameraDialog}
        apiData={apiData}
        handleClose={handleCloseCameraDialog}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />

      <AssignDeliveryAgentDialog
        openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
        agentListata={supplierDeliveries.getSupplerSupplierDeliveryAgentList}
        handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
        hadleClose={handleCloses}
      />
      <ReturnCanCameraCanSwapScanIvalidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        // handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        order_id={withdrawalOrderDetails.return_can_order_id}
      />
    </Box>
  );
}

export default ReturnCanSingleOrder;
