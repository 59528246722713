import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: "",
  msg: "",
  isLoading: false,
  loadingCount: 0,
  totalLoadingCounts: 0,
};

const spinnerReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.SPINNER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.SPINNER_LOADED:
      return {
        ...state,
        isLoading: false,
        loadingCount: state.loadingCount + 1,
      };
    default:
      return state;
  }
};

export default spinnerReducer;
