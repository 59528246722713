import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
import { toast } from "react-toastify";
import { url } from "../apis";

export const GetPoTrackDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `SSM/postpaid/lists?page_size=${reqObj.page_size}&page_number=${reqObj.page_number}&pp_track_id=${reqObj.pp_track_id}&order_status=${reqObj.order_status}&phone=${reqObj.phone}&sort=${reqObj.sort}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PO_TRACK_DETAILS,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_PO_TRACK_DETAILS,
        payload: error.response.data.data,
      });
    }
  };
};

export const PostPoRequest = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`SSM/postpaid/request`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_PO_REQUEST,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const GetSuppliersForPincode = (pincode) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/postpaid/getDistibuterPo?pincode=${pincode}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIERS_PINCODE,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        dispatch({
          type: actionType.GET_SUPPLIERS_PINCODE,
          payload: response.data,
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_SUPPLIERS_PINCODE,
        payload: error.response.data,
      });
    }
  };
};

export const PostpaidAcceptReject = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`SSM/postpaid/status`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PO_ACCEPT_REJECT,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const PlacePostPaid = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/postpaidbtorders`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_ORDER_PLACE,
          payload: response.data,
        });
        CustomConsole(response);

        CustomConsole("------Toast In PO Track Action-----");
        CustomConsole(response.data.msg);
        toast.success(
          `${response.data.msg} Order ID: ${response.data.order_id}`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const PostPaidInvoices = (reqObj) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      CustomConsole(`${url}/SSM/postpaid/generateInvoice`);
      await fetch(`${url}/SSM/postpaid/generateInvoice`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/pdf",
        },
        body: JSON.stringify(reqObj),
        // responseType: "arrayBuffer",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          CustomConsole("download" + link.href);
          window.open(link.href);
          dispatch({
            type: actionType.DOWNLOAD_INVOICE,
            payload: link.href,
          });
        })
        .catch((error) => {
          CustomConsole("download invoice error : " + error);
        });
    };
  } else {
    toast.dark("Error while downloading", {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};

export const GetInvoiceData = (id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`SSM/postpaid/invoices?user_id=${id}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_SETTLEMENT_INVOICES,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.POSTPAID_SETTLEMENT_INVOICES,
        payload: { error: true, msg: error.response.data.msg },
      });
    }
  };
};

export const GetPriceBreakDown = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/postpaid/settlements/pricebreak?invoiceId=${reqObj.invoiceId}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_INVOICES_DETAILS,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.POSTPAID_INVOICES_DETAILS,
        payload: { error: true, msg: error.response.data.msg },
      });
    }
  };
};

export const SettlementPostpaid = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`SSM/postpaid/settlements`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_SETTLE,
          payload: response.data,
        });
        CustomConsole(response);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.POSTPAID_SETTLE,
        payload: { error: true, msg: error.response.data.msg },
      });
    }
  };
};

export const PayThroughWalletPostPaid = (data, setObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/payments/webhook/waterWalletPay`,
        data
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch(SettlementPostpaid(setObj));

        toast.dark(
          `Payment of Rs.${data.amount} for Order Id: ${data.order_id} successfully paid`,
          {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          }
        );
        dispatch({
          type: actionType.PAY_THROUGH_WALLET_PP,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};

export const GenerateInvoice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/postpaid/consolidatedpo/invoice`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_GENERATE_INVOICE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const SendEmailPostpaidConsumer = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/postpaid/sendInvoice?invoiceId=${reqObj.invoiceId}&user=${reqObj.user}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SEND_EMAIL_POSTPAID,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};

export const UpdatePostpaidOrder = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/SSM/postpaid/updatepostpaid`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.UPDATE_POSTPAID_ORDER,
          payload: response.data,
        });

        console.log("----Response---");
        console.log(response);

        if (response.data.error) {
          toast.error(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {
      // CustomConsole(error);
      toast.error(error.response.data.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

export const DownloadChallanZip = (id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/files_mulitiple/${id}`, {
        responseType: "blob",
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_CHALLAN_ZIP,
          payload: response.data,
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = id;
        document.body.appendChild(link);
        link.click();

        //clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.dark("Error in downloading delivery challan..", {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Error in downloading delivery challan..", {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const ItemizedInvoice = (invoiceId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/postpaid/generate_itemized/invoice?invoiceId=${invoiceId}`,
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            // Calculate and use the progress
            const totalLength = progressEvent.total;
            const loaded = progressEvent.loaded;
            const progress = Math.round((loaded / totalLength) * 100);
            CustomConsole(`Download Progress: ${progress}%`);
            dispatch({
              payload: { error: false },
              type: actionType.INVOICE_ITEMIZED_GENERATION,
              progress: progress,
            });
          },
        }
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_ITEMIZED_GENERATION,
          payload: response.data,
        });
        CustomConsole("--------Response From the Api------");
        CustomConsole(response);
        // Create a Blob from the binary response data
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new window/tab
        window.open(url, "_blank");
        if (response.data.length <= 0) {
          CustomConsole("no available toast");
        }
        toast.dark("Invoice Generated!", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.INVOICE_GENERATION,
        payload: {
          error: true,
          msg: "Error in generating invoice check your order combinations!..",
        },
      });
      toast.dark(
        "Error in generating invoice check your order combinations!..",
        {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        }
      );
    }
  };
};

export const ItemInvoiceProgressReset = (progress) => {
  return (dispatch) => {
    dispatch({
      payload: { error: false },
      type: actionType.ITEM_UPDATE_GENERATION,
      progress: progress,
    });
  };
};

export const GetOrdersToConsolidate = (phone, fromDate, toDate) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `SSM/postpaid/settle/deliveries?phone=${phone}&startDate=${fromDate}&endDate=${toDate}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CONSOLIDATE_PO_GET,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};

export const GetBusinessUsers = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/b2b/users`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.BUSINESS_USERS,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};

export const DownloadChallanInvoiceZip = (id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/invoice/files_mulitiple/${id}`, {
        responseType: "blob",
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POSTPAID_CHALLAN_ZIP,
          payload: response.data,
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = id;
        document.body.appendChild(link);
        link.click();

        //clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
        toast.dark("Error in downloading delivery challan..", {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Error in downloading delivery challan..", {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};
