/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import * as Material from "@mui/material";

import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteLocalityFromPincode,
  SkuAvailablePincodes,
} from "../../Pages/Redux/Actions/skuPincodeAction";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import AddUpdatePicodeAreaSku from "../Dialog/AddUpdatePicodeSku";
import ConformationDialog from "../Dialog/conformationDialog";
import { CSVLink } from "react-csv";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function SkuPincodes(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [localityId, seLocalityId] = React.useState({
    locality_ids: [],
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  //function for handling edit product details
  // const handleFilterChange = (e) => {
  //   CustomConsole(e.target.value);
  //   // props.setQueryParams((prev) => ({ ...prev, page_size: e.target.value }));
  // };
  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    props.setQueryParams((prev) => ({
      ...prev,
      page_size: event.target.value,
    }));
  };

  //function for changing page number
  React.useEffect(() => {
    props.setQueryParams((prev) => ({ ...prev, page_number: pageNumber }));
  }, [pageNumber]);
  //function for storing locality value
  const handleClickDelete = (data) => {
    setConfirmDialog(true);
    seLocalityId(() => ({ locality_ids: [data.id] }));
  };

  const handleSearchPageSize = (e) => {
    dispatch(SkuAvailablePincodes(props.queryParams));
  };

  //function for deleting locality
  const handelConformDelete = () => {
    dispatch(DeleteLocalityFromPincode(localityId));
  };

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.deleteLocalityError) {
      setConfirmDialog(false);
    }
  }, [skuPincodeDetails.deleteLocalityError]);

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename: "SkuPincodeReport",
    data: csvFileData,
  };
  function toCSV(pincodeDetails) {
    const result = [];
    if (pincodeDetails && pincodeDetails.length) {
      for (const data of pincodeDetails) {
        CustomConsole(data);
        let productDetails = {};
        for (const product of data.products) {
          productDetails["One Time Sale"] =
            "Deposit: " +
            product.one_time_sale.deposit +
            "\n" +
            "MRP : " +
            product.one_time_sale.mrp +
            "\n" +
            "Refund Amount: " +
            product.one_time_sale.refund_amount;
        }
        for (const product of data.products) {
          productDetails["Refill"] =
            "Drposit: " +
            product.refill.deposit +
            "\n" +
            "MRP : " +
            product.refill.mrp +
            "\n" +
            "Refund Amount: " +
            product.refill.refund_amount;
        }
        for (const product of data.products) {
          productDetails["Capacity " + "\n" + "Material "] =
            product.capacity + "\n" + product.material;
        }
        result.push({
          pincode: data.pincode,
          locality: data.locality,
          district: data.district,
          state_name: data.state_name,
          ...productDetails,
        });
      }
    }
    // result.push(pincodeDetails)
    setCsvFileData(result);
  }
  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: 5,
          marginTop: "1rem",
          padding: "1rem",
        }}
      >
        <Material.Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          {" "}
          <Material.Button
            onClick={() => props.setOpenPincodeFilter(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Material.Button>
          {/* <Material.FormControl sx={{ minWidth: 120, }} size="small">
            <Material.InputLabel>Page size</Material.InputLabel>
            <Material.Select
              value={props.queryParams.page_size}
              label="Page size"
              onChange={handleFilterChange}
              name="page_size"
            >
              <Material.MenuItem value={25}>25</Material.MenuItem>
              <Material.MenuItem value={50}>50</Material.MenuItem>
              <Material.MenuItem value={100}>100</Material.MenuItem>
            </Material.Select>
          </Material.FormControl> */}
          <Material.Box
            sx={{
              maxWidth: "120px",
              maxHeight: "10px",
              // margin: { xs: 4 },
              // marginTop: 1,
            }}
          >
            <Material.TextField
              size="small"
              value={props.queryParams.page_size}
              label="Page size"
              // onChange={(e) => {
              //   setPageSize(e.target.value);
              //   // setSearchOrderFlag(true);
              // }}
              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleFilterChange, // Attach the input event handler
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPageSize}
                    />
                  </Material.InputAdornment>
                ),
              }}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.IconButton
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              />
            </Material.IconButton>
            <Material.Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {pageNumber}
            </Material.Typography>
            <Material.IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (props.queryParams.page_size == props.pincodeData?.length) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color:
                    props.queryParams.page_size < props.pincodeData?.length
                      ? "blue"
                      : "gray",
                }}
              />
            </Material.IconButton>
          </Material.Box>
          <CSVLink {...csvLink}>
            <SimCardDownloadRoundedIcon
              // sx={{ ...styles.reportDownloadIcon }}
              onClick={() => toCSV(props.pincodeData)}
            />
          </CSVLink>
        </Material.Box>
        {props.pincodeData !== undefined &&
        props.pincodeData !== null &&
        props.pincodeData.length > 0 ? (
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow sx={appColors.commonLayoutColor}>
                  <TableCell />
                  <TableCell size="small" align="start">
                    <strong>S.no</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>Pincode</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>Locality</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>District</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>State</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>Country</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>Edit</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.pincodeData?.map((data, index) => (
                  <>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      key={index}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handelIcon(index)}
                        >
                          {openFlag ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell size="small" align="start">
                        {index + 1}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.pincode}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.locality}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.district}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.state_name}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {data.country}
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Material.Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            onClick={() =>
                              props.handleClickEditPincode("pincode", data)
                            }
                            size="small"
                          >
                            Edit
                          </Material.Button>
                          <Material.Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleClickDelete(data)}
                            size="small"
                          >
                            Delete
                          </Material.Button>
                        </Material.Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                      >
                        {/* <Collapse in={open} timeout="auto" unmountOnExit > */}
                        {open === index && openFlag === true ? (
                          <Material.Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow sx={appColors.commonLayoutColor}>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid black",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid black",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      border: "1px solid black",
                                    }}
                                  >
                                    One Time Sale
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                      border: "1px solid black",
                                    }}
                                    colSpan={4}
                                    align="center"
                                  >
                                    Refill
                                  </TableCell>
                                </TableRow>
                                <TableRow sx={appColors.commonLayoutColor}>
                                  <TableCell
                                    size="small"
                                    sx={{ border: "1px solid black" }}
                                  >
                                    <strong>Product</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                  >
                                    <strong>Capacity</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Deposits</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> MRP</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Selling Price</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Refund Amount</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Deposit</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> MRP</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Selling Price</strong>
                                  </TableCell>
                                  <TableCell
                                    sx={{ border: "1px solid black" }}
                                    size="small"
                                    align="center"
                                  >
                                    <strong> Refund Amount</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.products.map((data) => (
                                  <TableRow
                                    key={data.product_details?.product_id}
                                  >
                                    <TableCell size="small">
                                      {data.material}
                                    </TableCell>
                                    <TableCell size="small">
                                      {data.capacity}
                                    </TableCell>

                                    <TableCell align="center">
                                      {data.one_time_sale?.deposit}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.one_time_sale?.mrp}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.one_time_sale?.sp}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.one_time_sale?.refund_amount}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.refill?.deposit}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.refill?.mrp}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.refill?.sp}
                                    </TableCell>
                                    <TableCell align="center">
                                      {data.refill?.refund_amount}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Material.Box>
                        ) : (
                          ""
                        )}
                        {/* </Collapse> */}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          "No Data Available"
        )}
        <ConformationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          dialogMsg="Are You Sure to delete locality from pincode"
          handelProceed={handelConformDelete}
        />
      </Paper>
    </>
  );
}

export default SkuPincodes;
