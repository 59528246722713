/**
 * @author vinay kumar
 * @description Add User Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
// This UI Complete ReDesign Done by Vinay
import React from "react";
import { Box, Paper } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import PersonAddAlt from "@mui/icons-material/PersonAddAlt";
import { GetDistributorDetails } from "../Redux/Actions/appSettingsAction";
import CustomConsole from "../../CustomConsole";
import SupplierStockDetails from "../AdminUsers/SupplerStockDetails";

import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminAddUser,
  GetRolesList,
  SearchUserAdmin,
} from "../Redux/Actions/addUserAdminAction";
import { toast } from "react-toastify";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  ADMIN_ROLES,
  employeeType,
} from "../../Components/AppMeta/appMetaConfig";

import * as Material from "@mui/material";
import * as IconName from "react-icons/fc";

//Admin use Details Starting
import GeneralDetailsCard from "./GeneralDetailsCard";
import SubscriptionDetailsCard from "./SubscriptionDetailsCard";

import {
  GetDepositDetails,
  GetProfileDetails,
  GetReferralCode,
  GetSubscriptionOrderDetails,
  GetUserAddressAdmin,
  GetUserStockDetails,
  GetWalletDetails,
  GetUserBasedName,
} from "../Redux/Actions/userDetailsAdminAction";
import { useHistory, useLocation } from "react-router-dom";
import {
  GetSetUpConfigAction,
  GetUserDepositDetails,
} from "../Redux/Actions/adminBookingAction";
import { GetInfluencerDetails } from "../Redux/Actions/adminInfluencerAction";

import PlaceOrderEmptyCansCard from "./PlaceOrderEmptyCansCard";
import SupplierDetailsCard from "./SupplierDetailsCard";
import AddUserDialog from "./AddUserDialog";
import { UsersStyles } from "./UsersStyles";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddressUsers from "./AddressUsers";
import AccountsUsers from "./AccountsUsers";
import UsersStock from "./UsersStock";
import WfsLineDetails from "./WfsLineDetails";
import AssignInchargeWfs from "./AssignInchargeWfs";
import AssignDeliveryArea from "../AdminDistributors/AssignDeliveryArea";
import { AppSettings } from "../Redux/Actions/appSettingsAction";
import AssignedAreasCard from "../AdminDistributors/AssignedAreasCard";
import DistributorViewDetails from "./DistributorViewDetails";
import DistributorAssignedAreaCard from "./DistributorAssignedAreaCard";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import UserTransactions from "./UserTransactions";
import { TabContext, TabList } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import UserNameDialog from "./UserNameDialog";
import SupplierDriversList from "./SupplierDriversList";
import DistributorOrderCard from "../../Components/Cards/distributorSkuOrderCard";
import WfsAdminEmptyCanOrder from "../../Components/Cards/wfsAdminSKUOrder";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
});

export default function AdminUsers() {
  // const theme = createTheme();
  const [reload, setReload] = React.useState(true);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [pageTitle, setPageTitle] = React.useState("");
  const { pathname } = useLocation();
  const [tabValue, setTabValue] = React.useState(1);
  const classes = useStyles();
  const [nameSearch, setNameSearch] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [referralCode, setReferralCode] = React.useState("");
  const [alignmentStock, setAlignmentStock] = React.useState("Regular");

  // Redirecting from orders page and getting the phone number from URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");

  // Setting the phone number and hitting the API for search user with the phone number from URL(Redirecting from orders page)
  React.useEffect(() => {
    CustomConsole(phoneNumberFromOrders);
    setPhoneEmail(phoneNumberFromOrders);
    if (phoneNumberFromOrders !== null) {
      handleSearchUser();
    } else {
      setIsUserDetailsOpen(false);
    }
  }, [phoneNumberFromOrders]);
  CustomConsole(pathname);

  React.useEffect(() => {
    if (reload) {
      const pageTitle = pathname.split("/dashboard/");
      CustomConsole(pageTitle[1]);
      setPageTitle(pageTitle[1]);
      // refreshDashboard(pageTitle[1]);
      setReload(false);
    }
  }, []);

  const dispatch = useDispatch();
  const [renderFlag, setRenderFlag] = React.useState(true);
  const [pwdPincodeAreas, setPwdPincodeAreas] = React.useState([]);
  const [roleId, setRoleId] = React.useState(2000);
  const [phoneEmail, setPhoneEmail] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [searchUserBtn, setSearchUserBtn] = React.useState(false);
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState(false);
  const [isEmailProper, setIsEmailProper] = React.useState(true);
  const [isNameProper, setIsNameProper] = React.useState(true);
  const [userRoleId, setUserRoleId] = React.useState("");
  const [supplierId, setSupplierId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [isPhoneProper, setisPhoneProper] = React.useState(true);
  const [isSupplierPhoneProper, setisSupplierPhoneProper] =
    React.useState(true);
  const [searchUserFlag, setSearchUserFlag] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const [roles, setRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [selectedRoleName, setSelectedRoleName] = React.useState("");
  const [adminRoles, setAdminRoles] = React.useState("");

  const [twntyLtrMin, setTwntyLtrMin] = React.useState(0);
  const [twntyLtrMax, setTwntyLtrMax] = React.useState(0);
  const [updateFlag, setUpdateFlag] = React.useState(false);

  const [updateFlagCheck, setUpdateFlagCheck] = React.useState(false);

  //Admin User Details startes
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);

  const history = useHistory();
  const [supplierData, setSupplierData] = React.useState({});
  const [supplierType, setSupplierType] = React.useState("");
  const [deliverySlotStatus, setDeliverySlotStatus] = React.useState(false);
  const [userStockData, setUserStockData] = React.useState({});
  const [showSupplierDetails, setShowSupplierDetails] = React.useState(false);
  const [wfsId, setWfsId] = React.useState("");
  const [openPdwOrderDialog, setOpenPdwOrderDialog] = React.useState(false);

  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [bookOrderBtn, setBookOrderBtn] = React.useState(false);
  const [checkAvailability, setCheckAvailability] = React.useState(false);
  const [walletBalance, setWalletBalance] = React.useState(0);
  const [referral, setReferral] = React.useState("");

  // Add user success Dialog box open
  const [addUserSuccessMsg, setAddUserSuccessMsg] = React.useState(false);
  const [addUserDialogFlag, setAddUserDialogFlag] = React.useState(false);

  // Distributor View Details States
  const [distributorPhoneNum, setDistributorPhoneNum] = React.useState("");
  const [distributorNum, setDistributorNum] = React.useState("");
  const [adminRole, setAdminRoleId] = React.useState("");
  const [postPaidFlag, setPostpaidFlag] = React.useState(true);
  const [influencerSetting, setInfluencerSetting] = React.useState(false);
  const [influencerValidFrom, setInfluencerValidFrom] = React.useState("");
  const [influencerValidTill, setInfluencerValidTill] = React.useState("");
  const [dialogOpenFlag, setDialogOpenFlag] = React.useState(false);
  const [openuserDialog, setOpenUserDialog] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    CustomConsole(newValue);
    setTabValue(newValue);
  };

  // React.useEffect(() => {
  //   if (nameSearch) {
  //     const timerId = setTimeout(() => {
  //       CustomConsole(`---------name Printing ${count++}----`);
  //       CustomConsole(nameSearch);
  //       const reqObj = {
  //         name: nameSearch,
  //       };
  //       dispatch(GetUserBasedName(reqObj));
  //     }, 1000);

  //     return () => {
  //       clearTimeout(timerId);
  //     };
  //   }
  // }, [nameSearch]);

  React.useEffect(() => {
    dispatch(AppSettings());
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);

  const setUpConfig = useSelector((state) => state.adminBookingData);

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);
  React.useEffect(() => {
    CustomConsole(setUpConfig);
    if (setUpConfig.error === false && setUpConfig.setupConfigData.length > 0) {
      setUpConfig.setupConfigData.map((data) => {
        if (data.key === "comapny_types") {
          setCompanyTypes(data.value);
        }
      });
    }
  }, [setUpConfig]);

  CustomConsole("Run...");

  //Distributor pincode length
  React.useEffect(() => {
    CustomConsole(appSettingsDetails);
    if (Object.keys(appSettingsDetails.admin_settings).length) {
      setTwntyLtrMin(appSettingsDetails.admin_settings.twenty_ltr_min);
      CustomConsole("---Look----");
      CustomConsole(appSettingsDetails.admin_settings.twenty_ltr_min);
      setTwntyLtrMax(appSettingsDetails.admin_settings.twenty_ltr_max);
    }
    if (
      appSettingsDetails.pdw_pincodes_areanames !== undefined &&
      appSettingsDetails.pdw_pincodes_areanames.length
    ) {
      // CustomConsole(appSettingsDetails.pdw_pincodes_areanames);
      appSettingsDetails.pdw_pincodes_areanames.map((data) => {
        // CustomConsole(data);
      });
      setPwdPincodeAreas(appSettingsDetails.pdw_pincodes_areanames);
      // setShowPwdPincodeAreas(true);
    } else {
      setPwdPincodeAreas([]);
    }
    if (appSettingsDetails.error === false && updateFlag === true) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateFlag(false);
      dispatch(AppSettings());
    }
  }, [appSettingsDetails]);

  React.useEffect(() => {
    CustomConsole(
      `userId ${userId}`,
      `userRoleId ${userRoleId}`,
      `supplierId ${supplierId}`
    );
  }, []);
  // React.useEffect(() => {
  //   CustomConsole(userId);
  //   if (userId !== "") {
  //     CustomConsole("user ID");
  //     dispatch(GetProfileDetails(userId));
  //     dispatch(GetDepositDetails(userId));
  //     dispatch(GetUserStockDetails(userId));
  //     dispatch(GetWalletDetails(userId));
  //     dispatch(GetInfluencerDetails(userId));
  //     dispatch(GetReferralCode(userId));
  //     dispatch(GetSubscriptionOrderDetails(userId));
  //     dispatch(GetUserAddressAdmin(userId));
  //   }
  // }, [userId]);
  // React.useEffect(() => {
  //   dispatch(GetProfileDetails(userId));
  // }, []);

  React.useEffect(() => {
    CustomConsole(updateUserDetails);
    CustomConsole(`updateUserDetails in userDetails`);
    if (updateUserDetails.referralCodeError === false) {
      setReferralCode(updateUserDetails.referralCode);
    }
    if (updateUserDetails.error === false) {
      setSupplierData(updateUserDetails.userProfileData.supplier);
      setWfsId(updateUserDetails.assignedWfsAddress.wfs_id);
      setWalletBalance(updateUserDetails.walletAmount);
      if (updateUserDetails.userStockData.length)
        updateUserDetails.userStockData.map((data) => {
          setUserStockData(data);
        });
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    if (updateUserDetails.userNames && dialogOpenFlag) {
      CustomConsole("-----------dialogOpenFlag----------");
      setOpenUserDialog(true);
    }
    setDialogOpenFlag(false);
  }, [updateUserDetails.userNames, dialogOpenFlag]);

  React.useEffect(() => {
    CustomConsole(supplierData);
    if (supplierData !== undefined) {
      setDeliverySlotStatus(supplierData.delivery_slots_status);
      setSupplierType(supplierData.supplier_type);
    }
  }, [supplierData]);
  const [id, setId] = React.useState("");

  React.useEffect(() => {
    CustomConsole(adminUsers);
    adminUsers.map((data) => {
      setId(data.id);
    });
  }, [adminUsers]);

  React.useEffect(() => {
    CustomConsole(adminRoles);
  }, []);

  //Admin UserDetails End

  React.useEffect(() => {
    setAdminRoles(parseInt(localStorage.getItem("roleId")));
  }, []);

  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  CustomConsole(addUserAdminDetails);
  React.useEffect(() => {
    dispatch(GetRolesList());
  }, []);
  React.useEffect(() => {
    if (addUserAdminDetails.rolesList.length > 0) {
      setRoles(addUserAdminDetails.rolesList);
    }
  }, [addUserAdminDetails]);

  const handleChangeRoles = (event) => {
    CustomConsole(event.target.value);
    setSelectedRole(event.target.value);
    roles.map((role) => {
      if (role.role_id === event.target.value) {
        CustomConsole(role.role_name);
        setSelectedRoleName(role.role_name);
      }
    });
  };

  React.useEffect(() => {
    CustomConsole(roles);
  }, [roles]);
  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    if (
      addUserAdminDetails.error === false &&
      addUserAdminDetails.users !== undefined
    ) {
      if (addUserAdminDetails.users.length > 0) {
        setAdminUsers(addUserAdminDetails.users);
        addUserAdminDetails.users.map((data) => {
          setUserId(data.id);
          setSupplierId(data.supplier_id);
          setUserRoleId(data.role_id);
        });
      } else {
      }
    } else if (addUserAdminDetails.msg !== "") {
      // toast.error(addUserAdminDetails.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (adminUsers.length && searchUserFlag === true) {
      setIsUserDetailsOpen(true);
      setSearchUserFlag(false);
    }
  }, [adminUsers]);

  React.useEffect(() => {
    let regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var resultEmail = regEmail.test(phoneEmail);
    let regPhone = /^[6-9][0-9]{9}$/g;
    var resultPhone = regPhone.test(phoneEmail);
    if (searchUserBtn) {
      if ((phoneEmail !== "" && resultEmail === true) || resultPhone === true) {
        dispatch(SearchUserAdmin(phoneEmail));
        setSearchUserBtn(false);
      } else {
        toast.error("Please enter Proper Phone/Email", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setSearchUserBtn(false);
      }
    }
  }, [searchUserBtn]);

  React.useEffect(() => {
    if (addUserAdminDetails.addusererror === false && addUserDialogFlag) {
      // setAddUserSuccessMsg(true);
      setAddUserSuccessMsg(true);
    }
    CustomConsole(addUserAdminDetails);
  }, [addUserAdminDetails]);

  const handleSubmit = (event) => {
    // setAddUserSuccessMsg(true);
    event.preventDefault();
    CustomConsole(selectedRole);
    if (selectedRole != 2100) {
      const data = new FormData(event.currentTarget);
      if (
        isPhoneProper === true &&
        data.get("phone") != "" &&
        isEmailProper === true &&
        data.get("email") != "" &&
        isNameProper === true &&
        data.get("fullname") != "" &&
        selectedRole != ""
      ) {
        dispatch(
          AdminAddUser(
            data.get("fullname"),
            data.get("phone"),
            data.get("email"),
            data.get("password"),
            selectedRole,
            data.get("supplierphone"),
            selectedRoleName,
            referral
          )
        );
        // setAddUserSuccessMsg(true);
        // setAddUserDialogFlag(false);
      } else {
        toast.error("Please fill all Details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      const data = new FormData(event.currentTarget);
      if (
        isPhoneProper === true &&
        data.get("phone") != "" &&
        isEmailProper === true &&
        data.get("email") != "" &&
        isNameProper === true &&
        data.get("fullname") != "" &&
        data.get("password") != "" &&
        isSupplierPhoneProper === true &&
        selectedRole != ""
      ) {
        dispatch(
          AdminAddUser(
            data.get("fullname"),
            data.get("phone"),
            data.get("email"),
            data.get("password"),
            selectedRole,
            selectedRoleName,
            referral
          )
        );
      } else {
        toast.error("Please fill all Details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const handleEmailChange = (event) => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var result = reg.test(event.target.value);
    setIsEmailProper(result);
  };
  const handlePhoneChange = (event) => {
    let reg = /^[6-9][0-9]{9}$/g;
    var result = reg.test(event.target.value);
    setisPhoneProper(result);
  };
  const handleSupplierPhoneChange = (event) => {
    let reg = /^[6-9][0-9]{9}$/g;
    var result = reg.test(event.target.value);
    setisSupplierPhoneProper(result);
  };
  const handleNameChange = (event) => {
    let reg = /^[A-Za-z]+([\ A-Za-z]+)*/g;
    var result = reg.test(event.target.value);
    setIsNameProper(result);
  };
  const handleChangeSearchUser = (event) => {
    CustomConsole(event.target.value);
    setPhoneEmail(event.target.value);
    setDistributorPhoneNum(event.target.value);
  };

  const handleSearchUser = () => {
    setSearchUserBtn(true);
    setSearchUserFlag(true);
    setAddUserDialogFlag(false);
    setBookOrderBtn(false);
    setCheckAvailability(false);
    setRenderFlag(true);
    setValue(0);
    setPostpaidFlag(false);
    setInfluencerSetting(false);
    setInfluencerValidFrom("");
    setInfluencerValidTill("");
  };
  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };

  // function handleShare() {
  //   if (navigator.share) {
  //     const content =
  //       "Water is essential for life, and so are friends. Refer a friend to our app and help them stay hydrated while earning rewards for yourself! To Signup using our android app follow the link: https://play.google.com/store/apps/details?id=com.bookwater.consumerapp&hl=en_IN&gl=US&referrer=8DC2B17E or to use our webapp follow: https://webappstage.bookwater.com/authenticate?referrer=8DC2B17E";
  //     navigator
  //       .share({
  //         text: content,
  //         // subject: "Referral test",
  //         // title: "My awesome app",
  //         // text: "Check out this amazing content!",
  //         // url: "Referral test",
  //       })
  //       .then(() => CustomConsole("Successfully shared"))
  //       .catch((error) => CustomConsole("Error sharing", error));
  //   } else {
  //     CustomConsole("Web Share API not supported");
  //   }
  // }

  // const content =
  //   "Water is essential for life, and so are friends. Refer a friend to our app and help them stay hydrated while earning rewards for yourself! To Signup using our android app follow the link: https://play.google.com/store/apps/details?id=com.bookwater.consumerapp&hl=en_IN&gl=US&referrer=8DC2B17E or to use our webapp follow: https://webappstage.bookwater.com/authenticate?referrer=8DC2B17E";

  // const shareContent = async () => {
  //   try {
  //     await navigator.share({
  //       text: content,
  //     });
  //   } catch (error) {
  //     console.error("Error sharing content: ", error);
  //   }
  // };

  // setTimeout(() => {
  //   scrollDownToDetailsCard.current?.scrollIntoView({ behavior: "smooth" });
  // }, 1000);

  const [alignment, setAlignment] = React.useState(() =>
    ADMIN_ROLES.BW_SUPERADMIN === parseInt(localStorage.getItem("roleId"))
      ? "AddUsers"
      : "SearchUser"
  );

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    CustomConsole(index);
    setValue(index);
  };

  const handleUserPage = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDistributorDetails = (distriNum) => {
    setDistributorPhoneNum(distributorNum);
    CustomConsole(distriNum);
    dispatch(GetDistributorDetails(distriNum));
  };

  const searchBasename = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/\d/g, "");
    e.target.value = alphaValue;
    setNameSearch(e.target.value);
  };

  const searchBtn = () => {
    // const reqObj = {
    //   name: nameSearch ,
    //   page_number:pageNumber,

    // };
    // if (reqObj.name) {
    dispatch(GetUserBasedName({ name: nameSearch, page_number: pageNumber }));
    setDialogOpenFlag(true);
    // }
  };

  React.useEffect(() => {
    searchBtn();
  }, [pageNumber]);

  const handleChangeStock = (event, newAlignment) => {
    setAlignmentStock(newAlignment);
  };

  return (
    <Box>
      <Box
        sx={{
          marginTop: "50px",
        }}
      >
        {/* created Toogle Button For Add Use And Search User */}
        {/* <Material.Box>
          <Material.ToggleButtonGroup
            sx={{
              background: appColors.commonLayoutColor.background,
              backgroundImage: appColors.commonLayoutColor.backgroundImage,
            }}
            backgroundColor="#3d6bb3"
            value={alignment}
            exclusive
            onChange={handleUserPage}
          >
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPERADMIN ? (
              <Material.ToggleButton value="AddUsers">
                <PersonAddAlt size="50px" /> Add Users
              </Material.ToggleButton>
            ) : (
              ""
            )}
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPERADMIN ? (
              <>
                <Material.ToggleButton
                  value="SearchUser"
                  sx={{
                    borderRadius: "10px",
                    background: appColors.commonLayoutColor.background,
                    backgroundImage:
                      appColors.commonLayoutColor.backgroundImage,
                  }}
                >
                  <IconName.FcSearch size="30px" /> Search User
                </Material.ToggleButton>
              </>
            ) : (
              ""
            )}
          </Material.ToggleButtonGroup>
        </Material.Box> */}

        {pageTitle === "superadmin" ? (
          <Material.Box sx={{ padding: "10px" }}>
            {parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_SUPERADMIN ? (
              <Paper elevation={1} sx={UsersStyles.cardshadow}>
                <Card sx={{ background: "#EBF5FB" }}>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                    ></Box>
                    <Box>
                      {roles.length > 0 && (
                        <Box
                          type="form"
                          component="form"
                          onSubmit={handleSubmit}
                          noValidate
                          marginRight={0.5}
                        >
                          <Material.Grid container spacing={2}>
                            <Material.Grid
                              item
                              md={
                                selectedRole === 3000 || selectedRole === 2001
                                  ? 4
                                  : 6
                              }
                            >
                              <FormControl
                                sx={{ m: 2, minWidth: 250 }}
                                size="small"
                              >
                                <InputLabel id="demo-select-small">
                                  Select role to add
                                </InputLabel>
                                <Select
                                  labelId="demo-select-small"
                                  id="demo-select-small"
                                  value={selectedRole}
                                  label="Select role to add"
                                  onChange={handleChangeRoles}
                                >
                                  {roles.map((role) => {
                                    return (
                                      <MenuItem value={role.role_id}>
                                        {role.role_name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Material.Grid>
                            <Material.Grid item md={4}>
                              <TextField
                                margin="normal"
                                required
                                size="small"
                                fullWidth
                                id="fullname"
                                label="Full Name"
                                name="fullname"
                                autoComplete="fullname"
                                autoFocus
                                onChange={handleNameChange}
                                error={isNameProper === false ? true : false}
                                helperText={
                                  isNameProper === false &&
                                  "Please enter a Valid Name"
                                }
                                InputProps={{
                                  startAdornment: (
                                    <Material.InputAdornment position="start">
                                      <IconName.FcManager size="30px" />
                                    </Material.InputAdornment>
                                  ),
                                }}
                              />
                            </Material.Grid>
                            {selectedRole === 3000 ? (
                              <Material.Grid item md={3}>
                                <TextField
                                  margin="normal"
                                  required
                                  size="small"
                                  // fullWidth
                                  autoFocus
                                  // id="Referral Code"
                                  label="Referral Code"
                                  // name="Referral Code"
                                  autoComplete="Referral Code"
                                  onChange={(event) =>
                                    setReferral(event.target.value)
                                  }
                                  value={referral}
                                />
                              </Material.Grid>
                            ) : (
                              ""
                            )}
                            {selectedRole === 2001 ? (
                              <Material.Grid item md={4}>
                                <TextField
                                  margin="normal"
                                  size="small"
                                  required
                                  fullWidth
                                  id="supplierphone"
                                  label="Supplier Phone"
                                  type="number"
                                  name="supplierphone"
                                  autoComplete="phone"
                                  autoFocus
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  onChange={handleSupplierPhoneChange}
                                  error={
                                    isSupplierPhoneProper === false
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    isSupplierPhoneProper === false &&
                                    "Please enter a Valid Phone Number"
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <Material.InputAdornment position="start">
                                        <IconName.FcPhoneAndroid size="30px" />
                                      </Material.InputAdornment>
                                    ),
                                  }}
                                />
                              </Material.Grid>
                            ) : (
                              ""
                            )}
                            <Material.Grid item md={4}>
                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                id="phone"
                                label="Phone"
                                type="number"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                onChange={handlePhoneChange}
                                error={isPhoneProper === false ? true : false}
                                helperText={
                                  isPhoneProper === false &&
                                  "Please enter a Valid Phone Number"
                                }
                                InputProps={{
                                  startAdornment: (
                                    <Material.InputAdornment position="start">
                                      <IconName.FcPhoneAndroid size="30px" />
                                    </Material.InputAdornment>
                                  ),
                                }}
                              />
                            </Material.Grid>

                            <Material.Grid item md={4}>
                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleEmailChange}
                                error={isEmailProper === false ? true : false}
                                helperText={
                                  isEmailProper === false &&
                                  "Please enter a valid email address"
                                }
                                InputProps={{
                                  startAdornment: (
                                    <Material.InputAdornment position="start">
                                      <IconName.FcInvite size="30px" />
                                    </Material.InputAdornment>
                                  ),
                                }}
                              />
                            </Material.Grid>
                            <Material.Grid item md={4}>
                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                InputProps={{
                                  startAdornment: (
                                    <Material.InputAdornment position="start">
                                      <IconName.FcPrivacy size="30px" />
                                    </Material.InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={handleClickShowPassword}
                                      >
                                        {values.showPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Material.Grid>
                            <Material.Grid item md={12} sm={12} xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                marginRight={0.5}
                              >
                                Add user
                              </Button>
                            </Material.Grid>
                          </Material.Grid>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            ) : (
              ""
            )}
          </Material.Box>
        ) : (
          <>
            <Box>
              <TabContext value={tabValue}>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <TabList onChange={handleTabChange} className={classes.tabUi}>
                    <Tab label="Phone/Email" value={1} />
                    <Tab label="Name" value={2} />
                  </TabList>
                </Box>
                <TabPanel value={tabValue} index={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      elevation={1}
                      sx={{
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        // marginLeft: "250px",
                        gap: "10px",
                        background: "#EBF5FB",
                        maxWidth: "450px",
                      }}
                    >
                      <TextField
                        label="Phone or Email"
                        value={phoneEmail}
                        name="phone"
                        onChange={handleChangeSearchUser}
                        InputProps={{
                          startAdornment: (
                            <Material.InputAdornment position="start">
                              <IconName.FcSearch size="30px" />
                            </Material.InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ background: appColors.commonBtnColor.background }}
                        size="small"
                        onClick={handleSearchUser}
                      >
                        Search User
                      </Button>
                    </Paper>
                  </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "stretch",
                      gap: "5px",
                    }}
                  >
                    <TextField
                      label="Name"
                      name="name"
                      value={nameSearch}
                      InputProps={{
                        startAdornment: (
                          <Material.InputAdornment position="start">
                            <IconName.FcSearch size="30px" />
                          </Material.InputAdornment>
                        ),
                      }}
                      onChange={searchBasename}
                    />
                    <Button
                      variant="contained"
                      sx={{ background: appColors.commonBtnColor.background }}
                      size="small"
                      onClick={searchBtn}
                    >
                      Search Users
                    </Button>
                    <UserNameDialog
                      setOpen={setOpenUserDialog}
                      open={openuserDialog}
                      setPhoneEmail={setPhoneEmail}
                      handleSearchUser={handleSearchUser}
                      setTabValue={setTabValue}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
      </Box>

      {isUserDetailsOpen ? (
        addUserAdminDetails.users !== undefined ? (
          <Box>
            <Box
              sx={{
                gap: "10px",
                padding: "10px",
                maxWidth: { md: "100%", xs: "340px" },
              }}
            >
              {/* Created App bar Here */}
              <Box>
                <Material.Grid item md={12} sm={6} xs={12}>
                  <AppBar
                    position="static"
                    sx={{
                      borderRadius: "10px",
                      background: appColors.commonLayoutColor.background,
                      backgroundImage:
                        appColors.commonLayoutColor.backgroundImage,
                    }}
                    variant="scrollable"
                    scrollButtons={false}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="inherit"
                      // variant="fullWidth"
                      variant="scrollable"
                      scrollButtons={false}
                      aria-label="full width tabs example"
                      sx={{
                        color: "black",
                        active: {
                          backgroundColor: "yellow",
                        },
                      }}
                    >
                      {/* Based On Condition Changing Tabs Here */}
                      <Tab label="General Details" {...a11yProps(0)} />

                      {userRoleId === ADMIN_ROLES.BW_CONSUMER ? (
                        <Tab label="Address" {...a11yProps(1)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="Address" {...a11yProps(1)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 1002 ? (
                        <Tab label="Line Details" {...a11yProps(1)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 3000 ? (
                        <Tab label="Transactions" {...a11yProps(2)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="Accounts" {...a11yProps(2)} />
                      ) : (
                        ""
                      )}

                      {userRoleId === 1002 ? (
                        <Tab label="Assign Incharge Wfs" {...a11yProps(2)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 3000 ? (
                        <Tab label="User stock" {...a11yProps(3)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="User stock" {...a11yProps(3)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 1002 ? (
                        <Tab label="Place Empty Can Wfs" {...a11yProps(3)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 1002 ? (
                        <Tab label="New  Empty Can Wfs" {...a11yProps(4)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 3000 ? (
                        <Tab label="User Details" {...a11yProps(4)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="Supplier Profile" {...a11yProps(4)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="Supplier Details" {...a11yProps(5)} />
                      ) : (
                        ""
                      )}
                      {userRoleId === 2000 ? (
                        <Tab label="Place Order" {...a11yProps(6)} />
                      ) : (
                        ""
                      )}
                      {/* <Tab label="Transactions" {...a11yProps(6)} /> */}
                    </Tabs>
                  </AppBar>
                </Material.Grid>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  {/* Based On Condition Calling Files here */}
                  <Box>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <GeneralDetailsCard
                        setRenderFlag={setRenderFlag}
                        renderFlag={renderFlag}
                        adminUsers={adminUsers}
                        id={id}
                        userRoleId={userRoleId}
                        userIdG={userId}
                        setIsUserDetailsOpen={setIsUserDetailsOpen}
                        roles={roles}
                        referralCode={referralCode}
                        setReferralCode={setReferralCode}
                        updateFlagCheck={updateFlagCheck}
                        setUpdateFlagCheck={setUpdateFlagCheck}
                        deliverySlotStatus={deliverySlotStatus}
                        setDeliverySlotStatus={setDeliverySlotStatus}
                        setSupplierType={setSupplierType}
                        supplierType={supplierType}
                        influencerSetting={influencerSetting}
                        setInfluencerSetting={setInfluencerSetting}
                        influencerValidFrom={influencerValidFrom}
                        setInfluencerValidFrom={setInfluencerValidFrom}
                        influencerValidTill={influencerValidTill}
                        setInfluencerValidTill={setInfluencerValidTill}
                      />
                    </TabPanel>
                  </Box>
                  <Box>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      {userRoleId === 3000 && (
                        <AddressUsers
                          id={id}
                          userRoleId={userRoleId}
                          userIdA={userId}
                        />
                      )}
                      {userRoleId === 2000 && (
                        <AddressUsers
                          id={id}
                          userRoleId={userRoleId}
                          userIdA={userId}
                        />
                      )}
                      {userRoleId === 1002 && (
                        <WfsLineDetails wfsId={wfsId} userId={userId} />
                      )}
                    </TabPanel>
                  </Box>
                  <Box>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                      <Box>
                        <Box>
                          <Card sx={UsersStyles.cardshadow}>
                            <CardContent>
                              {userRoleId === 3000 && (
                                <AccountsUsers
                                  id={id}
                                  userStockData={userStockData}
                                  userRoleId={userRoleId}
                                  userIdA={userId}
                                />
                              )}

                              {userRoleId === 2000 && (
                                <AccountsUsers
                                  id={id}
                                  userStockData={userStockData}
                                  userRoleId={userRoleId}
                                  userIdA={userId}
                                />
                              )}

                              {userRoleId === 1002 && (
                                <AssignInchargeWfs
                                  wfsId={wfsId}
                                  userId={userId}
                                />
                              )}
                            </CardContent>
                          </Card>
                        </Box>
                        <Box sx={{ marginTop: "30px" }}>
                          <UserTransactions userId={userId} />
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                  <Box>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                      <>
                        {userRoleId === 2000 && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "column",
                              marginBottom: "20px",
                            }}
                          >
                            <Material.Stack
                              direction="row"
                              // spacing={1}

                              sx={{ maxHeight: "30px" }}
                            >
                              <Material.ToggleButtonGroup
                                value={alignmentStock}
                                exclusive
                                size="sm"
                                color="primary"
                                onChange={handleChangeStock}
                                // sx={{ bgcolor: "#73AFEB" }}
                                // className={classes.reducedWidth}
                              >
                                <Material.ToggleButton value="Regular">
                                  REGULAR
                                </Material.ToggleButton>
                                <Material.ToggleButton value="Return">
                                  Return
                                </Material.ToggleButton>
                              </Material.ToggleButtonGroup>
                            </Material.Stack>
                          </Box>
                        )}
                      </>
                      {/* <Card sx={UsersStyles.cardshadow}>
                        <CardContent> */}
                      {userRoleId === 3000 && (
                        <UsersStock
                          id={id}
                          userStockData={userStockData}
                          userRoleId={userRoleId}
                          userIdA={userId}
                        />
                      )}

                      {userRoleId === 2000 && (
                        <>
                          <SupplierStockDetails
                            id={id}
                            alignmentStock={alignmentStock}
                            userStockData={userStockData}
                            userRoleId={userRoleId}
                            userIdA={userId}
                          />
                          {/* <UsersStock
                                id={id}
                                alignmentStock={alignmentStock}
                                userStockData={userStockData}
                                userRoleId={userRoleId}
                                userIdA={userId}
                              /> */}
                        </>
                      )}
                      {userRoleId === ADMIN_ROLES.BW_WFSADMIN && (
                        <>
                          <PlaceOrderEmptyCansCard userId={userId} />
                        </>
                      )}

                      {/* </CardContent>
                      </Card> */}
                    </TabPanel>
                  </Box>
                  <Box>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                      {userRoleId === 3000 &&
                      adminRoles !== ADMIN_ROLES.BW_QA &&
                      adminRoles !== ADMIN_ROLES.BW_QAHEAD ? (
                        <SubscriptionDetailsCard
                          adminUsers={adminUsers}
                          setOpenPdwOrderDialog={setOpenPdwOrderDialog}
                          adminRoles={adminRoles}
                          openPdwOrderDialog={openPdwOrderDialog}
                          setBookOrderBtn={setBookOrderBtn}
                          bookOrderBtn={bookOrderBtn}
                          checkAvailability={checkAvailability}
                          setCheckAvailability={setCheckAvailability}
                          walletBalance={walletBalance}
                          userProfileDetails={updateUserDetails.userProfileData}
                          userId={userId}
                          postPaidFlag={postPaidFlag}
                          setPostpaidFlag={setPostpaidFlag}
                        />
                      ) : (
                        ""
                      )}
                      {userRoleId === 1002 && (
                        <WfsAdminEmptyCanOrder userId={userId} />
                      )}
                    </TabPanel>
                    {userRoleId === 2000 ? (
                      <TabPanel value={value} index={4} dir={theme.direction}>
                        <SupplierDetailsCard
                          supplierData={supplierData}
                          userRoleId={userRoleId}
                          setShowSupplierDetails={setShowSupplierDetails}
                          showSupplierDetails={showSupplierDetails}
                          userId={userId}
                          companyTypes={companyTypes}
                        />
                      </TabPanel>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Card>
                    <CardContent>
                      {userRoleId === 2000 ? (
                        <TabPanel value={value} index={5} dir={theme.direction}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-around",
                            }}
                          >
                            <Box>
                              {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                              ADMIN_ROLES.BW_ADMIN === adminRole ||
                              ADMIN_ROLES.BW_CSHEAD === adminRole ||
                              ADMIN_ROLES.BW_SALESHEAD === adminRole ? (
                                <DistributorViewDetails
                                  // scrollDown={scrollDownToDetailsCard}
                                  distriNum={distributorPhoneNum}
                                  adminRole={adminRole}
                                  setAdminRoleId={setAdminRoleId}
                                />
                              ) : null}
                            </Box>
                            <Box>
                              {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                              ADMIN_ROLES.BW_ADMIN === adminRole ||
                              ADMIN_ROLES.BW_CSHEAD === adminRole ||
                              ADMIN_ROLES.BW_CS === adminRole ||
                              ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                              ADMIN_ROLES.BW_OPSHEAD === adminRole ? (
                                <DistributorAssignedAreaCard
                                  distriNum={distributorPhoneNum}
                                  pwdPincodeAreas={pwdPincodeAreas}
                                />
                              ) : (
                                <Box>
                                  <Material.Alert severity="error">
                                    Access denied
                                  </Material.Alert>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {userRoleId === 2000 ? (
                            <Box
                              sx={{
                                marginTop: "10px",
                                marginLeft: "20px",
                                // display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <SupplierDriversList
                                employee_type={employeeType.DRIVER}
                                supplierId={supplierId}
                              />
                            </Box>
                          ) : (
                            ""
                          )}
                        </TabPanel>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                  {/* <Box> */}
                  <TabPanel value={value} index={6} dir={theme.direction}>
                    {userRoleId === 2000 && (
                      <>
                        <DistributorOrderCard userId={userId} />
                      </>
                    )}
                  </TabPanel>
                  {/* </Box> */}
                </SwipeableViews>
              </Box>
            </Box>
          </Box>
        ) : (
          <Material.Alert severity="error">
            User Not Registered With System
          </Material.Alert>
        )
      ) : (
        ""
      )}
      {addUserSuccessMsg === true ? (
        <AddUserDialog
          addUserSuccessMsg={addUserSuccessMsg}
          setAddUserSuccessMsg={setAddUserSuccessMsg}
          addUserAdminDetails={addUserAdminDetails}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
