import actionType from "./actionType";
import { toast } from "react-toastify";
import { employeeType } from "../../../Components/AppMeta/appMetaConfig";
import useAxios from "../../Axios/useAxios";

const httpStatus = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
};

export const EmployeesPunchInOut = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/distributor/punch_in_out`, props);

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

          payload: response.data,
        });
        return true;
      } else {
        toast.error(
          response.data?.data?.msg || " Unable to Punch In Please Try Again",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return false;
      }
    } catch (error) {
      toast.error(
        error?.response.data?.msg || " Unable to Punch In Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );

      return false;
    }
  };
};

export const GetPunchDetails = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/distributor/get_punch_details`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

          payload: response.data,
        });
      } else {
        toast.error(
          response.data?.data?.msg || " Unable to Punch In Please Try Again",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(
        error?.response.data?.msg || " Unable to Punch In Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};

export const AddVehicle = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/add_vehicle`, props);

      if (response.status === httpStatus.SUCCESS) {
        toast.success("Vehicle details Added", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // dispatch({
        //   type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

        //   payload: response.data,
        // });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      toast.error(
        error?.response.data?.msg || " Unable to Add Vehicle. Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return false;
    }
  };
};
export const UpdateVehicle = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/vehicle`, props);

      if (response.status === httpStatus.SUCCESS) {
        toast.success("Vehicle details Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // dispatch({
        //   type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

        //   payload: response.data,
        // });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      toast.error(
        error?.response.data?.msg ||
          " Unable to update Vehicle. Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return false;
    }
  };
};

export const GetVehiclesWithTrip = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/vehicle_trips`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.VEHICLE_CURRENT_TRIPS,
          payload: response.data,
        });
      } else {
        toast.error(response.data?.data?.msg || "Unable to get Trips", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(
        error?.response.data?.msg || " Unable to Punch In Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};

export const GetVehicleCurrentStatus = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/vehicle_trips/get_trip`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(response.data?.data?.msg || "Unable to get Trips", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(
        error?.response.data?.msg || " Unable to Punch In Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};
export const GetListOfTransferredVtoV = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/QSM/distributor/vehicle_stock_swap`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(response.data?.data?.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(error?.response.data?.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const PutSubmitTransferProductVToV = (reqObj) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/QSM/distributor/request_vehicle_stock_swap`,
        reqObj
      );

      if (response.status === httpStatus.SUCCESS) {
        toast.success(response.data?.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
};
export const GetRunningVehiclesList = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/get_running_vehicles`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(response.data?.data?.msg || "Unable to get Trips", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(
        error?.response.data?.msg || " Unable to Punch In Please Try Again",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
};

export const GetPunchinEmployees = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/distributor/get_punch_by_warehouse`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(
          response.data?.data?.msg || "Unable to get Employees List",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(error?.response.data?.msg || "Unable to get Employees List", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const GetUnassignedEmployees = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/TM/vehicle_trips/un_assigned_employees`, {
        params: props,
      });

      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(
          response.data?.data?.msg || "Unable to get Employees List",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || "Unable to get Employees List", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const StartVehicleTrip = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/vehicle_trips/start`, props);

      if (response.status === httpStatus.SUCCESS) {
        // dispatch({
        //   type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

        //   payload: response.data,
        // });
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || " Unable to save Checklist!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
};
export const EndVehicleTrip = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/TM/vehicle_trips/end`, props);

      if (response.status === httpStatus.SUCCESS) {
        // dispatch({
        //   type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

        //   payload: response.data,
        // });
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || " Unable to End Trip!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
};

export const LoadVehicle = (props) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/QSM/distributor/inscan/wh/single`, props);

      if (response.status === httpStatus.SUCCESS) {
        // dispatch({
        //   type: actionType.EMPLOYEE_PUNCHIN_DETAILS,

        //   payload: response.data,
        // });
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || " Unable to save Checklist!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
};

//function to get warehouse details
export const WareHouseDetailsList = (params) => {
  let api = useAxios();
  const filteredReqObj = Object.fromEntries(
    Object.entries(params || {})?.filter(([key, value]) => value !== "")
  );
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/distributor/warehouses?${new URLSearchParams(filteredReqObj)}`
      );
      console.log("ware house details list");
      console.log(response.data);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ALL_WAREHOUSE_DETAILS,
          payload: response.data,
        });
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || " Unable to save Checklist!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
};
