import React from "react";
import * as Material from "@mui/material";
import Drawer from "@mui/material/Drawer";
import * as IconName from "react-icons/fc";
import CustomConsole from "../../../CustomConsole";

function SupplierDeliveriesSearchByOrderIdDialog(props) {
  const handleSearchOrderId = () => {
    CustomConsole("----------handleSearchOrderId-------------");
    props.setOpenDrawer(false);
  };
  return (
    <Drawer
      anchor="top"
      open={props.openDrawer}
      onClose={handleSearchOrderId}
    >
      <Material.Box sx={{ margin: "10px" }}>
        <Material.TextField
          //   sx={{ borderRadius: "30px" }}
          label="Search Order"
          variant="outlined"
          // value={orderId}
          // onChange={handleSearchOrderId}
          InputProps={{
            startAdornment: (
              <Material.InputAdornment position="start">
                <Material.Typography sx={{ fontWeight: "bold" }}>
                  BTORD-
                </Material.Typography>
              </Material.InputAdornment>
            ),
            endAdornment: (
              <Material.InputAdornment position="end">
                <IconName.FcSearch size="30px" onClick={handleSearchOrderId} />
              </Material.InputAdornment>
            ),
          }}
        />
      </Material.Box>
    </Drawer>
  );
}

export default SupplierDeliveriesSearchByOrderIdDialog;
