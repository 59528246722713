/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function WareHouseDetailsTable() {
  const wareHouseDetails = useSelector(
    (state) => state.userDetailsUpdate.addresses
  );
  const cellBorderStyle = { border: "1px solid black" };
  const warehouseBorderStyle = {
    border: "1.5px solid #000",
    borderCollapse: "separate",
  };
  return (
    <>
      <TableContainer sx={{ border: "1px solid black" }}>
        <Table
          id="products_summary_table"
          size="small"
          sx={{ overflow: "auto" }}
        >
          <TableBody>
            {/* Table Header */}
            <TableRow
              sx={{
                border: "1.4px solid black", // Add border to the row itself
                bgcolor: "#D5F5E3",
              }}
              align="center"
            >
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                WareHouse
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Product
              </TableCell>

              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Filled
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Empty
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Outgoing Filled
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Outgoing Empty
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Incoming Filled
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", ...cellBorderStyle }}
              >
                Incoming Empty
              </TableCell>
            </TableRow>

            {/* Table Data */}
            {wareHouseDetails?.length > 0 ? (
              wareHouseDetails.map((address, index) => (
                <React.Fragment key={index}>
                  {/* <TableRow>
                    <TableCell
                      colSpan={9}
                      sx={warehouseBorderStyle}
                    ></TableCell>{" "}
                  </TableRow> */}
                  {address.stocks?.length > 0 ? (
                    address.stocks.map((stock, stockIndex) => (
                      <TableRow
                        key={stockIndex}
                        sx={{
                          bgcolor: "#EAFAF1",

                          // Ensuring the border for every row, including the last one
                        }}
                      >
                        {/* Only show the contact name for the first stock of each warehouse */}
                        {stockIndex === 0 ? (
                          <TableCell
                            rowSpan={address.stocks.length}
                            align="center"
                            sx={{
                              ...cellBorderStyle,
                              fontWeight: "600", // Apply border to the warehouse name cell
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "left" }}
                            >
                              <strong>Name:</strong> {address.contact_name}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "left" }}
                            >
                              <strong>Pincode:</strong> {address.pincode}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "left" }}
                            >
                              <strong>Area:</strong> {address.service_area}
                            </Typography>
                          </TableCell>
                        ) : null}

                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.container_material || "-"}
                        </TableCell>
                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.filled || 0}
                        </TableCell>
                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.empty || 0}
                        </TableCell>
                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.outgoing_filled || 0}
                        </TableCell>
                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.outgoing_empty || 0}
                        </TableCell>
                        <TableCell align="center" sx={cellBorderStyle}>
                          {stock.incoming_filled || 0}
                        </TableCell>
                        <TableCell align="center" sx={[cellBorderStyle]}>
                          {stock.incoming_empty || 0}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={`no-stock-${index}`}>
                      <TableCell
                        colSpan={9}
                        align="center"
                        sx={[cellBorderStyle, { color: "red" }]}
                      >
                        Ware House Details Not Found
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    {/* <TableCell
                      colSpan={9}
                      sx={warehouseBorderStyle}
                    ></TableCell>{" "} */}
                    {/* Dark border between warehouses */}
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center" sx={cellBorderStyle}>
                  <Typography sx={{ color: "red" }}>No Data Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
