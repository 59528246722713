import React from "react";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { grey, blue } from "@mui/material/colors";

const StockClearance = ({ stockData }) => {
  const theme = useTheme();

  const hasNonZeroStock = stockData.some(
    (item) =>
      item.filled_container_qty > 0 ||
      item.total_deposited_containers_qty > 0 ||
      item.total_undeposited_containers_qty > 0 ||
      parseFloat(item.total_deposited_amount) > 0
  );

  return (
    <Box
      sx={{
        p: { sm: 4 },
        height: "100%",
        overflowY: "auto",
        backgroundColor: grey[50],
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: { xs: "1.1rem", sm: "1.1rem" },
          fontWeight: "bold",
          textAlign: "center",
          color: blue[800],
        }}
      >
        Stock Clearance
      </Typography>

      <Grid container spacing={2}>
        {hasNonZeroStock ? (
          stockData
            .filter(
              (item) =>
                item.filled_container_qty > 0 ||
                item.total_deposited_containers_qty > 0 ||
                item.total_undeposited_containers_qty > 0 ||
                parseFloat(item.total_deposited_amount) > 0
            )
            .map((item, index) => (
              <Grid
                item
                key={index}
                xs={12} // Full width on extra small screens (up to 600px)
                sm={12} // Two cards per row on small to medium screens (600px and up)
                md={6} // Maximum two cards per row on larger screens
              >
                <Paper
                  elevation={5}
                  sx={{
                    p: { xs: 3, sm: 3 },
                    borderRadius: 2,
                    transition: "0.3s",
                    "&:hover": {
                      boxShadow: theme.shadows[12],
                      transform: "scale(1.03)",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: { xs: "1rem", sm: "1rem" },
                      fontWeight: "bold",
                      color: blue[700],
                    }}
                  >
                    {item.more_details.name}
                  </Typography>
                  <Grid container spacing={3}>
                    {["Filled", "Deposit", "Undeposit", "Amount"].map(
                      (label, idx) => (
                        <Grid item xs={3} key={idx}>
                          <Typography
                            variant="body2"
                            align="center"
                            sx={{
                              borderBottom: `2px solid ${grey[300]}`,
                              fontWeight: "bold",
                              color: blue[900],
                              fontSize: { xs: "0.7rem", sm: "0.7rem" },
                            }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      )
                    )}

                    {/* Values */}
                    <Grid item xs={3}>
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{
                          color: grey[700],
                          fontSize: { xs: "0.7rem", sm: "0.7rem" },
                        }}
                      >
                        {item.filled_container_qty}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{
                          color: grey[700],
                          fontSize: { xs: "0.7rem", sm: "0.7rem" },
                        }}
                      >
                        {item.total_deposited_containers_qty}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{
                          color: grey[700],
                          fontSize: { xs: "0.7rem", sm: "0.7rem" },
                        }}
                      >
                        {item.total_undeposited_containers_qty}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{
                          color: grey[700],
                          fontSize: { xs: "0.7rem", sm: "0.7rem" },
                        }}
                      >
                        ₹{item.total_deposited_amount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
        ) : (
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                p: { xs: 2, sm: 3 },
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "0.7rem", sm: "0.7rem" } }}
              >
                Customer has no stocks.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: { xs: "0.7rem", sm: "0.7rem" } }}
              >
                All stock quantities and amounts are zero.
              </Typography>
            </Paper>
          </Grid>
        )}

        {hasNonZeroStock && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mt: 3,
                textAlign: "center",
                fontSize: { xs: "0.85rem", sm: "1rem" },
                p: { xs: 1, sm: 2 },
                backgroundColor: grey[100],
                borderRadius: 2,
              }}
            >
              Note: Kindly clear/remove all the stock clearances.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StockClearance;
