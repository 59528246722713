import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  actionTriger: true,
  msgTransferCansVerifyOtp: "",
  linkOrderToOrdererror: true,
  errorCurrentDeliveryVerifyOtp: false,
  errorCurrentDeliveriesOutScan: false,
  errorCurrentDeliveriesInScan: false,
  errorTransferCansVerifyOtp: false,
  employees: [],
  addDeliveyerror: false,
  wfsOnGoingerror: false,
  assigDeliveryAgenterror: false,
  replacementSubmitOtperror: false,
  replacementInScanerror: false,
  replacementOutScanerror: false,
  returnSubmitOtperror: false,
  returnInOutScanerror: false,
  getCurrentDayDeliveries: [],
  getNextDayDeliveries: [],
  getAvailableDeliverySlots: [],
  getCompletedDeliveries: [],
  RequestSlotForFilling: {},
  getSupplierWfsOngoingOrders: [],
  getSupplierWfsCompletedOrders: [],
  getSupplierWfsAllOrders: [],
  getSupplierReplacementCanOrders: [],
  getSupplierReplacementVerifyOtp: {},
  putInScanqrbarcode: {},
  putOutScanFilledCans: {},
  getSupplerReturnCanOrders: [],
  putSupplerReturnCanVerifyOtpo: {},
  putSupplerReturnINScanCan: {},
  putSupplerOnGoingStartSubmission: {},
  warehouseStockMovement: {},
  getSupplerCompanyProfile: [],
  getSupplerSupplierStockDetails: [],
  getSupplerSupplierDeliveryAgentList: [],
  postSupplierAddDeliveryAgent: {},
  putSupplierUpdateDeliveryAgent: {},
  postSupplierChangePassword: {},
  putaddDeliverySlots: {},
  putCurrentDayDeliveryVerifyOtp: {},
  putCurrentDayDeliveriesoutScan: {},
  putCurrentDayCompleteDeliveries: {},
  putCurrentDayDeliveriesInScan: {},
  getAssignDeliveryAgent: [],
  postSupplierAssignDeliveryAgent: {},
  postSupplierRollBackCans: {},
  grtSupplierDriverDataAdmin: [],
  postSupplierAssignDeliveryAgentReturnOrReplacement: {},
  getSupplierTransferCanOrders: [],
  qrBarcodeDetails: [],
  getSupplierPreviuosOngoingDeliveries: [],
  getSupplierAssignSlotAgentOngoingDeliveries: [],
  getSupplierOngoingDeliveriesCount: [],
  getSupplierOngoingDeliveriesStatus: [],
  getSupplierAssignUnassigedPincodeSlotAgent: [],
  getSupplierUnassigedSlotAgent: [],
  supplierPincodes: [],
  getSupplierCompletedDeliveriesNew: [],
  getCanAgeDetails: {},
  warehouseEmployees: {},
  warehouseMapped: {},
  onGoingError: true,
  validatedData: [],
  extraEmptyErr: false,
  extraMsg: "",
  wareHouseDetails: [],
};

const supplierReducer = (state = initialState, action) => {
  CustomConsole(action);
  switch (action.type) {
    case actionType.GET_CURRENT_DAY_DELIVERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getCurrentDayDeliveries: action.payload.deliveries,
      };
    case actionType.GET_ALL_WAREHOUSE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wareHouseDetails: action.payload.data,
      };
    case actionType.GET_NEXT_DAY_DELIVERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getNextDayDeliveries: action.payload.deliveries,
      };
    case actionType.GET_AVAILABLE_DELIVERY_SLOTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getAvailableDeliverySlots: action.payload.data,
      };
    case actionType.GET_COMPELETED_DELIVERIES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getCompletedDeliveries: action.payload.deliveries,
      };
    case actionType.POST_REQUEST_SLOT_FOR_FILLING:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        RequestSlotForFilling: action.payload.data,
      };
    case actionType.GET_SUPPLIER_WFS_ONGOING_ORDERS:
      return {
        ...state,
        wfsOnGoingerror: action.payload.error,
        msg: action.payload.msg,
        getSupplierWfsOngoingOrders: action.payload.data,
      };
    case actionType.GET_SUPPLIER_WFS_COMPLETED_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierWfsCompletedOrders: action.payload.data,
      };
    case actionType.GET_SUPPLIER_WFS_All_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierWfsAllOrders: action.payload.data,
      };
    case actionType.GET_SUPPLIER_REPLACEMENT_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierReplacementCanOrders: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_REPLACEMENT_VERIFY_OTP:
      return {
        ...state,
        replacementSubmitOtperror: action.payload.error,
        msg: action.payload.msg,
        getSupplierReplacementVerifyOtp: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_CURRENT_DELIVERIES_CANCEL_OTP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PUT_IN_SCAN_EMPTY_CANS:
      return {
        ...state,
        replacementInScanerror: action.payload.error,
        msg: action.payload.msg,
        putInScanqrbarcode: action.payload.data,
      };
    case actionType.PUT_OUT_SCAN_FILLED_CANS:
      return {
        ...state,
        replacementOutScanerror: action.payload.error,
        msg: action.payload.msg,
        putOutScanFilledCans: action.payload.data,
      };
    case actionType.GET_SUPPLIER_RETURN_CAN_ORDERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplerReturnCanOrders: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_RETURN_CAN_VERIFY_OTP:
      return {
        ...state,
        returnSubmitOtperror: action.payload.error,
        msg: action.payload.msg,
        putSupplerReturnCanVerifyOtpo: action.payload.data,
      };
    case actionType.PUT_RETURN_IN_SCAN_QR_CODE_CANS:
      return {
        ...state,
        returnInOutScanerror: action.payload.error,
        msg: action.payload.msg,
        putSupplerReturnINScanCan: action.payload.data,
      };
    case actionType.PUT_RETURN_IN_SCAN_BAR_CODE_CANS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        putSupplerReturnINScanCan: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_ONGOING_START_SUBMISSION:
      return {
        ...state,
        error: action.payload.error,
        onGoingError: action.payload.error,
        msg: action.payload.msg,
        putSupplerOnGoingStartSubmission: action.payload.data,
      };
    case actionType.SUPPLIER_WAREHOUSE_STOCK_SCANNING: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        warehouseStockMovement: action.payload.data,
        complete_scan: action.payload.complete_scan,
        actionTriger: action.payload.error,
      };
    }
    case actionType.GET_SUPPLIER_COMPANY_PROFILE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplerCompanyProfile: action.payload.profile.supplier,
      };
    case actionType.GET_SUPPLIER_USER_COMPANY_PROFILE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplerCompanyProfile: action.payload.profile.user,
      };
    case actionType.GET_SUPPLIER_USER_STOCK_:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplerSupplierStockDetails: action.payload.data,
        distStock: action.payload.distStock,
      };
    case actionType.GET_SUPPLIER_DELIVERY_AGENT_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplerSupplierDeliveryAgentList: action.payload.data,
      };
    case actionType.POST_SUPPLER_ADD_DELIVERY_AGENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postSupplierAddDeliveryAgent: action.payload.drivers,
        actionTriger: action.payload.error,
      };
    case actionType.PUT_SUPPLIER_UPDATE_DELIVERY_AGENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        putSupplierUpdateDeliveryAgent: action.payload.drivers,
        actionTriger: action.payload.error,
      };
    case actionType.POST_SUPPLIER_CHANGE_PASSWORD:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postSupplierChangePassword: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_ASSIGN_MULTI_DELIVERY_SLOTS:
      return {
        ...state,
        addDeliveyerror: action.payload.error,
        msg: action.payload.msg,
        putaddDeliverySlots: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_CURRENT_DELIVERIES_VERIFY_OTP:
      return {
        ...state,
        errorCurrentDeliveryVerifyOtp: action.payload.error,
        msg: action.payload.msg,
        putCurrentDayDeliveryVerifyOtp: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_CURRENTDAYDAY_DELIVERIES_OUTSCAN:
      return {
        ...state,
        errorCurrentDeliveriesOutScan: action.payload.error,
        msg: action.payload.msg,
        putCurrentDayDeliveriesoutScan: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_CURRENTDAYDAY_COMPELETE_DELIVERT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        putCurrentDayCompleteDeliveries: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_CURRENTDAYDAY_DELIVERIES_INSCAN:
      return {
        ...state,
        errorCurrentDeliveriesInScan: action.payload.error,
        msg: action.payload.msg,
        putCurrentDayDeliveriesInScan: action.payload.data,
      };
    case actionType.GET_ASSIGN_DELIVERY_AGENT: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getAssignDeliveryAgent:
          action.payload.data !== undefined && action.payload.data !== null
            ? action.payload.data
            : [],
      };
    }
    case actionType.POST_SUPPLIER_ASSIGN_DELIVERY_AGENT:
      return {
        ...state,
        assigDeliveryAgenterror: action.payload.error,
        msg: action.payload.msg,
        postSupplierAssignDeliveryAgent: action.payload.data,
      };
    case actionType.POST_SUPPLIER_ROLLBACK_CANS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postSupplierRollBackCans: action.payload.data,
      };
    case actionType.GET_SUPPLIER_DELIVERY_AGENT_LIST_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierDriverDataAdmin: action.payload.data,
      };
    case actionType.GET_SUPPLIER_EMPLOYEES_LIST_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        employees: action.payload.data,
      };

    case actionType.GET_SUPPLIER_WAREHOUSE_EMPLOYEES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        warehouseEmployees: action.payload.data,
      };
    case actionType.MAP_EMPLOYEES_TO_WAREHOUSE: {
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        warehouseMapped: action.payload,
        actionTriger: action.payload.error,
      };
    }
    case actionType.REFRESH_SUPPLIER_REDUCER: {
      return {
        ...state,
        actionTriger: true,
      };
    }
    case actionType.POST_SUPPLER_ASSIGN_DELIVERY_AGENT_RETURN_REPLACEMENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        postSupplierAssignDeliveryAgentReturnOrReplacement: action.payload.data,
      };
    case actionType.GET_SUPPLIER_TRANSFER_ORDER_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierTransferCanOrders: action.payload.data,
      };
    case actionType.PUT_SUPPLIER_TRANSFER_CANS_VERIFY_OTP:
      return {
        ...state,
        errorTransferCansVerifyOtp: action.payload.error,
        msgTransferCansVerifyOtp: action.payload.msg,
        putTransferCansVerifyOtpn: action.payload.data,
      };
    case actionType.GET_QR_BARCODE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        qrBarcodeDetails: action.payload.data,
      };
    case actionType.INSCAN_QR_BARCODE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedData: action.payload.data,
      };
    case actionType.OUTSCAN_QR_BARCODE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedData: action.payload.data,
      };
    case actionType.GET_SUPPLIER_PREVIOUS_ONGONING_DELIVERIES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierPreviuosOngoingDeliveries: action.payload.deliveriesDetails,
      };
    case actionType.GET_ONGOING_ASSIGN_SLOT_AGENT_DELIVERIES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierAssignSlotAgentOngoingDeliveries: action.payload.data,
      };
    case actionType.GET_ONGOING_ASSIGN_DELIVERIES_COUNT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierOngoingDeliveriesCount: action.payload.data,
      };
    case actionType.GET_ONGOING_ASSIGN_DELIVERIES_STATUS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierOngoingDeliveriesStatus: action.payload.data,
      };
    case actionType.GET_ONGOING_ASSIGN_UNASSINGED_SLOT_AGENT_DELIVERIES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierAssignUnassigedPincodeSlotAgent: action.payload.data,
      };
    case actionType.GET_ONGOING_UNASSINGED_SLOT_AGENT_DELIVERIES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierUnassigedSlotAgent: action.payload.data,
      };
    case actionType.GET_SUPPLIERS_PINCODES:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
        supplierPincodes: action.payload.data,
      };
    case actionType.GET_SUPPLIERS_COMPLETED_DELIVERIES:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
        getSupplierCompletedDeliveriesNew: action.payload.data,
      };
    case actionType.DELETE_DRIVER_DIST:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
        actionTriger: action.payload.error,
      };
    case actionType.POST_SUPPLIER_PRODUCT_IN_SCAN:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POST_SUPPLIER_PRODUCT_OUT_SCAN:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.POST_SUPPLIER_CRATE_OUT_SCAN:
      return {
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_CAN_AGE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        getCanAgeDetails: action.payload.data,
      };
    case actionType.GET_CAN_AGE_DETAILS_SEND_EMAIL:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PUT_LINK_ORDER_TO_ORDER:
      return {
        ...state,
        linkOrderToOrdererror: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.EXTRA_EMPTY_RETURN:
      return {
        ...state,
        extraEmptyErr: action.payload.error,
        extraMsg: action.payload.msg,
      };
    case actionType.CLEAR_DATA_REFRESH:
      return {
        ...state,
        linkOrderToOrdererror: true,
      };
    case actionType.SUBMIT_IN_SCAN_RETURN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PUT_COMPLETE_REPLACEMENT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};
export default supplierReducer;
