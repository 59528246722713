/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 30-01-2024
 */
// Importing the Libraries and Other Files
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import * as Material from "@mui/material";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AddingNewPincodes } from "../../Pages/Redux/Actions/pincodeAndServiceAreaAction";
import { toast } from "react-toastify";
import ConformationDialog from "./conformationDialog";
import {
  DeleteProductDiscount,
  SkuCoustomAddressProductPrice,
  SkuCoustomProductPrice,
  SkuRemoveAddressProduct,
} from "../../Pages/Redux/Actions/skuCoustomProductPriceAction";
import {
  GetProfileDetails,
  GetUserAddressAdmin,
} from "../../Pages/Redux/Actions/userDetailsAdminAction";
import CustomConsole from "../../CustomConsole";

function AddUpdateConsumerProductPrice({
  conformAddProduct,
  setConformAddProduct,
  userId,
  data,
  area,
  useridaddress,
}) {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const skuCoustomProductDetails = useSelector(
    (state) => state.skuCoustomProductDetails
  );
  const [customProductPrice, setCustomProductPrice] = React.useState({
    consumer_user_id: userId,
    products: [],
  });

  const [DeletedProduct, setDeletedProducts] = React.useState({
    consumer_user_id: userId,
    products: [],
  });
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  CustomConsole(customProductPrice);
  const [productList, setProductList] = React.useState({
    product_id: "",
    booking_type: "",
    name: "",
    refill: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 100,
      refund_amount: 0,
    },
    one_time_sale: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 0,
      refund_amount: 0,
    },
  });

  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: "100",
    },
    {
      label: "WATER DROPS",
      value: "200",
    },
    {
      label: "None",
      value: "0",
    },
  ];
  let updateFlag =
    area === "address"
      ? data?.length > 0
      : data?.user?.products_price?.length > 0;
  // function for closing dialog
  const handelClose = () => {
    setConformAddProduct(false);
  };
  CustomConsole(updateFlag);
  CustomConsole(area);
  //   //useEffect for setting up initial data in state
  React.useEffect(() => {
    if (updateFlag && area !== "address") {
      setCustomProductPrice((prev) => ({
        ...prev,
        consumer_user_id: userId,
        products: data.user?.products_price,
      }));
    }
    if (updateFlag && area === "address") {
      CustomConsole("I am Hitten while update");
      setCustomProductPrice((prev) => ({
        ...prev,
        consumer_user_id: userId,
        products: data,
      }));
    }
    return () =>
      setCustomProductPrice((prev) => ({
        ...prev,
        consumer_user_id: userId,
        products: [],
      }));
  }, [updateFlag, data]);
  //function for handling product selection change
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.name === event.target.value
    );
    if (selectedDetails) {
      setProductList((prev) => ({
        ...prev,
        name: selectedDetails.product_details.name,
        product_id: selectedDetails.product_details.id,
        booking_type: selectedDetails.product_details.booking_type,
        refill: {
          ...prev.refill,
          mrp: selectedDetails.product_details.refill.mrp,
          sp: selectedDetails.product_details.refill.sp,
          deposit: selectedDetails.product_details.refill.deposit,
          refund_type: selectedDetails.product_details.refill.refund_type,
          refund_amount: selectedDetails.product_details.refill.refund_amount,
        },
        one_time_sale: {
          ...prev.one_time_sale,
          mrp: selectedDetails.product_details.one_time_sale.mrp,
          sp: parseInt(selectedDetails.product_details.one_time_sale.sp),
          deposit: selectedDetails.product_details.one_time_sale.deposit,
          refund_type:
            selectedDetails.product_details.one_time_sale.refund_type,
          refund_amount:
            selectedDetails.product_details.one_time_sale.refund_amount,
        },
      }));
    }
  };

  //function for handling add product detaisls
  const handelAddProduct = () => {
    if (Object.keys(productList).length > 0) {
      let duplicateElement = customProductPrice.products.filter(
        (data) => data.product_id === productList.product_id
      );
      if (duplicateElement.length === 0) {
        setCustomProductPrice((prev) => ({
          ...prev,
          consumer_user_id: userId,
          products: [...prev.products, productList],
        }));
        setProductList({});
      }
    } else {
    }
  };

  // //function for handling product change
  const handleProductDetailsChange = (event, index, saleType, field) => {
    CustomConsole("------event----------");
    CustomConsole(event.target.value);
    const newValue = parseFloat(event.target.value);
    setCustomProductPrice((prev) => ({
      ...prev,
      consumer_user_id: userId,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [saleType]: {
                ...product[saleType],
                [field]: newValue,
              },
            }
          : product
      ),
    }));
  };

  //function for handling product Booking type change
  const handleProductBookingTypeChange = (value, index, field) => {
    const newValue = value;
    CustomConsole(newValue);
    setCustomProductPrice((prev) => ({
      ...prev,
      consumer_user_id: userId,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [field]: newValue,
            }
          : product
      ),
    }));
  };

  // Function for opening confirmation dialog
  const handelDeleteProduct = (value, idx, id) => {
    const newProductList = [...customProductPrice.products];
    if (value.name === undefined) {
      setConfirmDialog(true);
      // Product without name (assuming it's the condition for deletion)
      setDeletedProducts((prev) => ({
        ...prev,
        consumer_user_id: userId,
        products: [id],
      }));
    } else {
      newProductList.splice(idx, 1);
      setCustomProductPrice((prev) => ({
        ...prev,
        consumer_user_id: userId,
        products: newProductList,
      }));
    }
  };

  //function for handling updated data
  const handelUpdateData = () => {
    if (area !== "address") {
      dispatch(SkuCoustomProductPrice(customProductPrice));
    } else {
      let reqObj = {
        address_id: userId,
        products: customProductPrice.products,
      };
      dispatch(SkuCoustomAddressProductPrice(reqObj));
      setConformAddProduct(false);
      setTimeout(() => {
        dispatch(GetUserAddressAdmin(useridaddress));
      }, 1000);
    }
  };

  //function for deleting products from user
  const handelConformDelete = () => {
    if (area !== "address") {
      dispatch(DeleteProductDiscount(DeletedProduct));
    } else {
      let reqObj = {
        address_id: userId,
        products: DeletedProduct.products,
      };
      dispatch(SkuRemoveAddressProduct(reqObj));
    }
  };

  //function for calling api function
  React.useEffect(() => {
    if (!skuCoustomProductDetails.deleteProducterror) {
      dispatch(GetProfileDetails(userId));
      setConfirmDialog(false);
      handelClose();
      skuCoustomProductDetails.deleteProducterror = true;
    }
  }, [skuCoustomProductDetails.deleteProducterror]);

  //function for calling api function
  React.useEffect(() => {
    if (!skuCoustomProductDetails.customDataError) {
      dispatch(GetProfileDetails(userId));
      handelClose();
      skuCoustomProductDetails.customDataError = true;
    }
  }, [skuCoustomProductDetails.customDataError]);
  //function for calling api function
  React.useEffect(() => {
    if (!skuCoustomProductDetails.customDataErrorAddress) {
      handelClose();
    }
    if (!skuCoustomProductDetails.deleteAddressError) {
      handelClose();
      setConfirmDialog(false);
    }
  }, [
    skuCoustomProductDetails.customDataErrorAddress,
    skuCoustomProductDetails.deleteAddressError,
  ]);
  return (
    <Box>
      <Dialog open={conformAddProduct} onClose={handelClose}>
        <DialogContent>
          <DialogTitle>Add/Update Products</DialogTitle>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: ".5rem" }}
                justifyContent="center"
                alignItems="center"
              >
                <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
                  <InputLabel id="demo-select-small">Product</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productList.name}
                    label="Capacity"
                    onChange={handleChangeSelectedProduct}
                  >
                    {skuContainerDetails.getAvailableProducts.map((data) => (
                      <MenuItem
                        value={data.product_details.name}
                        key={data.product_details.id}
                      >
                        <div>
                          <span>
                            {" "}
                            <strong>{data.product_details.name}</strong>
                          </span>
                          <br />
                          <small>
                            Capacity:
                            {data.product_details.capacity}/
                          </small>
                          <small>
                            Material:
                            {data.product_details.material}
                          </small>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={handelAddProduct}
                  variant="contained"
                  size="small"
                >
                  Add
                </Button>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                {customProductPrice.products?.map((val, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Material.Grid item md={12} sm={12} xs={12}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 1}
                          value={val.booking_type}
                          label="Refill"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              1,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 2}
                          value={val.booking_type}
                          label="One time sale"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              2,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 3}
                          value={val.booking_type}
                          label="Both"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              3,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <>
                          <DeleteIcon
                            sx={{ color: "red" }}
                            onClick={() =>
                              handelDeleteProduct(val, idx, val.product_id)
                            }
                          />
                        </>
                      </Box>
                    </Material.Grid>
                    <Box
                      sx={{
                        border: ".5px solid gray",
                        borderRadius: "10px",
                        padding: ".5rem",
                      }}
                    >
                      {val.booking_type == 2 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {val.name}&nbsp;{val.capacity}/{val.material}/One
                            Time Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              size="small"
                              value={val.one_time_sale.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              size="small"
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "mrp"
                                )
                              }
                              value={val.one_time_sale.mrp}
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              sx={{
                                width: { sm: "70%", xs: "100%" },
                              }}
                              size="small"
                              label="Refund Type"
                              defaultValue={val.one_time_sale.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              size="small"
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_amount"
                                )
                              }
                              value={val.one_time_sale.refund_amount}
                            />
                            <TextField
                              type="number"
                              label="Selling Price"
                              name="sp"
                              size="small"
                              value={val.one_time_sale.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                      {val.booking_type == 1 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {val.name}&nbsp;{val.capacity}/{val.material}/Refill
                            Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              size="small"
                              value={val.refill.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              size="small"
                              value={val.refill.mrp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "mrp"
                                )
                              }
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              sx={{
                                width: { sm: "70%", xs: "100%" },
                              }}
                              size="small"
                              label="Refund Type"
                              defaultValue={val.refill.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              size="small"
                              value={val.refill.refund_amount}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_amount"
                                )
                              }
                            />

                            <TextField
                              type="number"
                              label="Selling Price"
                              name="country"
                              size="small"
                              value={val.refill.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
            <ConformationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              dialogMsg="Are you sure to delete Product from user"
              handelProceed={handelConformDelete}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelClose}>Close</Button>
          <Button
            // disabled={errors}
            onClick={handelUpdateData}
            variant="contained"
            size="small"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUpdateConsumerProductPrice;
