/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 18-01-2024
 */
import React from "react";
import * as Material from "@mui/material";
import ParticularProductCrateCard from "../Cards/ParticularProductCrateCard";
import CustomConsole from "../../CustomConsole";

function ParticularProductCrateDetailsDialog(props) {
  //function for handel close operation
  const handleOnClose = () => {
    props.handleOnClose();
  };
  CustomConsole(props.productCrateDetails);
  return (
    <Material.SwipeableDrawer
      anchor="right"
      onClose={handleOnClose}
      open={props.openDialogParticularProductCrate}
    >
      <ParticularProductCrateCard
        productCrateDetails={props.productCrateDetails}
        onClose={handleOnClose}
      />
    </Material.SwipeableDrawer>
  );
}

export default ParticularProductCrateDetailsDialog;
