import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Paper } from "@mui/material";
import IotDeviceInfoCard1 from "./IotDeviceInfoCard1";
import CustomConsole from "../../CustomConsole";

export default function IotDevicesCard({
  selectedDeviceData,
  scrollRef,
  addNewDevice,
  IotDevices,
  alignment,
  setAlignment,
}) {
  return (
    <Paper elevation={24}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          padding: "10px",
          borderRadius: "10px",
          background: "rgb(208,221,242)",  
          backgroundImage:
            "radial-gradient(circle, rgba(208,221,242,1) 0%, rgba(148,187,233,1) 100%)",
        }}
      >
        <Box ref={scrollRef}>
          <IotDeviceInfoCard1
            selectedDeviceData={selectedDeviceData}
            addNewDevice={addNewDevice}
            IotDevices={IotDevices}
            alignment={alignment}
            setAlignment={setAlignment}
          />
        </Box>
      </Box>
    </Paper>
  );
}
