import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton,
  Collapse,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState } from "react";
import ProductsDetailsDialog from "./ProductsDetailsDialog";
import { LocalShipping } from "@mui/icons-material";
import {
  GetListOfTransferredVtoV,
  GetRunningVehiclesList,
  GetVehicleCurrentStatus,
  PutSubmitTransferProductVToV,
} from "../../Redux/Actions/supplierVehiclesActions";
import { useDispatch, useSelector } from "react-redux";
import { GetAvailableProducts } from "../../Redux/Actions/SKUProductDetailsAction";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { ADMIN_ROLES } from "../../../Components/AppMeta/appMetaConfig";
import moment from "moment";
import { toast } from "react-toastify";

function TransferProductVehicleToVehicleMain(props) {
  const dispatch = useDispatch();
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [openAvailableProductsTransfer, setOpenAvailableProductsTransfer] =
    React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [vehicleTripDetails, setVehicleTripDetails] = React.useState({});
  const [listOfTransferredVehicles, setListOfTransferredVehicles] =
    React.useState([]);
  console.log(listOfTransferredVehicles);
  const [vehicleToTripDetails, setVehicleToTripDetails] = useState({});
  const [productStockDialog, setProductStockDialog] = useState({});
  const [tankerDetails, setTankerDetails] = React.useState({});
  const [tankerToDetails, setTankerToDetails] = React.useState({});
  const [open, setOpen] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [vehicleNumer, setVehicleNumer] = React.useState("TN03AB003");
  const [selectedFromVehicle, setSelectedFromVehicle] = useState("");
  const [selectedToVehicle, setSelectedToVehicle] = useState("");
  const [availableVehicles, setAvailableVehicles] = useState([]);

  React.useEffect(() => {
    dispatch(
      GetRunningVehiclesList({ supplier_number: props.supplier_number })
    ).then((data) => {
      setAvailableVehicles(data?.data || []);
    });
  }, []);
  const getTransferHistory = () => {
    let tanker_id;
    if (selectedToVehicle) {
      tanker_id = selectedToVehicle;
    }
    dispatch(
      GetListOfTransferredVtoV({
        page_number: pageNumber,
        page_size: pageSize,
        supplier_number: props.supplier_number,
        tanker_id,
      })
    ).then((data) => {
      setListOfTransferredVehicles(data || []);
    });
  };

  React.useEffect(() => {
    console.log(pageSize);
    // dispatch(
    //   GetListOfTransferredVtoV({
    //     page_number: pageNumber,
    //     page_size: pageSize,
    //     supplier_number: props.supplier_number,
    //   })
    // );
    getTransferHistory();
  }, [pageNumber, pageSize]);
  React.useEffect(() => {
    if (selectedFromVehicle) {
      console.log("--------selectedFromVehicle-------------");
      console.log(selectedFromVehicle);
      dispatch(
        GetVehicleCurrentStatus({
          tanker_id: selectedFromVehicle,
        })
      ).then((data) => {
        // setTripStartChecklist(data?.data?.checklist || []);
        setTankerDetails(data?.data?.tanker_details || {});
        setVehicleTripDetails(data?.data?.trip_details || {});
      });
      dispatch(GetAvailableProducts());
    }
  }, [selectedFromVehicle]);
  React.useEffect(() => {
    if (selectedToVehicle) {
      console.log("--------selectedToVehicle-----------");
      console.log(selectedToVehicle);
      dispatch(
        GetVehicleCurrentStatus({
          tanker_id: selectedToVehicle,
        })
      ).then((data) => {
        // setTripStartChecklist(data?.data?.checklist || []);
        setTankerToDetails(data?.data?.tanker_details || {});
        setVehicleToTripDetails(data?.data?.trip_details || {});
      });
    }
  }, [selectedToVehicle]);
  const handleOpenTransferProductDialog = (data, availableProductsImg) => {
    console.log(data);
    console.log(availableProductsImg);
    setProductStockDialog({
      data: data,
      availableProductsImg: availableProductsImg,
    });
    setOpenAvailableProductsTransfer(true);
  };

  const handleSubmitTransferProductToVehicle = (props) => {
    const reqObj = {
      from_vehicle: selectedFromVehicle,
      to_vehicle: selectedToVehicle,
      qr_less: [
        {
          material: props.material,
          capacity: props.capacity,
          filled: props.filled,
          empty: props.empty,
          damaged: props.damaged,
        },
      ],
    };
    console.log(reqObj);

    // if (
    //   isNaN(reqObj.qr_less[0]?.damaged) === false &&
    //   isNaN(reqObj.qr_less[0]?.empty) === false &&
    //   isNaN(reqObj.qr_less[0]?.filled) === false
    // ) {
    const allQty = props.filled + props.empty + props.damaged;
    if (allQty > 0) {
      dispatch(PutSubmitTransferProductVToV(reqObj)).then((data) => {
        if (data) {
          dispatch(
            GetVehicleCurrentStatus({
              tanker_id: selectedFromVehicle,
            })
          );
          dispatch(
            GetVehicleCurrentStatus({
              tanker_id: selectedToVehicle,
            })
          );
          getTransferHistory();
        }
      });
      setOpenAvailableProductsTransfer(false);
      setSelectedFromVehicle(null);
      setSelectedToVehicle(null);
      setVehicleToTripDetails({});
      setVehicleTripDetails({});
      setTankerDetails({});
      setTankerToDetails({});
      props.setEmptyQty(0);
      props.setFilledQty(0);
      props.setEmptyQty(0);
    } else {
      toast.dark("Can't Transfer Less Than One Quantity", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
    // } else {
    //   toast.dark("Can't Transfer Less Than One Quantity", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     type: "error",
    //   });
    // }
  };
  const handleCloseTransferProductDialog = () => {
    setOpenAvailableProductsTransfer(false);
  };
  const toggleRow = (index) => {
    const isOpen = open[index];
    const newOpenRows = [...open];
    newOpenRows[index] = !isOpen;
    setOpen(newOpenRows);
  };
  const pageSizeList = [25, 50, 100, 500, 1000];
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper
            elevation={10}
            sx={{
              padding: "10px",
              border: "1px solid black",
              bgcolor: "#DCEFF8",
            }}
          >
            <Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
              >
                <Typography
                  sx={{ textAlign: "center", mb: "10px", fontWeight: "bold" }}
                >
                  FROM VEHICLE
                </Typography>
                <Typography>
                  <LocalShipping />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: "10px",
                }}
              >
                <FormControl sx={{ width: "200px", fontSize: "16px" }}>
                  <InputLabel>Select From Vehicle </InputLabel>
                  <Select
                    value={selectedFromVehicle}
                    label="select From Vehicle"
                    onChange={(event) => {
                      setSelectedToVehicle(null);
                      setTankerToDetails({});
                      setVehicleToTripDetails({});
                      setSelectedFromVehicle(event.target.value);
                    }}
                  >
                    {availableVehicles.map((item) => {
                      return (
                        <MenuItem value={item.tanker_id}>
                          {item.tanker_id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              {selectedFromVehicle ? (
                <Divider sx={{ border: "1.3px solid black", mb: "10px" }} />
              ) : (
                ""
              )}
              {selectedFromVehicle ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Reg.No</Typography>
                    <Typography>{tankerDetails?.tanker_id}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Capacity
                    </Typography>
                    <Typography>{tankerDetails.capacity}(SKU)</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Model</Typography>
                    <Typography>
                      {tankerDetails?.tanker_profile?.model}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Make</Typography>
                    <Typography>
                      {tankerDetails?.tanker_profile?.make}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Fuel Type
                    </Typography>
                    <Typography>
                      {tankerDetails?.tanker_profile?.fuel_type}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Last Delivered Order
                    </Typography>
                    <Typography>{vehicleTripDetails?.recent_order}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Driver & Loaders
                      </Typography>
                    </Typography>
                    <Typography>
                      {vehicleTripDetails?.employees?.map((employee) => {
                        return (
                          <Typography>
                            {employee?.driver_profile?.fullname} (
                            {vehicleTripDetails?.employees[0]?.role_id == 2001
                              ? "Driver"
                              : vehicleTripDetails?.employees[0]?.role_id ==
                                2003
                              ? "Loader"
                              : ""}
                            ){" -"}
                            {
                              vehicleTripDetails?.employees[0]?.driver_profile
                                ?.phone
                            }
                          </Typography>
                        );
                      })}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {selectedFromVehicle ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          padding: "0px",
                          border: "1px solid black",
                          bgcolor: "#AED6F1",
                        }}
                        colSpan={5}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Current Vehicle Stock
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1px solid black", bgcolor: "#BDC3C7" }}
                    >
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Products
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Empty
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Filled
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Damaged
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicleTripDetails?.stock !== undefined &&
                    vehicleTripDetails?.stock !== null &&
                    vehicleTripDetails?.stock.length > 0 ? (
                      <>
                        {vehicleTripDetails?.stock.map((data) => {
                          let availableProductsImg =
                            skuContainerDetails.getAvailableProducts.filter(
                              (product) =>
                                product.product_details.material ===
                                  data.material &&
                                product.product_details.capacity ===
                                  data.capacity
                            )?.[0]?.product_details?.image;
                          let total_qty =
                            data.damaged + data.filled + data.empty;
                          return (
                            <TableRow sx={{ bgcolor: "#D7DBDD" }}>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.capacity + "-" + data.material}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.empty || 0}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.filled || 0}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.damaged || 0}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  disabled={
                                    selectedToVehicle === null ||
                                    total_qty === 0
                                  }
                                  size="small"
                                  sx={{ mt: "5px", mb: "5px" }}
                                  onClick={() =>
                                    handleOpenTransferProductDialog(
                                      data,
                                      availableProductsImg
                                    )
                                  }
                                >
                                  Transfer
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{ textAlign: "center", color: "red" }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              ) : (
                ""
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper
            elevation={10}
            sx={{
              padding: "10px",
              border: "1px solid black",
              bgcolor: "#F2D7D5",
            }}
          >
            <Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
              >
                <Typography
                  sx={{ textAlign: "center", mb: "10px", fontWeight: "bold" }}
                >
                  TO VEHICLE
                </Typography>
                <Typography>
                  <LocalShipping />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: "10px",
                }}
              >
                <FormControl sx={{ width: "200px", fontSize: "16px" }}>
                  <InputLabel>Select To Vehicle </InputLabel>
                  <Select
                    value={selectedToVehicle}
                    label="Select To Vehicle"
                    onChange={(event) =>
                      setSelectedToVehicle(event.target.value)
                    }
                  >
                    {availableVehicles.map((item) => {
                      if (selectedFromVehicle === item.tanker_id) {
                        return <></>;
                      }
                      return (
                        <MenuItem value={item.tanker_id}>
                          {item.tanker_id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              {selectedToVehicle ? (
                <Divider sx={{ border: "1.3px solid black", mb: "10px" }} />
              ) : (
                ""
              )}
              {selectedToVehicle ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Reg.No</Typography>
                    <Typography>{tankerToDetails?.tanker_id}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Capacity
                    </Typography>
                    <Typography>{tankerToDetails.capacity}(SKU)</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Model</Typography>
                    <Typography>
                      {tankerToDetails?.tanker_profile?.model}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Make</Typography>
                    <Typography>
                      {tankerToDetails?.tanker_profile?.make}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Fuel Type
                    </Typography>
                    <Typography>
                      {tankerToDetails?.tanker_profile?.fuel_type}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Last Delivered Order
                    </Typography>
                    <Typography>
                      {vehicleToTripDetails?.recent_order}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Driver & Loaders
                      </Typography>
                    </Typography>
                    <Typography>
                      {vehicleToTripDetails?.employees?.map((employee) => {
                        return (
                          <Typography>
                            {employee?.driver_profile?.fullname} (
                            {vehicleToTripDetails?.employees[0]?.role_id == 2001
                              ? "Driver"
                              : vehicleToTripDetails?.employees[0]?.role_id ==
                                2003
                              ? "Loader"
                              : ""}
                            ){" -"}
                            {
                              vehicleToTripDetails?.employees[0]?.driver_profile
                                ?.phone
                            }
                          </Typography>
                        );
                      })}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {selectedToVehicle ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          padding: "0px",
                          border: "1px solid black",
                          bgcolor: "#ECF0F1",
                        }}
                        colSpan={4}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Current Vehicle Stock
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ border: "1px solid black", bgcolor: "#CCD1D1" }}
                    >
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Products
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Empty
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Filled
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          border: "1px solid black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Damaged
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicleToTripDetails?.stock !== undefined &&
                    vehicleToTripDetails?.stock !== null &&
                    vehicleToTripDetails?.stock.length > 0 ? (
                      <>
                        {vehicleToTripDetails?.stock.map((data) => {
                          return (
                            <TableRow sx={{ bgcolor: "#D7DBDD" }}>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.capacity + "-" + data.material}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.empty || 0}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.filled || 0}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0px",
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {data.damaged || 0}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{ textAlign: "center", color: "red" }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              ) : (
                ""
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              mb: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <InputLabel sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Page size:
              </InputLabel>
              <Select
                variant="standard"
                label="Page Size"
                value={pageSize}
                sx={{ fontSize: "14px", fontWeight: "bold", width: "90px" }}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                {pageSizeList.map((data, key) => {
                  return (
                    <MenuItem value={data} key={key}>
                      {data}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
                mt: "10px",
              }}
            >
              <FirstPageIcon
                sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                onClick={() => {
                  if (pageNumber - 1 > 0) {
                    setPageNumber(() => pageNumber - 1);
                  }
                }}
              />
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                {pageNumber}
              </Typography>
              <LastPageIcon
                sx={{
                  color:
                    pageNumber > 0 &&
                    listOfTransferredVehicles?.data?.length === pageSize
                      ? "blue"
                      : "gray",
                }}
                onClick={() => {
                  if (
                    pageNumber > 0 &&
                    listOfTransferredVehicles?.data?.length === pageSize
                  ) {
                    setPageNumber(() => pageNumber + 1);
                  }
                }}
              />
            </Box>
          </Box>
          <Paper container={Paper} elevation={10}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        textAlign: "center",
                        bgcolor: "#F4ECF7",
                        fontWeight: "bold",
                      }}
                    >
                      Transfer History
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#E5E8E8" }}>
                    <TableCell
                      sx={{ padding: "0px", fontWeight: "bold" }}
                    ></TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Transferred From{" "}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Transferred To
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Created By Role{" "}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Created By Name{" "}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Created At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listOfTransferredVehicles.data !== undefined &&
                  listOfTransferredVehicles.data !== null &&
                  listOfTransferredVehicles.data?.length > 0 ? (
                    <>
                      {listOfTransferredVehicles.data?.map((product, index) => {
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell sx={{ padding: "0px" }}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => toggleRow(index)}
                                  sx={{ bgcolor: "#E5E8E8", color: "black" }}
                                >
                                  {open[index] ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                {product.details?.from_vehicle} <br />
                                {product.from_vehicle_details?.tanker_profile
                                  ?.make +
                                  " " +
                                  product.from_vehicle_details?.tanker_profile
                                    ?.model}
                              </TableCell>
                              <TableCell>
                                {product.details?.to_vehicle} <br />
                                {product.to_vehicle_details?.tanker_profile
                                  ?.make +
                                  " " +
                                  product.to_vehicle_details?.tanker_profile
                                    ?.model}
                              </TableCell>
                              <TableCell>
                                {product.created_user?.role_id ===
                                ADMIN_ROLES.BW_DISTRIBUTER
                                  ? "SUPPLIER"
                                  : [
                                      product.created_user?.role_id ===
                                      ADMIN_ROLES.BW_CONSUMER
                                        ? "CONSUMER"
                                        : [
                                            product.created_user?.role_id ===
                                            ADMIN_ROLES.BW_DELIVERYAGENT
                                              ? "DELIVERY AGENT"
                                              : [
                                                  product.created_user
                                                    ?.role_id ===
                                                  ADMIN_ROLES.BW_SUPERADMIN
                                                    ? "SUPER ADMIN"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_ADMIN
                                                    ? "ADMIN"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_DELIVERYAGENT
                                                    ? "Delivery agent"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_WFSADMIN
                                                    ? "WFS INCHARGE"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_FINANCE
                                                    ? "Finance"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_CS
                                                    ? "Customer Support"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_FINANCEHEAD
                                                    ? "Finance Head"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_CSHEAD
                                                    ? "Customer Support Head"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_ADMIN
                                                    ? "Admin"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_OPS
                                                    ? "Operation"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_OPSHEAD
                                                    ? "Operation Head"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_QA
                                                    ? "QA"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_QAHEAD
                                                    ? "QA Head"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_SALES
                                                    ? "Sales"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_SALESHEAD
                                                    ? "Sales Head"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_SUPERADMIN
                                                    ? "Super Admin"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_CSLEAD
                                                    ? "Customer Support Lead"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_IVML
                                                    ? "Inventory Lead"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_SUPPLIERMANAGER
                                                    ? "WAREHOUSE MANAGER"
                                                    : product.created_user
                                                        ?.role_id ===
                                                      ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                                    ? "LOADER"
                                                    : "",
                                                ],
                                          ],
                                    ]}
                              </TableCell>
                              <TableCell>
                                {product.created_user?.fullname}
                              </TableCell>
                              <TableCell>
                                {moment(product.created_at).format(
                                  "DD-MM-YYYY hh:mm a"
                                )}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={open[index]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box sx={{ margin: 1 }}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      Products
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Products Name</TableCell>
                                          <TableCell align="center">
                                            Filled Quantity
                                          </TableCell>
                                          <TableCell align="center">
                                            Empty Quantity
                                          </TableCell>
                                          <TableCell align="center">
                                            Damaged Quantity
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {product.details?.products?.map(
                                          (data) => {
                                            return (
                                              <TableRow key={100}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {data.capacity +
                                                    "-" +
                                                    data.material}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {data.filled}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {data.empty}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {data.damaged}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{ textAlign: "center", color: "red" }}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <ProductsDetailsDialog
        openAvailableProductsTransfer={openAvailableProductsTransfer}
        productStockDialog={productStockDialog}
        handleSubmitTransferProductToVehicle={
          handleSubmitTransferProductToVehicle
        }
        handleCloseTransferProductDialog={handleCloseTransferProductDialog}
      />
    </Box>
  );
}

export default TransferProductVehicleToVehicleMain;
