/**
 * @author Lebansty Valan
 * @date 03/05/2023
 * @description Home page form admin webapp to show the graphical representation of the datas.
 * @copyright Bookwater tech pvt ltd
 */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Chart } from "react-google-charts";

// export const data = [
//     ["Task", "Hours per Day"],
//     ["Work", 11],
//     ["Eat", 2],
//     ["Commute", 2],
//     ["Watch TV", 2],
//     ["Sleep", 7], // CSS-style declaration
//   ];

  export const options = {
    title: "",
    pieHole: 0.4,
    is3D: false,
  };

function DonutPie(props) {
  return (
    <Box>
      
      <Typography sx={{ fontWeight: "bold", fontSize: "20px", color: "blue" }}>Users</Typography>
     <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={props.data}
      options={options}
    />
    </Box>
  )
}

export default DonutPie