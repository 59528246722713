import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { url } from "../apis";
import React from "react";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const Reportbasedondate = (
  fromValue,
  toValue,
  date,
  reportPgNo,
  pageSize
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/salesreport?phone=&daterange=${fromValue},${toValue}&pageNumber=${reportPgNo}&date=${date}&page_size=${pageSize}`
      );
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REPORT_DETAILS,
          payload: response.data,
        });
        if (response.data.data.length === 0) {
          toast.dark("Please Try With Different Dates", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};

export const ReportbasedonPhone = (emailPhone, date, reportPgNo, pageSize) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/salesreport?phone=${emailPhone}&daterange=&pageNumber=${reportPgNo}&date=${date}&page_size=${pageSize}`
      );
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REPORT_DETAILSBY_PHONE,
          payload: response.data,
        });
        if (response.data.data.length === 0) {
          toast.dark("Please Enter Valid Phone No", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};

export const ReportbasedonType = (reportType, date, reportPgNo, pageSize) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/salesreport/viewtype?type=${reportType}&pageNumber=${reportPgNo}&date=${date}&page_size=${pageSize}`
      );
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_REPORT_BY_TYPE,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};
