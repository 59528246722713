import React from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import CustomConsole from "../../CustomConsole";
import {
  PostpaidAcceptReject,
  GetPoTrackDetails,
} from "../Redux/Actions/adminPoTrackPostpaid";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import SubOrderDialog from "./SubOrderDialog";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DownloadIcon from "@mui/icons-material/Download";
import { getFiles, DownloadFile } from "../Redux/Actions/fileHandlingAction";
import { postpaidOrderStatus } from "../../Components/AppMeta/appMetaConfig";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { GetUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.90rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    // display: "flex",
    overflowX: "auto",
  },

  tableContainer: {
    overflowX: "auto",
    height: "100vh",
    "& .MuiTable-root": {
      width: "100%",
    },
    "& .MuiTableCell-root": {
      padding: "20px",
    },
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#036bfc",
    },
  },
  smallButton: {
    padding: "5px 7px", // Customize padding to make it smaller
    fontSize: "0.60rem",
    // Reduce font size to make it smaller
  },
}));

function PoOrderTable({
  poData,
  setOpenOrderDialog,
  assignDialog,
  setPostPaidTrack,
  setUserPhone,
  setSupplier,
  pageNumber,
  setPageNumber,
  setFilterApply,
  setInvoiceDialog,
  setInvoiceData,
  setOpenSettle,
  setPoTrackSet,
  setLastDate,
  pageSize,
  setPoRemQty,
  setDistributerName,
  setStringId,
  setPoproducts,
  setCreateDeliveriesFlg,
  setCreateDeliveryBtnFlag,
  createDeliveryBtnFlag,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const poDetails = useSelector((state) => state.poTrackDetails);
  const [rejectFlag, setRejectFlag] = React.useState(false);
  const [openOrdersDialog, setOrdersDialog] = React.useState(false);
  const [isPostpaid, setIsPostpaid] = React.useState(false);
  const [ordersData, setOrdersdata] = React.useState([]);
  const [trackId, setTrackId] = React.useState("");
  const [isPoOrder, setIsPoOrder] = React.useState(false);
  const [poQty, setPoQty] = React.useState(0);
  const [startDatePO, setStartDatePo] = React.useState(
    moment().tz("Asia/kolkata")
  );
  const [poStatus, setPoStatus] = React.useState(0);
  const [poExpired, setPoExpired] = React.useState(false);
  const [collapse, setCollapse] = React.useState([]);
  const [totalProducts, setTotalProducts] = React.useState([]);
  const [poProducts, setPoProducts] = React.useState([]);

  const postPaidAccept = (id) => {
    setPostPaidTrack(id);
    assignDialog(true);
  };

  const rejectPostPaid = (id) => {
    let reqObj = {
      pp_track_id: id,
      is_accepted: false,
    };
    if (reqObj.pp_track_id) {
      dispatch(PostpaidAcceptReject(reqObj));
      setRejectFlag(true);
    }
  };

  const handlePlaceOrder = (
    phone,
    id,
    supId,
    endDate,
    qty,
    totalQty,
    supplierProfile,
    strId,
    prodArr
  ) => {
    setSupplier(parseInt(supId));
    setPostPaidTrack(id);
    setUserPhone(phone);
    setOpenOrderDialog(true);
    setLastDate(endDate);
    setPoRemQty(() => qty - totalQty);
    setIsPostpaid(true);
    setDistributerName(() =>
      supplierProfile?.[0]?.fullname ? supplierProfile[0].fullname : ""
    );
    setStringId(strId);
    dispatch(GetUserAddressAdmin(strId));
    setPoproducts(() => (prodArr ? prodArr : []));
    // setCreateDeliveriesFlg(true);
    setCreateDeliveryBtnFlag(true);
  };

  const pageNation = (val) => {
    if (val === 0) {
      setPageNumber((prev) => (prev - 1 < 0 ? 0 : prev - 1));
      setFilterApply(true);
    } else {
      setPageNumber((prev) => prev + 1);
      setFilterApply(true);
    }
  };

  const openInvoiceDialog = (obj) => {
    setInvoiceDialog(true);
    setInvoiceData(obj);
    setUserPhone(obj.phone);
  };

  const settleOnClick = (track, phone) => {
    setOpenSettle(true);
    setPoTrackSet(track);
    setUserPhone(phone);
  };

  const invoiveBtn = (orderdetails) => {
    if (orderdetails && orderdetails.length) {
      return orderdetails.some(
        (obj) => obj.order_status === 500 && obj.settled === false
      );
    } else {
      return false;
    }
  };

  const settleBtn = (orderdetails) => {
    if (orderdetails && orderdetails.length) {
      // obj.settled === false &&
      return orderdetails.some((obj) => obj.order_status === 500);
    } else {
      return false;
    }
  };

  const checkExpieryPO = (date) => {
    // CustomConsole(moment(date).tz("Asia/Kolkata").format("DD-MM-YYYY"));
    const date1 = moment().tz("Asia/Kolkata").endOf("day");
    const date2 = moment(date).tz("Asia/Kolkata").startOf("day");

    const differenceInDays = date2.diff(date1, "days") + 1;
    // CustomConsole(`The difference in days is: ${differenceInDays}`);
    return differenceInDays;
  };

  const openOrdersFun = (
    orders,
    track,
    isPoOrder,
    status,
    qty,
    startDate,
    endDate,
    poProduct,
    orderIdArr
  ) => {
    if (status === 200 || status === 400) {
      console.log("----Order Arr---");
      console.log(orderIdArr);
      setPoStatus(status);
      setOrdersDialog(true);
      setOrdersdata(() => (orderIdArr.length > 0 ? orders : []));
      setTrackId(track);
      setIsPoOrder(!isPoOrder);
      setPoQty(qty);
      setStartDatePo(startDate);
      setPoExpired(() => (checkExpieryPO(endDate) > 0 ? false : true));
      setPoProducts(poProduct);
    }
  };

  const redirectFun = (phone) => {
    if (phone) {
      const url = `/dashboard/UserDetailsMain?phoneNumber=${phone}`;
      history.push(url);
    }
  };

  const downloadPo = (poFile) => {
    CustomConsole(poFile);
    // dispatch(getFiles(poFile));
    dispatch(DownloadFile(poFile));
  };

  const calculatePercentage = (part, whole) => {
    if (part && whole) {
      return (part / whole) * 100;
    } else {
      return 0;
    }
  };

  const rolesAccess = () => {
    return (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN
    );
  };

  const rolesAccessForDeliveries = () => {
    return (
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD
    );
  };

  const collapseCtrl = (idx, orders) => {
    let newCollapseArr = [...collapse];
    newCollapseArr[idx] = !newCollapseArr[idx];
    setCollapse(newCollapseArr);
    CustomConsole("---Orders --- in Bug---");
    CustomConsole(orders);
    //For total calculation;
    let totalProducts = orders
      .filter((obj) => obj.order_status === 500 || obj.order_status === 200)
      .flatMap((obj) => obj.ordered_products);
    CustomConsole(totalProducts);

    const totalQtyObj = Object.values(
      totalProducts.reduce((acc, obj) => {
        const key = `${obj.material}-${obj.capacity}-${obj.sku_qty}`;

        if (acc[key]) {
          acc[key].totalQty += obj.quantity;
        } else {
          acc[key] = {
            totalQty: obj.quantity,
            sku_qty: obj.sku_qty,
            material: obj.material,
            capacity: obj.capacity,
          };
        }
        return acc;
      }, {})
    );

    setTotalProducts(totalQtyObj);

    CustomConsole(totalQtyObj);
  };

  const twtyLiterHandle = (products) => {
    CustomConsole("--- products ---");
    CustomConsole(products);
    // if (products && (products.length || Object.keys(products).length)) {
    const totalQty = products.reduce((acc, obj) => {
      if (
        obj.container_capacity === "20_Liters" &&
        obj.container_material === "PET"
      ) {
        acc += obj.qty;
      }
      return acc;
    }, 0);
    return totalQty;
    // } else {
    //   return 0;
    // }
  };

  React.useEffect(() => {
    CustomConsole("---------Rejection---------");
    CustomConsole(rejectFlag);
    CustomConsole(poDetails.error);
    if (rejectFlag && poDetails.error === false) {
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setRejectFlag(false);
      let reqObj = {
        page_size: "",
        page_number: pageNumber,
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
    }
  }, [poDetails]);

  return (
    <>
      <Card>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="center">S.No</StyledTableCell>
                <StyledTableCell align="center">Customer Name</StyledTableCell>
                <StyledTableCell align="center">Customer PO</StyledTableCell>
                <StyledTableCell align="center">PO Order Id</StyledTableCell>
                <StyledTableCell align="center">PO Qty</StyledTableCell>
                <StyledTableCell align="center">Ordered Qty</StyledTableCell>
                <StyledTableCell align="center">Qty left</StyledTableCell>
                <StyledTableCell align="center">Days To Expire</StyledTableCell>
                <StyledTableCell align="center">Start date</StyledTableCell>
                <StyledTableCell align="center">Order Status</StyledTableCell>
                <StyledTableCell align="center">Customer Phone</StyledTableCell>
                <StyledTableCell align="center">Distributer</StyledTableCell>
                <StyledTableCell align="center">PO File</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poData && poData.length ? (
                poData.map((row, idx) => (
                  <>
                    <StyledTableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      key={row.name}
                      className={
                        !row.is_po_order && row.order_status !== 300
                          ? "blinking-row"
                          : ""
                      }
                    >
                      <StyledTableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => collapseCtrl(idx, row.order_details)}
                        >
                          {collapse[idx] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {pageNumber *
                          (parseInt(pageSize) ? parseInt(pageSize) : 10) +
                          idx +
                          1}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tableRow}
                        onClick={() => redirectFun(row.phone)}
                        align="center"
                      >
                        {row.consumername}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.po_number ? row.po_number : "-"}
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.tableRow}
                        onClick={() =>
                          openOrdersFun(
                            row.order_details,
                            row.pp_track_id,
                            row.is_po_order,
                            row.order_status,
                            twtyLiterHandle(row.po_products),
                            row.start_date,
                            row.end_date,
                            row.po_products,
                            row.list_of_pdworders
                          )
                        }
                        align="center"
                      >
                        {row.pp_track_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {twtyLiterHandle(row.po_products)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {parseInt(row.total_quantity)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {calculatePercentage(
                          parseInt(row.total_quantity),
                          parseInt(twtyLiterHandle(row.po_products))
                        ) >= 80 ? (
                          <span style={{ color: "red" }}>
                            {parseInt(twtyLiterHandle(row.po_products)) -
                              parseInt(row.total_quantity)}
                          </span>
                        ) : (
                          parseInt(twtyLiterHandle(row.po_products)) -
                          parseInt(row.total_quantity)
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {checkExpieryPO(row.end_date) <= 0 ? (
                          <span style={{ color: "red" }}>Expired</span>
                        ) : checkExpieryPO(row.end_date) <= 7 ? (
                          <span style={{ color: "red" }}>
                            {checkExpieryPO(row.end_date)}
                          </span>
                        ) : (
                          checkExpieryPO(row.end_date)
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(row.start_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.order_status ===
                        postpaidOrderStatus.POST_PAID_REQUESTED
                          ? "Requested"
                          : row.order_status ===
                            postpaidOrderStatus.POST_PAID_ACCEPTED
                          ? "Accepted"
                          : row.order_status ===
                            postpaidOrderStatus.POST_PAID_REJECTED
                          ? "Rejected"
                          : row.order_status ===
                            postpaidOrderStatus.POST_PAID_FORCE_CANCEL
                          ? "Force Cancelled"
                          : "--"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.supplier_profile?.[0]?.fullname
                          ? row.supplier_profile[0].fullname
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.po_filename ? (
                          <DownloadIcon
                            onClick={() => downloadPo(row.po_filename)}
                            sx={{
                              color: "blue",
                              fontSize: "30px",
                              margin: "5px",
                              "&:hover": { backgroundColor: "#008080" },
                            }}
                          />
                        ) : (
                          "No PO File"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.order_status === 100 &&
                        checkExpieryPO(row.end_date) > 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.smallButton}
                              onClick={() => postPaidAccept(row.pp_track_id)}
                            >
                              Accept
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.smallButton}
                              color="error"
                              onClick={() => rejectPostPaid(row.pp_track_id)}
                            >
                              Reject
                            </Button>
                          </Box>
                        ) : row.order_status === 200 ||
                          row.order_status === 400 ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              {row.order_status === 200 &&
                              checkExpieryPO(row.end_date) > 0 &&
                              rolesAccessForDeliveries() ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.smallButton}
                                  onClick={() =>
                                    handlePlaceOrder(
                                      row.phone,
                                      row.pp_track_id,
                                      row.supplier_id,
                                      row.end_date,
                                      twtyLiterHandle(row.po_products),
                                      row.total_quantity,
                                      row.supplier_profile,
                                      row.stringid,
                                      row.po_products
                                    )
                                  }
                                >
                                  Create Deliveries
                                </Button>
                              ) : null}

                              {invoiveBtn(row.order_details) &&
                              rolesAccess() ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.smallButton}
                                  onClick={() => openInvoiceDialog(row)}
                                >
                                  Generate Invoice
                                </Button>
                              ) : null}
                            </Box>
                            <Box mt={1}>
                              {settleBtn(row.order_details) && rolesAccess() ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  className={classes.smallButton}
                                  onClick={() =>
                                    settleOnClick(row.pp_track_id, row.phone)
                                  }
                                >
                                  Settle Invoice
                                </Button>
                              ) : null}
                            </Box>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={collapse[idx]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Ordered Product Quantity
                            </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Material</TableCell>
                                  <TableCell>Capacity</TableCell>
                                  <TableCell align="right">SKU</TableCell>
                                  <TableCell align="right">Qty.</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {totalProducts.map((prod, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                      {prod.material}
                                    </TableCell>
                                    <TableCell>{prod.capacity}</TableCell>
                                    <TableCell align="right">
                                      {prod.sku_qty}
                                    </TableCell>
                                    <TableCell align="right">
                                      {prod.totalQty}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Alert severity="error">No PO request found!</Alert>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "center", margin: "30px" }}>
          <Button onClick={() => pageNation(0)} disabled={pageNumber === 0}>
            <ArrowBackIos />
          </Button>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber + 1}
          </Typography>
          <Button
            onClick={() => pageNation(1)}
            disabled={!poData || !poData.length}
          >
            <ArrowForwardIos />
          </Button>
        </Box>
      </Card>

      <SubOrderDialog
        open={openOrdersDialog}
        setOpen={setOrdersDialog}
        ordersData={ordersData}
        trackId={trackId}
        isPoOrder={isPoOrder}
        pageNumber={pageNumber}
        startDatePO={startDatePO}
        poQty={poQty}
        poStatus={poStatus}
        poExpired={poExpired}
        poProducts={poProducts}
      />
    </>
  );
}

export default PoOrderTable;
