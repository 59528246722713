/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  darkScrollbar,
  Chip,
} from "@mui/material";
import {
  CropFreeTwoTone,
  BatteryFullTwoTone,
  ShowChartOutlined,
  RepeatOne,
  HighlightOff,
} from "@mui/icons-material";
import { MarkDamageCanCallback } from "../../../Pages/Redux/Actions/supliersAction";
import QrScanners from "../../Scanners/QrScanners";
import { GetUserDepositDetails } from "../../../Pages/Redux/Actions/adminBookingAction";

const reasonCodes = {
  QrBarcodeDamaged: 100,
  PhysicallyDamaged: 200,
  containerReceived: 1,
  constanerNotReceived: 0,
};
const MarkingDamage = ({ open, setOpen, user_id, order_id, products }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [containerReceived, setContainerReceived] = useState();
  const [qrCodes, setQrCodes] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState();
  //   const [userStock, setUserStock] = useState(products ? products : []);
  //   const userDetails = useSelector((state) => state.adminBookingData);
  const dispatch = useDispatch();
  const handleQrChange = async (qr_code) => {
    if (qr_code.trim().length < 5) {
      return;
    }
    if (qrCodes.includes(qr_code)) {
    } else {
      qrCodes.push(qr_code);
      setQrCodes([...qrCodes]);
    }
  };
  const onSubmit = async () => {
    let selectedProduct;
    if (selectedProductId) {
      selectedProduct = products.find(
        (product) =>
          `${product?.capacity}${product?.material}` === selectedProductId
      );
    }
    const resp = await MarkDamageCanCallback({
      qr_code: qrCodes,
      order_id: order_id,
      type_of_can: selectedReason,
      capacity: selectedProduct?.capacity,
      material: selectedProduct?.material,
      is_received: containerReceived,
    });
    if (!resp) {
      toast.error("Unable to Mark Damage", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
    }
  };
  useEffect(() => {
    if (user_id) {
      dispatch(GetUserDepositDetails({ userId: user_id }));
    }
  }, [user_id]);
  //   useEffect(() => {
  //     if (!products) {
  //       setUserStock(userDetails?.userStockData || []);
  //        //@Removed/console.log(userDetails?.userStockData);
  //     }
  //   }, [userDetails?.userStockData]);
  return (
    <Box
      sx={
        {
          // width: "100%",
        }
      }
    >
      <Dialog
        fullWidth
        open={open}
        PaperProps={{
          style: {
            // width: "100%",

            backgroundColor: "#f0f0f0", // Light gray background for the entire Dialog
            boxShadow: "none", // Optional: removes shadow
            borderRadius: "8px", // Rounded corners for the Dialog
          },
        }}
      >
        <DialogContent
          sx={{
            width: "100%",
            height: "700px",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            alignContent: "center",
            // justifyContent: "center",
          }}
        >
          <FormControl variant="outlined" size="small">
            <InputLabel id="demo-multiple-name-label">
              Reason For Damage
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-multiple-name-label"
              id="demo-select-small"
              value={selectedReason}
              label="Reason For Damage"
              onChange={(e) => {
                setSelectedProductId();
                setContainerReceived();
                setQrCodes([]);
                setSelectedReason(e.target.value);
              }}
            >
              <MenuItem value={reasonCodes.PhysicallyDamaged}>
                Physical Damage{" "}
                <Stack position="relative">
                  <Box color="primary" aria-label="icon 1">
                    <BatteryFullTwoTone
                      sx={{
                        color: "red",
                      }}
                    />
                    {/* Your icon component here, e.g., <FavoriteIcon /> */}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                    }}
                    color="error"
                    aria-label="overlay icon"
                  >
                    <ShowChartOutlined
                      sx={{
                        color: "black",
                      }}
                    />
                    {/* Your other icon component here, e.g., <EditIcon /> */}
                  </Box>
                </Stack>
              </MenuItem>
              <MenuItem value={reasonCodes.QrBarcodeDamaged}>
                Qr/Barcode Damaged{" "}
                <CropFreeTwoTone
                  sx={{
                    color: "red",
                  }}
                />
              </MenuItem>
            </Select>
          </FormControl>
          {selectedReason && (
            <>
              {selectedReason !== reasonCodes.QrBarcodeDamaged ? (
                <>
                  <QrScanners handleChange={handleQrChange} />
                  {qrCodes && (
                    <Chip
                      label={`${qrCodes.length} Container Scanned`}
                      color="success"
                    ></Chip>
                  )}
                </>
              ) : (
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Select Product</InputLabel>
                  <Select
                    onChange={(e) => setSelectedProductId(e.target.value)}
                    label="Select Product"
                  >
                    {products?.map((item) => (
                      <MenuItem value={`${item?.capacity}${item?.material}`}>
                        {item?.capacity}
                        {item?.material}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {qrCodes.length || selectedProductId ? (
            <Box>
              <Box>Did you receive the Can?</Box>
              <RadioGroup
                defaultValue="scanner"
                key={containerReceived}
                value={containerReceived}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={(e) => {
                  setContainerReceived(
                    e.target.value === "false"
                      ? false
                      : e.target.value === "true"
                      ? true
                      : ""
                  );
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      borderRadius: 1,
                      border: 3,
                      borderColor: "green",
                    }}
                    value={true}
                    control={<Radio />}
                    label={
                      <>
                        Recieved <RepeatOne color="success" />
                      </>
                    }
                  />
                  <FormControlLabel
                    sx={{
                      borderRadius: 1,
                      border: 3,
                      borderColor: "red",
                    }}
                    value={false}
                    control={<Radio />}
                    label={
                      <>
                        Not Recieved <HighlightOff color="error" />
                      </>
                    }
                  />
                </Box>
              </RadioGroup>
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {(qrCodes.length || selectedProductId) &&
            (containerReceived === true || containerReceived === false) ? (
              <Button onClick={onSubmit} variant="contained">
                Submit
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            size="small"
            color="error"
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MarkingDamage;
