import axios from "axios";
import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";
import { url } from "../apis";
export const GetSearchOrderDetails = (
  {
    email_phone,
    from_date,
    to_date,
    order_status,
    sortorder,
    isBooking,
    delivery_status,
    pincode,
    orderType,
    page_size,
    pageNum,
  },
  props
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(
    email_phone,
    from_date,
    to_date,
    order_status,
    sortorder,
    isBooking,
    delivery_status,
    pincode,
    orderType,
    page_size,
    pageNum
  );
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/orders?email_phone=${email_phone}&is_booking_date=${isBooking}&from_date=${from_date}&to_date=${to_date}&order_status=${order_status}&delivery_status=${delivery_status}&order_type=${orderType}&pincode=${pincode}&pageSize=${page_size}&page_number=${pageNum}`,
        { params: props }
      );
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SEARCH_ORDER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_SEARCH_ORDER_DETAILS,
        payload: error.response.data,
      });
      // toast.dark(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 3000,
      //   type: "error",
      // });
    }
  };
};
export const GetNewSearchOrderDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/pdw/orders`, reqObj);
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_NEW_SEARCH_ORDER_DETAILS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GET_NEW_SEARCH_ORDER_DETAILS,
          payload: {
            data: [],
            order_summary: {},
          },
        });
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      // dispatch({
      //   type: actionType.GET_NEW_SEARCH_ORDER_DETAILS,
      //   payload: error.response.data,
      // });
      // toast.dark(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 3000,
      //   type: "error",
      // });
    }
  };
};
// export const GetSearchOrderDetails = ({
//   email_phone,
//   from_date,
//   to_date,
//   order_status,
//   sortorder,
//   isBooking,
//   delivery_status,
//   pincode,
//   orderType,
//   page_size,
//   pageNum,
// }) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };
//   let api = useAxios();
//   CustomConsole(
//     email_phone,
//     from_date,
//     to_date,
//     order_status,
//     sortorder,
//     isBooking,
//     delivery_status,
//     pincode,
//     orderType,
//     page_size,
//     pageNum
//   );
//   return async (dispatch) => {
//     try {
//       let response = await api.get(
//         `/OM/orders?email_phone=${email_phone}&is_booking_date=${isBooking}&from_date=${from_date}&to_date=${to_date}&order_status=${order_status}&delivery_status=${delivery_status}&sort_order=${sortorder}&order_type=${orderType}&pincode=${pincode}&pageSize=${page_size}&page_number=${pageNum}`
//       );
//       CustomConsole("subscription orders response");
//       CustomConsole(response);
//       if (response.status === httpStatus.SUCCESS) {
//         dispatch({
//           type: actionType.GET_SEARCH_ORDER_DETAILS,
//           payload: response.data,
//         });
//       } else {
//         // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
//       }
//     } catch (error) {
//       dispatch({
//         type: actionType.GET_SEARCH_ORDER_DETAILS,
//         payload: error.response.data,
//       });
//       // toast.dark(error.response.data.msg, {
//       //   position: "bottom-right",
//       //   autoClose: 3000,
//       //   type: "error",
//       // });
//     }
//   };
// };
export const GetViewOrderDetails = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  const errorObj = {
    error: true,
    order_details: {},
  };
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/orders/${orderId}`);
      CustomConsole("subscription orders response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error === true) {
          dispatch({
            type: actionType.GET_VIEW_ORDER_DETAILS,
            payload: errorObj,
          });
        } else {
          dispatch({
            type: actionType.GET_VIEW_ORDER_DETAILS,
            payload: response.data,
          });
        }
        if (response.data.msg) {
          //   toast.dark(response.data.msg, {
          //     position: "bottom-right",
          //     autoClose: 3000,
          //     type: "error",
          //   });
        }
        CustomConsole("----------error---------");
      } else {
        CustomConsole("Else block orders"); // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("-----Error---");
      CustomConsole(error);
    }
  };
};
export const GetDeliverySlotsList = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/supplierAdmin/deliverySlots/getAvailableSlots`
      );
      CustomConsole("---Delivery slots List---");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DELIVERY_SLOTS_LIST,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const GetOrderDeliverySlots = (orderId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/deliverySlots/getDeliveryTimeSlot?order_id=${orderId}`
      );
      CustomConsole("---Delivery slots ---");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DELIVERY_SLOTS,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const AddDeliverySlots = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/supplier/deliverySlots/addTiming`,
        reqObj
      );
      CustomConsole("---Delivery slots---");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_DELIVERY_SLOTS,
          payload: response.data,
        });
        if (response.data.error === false) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        } else if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
export const RejectResonDetails = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/orders/${orderId}/reject`, reqObj);
      CustomConsole("Reject Reason response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.REJECT_REASON_ORDER,
          payload: response.data,
        });
        toast.dark("Update has done", {
          position: "bottom-right",
          autoClose: 5000,
          type: "info",
        });
      } else {
      }
    } catch (error) {
      toast.dark("Alredy rejected", {
        position: "bottom-right",
        autoClose: 5000,
        type: "info",
      });
      CustomConsole("reject reason is not done");
    }
  };
};
export const CancelResonDetails = (orderId, reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/orders/${orderId}/cancel`, reqObj);
      CustomConsole("Reject Reason response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_REASON_ORDER,
          payload: response.data,
        });
        toast.dark("Update has done", {
          position: "bottom-right",
          autoClose: 5000,
          type: "info",
        });
      } else {
      }
    } catch (error) {
      toast.dark("Cannot cancel order after order has started", {
        position: "bottom-right",
        autoClose: 5000,
        type: "info",
      });
      CustomConsole("reject reason is not done");
    }
  };
};
export const GetPinCodeDeltails = (pincode, orderid) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(pincode, orderid);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/supplierlistPincodeBased?pincode=${pincode}&order_id=${orderid}`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PINCODE_DETAILS,
          payload: response.data,
        });
        if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};
export const RegularOrdersAssignSupplier = (reqobj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqobj);
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/order/assign/distributor`, reqobj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_REGULAR_ORDERS_SUPPLIER_DETAILS,
          payload: response.data,
        });
        if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        } else {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};
export const AssignSupplier = (reqobj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqobj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/admin/reAssignPdwSupplier`, reqobj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_SUPPLIER_DETAILS,
          payload: response.data,
        });
        if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        } else {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};
export const RegectReasonDetails = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/setupconfig/appmeta`);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.Regect_Reason_Details,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};
export const DownloadEmailInvoice = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.get(
        `PM/invoices/${reqObj.order_id}/send_email?is_consumer_supplier=${reqObj.radioType}`
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DOWNLOAD_EMAIL_INVOICE,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};
export const DownloadInvoice = (order_id) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      CustomConsole(`${url}/PM/invoices/${order_id}/download`);
      await fetch(`${url}/PM/invoices/${order_id}/download`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/pdf",
        },
        // responseType: "arrayBuffer",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          CustomConsole("download" + link.href);
          window.open(link.href);
          dispatch({
            type: actionType.DOWNLOAD_INVOICE,
            payload: link.href,
          });
        })
        .catch((error) => {
          CustomConsole("download invoice error : " + error);
        });
    };
  } else {
    toast.dark("Error while downloading", {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};
// export const EditOrderUser = (orderId, reqObj) => {
//   const httpStatus = {
//     SUCCESS: 200,
//     BAD_REQUEST: 400,
//   };
//   let api = useAxios();
//   CustomConsole(reqObj);
//   return async (dispatch) => {
//     try {
//       let response = await api.put(`OM/orders/${orderId}/editOrder`, reqObj);
//       CustomConsole(response);
//       if (response.status === httpStatus.SUCCESS) {
//         dispatch({
//           type: actionType.EDIT_ORDER_USER,
//           payload: response.data,
//         });
//         toast.dark(response.data.msg, {
//           position: "bottom-right",
//           autoClose: 5000,
//           type: "info",
//         });
//       }
//     } catch (error) {
//       CustomConsole("Error has occured");
//       toast.dark(error, {
//         position: "bottom-right",
//         autoClose: 5000,
//         type: "info",
//       });
//     }
//   };
// };
export const EditOrderUser = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/order/edit`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EDIT_ORDER_USER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "info",
        });
      }
    } catch (error) {
      CustomConsole("Error has occured");
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};
export const ToGetOtp = (_id) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/deliveries?order_id=${_id}`);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.TO_GET_OTP,
          payload: response.data,
        });
      }
    } catch (error) {
      CustomConsole("Error has occured");
    }
  };
};
export const ChangeDeliveryDate = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/changeDeliveryDate`, reqObj);
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        if (response.data.error) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 2000,
            type: "info",
          });
        } else {
          dispatch({
            type: actionType.CHANGE_DELIVERY_DATE,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      CustomConsole("Error has occured");
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "info",
      });
    }
  };
};
export const GetSupplierTransferCansOrderList = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/transferCans/orderLists`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIER_TRANSFER_CANS_ORDER_LIST,
          payload: response.data,
        });
      } else {
        // toast.error(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      }
    } catch (error) {
      CustomConsole(error);
      // toast.error(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  };
};
export const GetTransferCansSupplierList = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/admin/transferCans/supplierLists`, {
        params: props,
      });
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_SUPPLIER_TRANSFER_CANS_SUPPLIER_LIST,
          payload: response.data,
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
export const PutTransferAcceptReject = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/admin/transferCans/accept/reject`,
        reqObj
      );
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.PUT_TRANSFER_CANS_ACCEPT_REJECT,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      CustomConsole(error);
    }
  };
};

export const CancelOrderDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/order/cancel`, reqObj);
      CustomConsole("Reject Reason response");
      CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_ORDER,
          payload: response.data,
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      } else {
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 5000,
        type: "info",
      });
      CustomConsole("reject reason is not done");
    }
  };
};

/**
 * @author Tahir Shaik
 * @date 20-03-2024
 * @description Action to get the list of Packaged drinking water order details with different filter options
 * @param {}
 * @returns {}
 */
export const GetPdwOrderDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      dispatch({
        type: actionType.GET_PDW_ORDER_DETAILS,
        payload: {
          orders: [],
          order_summary: {},
        },
      });
      let response = await api.post(`/OM/pdw/orders`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response.data.order_summary);
        dispatch({
          type: actionType.GET_PDW_ORDER_DETAILS,
          payload: {
            orders: response.data.data,
            order_summary: response.data.order_summary,
          },
        });
      } else {
        dispatch({
          type: actionType.GET_PDW_ORDER_DETAILS,
          payload: { orders: [], order_summary: {} },
        });
      }
    } catch (error) {
      // toast.dark(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   type: "info",
      // });
      dispatch({
        type: actionType.GET_PDW_ORDER_DETAILS,
        payload: { orders: [], order_summary: {} },
      });
    }
  };
};

/**
 * @author Tahir Shaik
 * @date 06-06-2024
 * @description Get the Orders & Products Summary
 * @param {}
 * @returns {}
 */
export const GetPdwOrderSummaryDetails = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/pdw/orders/summary`, {
        params: reqObj,
      });
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(response.data);
      } else {
        CustomConsole("-- in else --");
        CustomConsole(response.data);
      }
      dispatch({
        type: actionType.GET_PDW_ORDER_SUMMARY_DETAILS,
        payload: {
          orders:
            response &&
            response.data &&
            response.data.data &&
            response.data.data.orders
              ? response.data.data.orders
              : {},
          products:
            response &&
            response.data &&
            response.data.data &&
            response.data.data.products
              ? response.data.data.products
              : [],
        },
      });
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.GET_PDW_ORDER_SUMMARY_DETAILS,
        payload: { orders: {}, products: [] },
      });
    }
  };
};

export const PostForceCompleteScanning = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `OM/consumer/order/force/complete/scanning`,
        reqObj
      );
      // CustomConsole(response);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.POST_FORCE_COMPLETE_SCANNING,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // CustomConsole("-----------error-------");
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      toast.error(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};
