/**
 * @copycenter Copycenter (c) 2024 Bookwater
 * @author Tahir Shaik
 * @date 20-03-2024
 * @description Component to get the list of PDE Order details
 */
//Importing the libraries
import React, { useEffect } from "react";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Paper,
  Card,
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import WidthFullIcon from "@mui/icons-material/WidthFull";
import SyncIcon from "@mui/icons-material/Sync";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchIcon from "@mui/icons-material/Search";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import moment from "moment";
import RegularOrdersTable from "./RegularOrdersTable";
import {
  GetDeliverySlotsList,
  GetNewSearchOrderDetails,
  GetPdwOrderDetails,
  GetPdwOrderSummaryDetails,
} from "../Redux/Actions/ordersAdminAction";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { CSVLink } from "react-csv";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { Discount } from "@mui/icons-material";
import { toast } from "react-toastify";
const TZ = "Asia/Kolkata";
//Main Component
function RegularOrders({ setIsEditOrderDetailsDialog }) {
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);

  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = React.useState(true);
  const orderDetailsList = useSelector((state) => state.orderDetailsAdmin);
  const seacrchedUserStore = useSelector((state) => state.addUserAdminDetails);
  const availableProducts = useSelector(
    (state) => state.newProductContainerDetails
  );
  const history = useHistory();
  const [orderSummary, setOrderSummary] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [user, setUser] = React.useState(" ");
  const [pincode, setPincode] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [product, setProduct] = React.useState(" ");
  const [sortBy, setSortBy] = React.useState("Order ID (Z-A)");
  const [orderType, setOrderType] = React.useState("None");
  const [orderStatus, setOrderStatus] = React.useState(200);
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [consumerId, setConsumerId] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(0);
  const [driverId, setDriverId] = React.useState(0);
  const [previouseDates, setPreviousDates] = React.useState({
    from: "",
    to: "",
  });
  const [isUserSearchValid, setIsUserSearchValid] = React.useState(false);
  const [products, setProducts] = React.useState([
    { capacity: "None", material: " ", name: " " },
  ]);
  const orderTypes = ["None", "INSTA", "REGULAR", "POSTPAID", "SUBSCRIBED"];
  const orderStatusDetails = [
    { key: 0, value: "None" },
    { key: 100, value: "New requests" },
    { key: 200, value: "Ongoing" },
    { key: 500, value: "Delivered" },
    { key: 400, value: "Cancelled" },
    { key: 300, value: "Rejected" },
  ];
  const sorKeys = [
    { key: "order_id", value: "Order ID (A-Z)", order: "ASC" },
    { key: "order_id", value: "Order ID (Z-A)", order: "DESC" },
    { key: "order_status_name", value: "Order Status (A-Z)", order: "ASC" },
    { key: "order_status_name", value: "Order Status (Z-A)", order: "DESC" },
    { key: "order_type_name", value: "Order Type (A-Z)", order: "ASC" },
    { key: "order_type_name", value: "Order Type (Z-A)", order: "DESC" },
    { key: "delivery_slot", value: "Delivery Slot (A-Z)", order: "ASC" },
    { key: "delivery_slot", value: "Delivery Slot (Z-A)", order: "DESC" },
    { key: "pincode", value: "Pincode (A-Z)", order: "ASC" },
    { key: "pincode", value: "Pincode (Z-A)", order: "DESC" },
    { key: "booking_date", value: "Booking Date  (A-Z)", order: "ASC" },
    { key: "booking_date", value: "Booking Date  (Z-A)", order: "DESC" },
    { key: "booking_time", value: "Booking Time  (A-Z)", order: "ASC" },
    { key: "booking_time", value: "Booking Time  (Z-A)", order: "DESC" },
    { key: "delivery_date", value: "Delivery Date  (A-Z)", order: "ASC" },
    { key: "delivery_date", value: "Delivery Date  (Z-A)", order: "DESC" },
    { key: "delivery_time", value: "Delivery Time  (A-Z)", order: "ASC" },
    { key: "delivery_time", value: "Delivery Time  (Z-A)", order: "DESC" },
    { key: "consumer_name", value: "Consumer Name (A-Z)", order: "ASC" },
    { key: "consumer_name", value: "Consumer Name (Z-A)", order: "DESC" },
    { key: "payment_status_name", value: "Payment Status (A-Z)", order: "ASC" },
    {
      key: "payment_status_name",
      value: "Payment Status (Z-A)",
      order: "DESC",
    },
    { key: "payment_type_name", value: "Payment Type (A-Z)", order: "ASC" },
    { key: "payment_type_name", value: "Payment Type (Z-A)", order: "DESC" },
    { key: "payment_method", value: "Payment Method (A-Z)", order: "ASC" },
    { key: "payment_method", value: "Payment Method (Z-A)", order: "ASC" },
  ];
  const pageSizeList = [25, 50, 100, 200, 500, 1000, 2500, 5000];
  const tableHeader = [
    "S.No",
    "Order ID",
    "OTP",
    "Pincode",
    "Service Area",
    "20L Can Qty.",
    "Delivery Slot",
    "Delivery Contact Details",
    "Vehicle ID",
    "Driver Details",
    "Order Type",
    "Order Status",
    "Consumer Details",
    "Payment Method",
    "Payment Status",
    "Order Price(With Discount)",
    "Discount",
    "Discount Type",
    "Supplier",
    "Booking Date & Time",
    "Delivery Date & Time",
    "Payment Details",
    "Last Updated at",
    "Last Updated by",
    "Cancel Reason",
  ];
  const [fromDate, setFromDate] = React.useState(
    moment().tz(TZ).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment().tz(TZ).format("YYYY-MM-DD")
  );
  //On-Mount Hit the Products And Get the Available Products
  React.useState(() => {
    if (isMounted) {
      CustomConsole("isMounted: ", isMounted);
      dispatch(GetAvailableProducts());
      setIsMounted(false);
    }
  }, [isMounted]);
  //Available Products Rendering & Updating the Products Variable
  React.useEffect(() => {
    const prod = [{ capacity: "None", material: " ", name: " " }];
    if (
      availableProducts.getAvailableProducts &&
      availableProducts.getAvailableProducts.length
    ) {
      for (const data of availableProducts.getAvailableProducts) {
        prod.push({
          capacity: data.product_details.capacity,
          material: data.product_details.material,
          name: data.product_details.name,
        });
      }
    }
    setProducts(prod);
  }, [availableProducts.getAvailableProducts]);

  //storing user number from users page
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");
  //storing Order Id from URL(Redirecting from Transaction page)
  const order_id = searchParams.get("order_id");
  //setting the Order Id and hitting the API for search user with the Order Id from URL(Redirecting from Transaction page)
  React.useEffect(() => {
    CustomConsole(order_id);
    if (order_id) {
      CustomConsole("------------order_id---------");
      // handleSearchOrderId();
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 1,
        page_number: 1,
        order_ids: [order_id],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      setTimeout(() => {
        dispatch(GetDeliverySlotsList());
      }, 1000);
      setIsEditOrderDetailsDialog(true);
    } else {
      setIsEditOrderDetailsDialog(false);
    }
  }, [order_id]);

  React.useEffect(() => {
    CustomConsole("user: VINBAY " + user);
    if (user === undefined || user === null || (!user.length && user === "")) {
      CustomConsole("SUCCESS");
      setConsumerId(0);
      setSupplierId(0);
      setDriverId(0);
    }
  }, [user]);
  // Setting the phone number and hitting the API for search user with the phone number from URL(Redirecting from users page)
  React.useEffect(() => {
    setUser(phoneNumberFromOrders);
    if (phoneNumberFromOrders !== null) {
      dispatch(SearchUserAdmin(phoneNumberFromOrders));
    }
  }, [phoneNumberFromOrders]);
  //Pdw Order Details rendering for Showing the Data to user (Response from API)
  React.useEffect(() => {
    console.log(orderDetailsList.pdwOrderSummary);
    if (
      orderDetailsList.pdwOrderDetails &&
      orderDetailsList.pdwOrderDetails.order_summary &&
      Object.keys(orderDetailsList.pdwOrderDetails.order_summary).length
    ) {
      setOrderSummary([
        {
          orderType: "Insta",
          data: [
            {
              header: "Total",
              value: orderDetailsList.pdwOrderDetails.order_summary.insta.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.cancelled,
            },
          ],
        },
        {
          orderType: "Regular",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .cancelled,
            },
          ],
        },
        {
          orderType: "Postpaid",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .cancelled,
            },
          ],
        },
        {
          orderType: "Subscription",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .cancelled,
            },
          ],
        },
      ]);
    } else {
      setOrderSummary([]);
    }
    if (
      orderDetailsList.pdwOrderDetails &&
      orderDetailsList.pdwOrderDetails.orders &&
      orderDetailsList.pdwOrderDetails.orders.length
    ) {
      setOrderList(orderDetailsList.pdwOrderDetails.orders);
    } else {
      setOrderList([]);
    }
    // if (consumerId) {
    //   setConsumerId(0);
    // }
    // if (supplierId) {
    //   setSupplierId(0);
    // }
    // if (driverId) {
    //   setDriverId(0);
    // }
    if (isUserSearchValid) {
      setIsUserSearchValid(false);
    }
  }, [orderDetailsList.pdwOrderDetails]);
  //User Details (Searched User)
  React.useEffect(() => {
    if (seacrchedUserStore.users && seacrchedUserStore.users.length) {
      if (seacrchedUserStore.users[0].consumer_id) {
        CustomConsole("---------users----------");
        setConsumerId(seacrchedUserStore.users[0].consumer_id);
        setDriverId(0);
        setSupplierId(0);
      } else if (seacrchedUserStore.users[0].delivery_agent_id) {
        setConsumerId(0);
        setDriverId(seacrchedUserStore.users[0].delivery_agent_id);
        setSupplierId(0);
      } else if (seacrchedUserStore.users[0].supplier_id) {
        setConsumerId(0);
        setDriverId(0);
        setSupplierId(seacrchedUserStore.users[0].supplier_id);
      }
    } else {
      setConsumerId(0);
      setDriverId(0);
      setSupplierId(0);
    }
  }, [seacrchedUserStore.users]);
  //Render & Hit the Order Details API on Seaching the User by phone or Email
  React.useEffect(() => {
    if (supplierId || consumerId || driverId) {
      OrderDetailsAndSummaryApi();
    }
  }, [supplierId, consumerId, driverId]);
  //User Effect to Render the Variables on Change
  React.useEffect(() => {}, [orderSummary, orderList, user, orderId]);
  //Get the Order Details on Change in PageNumber or Pagesize or sortBy
  React.useEffect(() => {
    OrderDetailsAndSummaryApi();
  }, [sortBy, pageNumber, pageSize]);

  const OrderDetailsAndSummaryApi = () => {
    let sortByDetails = {};
    const sortData = sorKeys.filter((data) => data.value === sortBy);
    if (sortData && sortData.length) {
      sortByDetails.key = sortData[0].key;
      sortByDetails.value = sortData[0].order;
    }
    let finalSelectedProduct = [];
    CustomConsole(product);
    if (product && product.length) {
      finalSelectedProduct = products.filter(
        (item) =>
          item.name === product &&
          item.name.trim() !== "" &&
          item.capacity !== "None"
      );
    }
    CustomConsole(finalSelectedProduct);
    dispatch(
      GetPdwOrderDetails({
        is_order_summary_required: true,
        delivery_from_date: fromDate,
        delivery_to_date: toDate,
        page_size: pageSize,
        page_number: pageNumber,
        sort_by: Object.keys(sortByDetails).length
          ? [sortByDetails]
          : undefined,
        order_status: orderStatus ? orderStatus : undefined,
        pincode: pincode ? pincode : undefined,
        order_type:
          orderType.trim().length && orderType !== "None"
            ? orderType.trim()
            : undefined,
        // order_ids: orderId.length ? [orderId] : undefined,
        consumer_id: consumerId ? consumerId : undefined,
        supplier_id: supplierId ? supplierId : undefined,
        delivery_agent_id: driverId ? driverId : undefined,
        products: finalSelectedProduct.length
          ? finalSelectedProduct
          : undefined,
      })
    );
    if (previouseDates.from !== fromDate || previouseDates.to !== toDate) {
      setPreviousDates({
        from: fromDate,
        to: toDate,
      });
      dispatch(
        GetPdwOrderSummaryDetails({
          delivery_from_date: fromDate,
          delivery_to_date: toDate,
        })
      );
    }
  };
  //Search User Order based on User Name or Phone or Email
  const handleSeacrhUserOrders = () => {
    CustomConsole("-------------handleSeacrhUserOrders------------------");
    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const regPhone = /^[0-9][0-9]{9}$/g;
    //Admin Entered an Email or Phone
    CustomConsole(user);
    if (
      user !== undefined &&
      user !== null &&
      (regPhone.test(user.trim()) || regEmail.test(user.trim()))
    ) {
      dispatch(SearchUserAdmin(user.trim()));
    } else {
      setConsumerId(0);
      setSupplierId(0);
      setIsUserSearchValid(true);
      CustomConsole("Please enter valid phone/email/name of the user!");
    }
  };
  //Search the Order details by order Id
  const handleSearchOrderId = (props) => {
    allOrderData.newallOrderDetails = [];
    //@ToDo : Vinay -> Hit the Single Order API & Open Dialog Box
    CustomConsole("-----------handleSearchOrderId----------------");
    let finalOrderId = "";
    if ((props && props.length) || orderId) {
      finalOrderId = props ? props : "BTORD-" + orderId;
    }
    if (finalOrderId.length) {
      //Hit API Here
      CustomConsole("-----------handleSearchOrderId----------------");
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 1,
        page_number: 1,
        order_ids: [finalOrderId],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      setTimeout(() => {
        dispatch(GetDeliverySlotsList());
      }, 1000);
    } else {
      toast.error("Please Enter Valid Order Id", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  React.useEffect(() => {
    if (allOrderData.newallOrderDetails.length) {
      setIsEditOrderDetailsDialog(true);
    }
  }, [allOrderData.newallOrderDetails]);
  //Function to redirect user to Users Page
  const handleOnUserSearchClick = (props) => {
    CustomConsole("-- handleOnUserSearchClick --");
    CustomConsole(props);
    //Redirect to User's Page
    if (props.phone && props.phone.length) {
      const url = `/dashboard/UserDetailsMain?phoneNumber=${props.phone}`;
      history.push(url);
    }
  };
  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename:
      "DeliveryReport" + moment().tz(TZ).format("YYYY-MM-DD HH:mm:ss") + ".csv",
    data: csvFileData,
  };
  function toCSV(orderDetails) {
    const result = [];
    if (orderDetails && orderDetails.length) {
      for (const data of orderDetails) {
        const typeOfDiscount =
          data.packaged_drinking_water.water_drop_details &&
          data.packaged_drinking_water.water_drop_details.is_valid &&
          data.packaged_drinking_water.coupon_code_details &&
          data.packaged_drinking_water.coupon_code_details.is_valid
            ? "WATER DROPS & PROMOCODE"
            : (data.packaged_drinking_water.water_drop_details &&
                data.packaged_drinking_water.water_drop_details.is_valid) ||
              (data.packaged_drinking_water.used_Water_Drops &&
                data.packaged_drinking_water.used_Water_Drops > 0 &&
                data.packaged_drinking_water.isWaterDropUsed)
            ? "WATER DROPS"
            : (data.packaged_drinking_water.coupon_code_details &&
                data.packaged_drinking_water.coupon_code_details.is_valid) ||
              (data.packaged_drinking_water.promo_label &&
                data.packaged_drinking_water.promo_label.length &&
                data.packaged_drinking_water.promo_discount_price &&
                data.packaged_drinking_water.promo_discount_price > 0)
            ? "PROMOCODE"
            : "-";
        let orderProducts = {};
        if (!data.packaged_drinking_water.is_invalid) {
          let orderQtyname =
            data.packaged_drinking_water.selected_container_capacity +
            "-" +
            data.packaged_drinking_water.selected_container_material +
            `-order_quantity (1/pack)`;
          let returnQtyname =
            data.packaged_drinking_water.selected_container_capacity +
            "-" +
            data.packaged_drinking_water.selected_container_material +
            `-return_quantity (Units)`;
          orderProducts[orderQtyname] =
            data.packaged_drinking_water.twentyltr_capacity_qty;
          orderProducts[returnQtyname] =
            data.packaged_drinking_water.empty_container_qty;
        }
        for (const product of data.products) {
          let orderQtyname =
            product.capacity +
            "-" +
            product.material +
            `-order_quantity (${product.sku_qty}/pack)`;
          let returnQtyname =
            product.capacity +
            "-" +
            product.material +
            `-return_quantity (Units)`;
          orderProducts[orderQtyname] = product.quantity;
          orderProducts[returnQtyname] = product.return;
        }
        result.push({
          order_id: data.order_id,
          otp: data.delivery_otp,
          pincode: data.pincode,
          areaname: data.areaname,
          delivery_address:
            data.delivery_address.address_line1 +
            "\n" +
            data.delivery_address.address_line2 +
            "\n" +
            data.delivery_address.address_line3 +
            "\n" +
            data.delivery_address.landmark +
            "\n" +
            data.delivery_address.pincode +
            "\n" +
            data.delivery_address.service_area +
            "\n" +
            "Contact Person: " +
            data.delivery_contact_name +
            ` - ${data.delivery_contact_phone}`,
          booking_date: data.booking_date,
          booking_time: data.booking_time,
          delivery_date: data.delivery_date,
          delivery_time: data.delivered_on,
          order_type: data.order_type,
          order_status: data.order_status_name,
          ...orderProducts,
          delivery_slot: data.delivery_slot,
          consumer_name: data.consumer_name,
          consumer_phone: data.consumer_phone,
          consumer_email: data.consumer_email,
          vehicle_id: data.tanker_id,
          delivery_contact_name: data.delivery_contact_name,
          delivery_contact_phone: data.delivery_contact_phone,
          payment_method: data.payment_method,
          payment_status: data.payment_status_name,
          "order_price(With_Discount)": data.final_price,
          discount: data.discount,
          discount_type: typeOfDiscount,
          supplier_name: data.supplier_name,
          driver_name:
            data.driver_name && data.driver_name.length
              ? data.driver_name
              : data.drivers && data.drivers.length
              ? data.drivers[0].fullname
              : "-",
          driver_phone:
            data.driver_phone && data.driver_phone.length
              ? data.driver_phone
              : data.drivers && data.drivers.length
              ? data.drivers[0].phone
              : "-",
          payment_details:
            data.payment_details && Object.keys(data.payment_details).length
              ? data.payment_details.transaction_id +
                "\n" +
                moment(data.payment_details.transaction_ts)
                  .add(330, "minutes")
                  .format("YYYY-MM-DD HH:MM:SS")
              : "-",
          last_updated_at: data.last_updated_at,
          last_updated_by_name: data.updated_by_name,
          last_updated_by_phone: data.updated_by_phone,
          last_updated_by_email: data.updated_by_email,
          cancel_reason: data.cancel_reason,
        });
      }
    }
    setCsvFileData(result);
  }
  const handleChangeOrderId = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    // if (e.target.value === "" || re.test(e.target.value)) {
    //   setOrderId(e.target.value);
    // }
    // if (/^\d*$/.test(e.target.value)) {
    //   setOrderId(e.target.value);
    // }
    setOrderId(e.target.value);
  };
  return (
    <Box>
      {/* Basic Filter Data & Search Data */}
      <Paper elevation={12} sx={styles.paperCommon}>
        <Grid container spacing={2}>
          <Grid item md={4.5}>
            <Box sx={styles.filterDataBoxMain}>
              <Box>
                <TextField
                  label="Deliveries from"
                  variant="standard"
                  value={fromDate}
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  sx={{ mr: "10px" }}
                />
                <TextField
                  label="Deliveries till"
                  variant="standard"
                  value={toDate}
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Box>
              <Box>
                <ManageSearchIcon
                  sx={styles.searchDateIcon}
                  onClick={() => OrderDetailsAndSummaryApi()}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={3} sx={{ mt: { md: 1.3, xs: 0 } }}>
            <TextField
              label="Search order by ID"
              variant="standard"
              value={parseInt(orderId)}
              type="number"
              onChange={handleChangeOrderId}
              InputProps={{
                startAdornment: "BTORD-",
                endAdornment: (
                  <SearchIcon
                    sx={styles.searchOrderUserIcon}
                    onClick={() => handleSearchOrderId()}
                  />
                ),
              }}
            />
          </Grid>
          <Grid item md={4} sx={{ mt: 1.3 }}>
            <TextField
              error={isUserSearchValid}
              label="Search orders of user"
              variant="standard"
              type="text"
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    sx={styles.searchOrderUserIcon}
                    onClick={() => handleSeacrhUserOrders()}
                  />
                ),
              }}
              helperText={isUserSearchValid ? "Invalid entry." : ""}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* Order & Products Summary */}
      {orderDetailsList.pdwOrderSummary &&
      orderDetailsList.pdwOrderSummary.orders &&
      orderDetailsList.pdwOrderSummary.products &&
      orderDetailsList.pdwOrderSummary.products.length &&
      Object.keys(orderDetailsList.pdwOrderSummary.orders).length ? (
        <Paper elevation={12} sx={styles.osPaper}>
          {/* <Typography sx={styles.osCardHeadText}>
            Orders & Products Summary
          </Typography> */}
          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {[
              {
                header: "Total",
                orderData: [
                  {
                    subheader: "Ongoing",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_ongoing,
                  },
                  {
                    subheader: "Delivered",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_delivered,
                  },
                  {
                    subheader: "Total",
                    value: orderDetailsList.pdwOrderSummary.orders.total_orders,
                  },
                ],
              },
              {
                header: "Ongoing",
                orderData: [
                  {
                    subheader: "Total",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_ongoing,
                  },
                  {
                    subheader: "Insta",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.insta,
                  },
                  {
                    subheader: "Subscribed",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing
                        .subscribed,
                  },
                  {
                    subheader: "Regular",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.regular,
                  },
                  {
                    subheader: "Postpaid",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.postpaid,
                  },
                ],
              },
              {
                header: "Delivered",
                orderData: [
                  {
                    subheader: "Total",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_delivered,
                  },
                  {
                    subheader: "Insta",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered.insta,
                  },
                  {
                    subheader: "Subscribed",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered
                        .subscribed,
                  },
                  {
                    subheader: "Regular",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered.regular,
                  },
                  {
                    subheader: "Postpaid",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered
                        .postpaid,
                  },
                ],
              },
            ].map((data, key) => {
              return (
                <Grid item xs={6} md={2}>
                  <Card
                    sx={{
                      ...styles.osCard,
                      backgroundImage:
                        key === 0
                          ? styles.instaBgImage
                          : key === 1
                          ? styles.regularBgImage
                          : key === 2
                          ? styles.postpaidBgImage
                          : styles.subscriptionBgImage,
                      minHeight: "150px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styles.osCardHeadText,
                        ...styles.osTextBox,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {key === 0 ? (
                        ""
                      ) : key === 1 ? (
                        <SyncIcon sx={{ color: "brown" }} />
                      ) : (
                        <LocalShippingIcon sx={{ color: "green" }} />
                      )}{" "}
                      {data.header}
                    </Typography>

                    {data.orderData.map((od, index) => {
                      return (
                        <Box sx={styles.osTextBox} key={index}>
                          <Typography sx={styles.osTextHead}>
                            &nbsp;&nbsp; {od.subheader} :
                          </Typography>
                          <Typography sx={styles.osTextAnswer}>
                            {od.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Card>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  ...styles.osCard,
                  backgroundImage: styles.postpaidBgImage,
                  minHeight: "150px",
                  marginRight: "10px",
                }}
              >
                <Typography
                  sx={{
                    ...styles.osCardHeadText,
                    ...styles.osTextBox,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ProductionQuantityLimitsIcon sx={{ color: "blue" }} />{" "}
                  Products
                </Typography>

                {orderDetailsList.pdwOrderSummary.products.map(
                  (product, index) => {
                    return (
                      <Box
                        sx={{ ...styles.osTextBox, marginBottom: "10px" }}
                        key={index}
                      >
                        <Typography sx={styles.osTextHead}>
                          &nbsp;&nbsp; {product.product_name} :
                        </Typography>
                        <Typography sx={styles.osTextAnswer}>
                          <span
                            style={{
                              ...styles.osTextAnswer,
                              color: "blue",
                              border: "1px solid black",
                              padding: "5px",
                            }}
                          >
                            T: {product.total}
                          </span>{" "}
                          <span
                            style={{
                              ...styles.osTextAnswer,
                              color: "green",
                              border: "1px solid black",
                              padding: "5px",
                            }}
                          >
                            D: {product.delivered}
                          </span>{" "}
                          <span
                            style={{
                              ...styles.osTextAnswer,
                              color: "red",
                              border: "1px solid black",
                              padding: "5px",
                            }}
                          >
                            O: {product.ongoing}
                          </span>
                        </Typography>
                      </Box>
                    );
                  }
                )}
                <Box sx={{ ...styles.osTextBox }}>
                  <Typography sx={styles.osTextHead}>Note:-</Typography>
                  <Typography sx={styles.osTextHead}>
                    T: Total, D: Delivered, O: Ongoing
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
      {/* Orders Summary */}
      {orderSummary.length ? (
        <Paper elevation={12} sx={styles.osPaper}>
          <Typography sx={styles.osCardHeadText}>Orders Summary</Typography>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {orderSummary.map((orderData, key) => {
              return (
                <Grid item md={3} key={key}>
                  <Card
                    sx={{
                      ...styles.osCard,
                      backgroundImage:
                        key === 0
                          ? styles.instaBgImage
                          : key === 1
                          ? styles.regularBgImage
                          : key === 2
                          ? styles.postpaidBgImage
                          : styles.subscriptionBgImage,
                    }}
                  >
                    <Typography sx={styles.osCardHeadText}>
                      {orderData.orderType}
                    </Typography>
                    {orderData.data.map((data, key) => {
                      return (
                        <Box sx={styles.osTextBox} key={key}>
                          {key === 0 ? (
                            <WidthFullIcon sx={{ color: "brown" }} />
                          ) : key === 1 ? (
                            <FiberNewRoundedIcon sx={{ color: "gray" }} />
                          ) : key === 2 ? (
                            <SyncIcon sx={{ color: "blue" }} />
                          ) : key === 3 ? (
                            <LocalShippingIcon sx={{ color: "green" }} />
                          ) : (
                            <PhonelinkEraseIcon sx={{ color: "red" }} />
                          )}
                          <Typography sx={styles.osTextHead}>
                            &nbsp;&nbsp; {data.header} :
                          </Typography>
                          <Typography sx={styles.osTextAnswer}>
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      ) : null}
      {/* Filter Options for Orders Table */}
      <Paper elevation={12} sx={{ ...styles.paperCommon }}>
        <Typography sx={styles.osCardHeadText}>Filter Options</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Box sx={{ display: "flex", gap: "30px" }}>
            {/* Order Type */}
            <Box
              sx={{
                ...styles.foMainBox,
              }}
            >
              <InputLabel sx={{ ...styles.foIpLabel }}>Order type:</InputLabel>
              <Select
                variant="standard"
                label="Order type"
                value={orderType}
                sx={{ ...styles.foSelect }}
                onChange={(e) => {
                  setOrderType(e.target.value);
                }}
              >
                {orderTypes.map((data, key) => {
                  return (
                    <MenuItem value={data} key={key}>
                      {data}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {/* Order Status */}
            <Box
              sx={{
                ...styles.foMainBox,
              }}
            >
              <InputLabel sx={{ ...styles.foIpLabel }}>
                Order status:
              </InputLabel>
              <Select
                variant="standard"
                label="Order status"
                value={orderStatus}
                sx={{ ...styles.foSelect }}
                onChange={(e) => {
                  setOrderStatus(e.target.value);
                }}
              >
                {orderStatusDetails.map((data, key) => {
                  return (
                    <MenuItem value={data.key} key={key}>
                      {data.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            {/* products */}
            <Box
              sx={{
                ...styles.foMainBox,
              }}
            >
              <InputLabel sx={{ ...styles.foIpLabel }}>Products:</InputLabel>
              <Select
                variant="standard"
                label="Products"
                value={product}
                sx={{ ...styles.foSelect }}
                onChange={(e) => {
                  setProduct(e.target.value);
                }}
              >
                {products.map((data, key) => {
                  return (
                    <MenuItem value={data.name} key={key}>
                      {data.name} - ({data.capacity} {data.material} )
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {/* pincode */}
            <TextField
              label="Pincode"
              variant="standard"
              type="number"
              value={pincode}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setPincode(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <FiberPinIcon
                    sx={{ ...styles.searchOrderUserIcon, margincenter: "10px" }}
                  />
                ),
              }}
              sx={{ width: "150px" }}
            />
          </Box>
          {/* Filter Search */}
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              backgroundImage: "linear-gradient(to bottom, #040C78, #552C2A)",
            }}
            onClick={() => OrderDetailsAndSummaryApi()}
          >
            Filter
          </Button>
          <Box sx={{ display: "flex", gap: "20px" }}>
            {/* Page Size */}
            <Box
              sx={{
                ...styles.foMainBox,
              }}
            >
              <InputLabel sx={{ ...styles.foIpLabel }}>Page size:</InputLabel>
              <Select
                variant="standard"
                label="Page Size"
                value={pageSize}
                sx={{ ...styles.foSelect }}
                onChange={(e) => {
                  setPageSize(e.target.value);
                }}
              >
                {pageSizeList.map((data, key) => {
                  return (
                    <MenuItem value={data} key={key}>
                      {data}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {/* Page Number */}
            <Box
              sx={{
                ...styles.foMainBox,
              }}
            >
              <InputLabel sx={{ ...styles.foIpLabel }}>Page number:</InputLabel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FirstPageIcon
                  sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                  onClick={() => {
                    if (pageNumber - 1 > 0) {
                      setPageNumber(() => pageNumber - 1);
                    }
                  }}
                />
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  {pageNumber}
                </Typography>
                <LastPageIcon
                  sx={{
                    color:
                      pageNumber > 0 && orderList.length === pageSize
                        ? "blue"
                        : "gray",
                  }}
                  onClick={() => {
                    if (pageNumber > 0 && orderList.length === pageSize) {
                      setPageNumber(() => pageNumber + 1);
                    }
                  }}
                />
              </Box>
            </Box>
            {/* Download Report */}
            <CSVLink {...csvLink}>
              <SimCardDownloadRoundedIcon
                sx={{ ...styles.reportDownloadIcon }}
                onClick={() => toCSV(orderList)}
              />
            </CSVLink>
          </Box>
        </Box>
      </Paper>
      {/* Orders Table */}
      <Paper
        elevation={12}
        sx={{ ...styles.paperCommon, maxHeight: "650px", overflow: "hidden" }}
      >
        <Box
          sx={{
            ...styles.foMainBox,
          }}
        >
          <InputLabel sx={{ ...styles.foIpLabel }}>Sort by: &nbsp;</InputLabel>
          <Select
            variant="standard"
            label="Sort by"
            value={sortBy}
            sx={{ ...styles.foSelect, width: "200px" }}
            onChange={(e) => setSortBy(e.target.value)}
          >
            {sorKeys.map((data, key) => {
              return (
                <MenuItem value={data.value} key={key}>
                  {data.value} - {data.order}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: "600px" }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                {tableHeader.map((data, key) => {
                  return (
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold" }}
                      key={key}
                    >
                      {data}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((row, index) => (
                <RegularOrdersTable
                  index={index + 1}
                  row={row}
                  handleOnUserSearchClick={handleOnUserSearchClick}
                  handleOpenOrderDetails={(data) => handleSearchOrderId(data)}
                />
              ))}
              {!orderList.length ? (
                <Typography
                  sx={{
                    color: "brown",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  No data found!
                </Typography>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default RegularOrders;

//Style of the component
const styles = {
  // Searc Order -> Filter Options
  foMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  foIpLabel: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  foSelect: { fontSize: "14px", fontWeight: "bold", width: "120px" },

  instaBgImage: "linear-gradient( #F6E48B, #E7F887)",
  regularBgImage: "linear-gradient( #DDFFC9, #FFEFC9)",
  postpaidBgImage: "linear-gradient( #D8FFF8, #F6E5FF)",
  subscriptionBgImage: "linear-gradient( #FFE5E9, #E6F4EB)",

  //Order Summary Design
  osPaper: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  osBox: {
    padding: "10px",
  },
  osCard: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    minWidth: "150px",
  },
  osCardHeadText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  osTextBox: { display: "flex", flexDirection: "row", gap: "10px" },
  osTextHead: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "gray",
  },
  osTextAnswer: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
  },

  filterDataBoxMain: {
    display: "flex",
    gap: { md: "15px", xs: "10px" },
    padding: "10px",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  searchOrderUserIcon: {
    color: "blue",
    fontSize: "25px",
    "&:hover": {
      color: "black",
    },
  },
  reportDownloadIcon: {
    color: "brown",
    fontSize: "35px",
    "&:hover": {
      color: "green",
    },
  },
  searchDateIcon: {
    color: "blue",
    fontSize: "35px",
    "&:hover": {
      color: "black",
    },
  },
  paperCommon: { padding: "10px", margin: "10px", width: "100%" },
};
