import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CustomConsole from "../../CustomConsole";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as IconName from "react-icons/fc";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import {
  GetReplacementOrders,
  GetOrderDetailsById,
} from "../Redux/Actions/adminReplacementOrderAction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { replacementCanOrdersStatus } from "../../Components/AppMeta/appMetaConfig";
import ReplacementFilterDialog from "../../Components/Dialog/ReplacementFilterDialog";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "0px",
  },

  // tableContainer: {
  //   overflowX: "auto",
  //   "& .MuiTable-root": {
  //     width: "100%",
  //     tableLayout: "fixed",
  //   },
  //   "& .MuiTableCell-root": {
  //     padding: "20px",
  //   },
  // },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "azure",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflowWrap: "break-word",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminReplacementOrderTable({
  setPageNumber,
  pageNumber,
  setCurrentOrderId,
  scrollDownToCard,
  setReplaceCard,
  setSupplierTable,
}) {
  const dispatch = useDispatch();
  const replecementCanOrders = useSelector(
    (state) => state.replacementOrderAdmin
  );
  const classes = useStyles();

  const [repOrderDetails, setRepOrderDetails] = React.useState([]);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [testArr, setTestArr] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(25);
  const [orderStatus, setOrderStatus] = React.useState("");
  // const [orderStatus, setOrderStatus] = React.useState("");
  CustomConsole(orderStatus);
  //flags
  const [farBtnDisable, setFarBtnDisable] = React.useState(false);

  React.useEffect(() => {
    CustomConsole(replecementCanOrders);
    setRepOrderDetails(replecementCanOrders.replacementOrders);
  }, [replecementCanOrders]);

  // React.useEffect(() => {
  //   dispatch(
  //     GetReplacementOrders({ page_number: pageNumber - 1, page_size: pageSize })
  //   );
  // }, []);

  React.useEffect(() => {
    dispatch(
      GetReplacementOrders({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus || undefined,
      })
    );
  }, [pageNumber]);

  // React.useEffect(() => {
  //   dispatch(
  //     GetReplacementOrders({
  //       page_number: pageNumber - 1,
  //       page_size: pageSize,
  //       order_status: orderStatus,
  //     })
  //   );
  // }, [pageSize]);

  const pageForward = () => {
    if (repOrderDetails.length > 0) {
      setPageNumber(pageNumber + 1);
    } else {
      setFarBtnDisable(true);
    }
  };

  const pageBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      setFarBtnDisable(false);
    }
  };

  const getOrderDetails = (id) => {
    dispatch(GetOrderDetailsById(id));
    setCurrentOrderId(id);
    setReplaceCard(true);
    setSupplierTable(false);
    setTimeout(() => {
      scrollDownToCard();
    }, 500);
  };
  const handleViewDetails = () => {
    CustomConsole("--------handleViewDetails----------");
    dispatch(GetOrderDetailsById("RP" + searchOrderId));
    setCurrentOrderId(searchOrderId);
    setReplaceCard(true);
    setSupplierTable(false);
    setTimeout(() => {
      scrollDownToCard();
    }, 500);
  };

  const handleFilter = (props) => {
    // setOrderStatus(props.order_status);
    dispatch(
      GetReplacementOrders({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus,
      })
    );
    setOpenFilterDialog(false);
    setPageNumber(1);
    setPageSize(25);
  };
  const handleClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleSearchPageSize = () => {
    dispatch(
      GetReplacementOrders({
        page_number: pageNumber - 1,
        page_size: pageSize,
        order_status: orderStatus,
      })
    );
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      items.map((a) => {
        result.push({
          order_id: a.order_id,
          consumer_name: a.consumer_name,
          consumer_phone: a.consumer_phone,
          order_status:
            a.order_status ===
            replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED
              ? "NEW REQUEST"
              : a.order_status ===
                replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
              ? "ACCEPTED"
              : a.order_status ===
                replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED
              ? "REJECTED "
              : a.order_status ===
                replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED
              ? "STARTED"
              : a.order_status ===
                replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED
              ? "DELIVERED"
              : null,
          product: a.container_capacity + "-" + a.container_material,
          container_qty: a.container_qty,
          service_area:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.service_area != undefined
              ? a.consumer_address.service_area
              : "--",
          pincode:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.pincode != undefined
              ? a.consumer_address.pincode
              : "--",
          address:
            a.consumer_address !== undefined &&
            a.consumer_address !== null &&
            a.consumer_address.address_line1 != undefined &&
            a.consumer_address.address_line2 != undefined
              ? a.consumer_address.address_line1 +
                a.consumer_address.address_line2
              : "--",
          return_can_otp: a.return_can_otp,
          supplier_name: a.supplier_name,
          delivery_agent_name:
            a.delivery_agent_name !== undefined ? a.delivery_agent_name : "---",
          created_at: a.created_at,
          updated_at: a.updated_at,
        });
        // result.push(a);
      });
    }
    setTestArr(result);
  }
  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "end", xs: "center" },
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Box sx={{ maxWidth: "150px" }}>
              <Material.TextField
                label="Search By Order Id"
                size="small"
                value={searchOrderId}
                onChange={(event) => setSearchOrderId(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <Material.InputAdornment position="start">
                      <Material.Typography
                        sx={{ fontWeight: "bold", color: "black" }}
                      >
                        RP
                      </Material.Typography>
                    </Material.InputAdornment>
                  ),
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <IconName.FcSearch
                        cursor="pointer"
                        size="30px"
                        onClick={handleViewDetails}
                      />
                    </Material.InputAdornment>
                  ),
                  style: { borderRadius: "50px" },
                }}
              />
            </Box>
            <Box sx={{ mt: 0.5 }}>
              <Button
                variant="contained"
                size="small"
                // height="15px"
                sx={{
                  backgroundColor: "black",
                  border: "1px solid #1FA6F9",
                  "&:hover": {
                    backgroundColor: "#D1E9F7",
                    color: "black",
                    marginLeft: "10px",
                  },
                }}
                startIcon={<FilterAltIcon />}
                onClick={handleFilterOpen}
              >
                Filter
              </Button>
            </Box>
            <Box sx={{ maxWidth: "100px", maxHeight: "10px", mb: 5, ml: 2 }}>
              <Material.TextField
                size="small"
                value={pageSize}
                label="Page size"
                // onChange={(e) => {
                //   setPageSize(e.target.value);
                //   // setSearchOrderFlag(true);
                // }}

                InputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleChangePageSize, // Attach the input event handler
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <MuiIcons.Search
                        cursor="pointer"
                        size="30px"
                        onClick={handleSearchPageSize}
                      />
                    </Material.InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // gap: "5px",
                  // padding: "10px",
                }}
              >
                <Box>
                  <Button
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={pageBackward}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                </Box>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{
                    // marginLeft: "5px",
                    // marginRight: "5px",
                    background:
                      "radial-gradient(rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
                    color: "white",
                    padding: "5px",
                    width: "40px",
                    height: "35px",
                    borderRadius: "15px",
                    textAlign: "center",
                  }}
                >
                  {pageNumber}
                </Typography>
                <Box>
                  <Button
                    sx={{
                      cursor: "pointer",
                    }}
                    disabled={farBtnDisable}
                    onClick={pageForward}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <CSVLink {...csvLink}>
                {" "}
                <DownloadIcon
                  sx={{ fontSize: 35 }}
                  onClick={() => toCSV(repOrderDetails)}
                />
              </CSVLink>
            </Box>
          </Box>
          {repOrderDetails.length > 0 ? (
            <>
              <Box
                sx={{
                  maxWidth: { md: "150vw", xs: "350px" },
                  overflow: "auto",
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" size="small">
                          <strong>S.no</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Order Id</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Consumer Name</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Consumer Number</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {" "}
                          <strong>Product</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {" "}
                          <strong>Status</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Replacement Qty</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Supplier Name</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>OTP</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Service Area</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Pincode</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Address</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Delivery Agement Name</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Created At</strong>
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <strong>Updated At</strong>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {repOrderDetails.map((row, key) => (
                        <StyledTableRow
                          key={key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                            "&:hover": {
                              boxShadow: "none",
                              backgroundColor: "#036bfc",
                            },
                          }}
                          onClick={() => {
                            getOrderDetails(row.order_id);
                          }}
                        >
                          <StyledTableCell align="center" size="small">
                            {pageNumber > 1
                              ? pageNumber * 10 + (key + 1) - 10
                              : key + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.order_id}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.consumer_name}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.consumer_phone ? row.consumer_phone : "---"}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.container_capacity +
                              "-" +
                              row.container_material}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            size="small"
                            sx={{
                              color:
                                row.order_status === 100
                                  ? "blue"
                                  : row.order_status === 200
                                  ? "green"
                                  : row.order_status === 300
                                  ? "red"
                                  : row.order_status === 400
                                  ? "#d9961a"
                                  : row.order_status === 500
                                  ? "black"
                                  : null,
                            }}
                          >
                            {row.order_status ===
                            replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED
                              ? "NEW REQUEST"
                              : row.order_status ===
                                replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED
                              ? "ACCEPTED"
                              : row.order_status ===
                                replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED
                              ? "REJECTED "
                              : row.order_status ===
                                replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED
                              ? "STARTED"
                              : row.order_status ===
                                replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED
                              ? "DELIVERED"
                              : null}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.replace_can_qty}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.supplier_name}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.otp}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.consumer_address !== undefined &&
                            row.consumer_address !== null &&
                            row.consumer_address.service_area != undefined
                              ? row.consumer_address.service_area
                              : "--"}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.consumer_address !== undefined &&
                            row.consumer_address !== null &&
                            row.consumer_address.pincode != undefined
                              ? row.consumer_address.pincode
                              : "--"}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.consumer_address.address_line1 !== undefined &&
                            row.consumer_address.address_line1 !== null
                              ? row.consumer_address.address_line1
                              : "--"}
                            ,<br />
                            {row.consumer_address.address_line2 !== undefined &&
                            row.consumer_address.address_line2 !== null
                              ? row.consumer_address.address_line2
                              : "--"}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row !== undefined &&
                            row !== null &&
                            row.delivery_agent_name !== undefined
                              ? row.delivery_agent_name
                              : "---"}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.created_at}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {row.updated_at}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : (
            <b>No Data available</b>
          )}

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                background:
                  "radial-gradient(rgba(67,135,245,1) 0%, rgba(4,68,144,1) 100%)",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                disabled={farBtnDisable}
                onClick={pageForward}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box> */}
        </CardContent>
      </Card>
      <ReplacementFilterDialog
        openFilterDialog={openFilterDialog}
        handleClose={handleClose}
        handleFilter={handleFilter}
        setOrderStatus={setOrderStatus}
        orderStatus={orderStatus}
      />
    </>
  );
}

export default AdminReplacementOrderTable;
