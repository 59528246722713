import React, { useState, useEffect } from "react";
import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Pages/Axios/useAxios";
// import { url } from "../../Redux/apis";
import { url } from "../../Pages/Redux/apis";

import CustomConsole from "../../CustomConsole";
const useProductImages = (products) => {
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderProductsImg, setOrderProductsImg] = React.useState([]);
  const api = useAxios();

  useEffect(() => {
    let productsData = [];
    if (!products?.packaged_drinking_water?.is_invalid) {
      productsData.push({
        image: products?.packaged_drinking_water?.image,
      });
    }
    if (products?.products?.length > 0) {
      for (const data of products?.products) {
        productsData.push({
          image: data.image,
        });
      }
    }
    setOrderProductsImg(productsData);
    const fetchImages = async () => {
      try {
        // Use Promise.all to fetch and store images in parallel
        const imagePromises = orderProductsImg?.map(async (product) => {
          const filename = product.image;

          // Check if the image is already available in local storage
          const storedImage = localStorage.getItem(`image_${filename}`);

          if (
            !storedImage &&
            filename !== undefined &&
            filename !== null &&
            filename.length
          ) {
            // Fetch the image from the API
            const imageResponse = await api.get(
              `${url}/SSM/files/${filename}`,
              {
                responseType: "blob", // Use blob to handle binary data
              }
            );

            // Convert the image blob to a base64-encoded string
            const reader = new FileReader();
            reader.readAsDataURL(imageResponse.data);

            // Update the local storage with the base64-encoded string
            reader.onloadend = () => {
              const base64Image = reader.result;
              localStorage.setItem(`image_${filename}`, base64Image);
              setImages((prevImages) => ({
                ...prevImages,
                [filename]: base64Image,
              }));
            };

            return { [filename]: null }; // Placeholder until base64 is ready
          }

          return { [filename]: storedImage };
        });

        // Wait for all image promises to resolve
        await Promise.all(imagePromises);
        setIsLoading(false);
      } catch (error) {
        CustomConsole("Error fetching product images:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [products]);

  return { images, isLoading, error };
};

export default useProductImages;
