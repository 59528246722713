import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CustomConsole from "../../CustomConsole";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Typography, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DownloadIcon from "@mui/icons-material/Download";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  Reportbasedondate,
  ReportbasedonPhone,
} from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ReportsBasedonDaterange({
  allReportData,
  date,
  setDate,
  fromValue,
  toValue,
  emailPhone,
  setReportPgNo,
  reportPgNo,
  setDatetabelVisibleFlag,
  datePageNumber,
  setDatePageNumber,
  moreFlag,
  dateFlag,
}) {
  const dispatch = useDispatch();
  const [reportFilterDialog, setReportFilterDialog] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [testArr, setTestArr] = React.useState([]);
  const handelSubmit = (e) => {
    e.preventDefault();

    dispatch(
      Reportbasedondate(fromValue, toValue, date, reportPgNo - 1, pageSize)
    );
    setReportFilterDialog(false);
  };
  const handleClose = (e) => {
    setReportFilterDialog(false);
  };
  let result = [];
  function toCSV(items) {
    if (items) {
      items.map((a) => {
        result.push(a);
        result.push(a.packaged_drinking_water);
      });
    }
    setTestArr(result);
  }

  const csvLink = {
    filename: "Report",
    data: testArr,
  };

  React.useEffect(() => {
    CustomConsole(reportPgNo - 1);
    dispatch(
      Reportbasedondate(fromValue, toValue, date, reportPgNo - 1, pageSize)
    );
    setReportFilterDialog(false);
  }, [reportPgNo]);

  const pageChangeForward = () => {
    if (allReportData.reportDetails.length > 0) {
      if (allReportData.reportDetails.length === 10) {
        setDatePageNumber(datePageNumber + 1);
        CustomConsole(datePageNumber);
        setReportPgNo(datePageNumber + 1);
        setEnableForBtn(false);
      } else {
        setEnableForBtn(false);
      }
    }

    if (datePageNumber - 1 >= 1) {
      setEnableBakBtn(false);
    }
  };

  const pageChangeBackward = () => {
    if (datePageNumber - 1 >= 1) {
      setDatePageNumber(datePageNumber - 1);
      setReportPgNo(datePageNumber - 1);
    }
    if (datePageNumber - 1 <= 1) {
      setEnableBakBtn(true);
    }
  };
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };

  const handleSearchPageSize = () => {
    dispatch(
      Reportbasedondate(fromValue, toValue, date, reportPgNo - 1, pageSize)
    );
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  return (
    <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
      <Box sx={{ margin: "1em" }}>
        <span style={{ fontSize: "1em" }}>
          <strong>Sales report based on date range</strong>
        </span>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={() =>
            reportFilterDialog
              ? setReportFilterDialog(false)
              : setReportFilterDialog(true)
          }
          sx={{ marginLeft: "1em" }}
        >
          Filter
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={() => setDatetabelVisibleFlag(false)}
          sx={{ marginLeft: "1em" }}
        >
          Close
        </Button>
      </Box>
      <Dialog
        open={reportFilterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Order by
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => setDate(event.target.value)}
              >
                <FormControlLabel
                  value="DESC"
                  control={<Radio />}
                  label="Descending"
                />
                <FormControlLabel
                  value="ASC"
                  control={<Radio />}
                  label="Ascending"
                />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" onClick={handelSubmit}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}
      >
        <Box sx={{ maxWidth: "120px", maxHeight: "10px", mt: 2 }}>
          <TextField
            size="small"
            value={pageSize}
            label="Page size"
            // onChange={(e) => {
            //   setPageSize(e.target.value);
            //   // setSearchOrderFlag(true);
            // }}

            InputProps={{
              maxLength: 10, // Optional: You can set the maximum length if needed
              onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPageSize}
                  />
                </Material.InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: "15px",
            padding: "5px",
            marginTop: "10px",
          }}
        >
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageChangeBackward}
              // disabled={enableBakBtn}
            >
              <ArrowBackIosIcon />
            </Button>
          </Box>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              // marginLeft: "10px",
              // marginRight: "10px",
              backgroundColor: "#2D81FF",
              color: "white",
              padding: "5px",
              width: "40px",
              height: "35px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {datePageNumber}
          </Typography>
          <Box>
            <Button
              sx={{
                cursor: "pointer",
              }}
              onClick={pageChangeForward}
              disabled={enableForBtn}
            >
              <ArrowForwardIosIcon />
            </Button>
          </Box>
        </Box>
        {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_FINANCEHEAD ||
        parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
        parseInt(localStorage.getItem("roleId")) ===
          ADMIN_ROLES.BW_SUPERADMIN ? (
          <CSVLink {...csvLink}>
            {" "}
            <DownloadIcon
              sx={{ fontSize: 35 }}
              onClick={() => toCSV(allReportData.reportDetails)}
            />
          </CSVLink>
        ) : null}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Order Id</StyledTableCell>
              <StyledTableCell align="left">Consumer Name</StyledTableCell>
              <StyledTableCell align="left">Consumer Gst</StyledTableCell>
              <StyledTableCell>Supplier Name</StyledTableCell>
              <StyledTableCell>Supplier Gst</StyledTableCell>
              <StyledTableCell>Ordered date</StyledTableCell>
              <StyledTableCell>Delivery Date</StyledTableCell>
              <StyledTableCell>Invoice Id</StyledTableCell>
              <StyledTableCell>hsn code</StyledTableCell>
              <StyledTableCell>Order qty</StyledTableCell>
              <StyledTableCell>Deposit Amount</StyledTableCell>
              <StyledTableCell>Final Price</StyledTableCell>
              <StyledTableCell>Transaction Id</StyledTableCell>
              <StyledTableCell>Payment Id</StyledTableCell>
              <StyledTableCell>Payment Type</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableRow>
          </TableHead>
          {allReportData.reportDetails.length > 0 ? (
            <TableBody>
              {allReportData.reportDetails.map((row) => (
                <StyledTableRow key={row.order_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.order_id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.consumer_name}
                  </StyledTableCell>
                  <StyledTableCell>{row.consumer_gst}</StyledTableCell>
                  <StyledTableCell>{row.supplier_name}</StyledTableCell>
                  <StyledTableCell>{row.supplier_gst}</StyledTableCell>
                  <StyledTableCell>
                    {endDateConverter(row.start_date)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {endDateConverter(row.end_date)}
                  </StyledTableCell>
                  <StyledTableCell>{row.invoice_id}</StyledTableCell>
                  <StyledTableCell>{row.hsn_code}</StyledTableCell>
                  <StyledTableCell>{row.ordered_can_qty}</StyledTableCell>

                  <StyledTableCell>
                    {row.extra_can_deposit_amount}
                  </StyledTableCell>
                  <StyledTableCell>{row.final_price}</StyledTableCell>
                  <StyledTableCell>{row.transaction_id}</StyledTableCell>
                  <StyledTableCell>
                    {row.transaction_meta != null
                      ? row.transaction_meta.p.paysharpReferenceNo
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell>{row.payment_method}</StyledTableCell>
                  <StyledTableCell>{row.payment_status}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
