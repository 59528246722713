import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import CommonSettleDialog from "../../../Pages/AdminPoTrackPostPaid/CommonSettleDialog";
import { GetInvoiceData } from "../../../Pages/Redux/Actions/adminPoTrackPostpaid";

const PendingAmountWrapper = styled(Box)({
  padding: "24px",
  borderRadius: "10px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  background: "linear-gradient(135deg, #f1f8e9, #dcedc8)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  "@media (max-width:600px)": {
    padding: "16px",
    boxShadow: "none",
    flexDirection: "column",
  },
});

const PendingAmount = ({ pendingAmount, userId }) => {
  console.log(userId);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const invoiceData = useSelector((state) => state.poTrackDetails.invoiceData);

  const handleOpenDialog = () => {
    if (userId) { 
      dispatch(GetInvoiceData(userId)); 
      setOpen(true);
    } else {
      console.error("User ID is undefined. Cannot fetch invoices.");
    }
  };

  useEffect(() => {
    if (invoiceData && invoiceData.length > 0) {
      console.log("Invoices fetched: ", invoiceData); 
    }
  }, [invoiceData]);

  return (
    <Box sx={{ width: "100%" }}>
      <PendingAmountWrapper>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          <span style={{ color: green[600] }}>₹{pendingAmount || 0}</span>
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          sx={{
            backgroundColor: green[600],
            "&:hover": { backgroundColor: green[800] },
          }}
        >
          Settle Amount
        </Button>
      </PendingAmountWrapper>

      <CommonSettleDialog
        open={open}
        setOpen={setOpen}
        poTrackSet={userId}
        hideSearchHeader={true}
        // invoices={invoiceData} 
      />
    </Box>
  );
};

export default PendingAmount;
