import React from "react";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  Chip,
  useTheme,
  Paper,
} from "@mui/material";
import { grey, blue } from "@mui/material/colors";

const OngoingOrders = ({ ongoingOrders }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        p: { sm: 4 },
        backgroundColor: grey[50],
        overflowY: "auto", // Ensures scrolling if content exceeds available space
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: { xs: "1.1rem", sm: "1.1rem" },
          fontWeight: "bold",
          textAlign: "center",
          color: blue[800],
        }}
      >
        Ongoing Orders
      </Typography>

      <Grid container spacing={2}>
        {["regular", "return", "replacement"].map((type) => (
          <Grid item xs={12} key={type}>
            <Paper
              elevation={5}
              sx={{
                borderRadius: 2,
                transition: "0.3s",
                "&:hover": {
                  boxShadow: theme.shadows[12],
                  transform: "scale(1.03)",
                },
              }}
            >
              <CardContent
                sx={{
                  p: { xs: 2, sm: 3 },
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "1rem", sm: "1rem" },
                    fontWeight: "bold",
                    color: blue[700],
                  }}
                >
                  {`${type.charAt(0).toUpperCase() + type.slice(1)} Orders`}
                </Typography>

                <Grid container spacing={1}>
                  {ongoingOrders?.[type]?.length > 0 ? (
                    ongoingOrders[type].map((orderId) => (
                      <Grid item xs={6} sm={4} md={3} key={orderId}>
                        <Chip
                          label={orderId}
                          sx={{
                            width: "100%", // Ensures full width for each chip
                            fontSize: { xs: "0.6rem", sm: "0.6rem" },
                            backgroundColor: blue[100],
                            "&:hover": {
                              backgroundColor: blue[300],
                              cursor: "pointer",
                            },
                            transition: "0.2s",
                          }}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      sx={{
                        fontSize: { xs: "0.6rem", sm: "0.6rem" },
                        color: grey[600],
                        ml: 1,
                      }}
                    >
                      No {type} orders
                    </Typography>
                  )}
                </Grid>

                {/* Add note below if orders are available */}
                {ongoingOrders?.[type]?.length > 0 && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mt: 2,
                      textAlign: "center",
                      fontSize: { xs: "0.6rem", sm: "0.6rem" },
                      p: { xs: 1, sm: 1 },
                      backgroundColor: grey[100],
                      borderRadius: 2,
                    }}
                  >
                    Note: Clear/Cancel the {type} orders
                  </Typography>
                )}
              </CardContent>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OngoingOrders;
