import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomConsole from "../../CustomConsole";
import ReportsBasedonDaterange from "./ReportsTablebyDaterange";
import { ReportbasedonType } from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Divider from "@mui/material/Divider";
import SearchbyDate from "./SearchReportbyDate";
import ReportsBasedonType from "./ReportBasedOnType";
import { ToastContainer, toast } from "react-toastify";
import ReportsBasedonPhone from "./ReportBasedonPhone";
import Alert from "@mui/material/Alert";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";

const names = [
  "Wallet Details",
  "Current Wallet Details",
  "Transaction Report",
  "Current Consumer Registered",
  "Current Supplier Registered",
  "Razorpay Report",
  "Wallet Report",
  "Paysharp Report",
  "Yesterday Consumer Registered",
  "Yesterday Supplier Registered",
  "Current deposit view",
  "Financial year paid but cancelled Table",
  "Paid monthly cancelled Table",
  "Yesterday paid but cancelled Table",
  "Financial year sales report",
  "Yesterday sales report",
];

function Adminreports() {
  const dispatch = useDispatch();
  const [dateFlag, setDateFlag] = React.useState(false);
  const [moreFlag, setMoreFlag] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const theme = useTheme();
  const [typeFlag, settypeFlag] = React.useState(false);
  const [phonetabelVisibleFlag, setPhonetabelVisibleFlag] =
    React.useState(false);
  const [datetabelVisibleFlag, setDatetabelVisibleFlag] = React.useState(false);
  const [fromValue, setFromValue] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [toValue, setToValue] = React.useState(moment().format("YYYY/MM/DD"));
  const [emailPhone, setEmailPhone] = React.useState("");
  const [date, setDate] = React.useState("DESC");
  const [reportType, setreportType] = React.useState("");
  const allReportData = useSelector((state) => state.reportDetailsAdmin);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [typevalue, settypeValue] = React.useState("");
  const [reportPgNo, setReportPgNo] = React.useState(1);
  const [phonePgNo, setPhonePgNo] = React.useState(1);
  const [datePageNumber, setDatePageNumber] = React.useState(1);
  const [reportTypePgNo, setTypeReportPgNo] = React.useState(1);
  console.log("date:", dateFlag);
  console.log("more:", moreFlag);
  const handleChange = (event) => {
    settypeValue(event.target.value);
  };
  CustomConsole(typevalue == "Current deposit view");
  const handelTypeSubmit = () => {
    settypeFlag(false);
    if (typevalue == "Wallet Details") {
      setreportType(100);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Current Wallet Details") {
      setreportType(200);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Transaction Report") {
      setreportType(600);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Current Consumer Registered") {
      setreportType(700);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Current Supplier Registered") {
      setreportType(800);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Razorpay Report") {
      setreportType("RAZORPAY");
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Wallet Report") {
      setreportType("WALLET");
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Paysharp Report") {
      setreportType("PAYSHARP");
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Yesterday Consumer Registered") {
      setreportType(1003);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Yesterday Supplier Registered") {
      setreportType(1005);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Current deposit view") {
      setreportType(1000);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Financial year paid but cancelled Table") {
      setreportType(1001);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Paid monthly cancelled Table") {
      setreportType(1002);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Yesterday paid but cancelled Table") {
      setreportType(1004);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue == "Financial year sales report") {
      CustomConsole("---setting 1006---");
      setreportType(1006);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    if (typevalue === "Yesterday sales report") {
      CustomConsole("---setting 1007---");
      setreportType(1007);
      setTypeReportPgNo(1);
      setPageNumber(1);
    }
    // if (allReportData.error === true) {
    //   toast.error("Error in getting report", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // } else {
    //   CustomConsole("error in report");
    // }
  };
  React.useEffect(() => {
    if (reportType && reportType.length) {
      dispatch(
        ReportbasedonType(reportType, date, reportTypePgNo - 1, pageSize)
      );
    }
  }, [reportType, reportTypePgNo, pageSize]);

  const handelTypeChange = (e) => {
    e.preventDefault();
    setreportType(e.target.value);
    setPageNumber(1);
    setTypeReportPgNo(1);
    settypeFlag(false);
    setMoreFlag(false);
    setDateFlag(false);
    // if (allReportData.error === true) {
    //   toast.error("Error in getting report", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }
  };

  const handleSearchbydateOrPhone = () => {
    setMoreFlag(false);
    // if (dateFlag) {
    //   setDateFlag(false);
    // } else {
    //   setDateFlag(true);
    // }
    setDateFlag(true);
  };

  const handleMore = () => {
    setDateFlag(false);
    setMoreFlag(true);
  };

  React.useEffect(() => {
    CustomConsole("------All reports page----");
    CustomConsole(allReportData.reportBasedonType != undefined);
    CustomConsole(allReportData.error === false);
    CustomConsole(typeFlag === false);
  }, [allReportData, typeFlag]);

  return (
    <Box>
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ? (
        <>
          {" "}
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "4em" }}
          >
            <Box sx={{ "& button": { m: 1 } }}>
              <div>
                <span>Reports :</span>
                <Button size="medium" onClick={handelTypeChange} value="900">
                  Yesterday Transaction
                </Button>
                <Button size="medium" onClick={handelTypeChange} value="300">
                  Current month
                </Button>
                <Button size="medium" onClick={handelTypeChange} value="500">
                  Current Year Report
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={handleSearchbydateOrPhone}
                  sx={appColors.commonBtnColor}
                >
                  Search by date/phone
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={handleMore}
                  sx={appColors.commonBtnColor}
                >
                  More
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {moreFlag === false && dateFlag ? (
                    <SearchbyDate
                      setFromValue={setFromValue}
                      setEmailPhone={setEmailPhone}
                      setToValue={setToValue}
                      fromValue={fromValue}
                      toValue={toValue}
                      emailPhone={emailPhone}
                      date={date}
                      setPageNumber={setPageNumber}
                      setReportPgNo={setReportPgNo}
                      reportPgNo={reportPgNo}
                      settypeFlag={settypeFlag}
                      setPhonetabelVisibleFlag={setPhonetabelVisibleFlag}
                      setDatetabelVisibleFlag={setDatetabelVisibleFlag}
                      setPhonePgNo={setPhonePgNo}
                      datePageNumber={datePageNumber}
                      setDatePageNumber={setDatePageNumber}
                    />
                  ) : (
                    ""
                  )}

                  {moreFlag ? (
                    <Box
                      sx={{
                        marginTop: "0em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-name-label">
                          Reports
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          onChange={handleChange}
                          autoWidth
                          label="Report Type"
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        size="medium"
                        variant="contained"
                        onClick={handelTypeSubmit}
                      >
                        Search
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
            </Box>
            <Divider />
            <Box>
              {allReportData?.reportBasedonType != undefined &&
              allReportData.error === false &&
              typeFlag === false ? (
                <ReportsBasedonType
                  allReportData={allReportData}
                  date={date}
                  reportType={reportType}
                  setDate={setDate}
                  setTypeReportPgNo={setTypeReportPgNo}
                  reportTypePgNo={reportTypePgNo}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  moreFlag={moreFlag}
                  dateFlag={dateFlag}
                />
              ) : (
                ""
              )}
            </Box>
            <Divider />
            <Box>
              {moreFlag === false &&
              dateFlag === true &&
              allReportData?.reportDetails?.length > 0 &&
              allReportData.error === false &&
              datetabelVisibleFlag === true ? (
                <ReportsBasedonDaterange
                  allReportData={allReportData}
                  date={date}
                  setDate={setDate}
                  fromValue={fromValue}
                  toValue={toValue}
                  emailPhone={emailPhone}
                  setReportPgNo={setReportPgNo}
                  reportPgNo={reportPgNo}
                  setDatetabelVisibleFlag={setDatetabelVisibleFlag}
                  datePageNumber={datePageNumber}
                  setDatePageNumber={setDatePageNumber}
                  dateFlag={dateFlag}
                  moreFlag={moreFlag}
                />
              ) : (
                ""
              )}
              <Divider />
              {moreFlag === false &&
              dateFlag === true &&
              allReportData?.reportBasedonPhoneType?.length > 0 &&
              allReportData.error === false &&
              phonetabelVisibleFlag === true ? (
                <ReportsBasedonPhone
                  allReportData={allReportData}
                  date={date}
                  setDate={setDate}
                  fromValue={fromValue}
                  toValue={toValue}
                  emailPhone={emailPhone}
                  setPhonePgNo={setPhonePgNo}
                  phonePgNo={phonePgNo}
                  setPhonetabelVisibleFlag={setPhonetabelVisibleFlag}
                />
              ) : (
                ""
              )}
            </Box>
            <ToastContainer />
          </Box>
        </>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
  );
}

export default Adminreports;
