import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import CustomConsole from "../../CustomConsole";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Reportbasedondate,
  ReportbasedonPhone,
} from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
const today = dayjs();
export default function SearchbyDate({
  allReportData,
  toValue,
  setToValue,
  fromValue,
  setFromValue,
  emailPhone,
  date,
  setReportPgNo,
  reportPgNo,
  settypeFlag,
  setEmailPhone,
  setPhonetabelVisibleFlag,
  setDatetabelVisibleFlag,
  setPhonePgNo,
  datePageNumber,
  setDatePageNumber,
}) {
  const dispatch = useDispatch();

  const handleChangeFrom = (newValue) => {
    if (newValue !== null) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      setFromValue(moment(newValue.$d).format("YYYY/MM/DD"));
    }
  };
  const handlePhoneChange = (e) => {
    e.preventDefault();
    setEmailPhone(e.target.value);
  };
  CustomConsole(allReportData);
  // const handlePhoneChange = (event) => {
  //   let reg = /^[6-9][0-9]{9}$/g;
  //   var result = reg.test(event.target.value);
  //   if (result === false) {
  //     toast.error("Please fill all Details", {
  //       position: "bottom-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   } else {
  //     setEmailPhone(event.target.value);
  //   }
  // };
  const handleChangeTo = (newValue) => {
    CustomConsole(newValue);
    if (newValue !== null) {
      CustomConsole(moment(newValue.$d).format("YYYY/MM/DD"));
      setToValue(moment(newValue.$d).format("YYYY/MM/DD"));
    }
  };
  const handleSearchOrder = () => {
    dispatch(Reportbasedondate(fromValue, toValue, date, 0));
    settypeFlag(true);
    setReportPgNo(1);
    setDatePageNumber(1);
    setDatetabelVisibleFlag(true);
  };
  const handleSearchPhone = (event) => {
    let reg = /^[6-9][0-9]{9}$/g;
    var result = reg.test(emailPhone);
    if (result === false) {
      toast.error("Please Enter a Valid Phone Number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (result === true) {
      dispatch(ReportbasedonPhone(emailPhone, date, reportPgNo - 1));
      settypeFlag(true);
      setPhonePgNo(1);
      setPhonetabelVisibleFlag(true);
    }
  };

  return (
    <>
      <Box sx={{ marginTop: "1em" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From"
            InputProps={{ sx: { height: 50, marginRight: "1em" } }}
            inputFormat="MM/DD/YYYY"
            value={fromValue}
            defaultValue={today}
            disableFuture
            onChange={handleChangeFrom}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="To"
            inputFormat="MM/DD/YYYY"
            InputProps={{ sx: { height: 50 } }}
            value={toValue}
            defaultValue={today}
            disableFuture
            onChange={handleChangeTo}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
              />
            )}
          />
        </LocalizationProvider>{" "}
        <Button
          size="small"
          variant="contained"
          onClick={handleSearchOrder}
          sx={appColors.commonBtnColor}
        >
          Search
        </Button>
        <Box sx={{ marginTop: "1em" }}>
          <TextField
            id="outlined-basic"
            label="Phone"
            type="number"
            variant="outlined"
            value={emailPhone}
            InputProps={{ sx: { height: 50 } }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            onChange={handlePhoneChange}
          />
          <Button
            size="small"
            variant="contained"
            onClick={handleSearchPhone}
            sx={appColors.commonBtnColor}
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
}
