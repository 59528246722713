import HomeIcon from "@mui/icons-material/Home";
import EvStationIcon from "@mui/icons-material/EvStation";
import Person2Icon from "@mui/icons-material/Person2";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import DevicesIcon from "@mui/icons-material/Devices";
import AddCardIcon from "@mui/icons-material/AddCard";
import FolderIcon from "@mui/icons-material/Folder";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import SystemSecurityUpdateGoodRoundedIcon from "@mui/icons-material/SystemSecurityUpdateGoodRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import ReplyAllRoundedIcon from "@mui/icons-material/ReplyAllRounded";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Diversity2RoundedIcon from "@mui/icons-material/Diversity2Rounded";
import CurtainsClosedRoundedIcon from "@mui/icons-material/CurtainsClosedRounded";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import AutoDeleteRoundedIcon from "@mui/icons-material/AutoDeleteRounded";
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded";
import AppSettingsAltRoundedIcon from "@mui/icons-material/AppSettingsAltRounded";
import Campaign from "@mui/icons-material/Campaign";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AssignmentIcon from "@mui/icons-material/Assignment";

import React from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LocalOffer from "@mui/icons-material/LocalOffer";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalShipping from "@mui/icons-material/LocalShipping";
import Settings from "@mui/icons-material/Settings";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import { Inventory, PersonSearch, SwapCalls } from "@mui/icons-material";
export const SideBarData = {
  wfsDashboard: [
    {
      id: 0,
      title: "Home",
      path: "/dashboard/wfsHome",
      icon: <HomeIcon />,
    },
    {
      id: 1,
      title: "WFS Can Orders",
      path: "/dashboard/wfsDetails",
      icon: <EvStationIcon />,
    },
    {
      id: 2,
      title: "Batch Details",
      path: "/dashboard/batchDetails",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 3,
      title: "QR/Barcode Details",
      path: "/dashboard/qrBarcodeDetails",
      icon: <QrCode2Icon />,
    },
    {
      id: 4,
      title: "Product Grouping",
      path: "/dashboard/groupDetails",
      icon: <QrCode2Icon />,
    },
  ],
  promoterDashboard: [
    {
      id: 17,
      title: "Marketing",
      path: "/dashboard/marketing",
      icon: <Campaign />,
    },
  ],
  /**
   * @author vinay kumar
   * @description Supplier Side Bar FData
   * @copyright Bookwater tech pvt ltd
   * @Changes Adding Side Bar Dashboard Data for Supplier
   * @version 0.0.1
   * @Date 12-06-2023
   */
  supplierDashboard: [
    // {
    //   id: 0,
    //   title: "Home",
    //   path: "/dashboard/home",
    //   icon: <HomeIcon />,
    // },
    {
      id: 0,
      title: "Trip Planning",
      path: "/dashboard/trip_planning",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 1,
      title: "Deliveries",
      path: "/dashboard/deliveries",
      icon: <LocalShipping />,
    },
    {
      id: 2,
      title: "Assign Slot/Agent",
      path: "/dashboard/assign_agent_slot",
      icon: <AssignmentIcon />,
    },
    {
      id: 3,
      title: "Re-Assign",
      path: "/dashboard/re_assign/agent",
      icon: <AssignmentIcon />,
    },
    {
      id: 4,
      title: "Orders",
      path: "/dashboard/orders",
      icon: <AddShoppingCart />,
    },
    {
      id: 5,
      title: "Book Now",
      path: "/dashboard/booknow",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 6,
      title: "Previous & Completed Deliveries",
      path: "/dashboard/prevCompleted",
      icon: <LocalShipping />,
    },
    // {
    //   id: 7,
    //   title: "Can Swap",
    //   path: "/dashboard/canSwap",
    //   icon: <Settings />,
    // },
    {
      id: 9,
      title: "QR/Barcode Details",
      path: "/dashboard/qrBarcodeDetails",
      icon: <QrCode2Icon />,
    },
    {
      id: 10,
      title: "Product Grouping",
      path: "/dashboard/groupDetails",
      icon: <QrCode2Icon />,
    },
    {
      id: 7,
      title: "Settings",
      path: "/dashboard/settings",
      icon: <Settings />,
    },
    {
      id: 8,
      title: "WareHouse",
      path: "/dashboard/warehouse",
      icon: <Diversity2RoundedIcon />,
    },
  ],
  /**
   * @author vinay kumar
   * @description Driver Side Bar Data
   * @copyright Bookwater tech pvt ltd
   * @Changes Adding Side Bar Dashboard Data for Driver
   * @version 0.0.1
   * @Date 23-08-2023
   */
  driverDashBoard: [
    // {
    //   id: 0,
    //   title: "Home",
    //   path: "/dashboard/home",
    //   icon: <HomeIcon />,
    // },
    {
      id: 1,
      title: "Deliveries",
      path: "/dashboard/deliveries",
      icon: <LocalShipping />,
    },
    {
      id: 2,
      title: "Profile",
      path: "/dashboard/driverprofile",
      icon: <Person2Icon />,
    },
    {
      id: 3,
      title: "Previous & Completed Deliveries",
      path: "/dashboard/prevCompleted",
      icon: <LocalShipping />,
    },
    {
      id: 8,
      title: "WareHouse",
      path: "/dashboard/warehouse",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 5,
      title: "Product Grouping",
      path: "/dashboard/groupDetails",
      icon: <QrCode2Icon />,
    },
  ],
  managerDashBoard: [
    // {
    //   id: 0,
    //   title: "Home",
    //   path: "/dashboard/home",
    //   icon: <HomeIcon />,
    // },
    {
      id: 0,
      title: "Trip Planning",
      path: "/dashboard/trip_planning",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 1,
      title: "Deliveries",
      path: "/dashboard/deliveries",
      icon: <LocalShipping />,
    },
    {
      id: 2,
      title: "Profile",
      path: "/dashboard/driverprofile",
      icon: <Person2Icon />,
    },
    {
      id: 3,
      title: "Previous & Completed Deliveries",
      path: "/dashboard/prevCompleted",
      icon: <LocalShipping />,
    },
    {
      id: 4,
      title: "Assign Slot/Agent",
      path: "/dashboard/assign_agent_slot",
      icon: <AssignmentIcon />,
    },
    {
      id: 5,
      title: "Re-Assign",
      path: "/dashboard/re_assign/agent",
      icon: <AssignmentIcon />,
    },
    {
      id: 8,
      title: "WareHouse",
      path: "/dashboard/warehouse",
      icon: <Diversity2RoundedIcon />,
    },
    {
      id: 7,
      title: "Product Grouping",
      path: "/dashboard/groupDetails",
      icon: <QrCode2Icon />,
    },
  ],

  adminDashboard: [
    {
      id: 1,
      title: "Dashboard",
      path: "/dashboard/home",
      icon: <HomeIcon />,
    },
    // {
    //   id: 22,
    //   title: "DashBoaed",
    //   path: "/dashboard/AdminDashboard",
    //   icon: <HomeIcon />,
    // },
    // {
    //   id: 26,
    //   title: "DashBoard",
    //   path: "/dashboard/AdmiNewDashboardMain",
    //   icon: <HomeIcon />,
    // },
    {
      id: 2,
      title: "Super Admin",
      path: "/dashboard/superadmin",
      icon: <AdminPanelSettingsIcon />,
    },
    {
      id: 22,
      title: "SKU",
      path: "/dashboard/newContainerDetails",
      icon: <Inventory />,
    },
    // {
    //   id: 3,
    //   title: "Users",
    //   path: "/dashboard/users",
    //   icon: <Person2Icon />,
    // },
    {
      id: 3,
      title: "Users",
      path: "/dashboard/UserDetailsMain",
      icon: <Person2Icon />,
    },
    {
      id: 4,
      title: "Orders",
      path: "/dashboard/orders",
      icon: <ShoppingCartCheckoutIcon />,
    },
    {
      id: 18,
      title: "Postpaid",
      path: "/dashboard/postpaidtrack",
      icon: <CreditCardIcon />,
    },

    {
      id: 19,
      title: "Transactions",
      path: "/dashboard/transactions",
      icon: <ReceiptLongIcon />,
    },
    {
      id: 23,
      title: "WFS Details",
      path: "/dashboard/wfsAllDetails",
      icon: <SystemSecurityUpdateGoodRoundedIcon />,
    },
    {
      id: 24,
      title: "Product Grouping",
      path: "/dashboard/groupDetails",
      icon: <QrCode2Icon />,
    },

    {
      id: 21,
      title: "Can Swap",
      path: "/dashboard/canSwapadmin",
      icon: <SwapCalls />,
    },
    {
      id: 10,
      title: "Reports",
      path: "/dashboard/report",
      icon: <FolderIcon />,
    },
    // {
    //   id: 11,
    //   title: "QR/Barcode Details",
    //   path: "/dashboard/qrBarcodeDetails",
    //   icon: <QrCode2Icon />,
    // },

    {
      id: 17,
      title: "Marketing",
      path: "/dashboard/marketing",
      icon: <Campaign />,
    },
    // {
    //   id: 25,
    //   title: "Influencer Settlement",
    //   path: "/dashboard/settlement",
    //   icon: <Campaign />,
    // },

    {
      id: 20,
      title: "Settings",
      path: "/dashboard/settings",
      icon: <SettingsSuggestIcon />,
    },
  ],
};
