import React from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import Consumer from "./Consumer";
import Supplier from "./Supplier";
import moment from "moment";
import CustomConsole from "../../CustomConsole";
import * as IconName from "react-icons/fc";
import waterDrop from "../../Images/waterDrop.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddWaterDrops,
  RemoveWaterDrops,
  ClearUpdateUserDetailsData,
  GetAccountsDetails,
  GetAssignedWfsDetails,
  GetCansRollBack,
  GetDepositDetails,
  GetProfileDetails,
  GetReferralCode,
  GetSubscriptionOrderDetails,
  GetUserAddressAdmin,
  GetUserBasedName,
  GetUserStockDetails,
  GetWalletDetails,
  PostReduceWaterWallet,
} from "../Redux/Actions/userDetailsAdminAction";
import { GetInfluencerDetails } from "../Redux/Actions/adminInfluencerAction";
import {
  ClearData,
  GetRolesList,
  SearchUserAdmin,
} from "../Redux/Actions/addUserAdminAction";
import { GetUserWaterDrops } from "../Redux/Actions/transactionsActions";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import { useHistory, useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import ReduceWalletAmountDialog from "./ReduceWalletAmountDialog";
import AddWaterDropsDialog from "./AddWaterDropsDialog";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { AppSettings } from "../Redux/Actions/appSettingsAction";
import WFSIncharge from "./WFSIncharge";
import UserNameDialog from "../AdminUsers/UserNameDialog";
import OtherUsers from "./OtherUsers";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import {
  GetPdwOrderDetails,
  GetSearchOrderDetails,
} from "../Redux/Actions/ordersAdminAction";
import { ClearFilesData } from "../Redux/Actions/fileHandlingAction";
import RemoveWaterDropsDialog from "./RemoveWaterDropsDialog";

function UserDetailsMain() {
  const dispatch = useDispatch();
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  CustomConsole(addUserAdminDetails);
  const orderDetailsList = useSelector((state) => state.orderDetailsAdmin);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const influencerDetails = useSelector((state) => state.influencerDetails);
  const userUpdates = useSelector((state) => state.userDetailsUpdate);
  const bookingDetails = useSelector((state) => state.adminBookingData);
  const appSettingsDetails = useSelector((state) => state.appSettingsDetails);
  const adminTransactionDetails = useSelector(
    (state) => state.adminTransactionDetails
  );
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);
  CustomConsole(adminTransactionDetails);
  const { pathname } = useLocation();

  const [fullname, setFullname] = React.useState("");
  const [renderFlag, setRenderFlag] = React.useState(true);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [roleId, setRoleId] = React.useState(0);
  const [userRoleId, setUserRoleId] = React.useState("");
  const [supplierId, setSupplierId] = React.useState("");
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [walletBalance, setWalletBalance] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const [phoneEmailName, setPhoneEmailName] = React.useState("");
  const [searchUserBtn, setSearchUserBtn] = React.useState(false);
  const [deliverySlotStatus, setDeliverySlotStatus] = React.useState(false);
  const [influencerSetting, setInfluencerSetting] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [allOrders, setAllOrders] = React.useState([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState(false);
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [showSupplierDetails, setShowSupplierDetails] = React.useState(false);
  const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [openuserDialog, setOpenUserDialog] = React.useState(false);
  const [updatedMail, setUpdatedMail] = React.useState("");
  const [searchUserFlag, setSearchUserFlag] = React.useState(false);
  const [orderList, setOrderList] = React.useState([]);

  const [nameSearch, setNameSearch] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [adminRole, setAdminRoleId] = React.useState("");

  const [bookOrderBtn, setBookOrderBtn] = React.useState(false);
  const [checkAvailability, setCheckAvailability] = React.useState(false);
  const [dialogOpenFlag, setDialogOpenFlag] = React.useState(false);
  const [supplierType, setSupplierType] = React.useState("");
  const [userStockData, setUserStockData] = React.useState({});
  const [wfsId, setWfsId] = React.useState("");
  const [supplierData, setSupplierData] = React.useState({});
  const [orderBtn, setOrderBtn] = React.useState(false);
  const [postPaidFlag, setPostpaidFlag] = React.useState(true);
  const [consumerId, setConsumerId] = React.useState("");
  const [openReduceWalletAmount, setOpenReduceWalletAmount] =
    React.useState(false);
  const [referralCode, setReferralCode] = React.useState("");
  const [openAddWaterDropsDialog, setOpenAddWaterDropsDialog] =
    React.useState(false);
  const [openRemoveWaterDropsDialog,setOpenRemoveWaterDropsDialog] = 
    React.useState(false);
  const [pwdPincodeAreas, setPwdPincodeAreas] = React.useState([]);
  // Redirecting from orders page and getting the phone number from URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");
  // React.useEffect(() => {
  //   dispatch(GetRolesList());
  // }, []);
  // Setting the phone number and hitting the API for search user with the phone number from URL(Redirecting from orders page)
  React.useEffect(() => {
    CustomConsole(phoneNumberFromOrders);
    setPhoneEmailName(phoneNumberFromOrders);
    dispatch(ClearData());
    if (phoneNumberFromOrders !== null) {
      CustomConsole("----------handleSearchPhoneEmailName-----------------");
      handleSearchPhoneEmailName();
    } else {
      setIsUserDetailsOpen(false);
    }
  }, [phoneNumberFromOrders]);

  React.useEffect(() => {
    dispatch(ClearData());
    dispatch(ClearUpdateUserDetailsData());
  }, []);
  //Distributor pincode length
  React.useEffect(() => {
    CustomConsole(appSettingsDetails);
    if (Object.keys(appSettingsDetails.admin_settings || {}).length) {
      // setTwntyLtrMin(appSettingsDetails.admin_settings.twenty_ltr_min);
      CustomConsole("---Look----");
      CustomConsole(appSettingsDetails.admin_settings.twenty_ltr_min);
      // setTwntyLtrMax(appSettingsDetails.admin_settings.twenty_ltr_max);
    }
    if (
      appSettingsDetails.pdw_pincodes_areanames !== undefined &&
      appSettingsDetails.pdw_pincodes_areanames.length
    ) {
      // CustomConsole(appSettingsDetails.pdw_pincodes_areanames);
      appSettingsDetails?.pdw_pincodes_areanames?.map((data) => {
        // CustomConsole(data);
      });
      setPwdPincodeAreas(appSettingsDetails.pdw_pincodes_areanames);
      // setShowPwdPincodeAreas(true);
    } else {
      setPwdPincodeAreas([]);
    }
    if (appSettingsDetails.error === false && updateFlag === true) {
      toast.success(appSettingsDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setUpdateFlag(false);

      dispatch(AppSettings());
    }
  }, [appSettingsDetails]);

  React.useEffect(() => {
    CustomConsole(orderDetailsList.pdwOrderDetails.orders);
    if (
      orderDetailsList.pdwOrderDetails &&
      orderDetailsList.pdwOrderDetails.orders &&
      orderDetailsList.pdwOrderDetails.orders.length
    ) {
      setOrderList(orderDetailsList.pdwOrderDetails.orders);
    } else {
      setOrderList([]);
    }
  }, [orderDetailsList.pdwOrderDetails]);
  React.useEffect(() => {
    if (adminUsers.length && searchUserFlag === true) {
      setIsUserDetailsOpen(true);
      setSearchUserFlag(false);
    }
  }, [adminUsers]);

  React.useEffect(() => {
    if (phoneEmailName) {
      dispatch(
        GetUserBasedName({ name: phoneEmailName, page_number: pageNumber - 1 })
      );
    }
  }, [pageNumber]);

  React.useEffect(() => {
    let regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    var resultEmail = regEmail.test(phoneEmailName);
    let regPhone = /^[0-9][0-9]{9}$/g;
    var resultPhone = regPhone.test(phoneEmailName);
    let regText = /[\w-\.]\w+/g;
    var resultText = regText.test(phoneEmailName);
    // dispatch(ClearUpdateUserDetailsData());
    updateUserDetails.userStockData = [];
    updateUserDetails.address = [];
    updateUserDetails.accounts = [];
    addUserAdminDetails.users = {};
    setPhone("");
    // dispatch(ClearData());
    // dispatch(ClearUpdateUserDetailsData());
    if (searchUserBtn) {
      // dispatch(ClearUpdateUserDetailsData());
      if (
        (phoneEmailName !== "" && resultEmail === true) ||
        resultPhone === true
      ) {
        dispatch(SearchUserAdmin(phoneEmailName));
        setSearchUserBtn(false);
        setSearchUserFlag(true);
        setRenderFlag(true);
        setInfluencerSetting(false);
      } else if (
        phoneEmailName !== "" &&
        resultText === true &&
        resultPhone === false &&
        resultEmail === false
      ) {
        dispatch(
          GetUserBasedName({
            name: phoneEmailName,
            page_number: pageNumber - 1,
          })
        );
        setDialogOpenFlag(true);
        setSearchUserFlag(true);
        setSearchUserBtn(false);
        setRenderFlag(true);
        setInfluencerSetting(false);
      } else {
        toast.error("Please enter Proper Phone/Email", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // setSearchUserBtn(false);
      }
    }
  }, [searchUserBtn]);

  // React.useEffect(() => {
  //   let regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  //   var resultEmail = regEmail.test(phoneEmailName);
  //   let regPhone = /^[0-9][0-9]{9}$/g;
  //   var resultPhone = regPhone.test(phoneEmailName);
  //   let regText = /[\w-\.]\w+/g;
  //   var resultText = regText.test(phoneEmailName);
  //   // dispatch(ClearUpdateUserDetailsData());
  //   updateUserDetails.userStockData=[]
  //   updateUserDetails.address=[]
  //   updateUserDetails.accounts=[]
  //   addUserAdminDetails.users={}
  //   // dispatch(ClearData());
  //   // dispatch(ClearUpdateUserDetailsData());
  //   if (searchUserBtn) {
  //     // dispatch(ClearUpdateUserDetailsData());
  //     if (
  //       (phoneEmailName !== "" && resultEmail === true) ||
  //       resultPhone === true
  //     ) {
  //       dispatch(SearchUserAdmin(phoneEmailName));
  //       setSearchUserBtn(false);
  //       setSearchUserFlag(true);
  //       setRenderFlag(true);
  //       setInfluencerSetting(false);
  //     } else if (
  //       phoneEmailName !== "" &&
  //       resultText === true &&
  //       resultPhone === false &&
  //       resultEmail === false
  //     ) {
  //       dispatch(
  //         GetUserBasedName({
  //           name: phoneEmailName,
  //           page_number: pageNumber - 1,
  //         })
  //       );
  //       setDialogOpenFlag(true);
  //       setSearchUserFlag(true);
  //       setSearchUserBtn(false);
  //       setRenderFlag(true);
  //       setInfluencerSetting(false);
  //     } else {
  //       toast.error("Please enter Proper Phone/Email", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //       // setSearchUserBtn(false);
  //     }
  //   }
  // }, [searchUserBtn]);
  React.useEffect(() => {
    CustomConsole(updateUserDetails.userNames);
    if (updateUserDetails.userNames && dialogOpenFlag) {
      CustomConsole("-----------dialogOpenFlag----------");
      setOpenUserDialog(true);
    }
    setDialogOpenFlag(false);
  }, [updateUserDetails.userNames, dialogOpenFlag]);

  React.useEffect(() => {
    CustomConsole(ADMIN_ROLES.BW_ADMIN);
    CustomConsole(
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN
    );
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);

  React.useEffect(() => {
    // if (addUserAdminDetails.error === false) {
    //   addUserAdminDetails?.users.map((data) => {
    //     CustomConsole("hitting address api in address card");
    //     dispatch(GetUserAddressAdmin(userId));
    //     // setUserId(data.id);
    //   });
    // }
    if (addUserAdminDetails.rolesList.length > 0) {
      setRoles(addUserAdminDetails.rolesList);
    }
  }, [addUserAdminDetails]);
  React.useEffect(() => {
    CustomConsole(allOrderData);
    setAllOrders(allOrderData.allOrderDetails);
  }, [allOrderData]);

  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    if (
      addUserAdminDetails.error === false &&
      addUserAdminDetails.users !== undefined
    ) {
      if (addUserAdminDetails.users.length > 0) {
        setAdminUsers(addUserAdminDetails.users);
        setUserId(addUserAdminDetails?.users?.[0]?.id);
        setRoleId(addUserAdminDetails?.users?.[0]?.role_id);
        setConsumerId(addUserAdminDetails?.users?.[0]?.consumer_id);
        setSupplierId(addUserAdminDetails?.users?.[0]?.supplier_id);
        setUserRoleId(addUserAdminDetails?.users?.[0]?.role_id);
        // addUserAdminDetails?.users.map((data) => {
        //   setUserId(data.id);
        //   setSupplierId(data.supplier_id);
        //   setUserRoleId(data.role_id);
        // });
      } else {
      }
    } else if (addUserAdminDetails.msg !== "") {
      // toast.error(addUserAdminDetails.msg, {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
  }, [addUserAdminDetails]);
  React.useEffect(() => {
    CustomConsole(updateUserDetails);
    CustomConsole(`updateUserDetails in userDetails`);
    if (updateUserDetails.referralCodeError === false) {
      setReferralCode(updateUserDetails.referralCode);
    }
    if (updateUserDetails.error === false) {
      setSupplierData(updateUserDetails.userProfileData.supplier);
      setWfsId(updateUserDetails.assignedWfsAddress.wfs_id);
      setWalletBalance(updateUserDetails.walletAmount);
      if (updateUserDetails.userStockData.length)
        updateUserDetails?.userStockData?.map((data) => {
          setUserStockData(data);
        });
    }
  }, [updateUserDetails]);
  React.useEffect(() => {
    CustomConsole(supplierData);
    if (supplierData !== undefined) {
      setDeliverySlotStatus(supplierData.delivery_slots_status);
      setSupplierType(supplierData.supplier_type);
    }
  }, [supplierData]);
  React.useEffect(() => {
    CustomConsole(adminUsers);
    adminUsers?.map((data, key) => {
      CustomConsole(data);
      setFullname(data.fullname);
      setPhone(data.phone);
      setEmail(data.email);
      setUpdatedMail(data.email);
      setRoleId(data.role_id);
      // setUserId(data.id);
      setIsSwitchOn(data.status);
    });
    if (adminUsers.length) {
      dispatch(GetCansRollBack(adminUsers[0].id));
    }
  }, [adminUsers]);

  React.useEffect(() => {
    CustomConsole("---user ID----");
    CustomConsole(userId);
    if (userId) {
      CustomConsole("---user ID----");
      dispatch(GetProfileDetails(userId));
      dispatch(GetDepositDetails(userId));
      dispatch(GetUserStockDetails(userId));
      if (roleId === ADMIN_ROLES.BW_CONSUMER) {
        dispatch(GetWalletDetails(userId));
        dispatch(GetInfluencerDetails(userId));
        dispatch(GetReferralCode(userId));
        dispatch(GetSubscriptionOrderDetails(userId));
        dispatch(GetUserWaterDrops(userId));
        dispatch(
          GetPdwOrderDetails({
            consumer_id: consumerId,
            delivery_from_date: moment().add(-10, "days").format("YYYY-MM-DD"),
            delivery_to_date: moment().format("YYYY-MM-DD"),
            page_size: 10,
            page_number: 1,
            sort_by: [{ key: "order_id", value: "DESC" }],
          })
        );
      }
      if (roleId === ADMIN_ROLES.BW_WFSADMIN) {
        dispatch(GetAssignedWfsDetails(userId));
      } else {
        dispatch(GetUserAddressAdmin(userId));
        dispatch(GetAccountsDetails(userId));
        dispatch(GetAvailableProducts());
      }
      CustomConsole("---user wallet----");
      // dispatch(GetRolesList());
      dispatch(AppSettings());
      // setSearchUserBtn(false);
      // setSearchUserBtn(false);
    }
  }, [userId]);

  const handlePhoneEmailNameChange = (event) => {
    CustomConsole(
      "-----------handlePhoneEmailNameChange-----------------------"
    );
    setPhoneEmailName(event.target.value);
  };
  const handleSearchPhoneEmailName = () => {
    setSearchUserBtn(true);
    setUserId("");
    // dispatch(ClearData());
    // dispatch(ClearUpdateUserDetailsData());
  };
  const handleReduceWalletAmount = (props) => {
    if (
      props.amountToReduceWaterWallet !== "" &&
      props.reasonForReduceWaterWallet !== "" &&
      props.reduceWalletTransActionId !== ""
    ) {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForReduceWaterWallet,
        amount: parseFloat(props.amountToReduceWaterWallet),
        trans_id: props.reduceWalletTransActionId,
        transaction_meta: {
          transaction_receipt: props.receiptFileName,
        },
      };
      CustomConsole(reqObj);
      if (
        parseFloat(props.amountToReduceWaterWallet) <= parseFloat(walletBalance)
      ) {
        dispatch(PostReduceWaterWallet(reqObj));
        setOpenReduceWalletAmount(false);
      } else {
        toast.error("Please Enter Less Than Wallet Amount", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpenReduceWalletAmount(true);
      }
      props.setAmountToReduceWaterWallet("");
      props.setReasonForReduceWaterWallet("");
      props.setReduceWalletTransActionId("");
      setTimeout(() => {
        dispatch(GetWalletDetails(userId));
      }, 1000);
    } else {
      toast.error("Please Fill All Data", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setOpenReduceWalletAmount(true);
    }
    // setOpenReduceWalletAmount(false);
  };
  // const searchBtn = () => {
  //   // const reqObj = {
  //   //   name: nameSearch ,
  //   //   page_number:pageNumber,

  //   // };
  //   // if (reqObj.name) {
  //   dispatch(GetUserBasedName({ name: nameSearch, page_number: pageNumber }));
  //   setDialogOpenFlag(true);
  //   // }
  // };
  const handleCloseReduceWalletAmount = () => {
    setOpenReduceWalletAmount(false);
  };
  const handleCloseAddWaterDrops = () => {
    setOpenAddWaterDropsDialog(false);
  };
  const handleCloseRemoveWaterDrops = () => {
    setOpenRemoveWaterDropsDialog(false);
  }
  const handleAddWaterDrops = (props) => {
    if (props.enterWaterDrops !== "" && props.reasonForAddWaterDrops !== "") {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForAddWaterDrops,
        water_drops: parseInt(props.enterWaterDrops),
      };
      CustomConsole(reqObj);
      dispatch(AddWaterDrops(reqObj));
      setTimeout(() => {
        dispatch(GetUserWaterDrops(userId));
      }, 1000);
      props.setEnterWaterDrops("");
      props.setReasonForAddWaterDrops("");
    } else {
      toast.error("Please Fill All Data", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    setOpenAddWaterDropsDialog(false);
  };
  const handleRemoveWaterDrops = (props) => {
    if (props.enterWaterDrops !== "" && props.reasonForRemoveWaterDrops !== "") {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForRemoveWaterDrops,
        water_drops: parseInt(props.enterWaterDrops),
      };
      CustomConsole(reqObj);
      dispatch(RemoveWaterDrops(reqObj));
      setTimeout(() => {
        dispatch(GetUserWaterDrops(userId));
      }, 1000);
      props.setEnterWaterDrops("");
      props.setReasonForRemoveWaterDrops("");
    } else {
      toast.error("Please Fill All Data", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    setOpenRemoveWaterDropsDialog(false);
  };
  
  const handleOpenAddWaterdropDialog = () => {
    CustomConsole("---------handleOpenAddWaterdropDialog-----------");
    setOpenAddWaterDropsDialog(true);
  };
  const handleOpenRemoveWaterdropDialog = () => {
    setOpenRemoveWaterDropsDialog(true);
  }

  //useffect for calling product details function
  // React.useEffect(() => {
  //   dispatch(GetAvailableProducts());
  // }, []);
  return (
    <Material.Box
      sx={{
        mt: "50px",
        bgcolor: "#F5EEF8",
        width: { md: "100%", xs: "100%" },
      }}
    >
      <Material.Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          flexWrap: "wrap",

          // maxHeight: "10px",
          // //   margin: "25px",
          // mb: "37px",
          // gap: "40px",
        }}
      >
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: { md: "flex-start", xs: "center" },
            flexWrap: "wrap",
            gap: "5px",
            m: 1,
          }}
        >
          {roleId === ADMIN_ROLES.BW_CONSUMER &&
          isUserDetailsOpen &&
          addUserAdminDetails.users !== undefined ? (
            <Material.Paper>
              <Material.Box sx={{ display: "flex", m: 1, gap: "10px" }}>
                <MuiIcons.AccountBalanceWallet />
                <Material.Typography>
                  : &#8377; {parseFloat(walletBalance).toFixed(2)}
                </Material.Typography>
                <Material.Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ height: "25px" }}
                  onClick={() => setOpenReduceWalletAmount(true)}
                >
                  <MuiIcons.Remove sx={{ fontSize: "18px" }} />{" "}
                  <MuiIcons.AccountBalanceWallet />
                </Material.Button>
              </Material.Box>
            </Material.Paper>
          ) : (
            ""
          )}
          {roleId === ADMIN_ROLES.BW_CONSUMER &&
          isUserDetailsOpen &&
          addUserAdminDetails.users !== undefined ? (
<Material.Paper>
      <Material.Box sx={{ display: "flex", m: 1, gap: "10px", alignItems: "center" }}>
        <Material.Button
          variant="contained"
          size="small"
          color="error"
          sx={{ height: "25px" }}
          onClick={handleOpenRemoveWaterdropDialog}
        >
          <MuiIcons.Remove sx={{ fontSize: "18px" }} />{" "}
          <img
            src={waterDrop}
            alt="icon"
            style={{
              height: "20px",
              width: "12px",
              marginTop: "3px",
            }}
          />
        </Material.Button>

        {/* <img
          src={waterDrop}
          alt="icon"
          style={{
            height: "20px",
            width: "12px",
            marginTop: "3px",
          }}
        /> */}
        <Material.Typography>
          :{" "}
          {adminTransactionDetails.waterDropsData.water_drops
            ? adminTransactionDetails.waterDropsData.water_drops
            : 0}
        </Material.Typography>

        <Material.Button
          variant="contained"
          size="small"
          color="success"
          sx={{ height: "25px" }}
          onClick={handleOpenAddWaterdropDialog}
        >
          <MuiIcons.Add sx={{ fontSize: "18px" }} />{" "}
          <img
            src={waterDrop}
            alt="icon"
            style={{
              height: "20px",
              width: "12px",
              marginTop: "3px",
            }}
          />
        </Material.Button>
      </Material.Box>
    </Material.Paper>
          ) : (
            ""
          )}
          {isUserDetailsOpen && addUserAdminDetails.users !== undefined ? (
            <Material.Paper>
              <Material.Box>
                <Material.Typography
                  sx={{ fontWeight: "bold", mt: 1, pl: "10px", pr: "10px" }}
                >
                  {roleId === ADMIN_ROLES.BW_DISTRIBUTER
                    ? "SUPPLIER"
                    : [
                        roleId === ADMIN_ROLES.BW_CONSUMER
                          ? "CONSUMER"
                          : [
                              roleId === ADMIN_ROLES.BW_DELIVERYAGENT
                                ? "DELIVERY AGENT"
                                : [
                                    roleId === ADMIN_ROLES.BW_SUPERADMIN
                                      ? "SUPER ADMIN"
                                      : roleId === ADMIN_ROLES.BW_ADMIN
                                      ? "ADMIN"
                                      : roleId === ADMIN_ROLES.BW_DELIVERYAGENT
                                      ? "Delivery agent"
                                      : roleId === ADMIN_ROLES.BW_WFSADMIN
                                      ? "WFS INCHARGE"
                                      : roleId === ADMIN_ROLES.BW_FINANCE
                                      ? "Finance"
                                      : roleId === ADMIN_ROLES.BW_CS
                                      ? "Customer Support"
                                      : roleId === ADMIN_ROLES.BW_FINANCEHEAD
                                      ? "Finance Head"
                                      : roleId === ADMIN_ROLES.BW_CSHEAD
                                      ? "Customer Support Head"
                                      : roleId === ADMIN_ROLES.BW_ADMIN
                                      ? "Admin"
                                      : roleId === ADMIN_ROLES.BW_OPS
                                      ? "Operation"
                                      : roleId === ADMIN_ROLES.BW_OPSHEAD
                                      ? "Operation Head"
                                      : roleId === ADMIN_ROLES.BW_QA
                                      ? "QA"
                                      : roleId === ADMIN_ROLES.BW_QAHEAD
                                      ? "QA Head"
                                      : roleId === ADMIN_ROLES.BW_SALES
                                      ? "Sales"
                                      : roleId === ADMIN_ROLES.BW_SALESHEAD
                                      ? "Sales Head"
                                      : roleId === ADMIN_ROLES.BW_SUPERADMIN
                                      ? "Super Admin"
                                      : roleId === ADMIN_ROLES.BW_CSLEAD
                                      ? "Customer Support Lead"
                                      : roleId === ADMIN_ROLES.BW_IVML
                                      ? "Inventory Lead"
                                      : roleId ===
                                        ADMIN_ROLES.BW_SUPPLIERMANAGER
                                      ? "WAREHOUSE MANAGER"
                                      : roleId ===
                                        ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                      ? "LOADER"
                                      : "",
                                  ],
                            ],
                      ]}
                </Material.Typography>
              </Material.Box>
            </Material.Paper>
          ) : (
            ""
          )}
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: { md: "flex-end", xs: "center" },
            m: 1,
          }}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault(); // Prevent default form submission
              setSearchUserBtn(true);
            }}
          >
            {/* Form element wrapping the TextField */}
            <Material.TextField
              size="small"
              value={phoneEmailName}
              label="Phone/Email/Name"
              onChange={handlePhoneEmailNameChange}
              InputProps={{
                // maxLength: 10, // Optional: You can set the maximum length if needed
                // onInput: handleChangePageSize, // Attach the input event handler
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPhoneEmailName}
                    />
                  </Material.InputAdornment>
                ),
                style: { borderRadius: "50px" },
              }}
            />
            <Material.Button type="submit" style={{ display: "none" }} />{" "}
            {/* Hidden submit button */}
          </form>
          {/* <Material.TextField
            size="small"
            value={phoneEmailName}
            label="Phone/Email/Name"
            onChange={handlePhoneEmailNameChange}
            InputProps={{
              // maxLength: 10, // Optional: You can set the maximum length if needed
              // onInput: handleChangePageSize, // Attach the input event handler
              endAdornment: (
                <Material.InputAdornment position="end">
                  <MuiIcons.Search
                    cursor="pointer"
                    size="30px"
                    onClick={handleSearchPhoneEmailName}
                  />
                </Material.InputAdornment>
              ),
              style: { borderRadius: "50px" },
            }}
          /> */}
        </Material.Box>
      </Material.Box>
      {isUserDetailsOpen && addUserAdminDetails.users !== undefined ? (
        <>
          {roleId === ADMIN_ROLES.BW_CONSUMER ? (
            <Consumer
              userData={addUserAdminDetails.users}
              setIsSwitchOn={setIsSwitchOn}
              isSwitchOn={isSwitchOn}
              renderFlag={renderFlag}
              setRenderFlag={setRenderFlag}
              setInfluencerSetting={setInfluencerSetting}
              influencerSetting={influencerSetting}
              setIsUserDetailsOpen={setIsUserDetailsOpen}
              roles={roles}
              adminRole={adminRole}
              setUserId={setUserId}
              userId={userId}
              setRoleId={setRoleId}
              roleId={roleId}
              setEmail={setEmail}
              email={email}
              setPhone={setPhone}
              phone={phone}
              setFullname={setFullname}
              fullname={fullname}
              updateUserDetails={updateUserDetails}
              addUserAdminDetails={addUserAdminDetails}
              referralCode={referralCode}
              setReferralCode={setReferralCode}
              influencerDetails={influencerDetails}
              userUpdates={userUpdates}
              bookingDetails={bookingDetails}
              setBookOrderBtn={setBookOrderBtn}
              bookOrderBtn={bookOrderBtn}
              walletBalance={walletBalance}
              adminUsers={adminUsers}
              checkAvailability={checkAvailability}
              setCheckAvailability={setCheckAvailability}
              postPaidFlag={postPaidFlag}
              setPostpaidFlag={setPostpaidFlag}
              orderBtn={orderBtn}
              setOrderBtn={setOrderBtn}
              allOrders={allOrders}
              orderList={orderList}
              consumerId={consumerId}
            />
          ) : roleId === ADMIN_ROLES.BW_DISTRIBUTER ? (
            <Supplier
              setIsSwitchOn={setIsSwitchOn}
              isSwitchOn={isSwitchOn}
              roles={roles}
              setIsUserDetailsOpen={setIsUserDetailsOpen}
              pwdPincodeAreas={pwdPincodeAreas}
              supplierId={supplierId}
              adminRole={adminRole}
              setUserId={setUserId}
              userId={userId}
              setRoleId={setRoleId}
              roleId={roleId}
              setEmail={setEmail}
              email={email}
              setPhone={setPhone}
              phone={phone}
              setFullname={setFullname}
              fullname={fullname}
              updateUserDetails={updateUserDetails}
              supplierData={supplierData}
              setSupplierType={setSupplierType}
              supplierType={supplierType}
              deliverySlotStatus={deliverySlotStatus}
              setDeliverySlotStatus={setDeliverySlotStatus}
              bookingDetails={bookingDetails}
            />
          ) : roleId === ADMIN_ROLES.BW_WFSADMIN ? (
            <WFSIncharge
              setIsSwitchOn={setIsSwitchOn}
              isSwitchOn={isSwitchOn}
              setIsUserDetailsOpen={setIsUserDetailsOpen}
              updatedMail={updatedMail}
              userRoleId={userRoleId}
              roles={roles}
              adminRole={adminRole}
              setUserId={setUserId}
              userId={userId}
              setRoleId={setRoleId}
              roleId={roleId}
              setEmail={setEmail}
              email={email}
              setPhone={setPhone}
              phone={phone}
              setFullname={setFullname}
              fullname={fullname}
              updateUserDetails={updateUserDetails}
              addUserAdminDetails={addUserAdminDetails}
            />
          ) : roleId === ADMIN_ROLES.BW_CS ||
            roleId === ADMIN_ROLES.BW_CSHEAD ||
            roleId === ADMIN_ROLES.BW_CSLEAD ||
            roleId === ADMIN_ROLES.BW_DELIVERYAGENT ||
            roleId === ADMIN_ROLES.BW_FINANCE ||
            roleId === ADMIN_ROLES.BW_FINANCEHEAD ||
            roleId === ADMIN_ROLES.BW_IVML ||
            roleId === ADMIN_ROLES.BW_OPS ||
            roleId === ADMIN_ROLES.BW_OPSHEAD ||
            roleId === ADMIN_ROLES.BW_PROMOTER ||
            roleId === ADMIN_ROLES.BW_QA ||
            roleId === ADMIN_ROLES.BW_QAHEAD ||
            roleId === ADMIN_ROLES.BW_SALES ||
            roleId === ADMIN_ROLES.BW_SALESHEAD ||
            roleId === ADMIN_ROLES.BW_SUPERADMIN ||
            roleId === ADMIN_ROLES.BW_ADMIN ||
            roleId === ADMIN_ROLES.BW_SUPPLIERMANAGER ||
            roleId === ADMIN_ROLES.BW_SUPPLIER_LOADERS ? (
            <OtherUsers
              setIsSwitchOn={setIsSwitchOn}
              isSwitchOn={isSwitchOn}
              setIsUserDetailsOpen={setIsUserDetailsOpen}
              updatedMail={updatedMail}
              userRoleId={userRoleId}
              roles={roles}
              adminRole={adminRole}
              setUserId={setUserId}
              userId={userId}
              setRoleId={setRoleId}
              roleId={roleId}
              setEmail={setEmail}
              email={email}
              setPhone={setPhone}
              phone={phone}
              setFullname={setFullname}
              fullname={fullname}
              updateUserDetails={updateUserDetails}
              addUserAdminDetails={addUserAdminDetails}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {/* <Supplier /> */}
      <ReduceWalletAmountDialog
        openReduceWalletAmount={openReduceWalletAmount}
        handleReduceWalletAmount={handleReduceWalletAmount}
        handleCloseReduceWalletAmount={handleCloseReduceWalletAmount}
      />
      <AddWaterDropsDialog
        openAddWaterDropsDialog={openAddWaterDropsDialog}
        handleAddWaterDrops={handleAddWaterDrops}
        handleCloseAddWaterDrops={handleCloseAddWaterDrops}
      />
      <RemoveWaterDropsDialog
        openRemoveWaterDropsDialog={openRemoveWaterDropsDialog}
        handleRemoveWaterDrops={handleRemoveWaterDrops}
        handleCloseRemoveWaterDrops={handleCloseRemoveWaterDrops}
      />
      <UserNameDialog
        setOpen={setOpenUserDialog}
        open={openuserDialog}
        setDialogOpenFlag={setDialogOpenFlag}
        setPhoneEmail={setPhoneEmailName}
        handleSearchUser={handleSearchPhoneEmailName}
        // setTabValue={setTabValue}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </Material.Box>
  );
}

export default UserDetailsMain;
