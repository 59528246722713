import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Paper, Typography, Button, Grid, Tooltip } from "@mui/material";
import { grey, blue } from "@mui/material/colors";
import AddWaterDropsDialog from "../../../Pages/UserDetails/AddWaterDropsDialog";
import RemoveWaterDropsDialog from "../../../Pages/UserDetails/RemoveWaterDropsDialog";
import { AddWaterDrops, RemoveWaterDrops } from "../../../Pages/Redux/Actions/userDetailsAdminAction";
import { GetUserWaterDrops } from "../../../Pages/Redux/Actions/transactionsActions";
import { Add, Remove } from "@mui/icons-material";

const WaterDrops = ({ waterDrops, userId }) => {
  const dispatch = useDispatch();
  const [openAddWaterDropsDialog, setOpenAddWaterDropsDialog] = useState(false);
  const [openRemoveWaterDropsDialog, setOpenRemoveWaterDropsDialog] = useState(false);

  const handleOpenAddWaterdropDialog = () => setOpenAddWaterDropsDialog(true);
  const handleOpenRemoveWaterdropDialog = () => setOpenRemoveWaterDropsDialog(true);
  const handleCloseAddWaterDrops = () => setOpenAddWaterDropsDialog(false);
  const handleCloseRemoveWaterDrops = () => setOpenRemoveWaterDropsDialog(false);

  const handleAddWaterDrops = (props) => {
    if (props.enterWaterDrops !== "" && props.reasonForAddWaterDrops !== "") {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForAddWaterDrops,
        water_drops: parseInt(props.enterWaterDrops),
      };
      dispatch(AddWaterDrops(reqObj));
      setTimeout(() => dispatch(GetUserWaterDrops(userId)), 1000);
      props.setEnterWaterDrops("");
      props.setReasonForAddWaterDrops("");
    }
    setOpenAddWaterDropsDialog(false);
  };

  const handleRemoveWaterDrops = (props) => {
    if (props.enterWaterDrops !== "" && props.reasonForRemoveWaterDrops !== "") {
      const reqObj = {
        user_id: userId,
        reason: props.reasonForRemoveWaterDrops,
        water_drops: parseInt(props.enterWaterDrops),
      };
      dispatch(RemoveWaterDrops(reqObj));
      setTimeout(() => dispatch(GetUserWaterDrops(userId)), 1000);
      props.setEnterWaterDrops("");
      props.setReasonForRemoveWaterDrops("");
    }
    setOpenRemoveWaterDropsDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        p: { sm: 4 },
        backgroundColor: grey[50],
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: { xs: "1.1rem", sm: "1.1rem" },
          fontWeight: "bold",
          textAlign: "center",
          color: blue[800],
        }}
      >
        Water Drops
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper
            elevation={5}
            sx={{
              borderRadius: 2,
              transition: "0.3s",
              "&:hover": {
                boxShadow: 12,
                transform: "scale(1.03)",
              },
              p: { xs: 2, sm: 3 },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              
              {/* Remove Button with Tooltip */}
              <Tooltip title="Reduce" arrow>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenRemoveWaterdropDialog}
                  sx={{ mr: 1 }} // Margin to the right
                >
                  <Remove />
                </Button>
              </Tooltip>

              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem" },
                  fontWeight: "bold",
                  color: blue[700],
                  textAlign: "center",
                }}
              >
                Available Water Drops: {waterDrops > 0 ? waterDrops : "0"}
              </Typography>

              {/* Add Button with Tooltip */}
              <Tooltip title="Add" arrow>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleOpenAddWaterdropDialog}
                  sx={{ ml: 1 }} // Margin to the left
                >
                  <Add />
                </Button>
              </Tooltip>
            </Box>

            {waterDrops > 0 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mt: 2,
                  textAlign: "center",
                  fontSize: { xs: "0.8rem", sm: "0.9rem" },
                  p: { xs: 1, sm: 1 },
                  backgroundColor: grey[100],
                  borderRadius: 2,
                }}
              >
                Note: Clear or remove the water drops
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Dialogs for Adding and Removing Water Drops */}
      <AddWaterDropsDialog
        openAddWaterDropsDialog={openAddWaterDropsDialog}
        handleAddWaterDrops={handleAddWaterDrops}
        handleCloseAddWaterDrops={handleCloseAddWaterDrops}
      />
      <RemoveWaterDropsDialog
        openRemoveWaterDropsDialog={openRemoveWaterDropsDialog}
        handleRemoveWaterDrops={handleRemoveWaterDrops}
        handleCloseRemoveWaterDrops={handleCloseRemoveWaterDrops}
      />
    </Box>
  );
};

export default WaterDrops;
