/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { url } from "../apis";
import actionType from "./actionType";
import CustomConsole from "../../../CustomConsole";
//  import * as toastMessage from "../../Assets/Constants/ToastMessages";
//  import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Axios/useAxios";
//  import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";

//Get File From the server
export const getFiles = (filename) => {
  if (
    filename === undefined ||
    filename === null ||
    filename === "undefined" ||
    !filename ||
    !filename.length
  ) {
    return;
  }
  CustomConsole("------- (getFiles) getFiles Action--------");
  CustomConsole(filename);
  let fileDetails = "";
  if (filename.indexOf(".") === -1) {
    // If no dot is found, add '.jpg' to the filename
    fileDetails = filename + ".jpg";
  } else {
    fileDetails = filename;
  }
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/SSM/files/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          //   "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileDetails);
          document.body.appendChild(link);
          link.click();
          // CustomConsole("getFilesAction" + link.href);
          dispatch({
            type: actionType.GET_FILES,
            payload: link.href,
          });
          toast.dark("Successfully downloaded", {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
        })
        .catch((error) => {
          CustomConsole("getFiles Action Error : " + error);
        });
    };
  } else {
    //  toast.dark(toastMessage.TOKEN_INVALID, {
    //    position: "bottom-right",
    //    autoClose: 5000,
    //    type: "error",
    //  });
  }
};

export const DownloadFile = (filename) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      const response = await api.get(`/SSM/files/${filename}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${filename}`);
      document.body.appendChild(link);
      link.click();
      CustomConsole("----------Image UrL----------");

      CustomConsole(response.status);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_FILES,
          payload: link.href,
        });
      } else {
        dispatch({
          type: actionType.GET_FILES,
          payload: "",
        });
      }
    } catch (error) {
      // console.error("Error fetching image:", error);
      dispatch({
        type: actionType.GET_FILES,
        payload: "",
      });
    }
  };
};
// Upload Quality report Files/Images

export const UploadFile = (uploadedFile) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let file = new FormData();
  CustomConsole(uploadedFile);
  file.append("name", "filename");
  file.append("filename", uploadedFile);
  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post("/SSM/files/upload", file).catch((e) => {
      error = e;
      CustomConsole(`Error: ${e}`);
    });
    if (error !== "" && error.message.includes("400")) {
    } else if (response.status === httpStatus.SUCCESS) {
      CustomConsole(
        `response.status: ${response.status}, ${JSON.stringify(response)}`
      );
      dispatch({
        type: actionType.UPLOAD_FILE,
        payload: { data: response.data, statusFlag: true },
      });
      toast.dark("File Uploded Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
      return response.data;
    } else {
      CustomConsole("Unhandled Error: " + error);
      return false;
    }
  };
};

export const UploadFileCallBack = async (uploadedFile) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let file = new FormData();
  CustomConsole(uploadedFile);
  file.append("name", "filename");
  file.append("filename", uploadedFile);
  let error = "";
  let api = useAxios();
  // return async (dispatch) => {
  let response = await api.post("/SSM/files/upload", file).catch((e) => {
    error = e;
    CustomConsole(`Error: ${e}`);
  });
  if (error !== "" && error.message.includes("400")) {
  } else if (response.status === httpStatus.SUCCESS) {
    CustomConsole(
      `response.status: ${response.status}, ${JSON.stringify(response)}`
    );
    return response.data;
  } else {
    CustomConsole("Unhandled Error: " + error);
  }
  // };
};

export const UploadQrFile = (file, wfsId) => {
  CustomConsole("-------------UploadQualityFile----------------------");
  CustomConsole(file);
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put("/TM/updateWfsQualityReport", {
        file_name: file,
        wfs_id: wfsId,
      });
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(`response.status: ${response.status}}`);
        CustomConsole(response);
        dispatch({
          type: actionType.UPLOAD_QUALITY_FILE,
          payload: { data: response.data, statusFlag: true },
        });
        CustomConsole(response);
      } else {
        CustomConsole("Unhandled Error: " + error);
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const UploadPoFile = (data) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  CustomConsole("-------------UploadPoFile----------------------");
  CustomConsole(data);
  let file = new FormData();
  file.append("order_id", data.order_id);
  file.append("order_price", data.order_price);
  file.append("postpaid_perc", data.postpaid_perc);
  file.append("postpaid_charges", data.postpaid_charges);
  file.append("final_order_price", data.final_order_price);
  file.append("user_id", data.user_id);
  file.append("payment_type", data.payment_type);
  file.append("status_id", data.status_id);
  file.append("status", data.status);
  file.append("conv_fee", data.conv_fee);
  file.append("conv_charges", data.conv_charges);
  // file.append("name", "filename");
  file.append("filename", data.poFile);

  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post("/SSM/files/poupload", file, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === httpStatus.SUCCESS) {
        CustomConsole(`response.status: ${response.status}}`);
        CustomConsole(response);
        dispatch({
          type: actionType.UPLOAD_PO_FILE,
          payload: { data: response.data, statusFlag: true },
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        CustomConsole("Unhandled Error: " + error);
        toast.dark("Error while sending request to admin", {
          position: "bottom-right",
          autoClose: 3000,
          type: "info",
        });
      }
    } catch (error) {
      CustomConsole(error);
      toast.dark("Error while sending request to admin", {
        position: "bottom-right",
        autoClose: 3000,
        type: "info",
      });
    }
  };
};
export const ClearFilesData = () => {
  return {
    type: actionType.CLEAR_FILES_DATA,
  };
};

//show images without download
export const ShowImagesInApp = (filename) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      const response = await api.get(`/SSM/files/${filename}`, {
        responseType: "blob",
      });
      const imageUrl = URL.createObjectURL(response.data);
      CustomConsole("----------Image UrL----------");
      CustomConsole(imageUrl);
      CustomConsole(response.status);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_FILES,
          payload: imageUrl,
        });
      } else {
        dispatch({
          type: actionType.GET_FILES,
          payload: "",
        });
      }
    } catch (error) {
      // console.error("Error fetching image:", error);
      dispatch({
        type: actionType.GET_FILES,
        payload: "",
      });
    }
  };
};

//Get File From the server
export const getFilesImages = (filename) => {
  if (
    filename === undefined ||
    filename === null ||
    filename === "undefined" ||
    !filename ||
    !filename.length
  ) {
    return;
  }
  CustomConsole(filename);
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/SSM/files/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            const imageDataUrl = reader.result;
            // CustomConsole(imageDataUrl);
            dispatch({
              type: actionType.GET_IMAGE_FILES,
              payload: imageDataUrl,
            });
          };
          reader.readAsDataURL(blob);
        })

        .catch((error) => {
          CustomConsole("getFiles Action Error : " + error);
        });
    };
  } else {
    toast.dark("Error in process", {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};
