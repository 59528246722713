import React from "react";
import * as Material from "@mui/material";
import { styles } from "../wfsCanOrders/cardStyles";
import moment from "moment";
import WfsOngoingBatchCardDialog from "./WfsOngoingBatchCardDialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CustomConsole from "../../CustomConsole";
import Typography from "@mui/material/Typography";
import EditBatchDetailsDilaog from "./EditBatchDetailsDilaog";
import { toast } from "react-toastify";
import {
  GetWfsOngoingBatch,
  GetWfsOngoingBatchLineDetails,
  PutStartBatchAction,
  PutStopBatchAction,
  PutWfsQrlinkAction,
  WfsCreateBatchAction,
  WfsEditBatchAction,
} from "../Redux/Actions/wfsOnGoingBatchAction";
import ResultContainerPlugin1 from "../wfsHome/ResultContainerPlugin1";
import Html5QrcodePlugin1 from "../wfsHome/HtmlQrcodePlugin1";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Grid,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  CheckCircle,
  Close,
  DocumentScanner,
  HighlightOff,
  RedoTwoTone,
} from "@mui/icons-material";
import {
  ClearDataRefershBatch,
  LinkValidCans,
  ValidateExpired,
  // ValidateScannedCodes,
} from "../Redux/Actions/wfsBatchLinkingAction";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import WfsBatchCameraDialogCanSwapScanInvalid from "./WfsBatchCameraDialogCanSwapScanInvalid";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import ReturnCanCameraCanSwapScanIvalidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/ReturnCanCameraCanSwapScanIvalidCansDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import { PutWfsEditBatchDetails } from "../Redux/Actions/adminWfsCanOrdersAction";
import {
  EditWfsBatchSku,
  LinkQrLessProductToBatch,
} from "../Redux/Actions/skuWfsDetailsAction";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import ToggleButton from "@mui/material/ToggleButton";
import ScanningDialog from "../../Components/Custom/ScanningDialog";
import {
  ClearDataRefersh,
  LinkProductsOrderToOrder,
} from "../Redux/Actions/supliersAction";
import LinkOrderToOrderScanningProcessDialog from "../../Components/Dialog/LinkOrderToOrderScanningProcessDialog";

function WfsBatchFormView({
  data,
  ongoingBatch,
  pageNumber,
  setIsCreateBatchDialogOpen,
  setIsUpdate,
}) {
  const isBatch = true;
  CustomConsole(data);
  CustomConsole(ongoingBatch);
  const dispatch = useDispatch();
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const wfsBatchDetails = useSelector((state) => state.wfsOngoingBatchDetails);
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [singleSelectedData, setSingleSelectedData] = React.useState({});
  const [openOrderToOrderScanningDialog, setOpenOrderToOrderScanningDialog] =
    React.useState("");
  const [orderIdStartingLetters, setOrderIdStartingLetters] =
    React.useState("");
  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);
  const [valueOrderId, setValueOrderId] = React.useState("");
  const [showingbatchDialogFlag, setShowingbatchDialogFlag] =
    React.useState(false);

  // const [pageNumber, setPageNumber] = React.useState(1);
  const [scanQrcodebtn, setScanQrcodebtn] = React.useState(false);
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [scanQrCodeDetails, setScanQrCodeDetails] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [openEditBatchDialog, setOpenEditBatchDialog] = React.useState(false);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [updateDetails, setUpdateDetails] = React.useState({});
  const [scanCount, setScanCount] = React.useState(0);
  const [batchId, setBatchId] = React.useState("");
  const [scanOption, setScanOption] = React.useState("scanner");
  const [value, setValue] = React.useState(0);
  const [selectedbatch, setSelectedBatch] = React.useState({});

  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const validatedData = useSelector((state) => state.wfsBatchBulkLinking);
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);

  const [validData, setValidData] = React.useState(() =>
    localStorage.getItem("valid_data") &&
    localStorage.getItem("valid_data").length
      ? JSON.parse(localStorage.getItem("valid_data"))
      : []
  );
  function loadInitialState() {
    const data = localStorage.getItem("current_scanned_codes");
    return data ? JSON.parse(data) : {};
  }
  const [currentScanned, setCurrentScanned] = React.useState({});
  const [cameraScannedCodes, setCameraScannedCodes] = React.useState([]);
  const [decodedText, setDecodedText] = React.useState("");
  const [switchScanning, setSwitchScanning] = React.useState(false);
  const [openSwappedCanDialog, setOpenSwappedCanDialog] = React.useState(false);

  const [currentScannedCodes, setCurrentScannedCodes] = React.useState(() =>
    localStorage.getItem("current_scanned_codes") &&
    localStorage.getItem("current_scanned_codes").length
      ? JSON.parse(localStorage.getItem("current_scanned_codes"))
      : []
  );

  const [validDataToApi, setValidDataToApi] = React.useState();
  const [locallyScannedStored, setLocallyScannedStored] = React.useState([]);

  const [batchValidatedData, setBatchValidatedData] = React.useState(() =>
    localStorage.getItem("validatedData") &&
    localStorage.getItem("validatedData").length
      ? JSON.parse(localStorage.getItem("validatedData"))
      : []
  );
  const [codesToValidate, setCodesToValidate] = React.useState([]);
  const [currentBatchId, setCurrentBatchId] = React.useState("");

  const [scanLimit, setScanLimit] = React.useState(
    ongoingBatch?.linked_container_qty
  );
  const [batchQuantity, setBatchQuantity] = React.useState(0);

  // Scanning new
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});

  const [qrLessProductOpenDialog, setQrLessProductOpenDialog] =
    React.useState(false);
  const [validateExpired, setValidateExpired] = React.useState([]);
  CustomConsole(scanQrCodeDetails);
  React.useEffect(() => {
    dispatch(GetWfsOngoingBatchLineDetails());
  }, []);

  React.useEffect(() => {
    if (!supplierDeliveries.linkOrderToOrdererror) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      dispatch(ClearDataRefersh());
    }
  }, [supplierDeliveries.linkOrderToOrdererror]);

  React.useEffect(() => {
    if (!validatedData.linkbatcherror) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      dispatch(ClearDataRefershBatch());
    }
  }, [validatedData.linkbatcherror]);
  React.useEffect(() => {
    CustomConsole("codesToValidate");
    CustomConsole(codesToValidate);
  }, [codesToValidate]);

  React.useEffect(() => {
    CustomConsole("ongoingBatch");
    CustomConsole(ongoingBatch);
    setScanLimit(ongoingBatch?.quantity - ongoingBatch?.linked_container_qty);
  }, [ongoingBatch]);

  React.useEffect(() => {
    CustomConsole("scan Limit");
    CustomConsole(scanLimit);
  }, [scanLimit]);
  const handleChangeOrderId = (event) => {
    const selectedOrdersData = data.find(
      (data) => data.batch_id === event.target.value
    );
    CustomConsole(selectedOrdersData);
    setSingleSelectedData(selectedOrdersData);
    setValueOrderId(event.target.value);
    setCurrentBatchId(event.target.value);
  };

  React.useEffect(() => {
    CustomConsole("locally scanned stored");
    CustomConsole(locallyScannedStored);
    locallyScannedStored?.map((data) => {
      if (ongoingBatch.batch_id === data.orderId) {
        // setCodesToVerify(data.scannedCodes);
      }
    });
  }, [locallyScannedStored]);

  React.useEffect(() => {
    setCurrentScannedCodes(() =>
      localStorage.getItem("current_scanned_codes") &&
      localStorage.getItem("current_scanned_codes").length
        ? JSON.parse(localStorage.getItem("current_scanned_codes"))
        : []
    );
  }, []);

  React.useEffect(() => {
    CustomConsole("validateData");
    CustomConsole(validatedData);
    if (validatedData?.error === false) {
      setValidateExpired(validatedData?.validatedExpired);
      let batchId = currentBatchId;
      const storedData =
        localStorage.getItem("validatedData") &&
        localStorage.getItem("validatedData").length
          ? JSON.parse(localStorage.getItem("validatedData"))
          : [];
      const isBatchIdAlreadyStored = storedData.some(
        (entry) => entry.batchId === currentBatchId
      );

      // Update or create a new entry in the local storage array
      if (currentBatchId !== "" && isBatchIdAlreadyStored === false) {
        const newData = {
          batchId,
          validatedData: validatedData?.validatedData,
        };

        const updatedData = [...storedData, newData];
        setBatchValidatedData(updatedData);
        const vd = updatedData ? JSON.stringify(updatedData) : [];
        localStorage.setItem("validatedData", vd);
      }
      // ECON01095
      setValidData(validatedData?.validatedData?.user_valid_data);
      // setInvalidData(validatedData?.validatedData?.invalid_data);
      // setOtherValidData(validatedData?.validatedData?.other_valid_data);
    }
  }, [validatedData]);
  React.useEffect(() => {
    CustomConsole("ValidData");
    CustomConsole(validData);
    const transformedArray = validData?.map((item) => ({
      id: item.id,
    }));
    setValidDataToApi(transformedArray);
  }, [validData]);

  React.useEffect(() => {
    CustomConsole("validDataToApi");
    CustomConsole(validDataToApi);
  }, [validDataToApi]);

  const handleCreateBatchCard = (ongoingBatch) => {
    CustomConsole(ongoingBatch);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScanQrCodeDetails(decodedText);
    }
  };

  const onScanAll = (decodedText) => {
    setDecodedText(decodedText);
  };

  const handleOpenLinkOrderToOrderDialog = (props) => {
    setOrderIdStartingLetters(props);
    setOpenOrderToOrderScanningDialog(true);
  };

  React.useEffect(() => {
    // if (!cameraScannedCodes.includes(decodedText) && decodedText !== "") {
    if (decodedText !== "") {
      setCameraScannedCodes((prev) => [...prev, decodedText]);
      setTimeout(() => {
        setDecodedText("");
      }, 300);
    }
  }, [decodedText]);

  React.useEffect(() => {
    //@Removed/console.log("cameraScannedCodes", cameraScannedCodes);
    const code = cameraScannedCodes[cameraScannedCodes.length - 1];
    const maxQty =
      selectedbatch?.quantity - selectedbatch?.linked_container_qty;
    const state = selectedbatch && selectedbatch?.batch_id ? true : false;
    if (cameraScannedCodes.length) {
      if (state === true) {
        //@Removed/console.log("-- Inside main state ---");
        //@Removed/console.log(code);
        const newOrderId = selectedbatch.batch_id;
        // if (currentScanned && Object.keys(currentScanned).length) {
        //@Removed/console.log("currentScanned in scanner: ");
        //@Removed/console.log(currentScanned);
        //@Removed/console.log(currentScanned[newOrderId]);
        let qrCodeList =
          currentScanned &&
          Object.keys(currentScanned).length &&
          currentScanned[newOrderId]
            ? currentScanned[newOrderId]
            : [];
        //@Removed/console.log("qrCodeList: ");
        //@Removed/console.log(qrCodeList);
        if (qrCodeList.length && qrCodeList.includes(code)) {
          toast.error(`Can is scanned already!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //@Removed/console.log("qrCodeList.length <= maxQty: ");
          //@Removed/console.log(qrCodeList.length <= maxQty);
          if (qrCodeList.length < maxQty) {
            qrCodeList = [...qrCodeList, code];
            setScannedCount(qrCodeList.length);
            let localStorageData = {};
            localStorageData[newOrderId] = qrCodeList;
            //@Removed/console.log("localStorageData: ");
            //@Removed/console.log(localStorageData);
            setCurrentScanned(() => localStorageData);
            // localStorage.setItem(
            //   "current_scanned_codes",
            //   JSON.stringify(localStorageData)
            // );
            toast.success("Successfully Scanned!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            //@Removed/console.log("WFS Order limit reached!");
            toast.error(`Scan limit reached, please click submit button`, {
              position: "top-right",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      }
    }
  }, [cameraScannedCodes]);

  React.useEffect(() => {
    //@Removed/console.log("currentScanned");
    //@Removed/console.log(currentScanned);
    //@Removed/console.log("currentScanned && Object.keys(currentScanned).length: ");
    //@Removed/console.log(currentScanned && Object.keys(currentScanned).length);
    if (currentScanned && Object.keys(currentScanned).length) {
      //@Removed/console.log("Setting the data in local storage!");
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
  }, [currentScanned]);

  React.useEffect(() => {
    if (scanQrCodeDetails) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
    }
  }, [scanQrCodeDetails]);

  CustomConsole();

  React.useEffect(() => {
    if (scanQrCodeDetails !== "" && value === 0) {
      // dispatch(PutWfsQrlinkAction(reqObj));
      const reqObj = {
        is_inscan: true,
        order_id: valueOrderId,
        data: [{ id: scanQrCodeDetails }],
      };
      CustomConsole("Link API");
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
      if (scanOption === "camera") {
        setScanQrcodebtn(false);
      }
      setScanQrCodeDetails("");
      setShowingbatchDialogFlag(false);
      // setTimeout(() => {
      //   dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      // }, 1500);
    }
  }, [scanQrCodeDetails]);

  React.useEffect(() => {
    if (switchScanning) {
      loadData();
      setSwitchScanning(false);
    }
  }, [switchScanning]);

  const handleLinkQr = (productData) => {
    CustomConsole(currentScannedCodes);
    // setSwitchScanning(true);
    setCurrentBatchId(ongoingBatch.batch_id);
    // setShowingbatchDialogFlag(true);
    if (productData === true) {
      setOpenScanningDialog(true);
    } else {
      setQrLessProductOpenDialog(true);
    }

    setScanQtyMax(
      selectedbatch?.quantity - selectedbatch?.linked_container_qty
    );
    setScanOrderId(selectedbatch?.batch_id);
    dispatch(ValidateExpired());
  };

  const [startBatchBtn, setStartBatchBtn] = React.useState(false);
  const [stopBatchBtn, setStopBatchBtn] = React.useState(false);
  const handleStartBatch = () => {
    const reqObj = {
      batch_id: singleSelectedData?.batch_id,
    };
    dispatch(PutStartBatchAction(reqObj));
    setStartBatchBtn(true);
  };

  const handleStopBatch = () => {
    const reqObj = {
      batch_id: singleSelectedData?.batch_id,
    };
    dispatch(PutStopBatchAction(reqObj));
    setStopBatchBtn(true);
  };

  React.useEffect(() => {
    dispatch(
      GetQrBarcodeSwapList({
        status: 200,
        page_number: 1,
        page_size: 100,
        order_id: singleSelectedData?.batch_id,
      })
    );
  }, []);
  React.useEffect(() => {
    CustomConsole("hi iam running");
    if (wfsBatchDetails.errorStartBatch === false && startBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStartBatchBtn(false);
      wfsBatchDetails.errorStartBatch = true;
    }
    if (wfsBatchDetails.errorStopBatch === false && stopBatchBtn === true) {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      setStopBatchBtn(false);
      wfsBatchDetails.errorStopBatch = true;
    }
  }, [wfsBatchDetails.errorStartBatch, wfsBatchDetails.errorStopBatch]);
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [qrBarcodeIdBulk, setQrBarcodeIdBulk] = React.useState("");

  const [scannedCount, setScannedCount] = React.useState(0);
  const [ownerDetails, setOwnerDetails] = React.useState([]);

  React.useEffect(() => {
    if (data && valueOrderId) {
      const output = data.find((obj) => {
        return obj.batch_id === valueOrderId;
      });
      CustomConsole("----Render this value in card----");
      CustomConsole(output);
      setSelectedBatch(output);
    }
  }, [data, valueOrderId]);

  React.useEffect(() => {
    CustomConsole("batchValidatedData");
    CustomConsole(batchValidatedData);
    batchValidatedData?.map((data) => {
      if (data.validatedData?.other_valid_data) {
        if (parseInt(data?.owner_type) === 1) {
          if (data?.ownerDetails[0]?.consumer_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Customer",
              },
              {
                key: "Name",
                value: data?.ownerDetails[0].consumer_details?.fullname,
              },
              {
                key: "Phone number",
                value: data?.ownerDetails[0].consumer_details?.phone
                  ? data?.consumer_details?.phone
                  : "-",
              },
            ];
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(data?.owner_type) === 2) {
          if (data?.ownerDetails[0]?.supplier_details) {
            const ownerData = [
              {
                key: "Owner Type",
                value: "Distributor",
              },
              {
                key: "Name",
                value: data.ownerDetails[0].supplier_details.fullname,
              },

              {
                key: "Phone number",
                value: data.ownerDetails[0].supplier_details.phone
                  ? data.ownerDetails[0].supplier_details.phone
                  : "-",
              },
            ];
            setOwnerDetails(ownerData);
          }
        } else if (parseInt(data?.owner_type) === 3) {
          if (data?.ownerDetails[0]?.wfs_details) {
            const ownerData = [
              {
                key: "Filling Station",
                value: data.ownerDetails[0].wfs_details.company_name,
              },

              {
                key: "Owner name",
                value: data.ownerDetails[0].wfs_details.owner_name,
              },
              {
                key: "Phone number",
                value: data.ownerDetails[0].wfs_details.owner_phone,
              },
            ];
            setOwnerDetails(ownerData);
          }
        }
        if (
          (parseInt(data.ownerType) === 1 || parseInt(data.ownerType) === 2) &&
          data.ownerDetails[0].assigned_to_details
        ) {
          const ownerData = [
            {
              key: "Owner Type",
              value:
                parseInt(data.ownerType) === 1 ? "Customer" : "Distributor",
            },
            {
              key: "Name",
              value: data.ownerDetails[0].assigned_to_details.fullname,
            },

            {
              key: "Phone number",
              value: data.ownerDetails[0].assigned_to_details.phone
                ? data.ownerDetails[0].assigned_to_details.phone
                : "-",
            },
          ];
          CustomConsole("ownerData: ");
          CustomConsole(ownerData);
          setOwnerDetails(ownerData);
        }
      }
    });
  }, [batchValidatedData]);

  // React.useEffect(() => {
  const loadData = () => {
    //@Removed/console.log("Change in order id");
    // Assume `props.orderId` is the current order ID we need to check against
    const storedData = localStorage.getItem("current_scanned_codes");
    const data = storedData ? JSON.parse(storedData) : {};
    //@Removed/console.log("storedData");
    //@Removed/console.log(storedData);
    //@Removed/console.log(currentBatchId);
    //@Removed/console.log(selectedbatch.batch_id);
    //@Removed/console.log(data);
    //@Removed/console.log(data[selectedbatch.batch_id]);

    // Check if the current order ID from props matches the one stored in local storage
    if (data && data[selectedbatch.batch_id]) {
      //@Removed/console.log("matching");
      setCurrentScanned(data);
      setScannedCount(data[selectedbatch.batch_id].length);
    } else {
      // If not matching or not existing, reset the scanned codes
      //@Removed/console.log("not matching");
      setCurrentScanned({});
      setCameraScannedCodes([]);
      setScannedCount(0);
      // localStorage.setItem("current_scanned_codes", {});
      if (localStorage.getItem("current_scanned_codes")) {
        localStorage.removeItem("current_scanned_codes");
      }
    }
  };
  // loadData();
  // }, [currentBatchId]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      // setDecodedResults((prev) => [...prev, qrBarcodeId]);
      setScanQrCodeDetails(qrBarcodeId);
      // if (value === 1) {
      //   const newScannedCode = { id: qrBarcodeId };
      //   setCurrentScannedCodes((prev) => [...prev, newScannedCode]);
      // }

      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
        setQrBarcodeIdBulk("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);

  const [scanFlag, setScanFlag] = React.useState(false);

  const [qrBarcodeIdPrevBulk, setQrBarcodeIdPrevBulk] = React.useState("");

  React.useEffect(() => {
    //@Removed/console.log("qrBarcodeIdBulk");
    //@Removed/console.log(qrBarcodeIdBulk.length);
    //@Removed/console.log("qrBarcodeIdPrevBulk: ");
    //@Removed/console.log(qrBarcodeIdPrevBulk);
    //@Removed/console.log(qrBarcodeIdBulk && qrBarcodeIdBulk.length > 0);
    const maxQty =
      selectedbatch?.quantity - selectedbatch?.linked_container_qty;
    const state =
      qrBarcodeIdBulk &&
      qrBarcodeIdBulk.length > 0 &&
      selectedbatch &&
      selectedbatch.batch_id
        ? true
        : false;
    //@Removed/console.log("state: ", state);
    if (state === true) {
      //@Removed/console.log("-- Inside main state ---");
      //@Removed/console.log(qrBarcodeIdBulk);
      const newOrderId = selectedbatch.batch_id;
      // if (currentScanned && Object.keys(currentScanned).length) {
      //@Removed/console.log("currentScanned in scanner: ");
      //@Removed/console.log(currentScanned);
      //@Removed/console.log(currentScanned[newOrderId]);
      let qrCodeList =
        currentScanned &&
        Object.keys(currentScanned).length &&
        currentScanned[newOrderId]
          ? currentScanned[newOrderId]
          : [];
      //@Removed/console.log("qrCodeList: ");
      //@Removed/console.log(qrCodeList);
      if (qrCodeList.length && qrCodeList.includes(qrBarcodeIdBulk)) {
        toast.error(`Can is scanned already!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        //@Removed/console.log("qrCodeList.length <= maxQty: ");
        //@Removed/console.log(qrCodeList.length <= maxQty);
        if (qrCodeList.length < maxQty) {
          qrCodeList = [...qrCodeList, qrBarcodeIdBulk];
          setScannedCount(qrCodeList.length);
          let localStorageData = {};
          localStorageData[newOrderId] = qrCodeList;
          //@Removed/console.log("localStorageData: ");
          //@Removed/console.log(localStorageData);
          setCurrentScanned(() => localStorageData);

          toast.success("Successfully Scanned!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          //@Removed/console.log("WFS Order limit reached!");
          toast.error(`Scan limit reached, please click submit button`, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
      setTimeout(() => {
        setQrBarcodeIdBulk("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [
    qrBarcodeIdBulk,
    // skuWfsDetails?.ec_order_id,
    // selectedbatch.quantity,
    // setCurrentScanned,
  ]);

  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);

    if (value === 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdBulk) {
          CustomConsole("set code for bulk");
          setQrBarcodeIdBulk(event.target.value);
          setScanFlag(true);
        } else {
          CustomConsole("---code not set for bulk ---");
        }
      }, 500);
    }

    if (value === 1) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdNew) {
          CustomConsole("-- set data --");
          setQrBarcodeIdNew(event.target.value);
        } else {
          CustomConsole("--- data is set ---");
        }
      }, 500);
    }
  };

  React.useEffect(() => {}, [scanOption]);
  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
  };

  const handleVerifyScanned = () => {
    const reqObj = {
      is_inscan: true,
      order_id: singleSelectedData?.batch_id,
      data: codesToValidate,
    };
    CustomConsole(reqObj);
    // dispatch(ValidateScannedCodes(reqObj));
  };
  const [selectedPosition, setSelectedPosition] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [deselectedItems, setDeselectedItems] = React.useState([]);

  const handleItemClick = (scannedPosition, item) => {
    setSelectedPosition((prevSelectedPositions) => {
      // Toggle selection for scanned positions
      if (prevSelectedPositions.includes(scannedPosition)) {
        // If already selected, remove from the array
        return prevSelectedPositions.filter(
          (selectedPosition) => selectedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array
        return [...prevSelectedPositions, scannedPosition];
      }
    });

    setSelectedItems((prevSelectedItems) => {
      // Toggle selection for items
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.scannedPosition === scannedPosition
      );

      if (isSelected) {
        // If already selected, remove from the array (deselect)
        setDeselectedItems((prevDeselectedItems) => {
          // Make sure it's not repeated in the deselected array
          return [
            ...prevDeselectedItems,
            ...(prevDeselectedItems.includes(item) ? [] : [item]),
          ];
        });

        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.scannedPosition !== scannedPosition
        );
      } else {
        // If not selected, add to the array (select)
        CustomConsole("Getting in else");
        setDeselectedItems((prevDeselectedItems) =>
          prevDeselectedItems.filter(
            (deselectedItem) =>
              deselectedItem.scannedPosition !== scannedPosition
          )
        );
        return [...prevSelectedItems, item];
      }
    });
  };
  React.useEffect(() => {
    CustomConsole("selectedPosition");
    CustomConsole(selectedPosition);
    CustomConsole("selectedItems");
    CustomConsole(selectedItems);
    CustomConsole("deselectedItems");
    CustomConsole(deselectedItems);
    setValidData(deselectedItems);
  }, [selectedPosition]);

  const handleLinkValidCans = () => {
    const reqObj = {
      is_inscan: true,
      order_id: singleSelectedData?.batch_id,
      data: validDataToApi,
    };
    CustomConsole(reqObj);
    dispatch(LinkValidCans(reqObj));
    setTimeout(() => {
      setCurrentScannedCodes([]);
      setValidData([]);
      // setInvalidData([]);
      setValidDataToApi([]);
      localStorage.removeItem("valid_data");
      localStorage.removeItem("invalid_data");
      localStorage.removeItem("current_scanned_codes");
    }, 1000);
  };

  const handleSendRequest = (props) => {
    setOpenScanInValidCsnsDialog(false);
    const reqObj = {
      scan_type: props.scan_type,
      qr_code: props.qr_code,
      // order_id: orderId,
    };
    CustomConsole(reqObj);
  };
  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };
  const handleScanInValidCans = () => {
    setOpenScanInValidCsnsDialog(true);
  };

  const handleApprovedOrRejectedCans = () => {
    if (
      selectedbatch &&
      selectedbatch.batch_id &&
      selectedbatch.batch_id.length
    ) {
      setOpenApprovedRejectedCans(true);
    }
  };

  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    if (props.approved_empty.length) {
      //Link the approved empty
      let approvedEmpty = [];
      for (const data of props.approved_empty) {
        approvedEmpty.push({
          id: data,
        });
      }
      const reqObj = {
        order_id: props.order_id,
        data: approvedEmpty,
      };
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
    }
    if (props.approved_filled.length) {
      //Link the approved filled
      let approvedFilled = [];
      for (const data of props.approved_filled) {
        approvedFilled.push({
          id: data,
        });
      }
      const reqObj = {
        order_id: props.order_id,
        data: approvedFilled,
      };
      CustomConsole(reqObj);
      dispatch(LinkValidCans(reqObj));
    }
  };

  const handleEditBatch = (selectedbatch) => {
    CustomConsole(selectedbatch);
    setUpdateDetails(selectedbatch);
    setOpenEditBatchDialog(true);
    // setIsUpdate(true);
  };

  const handlebatch = (props) => {
    CustomConsole("-----------handlebatch----------------");
    if (
      props.batchId !== "" &&
      props.material !== "" &&
      props.lineId !== "" &&
      props.capacity !== "" &&
      props.batchQuantity !== ""
    ) {
      const reqObj = {
        batch_id: props.batchId,
        // container_material: props.material,
        // line_id: props.lineId,
        // container_capacity: props.capacity,
        quantity: parseInt(props.batchQuantity),
      };
      CustomConsole(reqObj);
      dispatch(EditWfsBatchSku(reqObj));
      setOpenEditBatchDialog(false);
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      }, 1500);
    } else {
      CustomConsole("-----filll---------");
      toast.error("Please Fill All Fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleClose = () => {
    CustomConsole("------------handleClose-------------");
    setOpenEditBatchDialog(false);
  };

  CustomConsole(selectedbatch);

  //function for handling batch qty change
  const handelBatchChange = (e) => {
    let { value } = e.target;
    let numericValue = value.replace(/\D/g, "");
    value = numericValue;
    setBatchQuantity(value);
  };

  //function for handling qr less product submit
  const handelLinkQrLessSubmit = () => {
    const reqObj = {
      batch_id: selectedbatch.batch_id,
      quantity: batchQuantity,
    };
    if (batchQuantity > 0) {
      dispatch(LinkQrLessProductToBatch(reqObj));
      setQrLessProductOpenDialog(false);
      setScanQrcodebtn(false);
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      }, 1500);
    } else {
      //@Removed/console.log("-----------Enter 1-------------");
      toast.error("Please Enter More Than Zero", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const [otherValidCans, setOtherValidCans] = React.useState(0);
  React.useEffect(() => {
    //@Removed/console.log("validatedData new");
    //@Removed/console.log(validatedData);
    if (!validatedData.error) {
      setScanQrcodebtn(false);
      if (validatedData?.validatedData?.other_valid_data?.length) {
        setOtherValidCans(validatedData.validatedData.other_valid_data.length);
      }
    }
  }, [validatedData]);

  React.useEffect(() => {
    //@Removed/console.log("otherValidCans");
    //@Removed/console.log(otherValidCans);
    if (otherValidCans > 0) {
      setOpenSwappedCanDialog(true);
    } else {
      setOpenSwappedCanDialog(false);
    }
  }, [otherValidCans]);

  const handleBulkLink = () => {
    //@Removed/console.log(currentScanned);
    const reqObj = {
      is_inscan: true,
      order_id: selectedbatch.batch_id,
      data: currentScanned[selectedbatch.batch_id]?.map((item) => ({
        id: item,
      })),
    };
    //@Removed/console.log(reqObj);
    dispatch(LinkValidCans(reqObj));
    setTimeout(() => {
      dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
      localStorage.removeItem("current_scanned_codes");
      setCameraScannedCodes([]);
    }, 1500);
  };

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      dispatch(LinkValidCans(requestObject));
      setTimeout(() => {
        dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
        localStorage.removeItem("current_scanned_codes");
        setCurrentScanned({});
        setScannedCount(0);
        setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);

  const handleLinkOrder = (props) => {
    const reqObj = {
      from_order_id: orderIdStartingLetters + props.scanOrderId,
      to_order_id: selectedbatch?.batch_id,
    };
    CustomConsole(reqObj);
    dispatch(LinkProductsOrderToOrder(reqObj));
    setOpenOrderToOrderScanningDialog(false);
    // setTimeout(() => {
    //   dispatch(GetWfsOngoingBatch(pageNumber, wfsDetails?.data.wfs_id));
    // }, 1500);
  };

  return (
    <Material.Box>
      <Material.Paper
        elevation={14}
        sx={{ padding: "20px", bgcolor: "#E9F7EF" }}
      >
        <Material.Box
          sx={{
            // padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "row",
            },
            gap: "10px",
          }}
        >
          <Material.Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
                alignItems: "center",
              }}
            >
              <Material.InputLabel
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Batch ID:
              </Material.InputLabel>
              <Material.Select
                variant="standard"
                sx={{ fontSize: "16px", fontWeight: "bold", width: "190px" }}
                value={valueOrderId}
                label="Order Id"
                onChange={handleChangeOrderId}
              >
                {data &&
                  data !== undefined &&
                  data !== null &&
                  data.length > 0 &&
                  data.map((orderIds, index) => {
                    return (
                      <Material.MenuItem key={index} value={orderIds.batch_id}>
                        {orderIds.batch_id}
                      </Material.MenuItem>
                    );
                  })}
              </Material.Select>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            textAlign: "center",
          }}
        >
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Batch ID </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.batch_id}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Cans in batch </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.quantity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Material</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.container_material}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Capacity</Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.container_capacity}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Status </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {" "}
                  {selectedbatch?.is_batch_started === true &&
                  selectedbatch?.is_batch_stopped === true &&
                  selectedbatch?.linked_container_qty !== 0
                    ? "Completed"
                    : selectedbatch?.is_batch_started === true &&
                      selectedbatch?.is_batch_stopped === false
                    ? "Ongoing"
                    : selectedbatch?.is_batch_started === false &&
                      selectedbatch?.is_batch_stopped === false
                    ? "Yet to start"
                    : selectedbatch?.is_batch_started === true &&
                      selectedbatch?.is_batch_stopped === true &&
                      selectedbatch?.linked_container_qty === 0
                    ? "Cancelled"
                    : "Started"}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Created at </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.created_at?.slice(0, 10)}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={{ display: "flex" }}>
              <Material.Box sx={styles.cardStyleHeading}>
                <Material.Typography>Linked Can Qty </Material.Typography>
              </Material.Box>
              <Material.Box sx={styles.cardStyleValue}>
                <Material.Typography>
                  {selectedbatch?.linked_container_qty}
                </Material.Typography>
              </Material.Box>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <Material.Box>
          {selectedbatch?.container_material === "PET" &&
          selectedbatch?.container_capacity === "20_Liters" &&
          selectedbatch?.linked_container_qty !== selectedbatch?.quantity &&
          selectedbatch?.is_batch_started === false &&
          selectedbatch?.is_batch_stopped === false ? (
            <Material.Box sx={{ m: 1 }}>
              <Material.Button
                size="small"
                variant="contained"
                onClick={() => handleOpenLinkOrderToOrderDialog("ECON")}
              >
                Link Order Id
              </Material.Button>
            </Material.Box>
          ) : (
            ""
          )}
        </Material.Box>
        <Material.Box sx={{ display: "flex", justifyContent: "center" }}>
          <Material.Box>
            <Material.Box>
              {selectedbatch?.linked_container_qty !==
                selectedbatch?.quantity &&
              selectedbatch?.is_batch_started === false &&
              selectedbatch?.is_batch_stopped === false ? (
                <Material.Button
                  sx={{
                    margin: "10px",
                    ":hover": {
                      backgroundColor: "black",
                    },
                  }}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    handleLinkQr(selectedbatch?.product_details?.is_qr)
                  }
                >
                  {" "}
                  {selectedbatch?.product_details?.is_qr
                    ? "Link QR"
                    : "Link Product"}
                </Material.Button>
              ) : (
                ""
              )}
            </Material.Box>

            <Material.Box>
              {selectedbatch?.is_batch_started !== true &&
              selectedbatch?.linked_container_qty ===
                selectedbatch?.quantity ? (
                <Material.Button
                  sx={{
                    margin: "10px",
                    ":hover": {
                      backgroundColor: "black",
                    },
                  }}
                  variant="contained"
                  size="small"
                  onClick={handleStartBatch}
                >
                  Start Batch
                </Material.Button>
              ) : (
                ""
              )}
            </Material.Box>
            <Material.Box>
              {selectedbatch?.is_batch_started === true &&
              selectedbatch?.is_batch_stopped === false &&
              selectedbatch?.linked_container_qty ===
                selectedbatch?.quantity ? (
                <Material.Button
                  sx={{
                    margin: "10px",
                    ":hover": {
                      backgroundColor: "black",
                    },
                  }}
                  onClick={handleStopBatch}
                  variant="contained"
                  size="small"
                >
                  Stop Batch
                </Material.Button>
              ) : (
                ""
              )}
            </Material.Box>
          </Material.Box>
          {(selectedbatch?.is_batch_started === false &&
            selectedbatch?.is_batch_stopped === false) ||
          (selectedbatch?.is_batch_started === true &&
            selectedbatch?.is_batch_stopped === false) ? (
            <Material.Box sx={{ mt: 1.5 }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ":hover": {
                    backgroundColor: "black",
                  },
                }}
                onClick={() => handleEditBatch(selectedbatch)}
              >
                Edit
              </Button>
            </Material.Box>
          ) : (
            ""
          )}
        </Material.Box>
      </Material.Paper>

      {openEditBatchDialog && (
        <EditBatchDetailsDilaog
          openEditBatchDialog={openEditBatchDialog}
          updateDetails={updateDetails}
          handlebatch={handlebatch}
          handleClose={handleClose}
          wfsBatchDetails={wfsBatchDetails}
        />
      )}
      {selectedbatch &&
      selectedbatch.batch_id &&
      selectedbatch.batch_id.length &&
      openApprovedRejectedCans ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          handleClose={handleCloseApprovedRejectedDialog}
          order_id={selectedbatch.batch_id}
          handleLink={handleLinkApprovedQrs}
        />
      ) : null}
      <ReturnCanCameraCanSwapScanIvalidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        order_id={selectedbatch?.batch_id}
      />
      {showingbatchDialogFlag ? (
        <WfsOngoingBatchCardDialog
          showingbatchDialogFlag={showingbatchDialogFlag}
          // handleClose={handleCloseDialogs}
          setShowingbatchDialogFlag={setShowingbatchDialogFlag}
          setScanQrcodebtn={setScanQrcodebtn}
          scanQrcodebtn={scanQrcodebtn}
        />
      ) : (
        ""
      )}

      {}

      {/* <Material.Dialog open={scanQrcodebtn}>
        <Material.DialogContent>
          <>
            {selectedbatch?.product_details?.is_qr ? (
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <Tabs value={value} onChange={handleChange} centered>
                  <Tab label="Link Bulk" />
                 
                </Tabs>

                <Material.Box
                  sx={{
                    marginTop: "15px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={scanOption}
                    exclusive
                    onChange={handleSelectScanOption}
                    aria-label="Platform"
                  >
                    <ToggleButton value="scanner">
                      Scanner <ScannerRoundedIcon />
                    </ToggleButton>
                    <ToggleButton value="camera">
                      Camera <CameraAltRoundedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Material.Box>
                {scanOption === "camera" ? (
                  <>
                    {value === 1 && (
                      <Html5QrcodePlugin1
                        fps={1}
                        qrbox={200}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResultQrCode}
                      />
                    )}

                    {value === 0 && (
                      <>
                        <Html5QrcodePlugin1
                          fps={1}
                          qrbox={200}
                          disableFlip={false}
                          qrCodeSuccessCallback={onScanAll}
                        />
                        <Material.Box sx={{ marginTop: "10px" }}>
                          <Material.Chip
                            label={`Scanned Cans Count: ${scannedCount} / ${
                              selectedbatch?.quantity -
                              selectedbatch?.linked_container_qty
                            }`}
                            color="secondary"
                          />
                        </Material.Box>
                        <Material.Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Material.Button
                            onClick={handleBulkLink}
                            variant="contained"
                            color="success"
                            size="small"
                          >
                            Submit
                          </Material.Button>
                        </Material.Box>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {value === 0 && (
                      <>
                        <Material.Box sx={{ marginTop: "10px" }}>
                          <Material.Chip
                            label={`Scanned Cans Count: ${scannedCount} / ${
                              selectedbatch?.quantity -
                              selectedbatch?.linked_container_qty
                            }`}
                            color="secondary"
                          />
                          <Material.Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <Material.Button
                              onClick={handleBulkLink}
                              variant="contained"
                              color="success"
                              size="small"
                            >
                              Submit
                            </Material.Button>
                          </Material.Box>
                        </Material.Box>
                      </>
                    )}
                  </>
                )}
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  Enter Quantity to link Qrless product to batch
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                    gap: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    size="small"
                    value={batchQuantity}
                    onChange={handelBatchChange}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handelLinkQrLessSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </Material.DialogContent>

        <Material.DialogActions>
          <Material.Grid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Material.Grid item>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => setOpenLinkFromStartDialog(true)}
              >
                Reset Scan
              </Button>
            </Material.Grid>
            <Material.Grid item>
              <Button
                size="small"
                color="error"
                onClick={() => setScanQrcodebtn(false)}
                variant="contained"
              >
                Close
              </Button>
            </Material.Grid>
          </Material.Grid>
        </Material.DialogActions>
      </Material.Dialog> */}

      <ResultContainerPlugin1
        results={decodedResults}
        setScanCount={setScanCount}
        scanType={scanOption}
      />

      {openLinkFromStartDialog && (
        <Box>
          <Material.Dialog
            open={openLinkFromStartDialog}
            onClose={() => setOpenLinkFromStartDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle id="alert-dialog-title">
              {"Are you sure you want to link from the beginning?"}
            </Material.DialogTitle>

            <Material.DialogActions>
              <Button
                onClick={() => setOpenLinkFromStartDialog(false)}
                variant="outlined"
                size="small"
              >
                No
              </Button>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  localStorage.removeItem("current_scanned_codes");
                  setCurrentScanned({});
                  setScannedCount(0);
                  setOpenLinkFromStartDialog(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </Material.DialogActions>
          </Material.Dialog>
        </Box>
      )}

      {openSwappedCanDialog && (
        <Material.Dialog
          open={openSwappedCanDialog}
          onClose={() => {
            setOpenSwappedCanDialog(false);
            // props.setIsProperScan(true);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Material.DialogContent
            sx={{
              backgroundColor: "#e86d7b",
              // padding: 20,
            }}
          >
            <Material.Typography sx={{ fontWeight: "bold", color: "white" }}>
              No of cans swapped : {otherValidCans}
            </Material.Typography>
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOpenSwappedCanDialog(false);
                validatedData.validatedDataOrder = [];
                validatedData.validatedData = [];
                // props.setIsProperScan(true);
              }}
              autoFocus
            >
              Close
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      )}

      <ScanningDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={currentScanned}
        setCurrentScanned={setCurrentScanned}
        scanQtyMax={scanQtyMax}
        scanOrderId={scanOrderId}
        scannedCount={scannedCount}
        setScannedCount={setScannedCount}
        setRequestObject={setRequestObject}
        setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
        isBatch={isBatch}
        validateExpired={validateExpired}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
      <Material.Dialog open={qrLessProductOpenDialog}>
        <Material.DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setQrLessProductOpenDialog(false)}
              >
                <Close />
              </Button>
            </Box>
            <Typography sx={{ fontWeight: 600 }}>
              Enter Quantity to link Qrless product to batch
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                gap: 2,
              }}
            >
              <TextField
                fullWidth
                label="Quantity"
                name="quantity"
                size="small"
                value={batchQuantity}
                onChange={handelBatchChange}
              />
              <Button
                size="small"
                variant="contained"
                onClick={handelLinkQrLessSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Material.DialogContent>
      </Material.Dialog>
      {openOrderToOrderScanningDialog && (
        <LinkOrderToOrderScanningProcessDialog
          openOrderToOrderScanningDialog={openOrderToOrderScanningDialog}
          handlCloseDialogOrdertoOrder={() =>
            setOpenOrderToOrderScanningDialog(false)
          }
          handleLinkOrder={handleLinkOrder}
          orderIdStartingLetters={orderIdStartingLetters}
        />
      )}
    </Material.Box>
  );
}

export default WfsBatchFormView;
