import actionType from "../Actions/actionType";

const initialState = {
  pendingAmount: 0,
  loading: false,
  error: null,
  ongoingOrders: {
    regular: [],
    return: [],
    replacement: [],
  }
};

const accountClosureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_ONGOING_ORDERS:
      console.log("Pending Amount in Reducer:", action.payload.amount);
      return {
        ...state,
        ongoingOrders: {
          regular: action.payload.regular || [],
          return: action.payload.return || [],
          replacement: action.payload.replacement || [],
        },
      };
    case actionType.GET_PENDING_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_PENDING_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingAmount: action.payload.amount,
      };
    case actionType.GET_PENDING_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        pendingAmount:0,
        error: action.error,
      };
    default:
      return state;
  }
};

export default accountClosureReducer;
