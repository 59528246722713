/**
 * @author vinay kumar
 * @description New Container Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 14-12-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { Box } from "@material-ui/core";
import AddnewProductDialog from "../../Components/Dialog/AddnewProductDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAvailableProducts,
  GetListofCrates,
  PostAddNewProduct,
  UpdateSkuProductDetails,
} from "../Redux/Actions/SKUProductDetailsAction";
import AddnewCrateDialog from "../../Components/Dialog/AddNewCrateDialog";
import SupplierUserStock from "../SupplierSettings/SupplierUserStock";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import NewProductDetailsCard from "../../Components/Cards/NewProductDetailsCard";
import ParticularProductCrateDetailsDialog from "../../Components/Dialog/ParticularProductCrateDetailsDialog";
import NewCrateDetailsCard from "../../Components/Cards/NewCrateDetailsCard";
import LinkCrateDialog from "../../Components/Dialog/LinkCrateDialog";
import { SkuAvailablePincodes } from "../Redux/Actions/skuPincodeAction";
import SkuPincodes from "../../Components/Tables/skuAvailablePincodeTable";
import SkuPincodeFilterDialog from "../../Components/DialogBoxes/SkuFilterDialog/pincodeFilterDialog";
import AddUpdatePicodeAreaSku from "../../Components/Dialog/AddUpdatePicodeSku";
import QRBarcode from "../AdminGenrateQRBarcode/QRBarcode";
import QrBarcodeCrates from "../AdminGenrateQRBarcode/QrBarcodeCrates";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      // style={{ width: "85vw" }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function NewContainerDetailsMain() {
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const skuCrateDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;
  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  const [openAddOrUpdateProduct, setOpenAddOrUpdateProduct] =
    React.useState(false);
  const [openAddOrUpdateCrateProduct, setOpenAddOrUpdateCrateProduct] =
    React.useState(false);
  const [openLinkCrateDialog, setOpenLinkCrateDialog] = React.useState(false);
  const [productAvailableDetails, setProductAvailableDetails] = React.useState(
    []
  );
  const [
    openDialogParticularProductCrate,
    setOpenDialogParticularProductCrate,
  ] = React.useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [updateProductDetails, setUpdateProductDetails] = React.useState({});
  const [alignmentProductQr, setAlignmentProductQr] = React.useState("Product");
  const [alignmentCrates, setAlignmentCrates] = React.useState("Crates");
  const [updateCrateDetails, setUpdateCrateDetails] = React.useState([]);
  const [productCrateDetails, setProductCrateDetails] = React.useState([]);
  const [crateFilterDetails, setCrateFilterDetails] = React.useState({
    activeStatus: true,
    capacity: "DESC",
    sort_by_name: "ASC",
    sort_by_price: "ASC",
    sort_by_created_at: "DESC",
  });
  const [openPincodeFilter, setOpenPincodeFilter] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(true);
  const [updatePincodeData, setupdatePincodeData] = React.useState({});
  //useEffect for getiing available products
  React.useState(() => {
    if (isMounted) {
      dispatch(GetAvailableProducts());
      setIsMounted(false);
    }
    // dispatch(GetListofCrates(crateFilterDetails));
  }, [isMounted]);
  //variable for different order types
  const orderTypes = [
    {
      label: "All Types",
      value: "400",
    },
    {
      label: "Insta",
      value: "100",
    },
    {
      label: "Regular",
      value: "200",
    },
    {
      label: "Subscription",
      value: "300",
    },
  ];
  const intialParamsState = {
    pincode: "" || undefined,
    locality: "" || undefined,
    district: "" || undefined,
    country: "INDIA",
    state: "" || undefined,
    page_size: 25,
    page_number: 1,
  };
  const [queryParams, setQueryParams] = React.useState(intialParamsState);
  const [sortByKey, setSortByKey] = React.useState({ created_at: "DESC" });
  //useEffect for setting product value to the state
  React.useEffect(() => {
    setProductAvailableDetails(skuContainerDetails.getAvailableProducts);
  }, [skuContainerDetails.getAvailableProducts]);

  //function for handling add/update product dialog box
  const handleOpenDialog = () => {
    setOpenAddOrUpdateProduct(true);
  };
  const handleOpenSingleProduct = () => {
    setOpenAddOrUpdateProduct(true);
  };
  //function for handling closing dialog action
  const handleCancle = () => {
    setOpenAddOrUpdateProduct(false);
    fileHandler.isFileUploaded = false;
  };

  //function for handling add product
  const handleAddProduct = (reqObj) => {
    dispatch(PostAddNewProduct(reqObj));
  };

  //function for opening crate add/update dialog
  const handleOpenCrateDialog = () => {
    setOpenAddOrUpdateCrateProduct(true);
  };

  //function for closing crate add/update dialog
  const handleCloseCrateDialog = () => {
    setOpenAddOrUpdateCrateProduct(false);
    fileHandler.isFileUploaded = false;
  };

  //fuction for handling value change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //function for setting up index value
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //function for handling edit click for product and crates
  const handleClickEdit = (data, name, crate) => {
    setUpdateProductDetails(data);
    setUpdateCrateDetails(crate);
    if (name !== "crate") {
      setOpenAddOrUpdateProduct(true);
    } else {
      setOpenAddOrUpdateCrateProduct(true);
    }
  };

  //function for handling edit click for product and crates
  const handleClickEditPincode = (name, data) => {
    CustomConsole(data);
    if (name === "pincode") {
      setOpenPicodeDialog(true);
      setupdatePincodeData(data);
    }
  };
  //function for handling product crate details card
  const handleOpenProductCrate = (data) => {
    setProductCrateDetails(data);
    setOpenDialogParticularProductCrate(true);
  };

  //function for closing product crate dialog
  const handleOnClose = () => {
    setOpenDialogParticularProductCrate(false);
  };

  //function for opening link crate dialog
  const handleOpenLinkCrateDialog = () => {
    setOpenLinkCrateDialog(true);
  };

  //useEffect for getting product details
  CustomConsole(skuContainerDetails);
  React.useEffect(() => {
    if (!skuContainerDetails.AddProductError) {
      dispatch(GetAvailableProducts());
      setOpenAddOrUpdateProduct(false);
      skuContainerDetails.AddProductError = true;
    }
  }, [skuContainerDetails.AddProductError]);

  //useEffect for getiing crate details by filter
  React.useEffect(() => {
    dispatch(GetListofCrates(crateFilterDetails));
  }, [crateFilterDetails]);

  //useEffect for getting pincode detaisl
  React.useEffect(() => {
    handleGetPincodesActions(queryParams);
    // dispatch(SkuAvailablePincodes(queryParams));
  }, []);

  //function for handling pincode filter closing
  const handelPincodeFilterClose = () => {
    CustomConsole("adhbsjvguv");
    setOpenPincodeFilter(false);
  };

  //function for handling filter changes

  const handleApplyFilter = (params, sort_by) => {
    CustomConsole("-------handleApplyFilter---------");
    CustomConsole(params);
    CustomConsole(params);
    //@Removed/console.log(sortByKey);
    const sortByData = sort_by ? sort_by : { created_at: "DESC" };
    handleGetPincodesActions(params, sortByData);
    // dispatch(SkuAvailablePincodes(params));
  };

  //useEffect for getting pincode detaisl
  React.useEffect(() => {
    if (queryParams.page_number > 1) {
      handleGetPincodesActions(queryParams);
      // dispatch(SkuAvailablePincodes(queryParams));
    }
  }, [queryParams.page_number]);

  //function for opening add pincode dialog
  const handelOpenAddPicodeDialog = () => {
    setOpenPicodeDialog(true);
  };

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.addPincodeError) {
      handleGetPincodesActions(queryParams);
      // dispatch(SkuAvailablePincodes(queryParams));
      skuPincodeDetails.addPincodeError = true;
    }
  }, [skuPincodeDetails.addPincodeError]);

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.updatePincodeError) {
      handleGetPincodesActions(queryParams);
      // dispatch(SkuAvailablePincodes(queryParams));
      skuPincodeDetails.updatePincodeError = true;
    }
  }, [skuPincodeDetails.updatePincodeError]);

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.deleteLocalityError) {
      handleGetPincodesActions(queryParams);
      // dispatch(SkuAvailablePincodes(queryParams));
      skuPincodeDetails.deleteLocalityError = true;
    }
  }, [skuPincodeDetails.deleteLocalityError]);
  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.deleteProductError) {
      handleGetPincodesActions(queryParams);
      skuPincodeDetails.deleteProductError = true;
    }
  }, [skuPincodeDetails.deleteProductError]);

  React.useEffect(() => {}, [sortByKey]);
  const handleGetPincodesActions = (params, sort_by) => {
    CustomConsole("-------handleGetPincodesActions----------");
    CustomConsole(params);
    //@Removed/console.log(sortByKey);
    const sortByData = sort_by ? sort_by : { created_at: "DESC" };
    let reqPrams = {
      ...sortByData,
      page_number: params.page_number,
      page_size: params.page_size,
    };
    if (params.country && params.country.length) {
      reqPrams.country = params.country;
    }
    if (params.district && params.district.length) {
      reqPrams.district = params.district;
    }
    if (params.locality && params.locality.length) {
      reqPrams.locality = params.locality;
    }
    if (params.pincode && params.pincode.length) {
      reqPrams.pincode = params.pincode;
    }
    if (params.state && params.state.length) {
      reqPrams.state = params.state;
    }

    dispatch(SkuAvailablePincodes(reqPrams));
  };
  //useEffect for getting product details
  CustomConsole(skuCrateDetails);
  React.useEffect(() => {
    if (!skuCrateDetails.AddCrateError) {
      dispatch(GetAvailableProducts());
      handleCancle();
      skuCrateDetails.AddCrateError = true;
    }
  }, [skuCrateDetails.AddCrateError]);

  const handleChangeToggleProductQr = (event, newAlignment) => {
    setAlignmentProductQr(newAlignment);
  };
  const handleChangeToggleCratesQr = (event, newAlignment) => {
    setAlignmentCrates(newAlignment);
  };
  return (
    <Material.Grid container sx={{ marginTop: "60px" }}>
      <Material.Grid item xs={12}>
        <AppBar
          position="static"
          sx={{
            color: "white",
            borderRadius: "10px",
            background: "#6495ED",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            // variant="scrollable"
            aria-label="full width tabs example"
          >
            <Tab label="Product Details" {...a11yProps(0)} />
            <Tab label="Crate Details" {...a11yProps(1)} />
            <Tab label="Pincode Details" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Material.Grid>
      <Material.Grid item xs={12}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Box
            value={value}
            index={0}
            dir={theme.direction}
            sx={{
              marginTop: "1rem",
            }}
          >
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                m: 1,
              }}
            >
              <Material.ToggleButtonGroup
                color="primary"
                value={alignmentProductQr}
                exclusive
                onChange={handleChangeToggleProductQr}
                aria-label="Platform"
                size="small"
              >
                <Material.ToggleButton value="Product">
                  Products
                </Material.ToggleButton>
                <Material.ToggleButton value="QrDetails">
                  Products Qr/BarCode Details
                </Material.ToggleButton>
              </Material.ToggleButtonGroup>
              <Material.Button
                variant="contained"
                sx={{ margin: "10px" }}
                size="small"
                onClick={handleOpenDialog}
              >
                add product
              </Material.Button>
              <Material.Button
                variant="contained"
                sx={{ margin: "10px" }}
                onClick={handleOpenLinkCrateDialog}
                size="small"
              >
                Link crate
              </Material.Button>
            </Material.Box>
            {alignmentProductQr === "Product" ? (
              <>
                {productAvailableDetails !== undefined &&
                productAvailableDetails !== null &&
                productAvailableDetails.length > 0 ? (
                  <>
                    <NewProductDetailsCard
                      handleClickEdit={handleClickEdit}
                      handleOpenProductCrate={handleOpenProductCrate}
                      productAvailableDetails={productAvailableDetails}
                      orderTypes={orderTypes}
                    />
                  </>
                ) : (
                  "No Data Available"
                )}
              </>
            ) : (
              <QRBarcode />
            )}
          </Box>
          <Box value={value} index={1} dir={theme.direction}>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                m: 1,
              }}
            >
              <Material.ToggleButtonGroup
                color="primary"
                value={alignmentCrates}
                exclusive
                onChange={handleChangeToggleCratesQr}
                aria-label="Platform"
                size="small"
              >
                <Material.ToggleButton value="Crates">
                  Crates
                </Material.ToggleButton>
                <Material.ToggleButton value="CratesQrDetails">
                  Crates Qr/BarCode Details
                </Material.ToggleButton>
              </Material.ToggleButtonGroup>
              <Material.Button
                variant="contained"
                sx={{ margin: "10px" }}
                onClick={handleOpenCrateDialog}
                size="small"
              >
                add crate
              </Material.Button>
            </Material.Box>
            {alignmentCrates === "Crates" ? (
              <>
                {crateDetails !== undefined &&
                crateDetails !== null &&
                crateDetails.length > 0 ? (
                  <>
                    <NewCrateDetailsCard
                      handleClickEdit={handleClickEdit}
                      crateDetails={crateDetails}
                      setCrateFilterDetails={setCrateFilterDetails}
                    />
                  </>
                ) : (
                  "No Data Available"
                )}
              </>
            ) : (
              <QrBarcodeCrates alignmentCrates={alignmentCrates} />
            )}
          </Box>
          <Box value={value} index={2} dir={theme.direction}>
            <>
              <Material.Button
                variant="contained"
                sx={{ margin: "10px" }}
                onClick={handelOpenAddPicodeDialog}
                size="small"
              >
                Add Pincode/Area
              </Material.Button>
              <SkuPincodes
                pincodeData={skuPincodeDetails.pincodeData}
                setOpenPincodeFilter={setOpenPincodeFilter}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setQueryParams={setQueryParams}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                queryParams={queryParams}
                handleClickEditPincode={handleClickEditPincode}
              />
            </>
          </Box>
        </SwipeableViews>
      </Material.Grid>
      {openAddOrUpdateProduct && (
        <AddnewProductDialog
          openAddOrUpdateProduct={openAddOrUpdateProduct}
          handleAddProduct={handleAddProduct}
          handleCancle={handleCancle}
          updateProductDetails={updateProductDetails}
          setUpdateProductDetails={setUpdateProductDetails}
          orderTypes={orderTypes}
          updateCrateDetails={updateCrateDetails}
        />
      )}
      <AddnewCrateDialog
        openAddOrUpdateCrateProduct={openAddOrUpdateCrateProduct}
        handleCancle={handleCloseCrateDialog}
        updateProductDetails={updateProductDetails}
        setUpdateProductDetails={setUpdateProductDetails}
        crateFilterDetails={crateFilterDetails}
      />
      <ParticularProductCrateDetailsDialog
        openDialogParticularProductCrate={openDialogParticularProductCrate}
        handleOnClose={handleOnClose}
        productCrateDetails={productCrateDetails}
        setProductCrateDetails={setProductCrateDetails}
      />
      <LinkCrateDialog
        openLinkCrateDialog={openLinkCrateDialog}
        setOpenLinkCrateDialog={setOpenLinkCrateDialog}
        crateDetails={crateDetails}
        productAvailableDetails={productAvailableDetails}
      />
      <SkuPincodeFilterDialog
        open={openPincodeFilter}
        setOpenPincodeFilter={setOpenPincodeFilter}
        onClose={handelPincodeFilterClose}
        intialParamsState={intialParamsState}
        handleApplyFilter={handleApplyFilter}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        sortByKey={sortByKey}
        setSortByKey={setSortByKey}
      />
      {openPincodeDialog && (
        <AddUpdatePicodeAreaSku
          openPincodeDialog={openPincodeDialog}
          setOpenPicodeDialog={setOpenPicodeDialog}
          updatePincodeData={updatePincodeData}
          setupdatePincodeData={setupdatePincodeData}
        />
      )}
    </Material.Grid>
  );
}

export default NewContainerDetailsMain;
