import React from "react";
import * as Material from "@mui/material";
import Html5QrcodePlugin1 from "../../../Pages/wfsHome/HtmlQrcodePlugin1";
import ResultContainerPlugin1 from "../../../Pages/wfsHome/ResultContainerPlugin1";
import {
  CurrentDayDeliveries,
  GetQrBarcodeDetails,
  GetSupplierOnGoingAssignedSlotAndAgent,
  InscanQRBarcode,
  OutScanQRBarcode,
  PutSupplierCurrentDayDeliveriesInScan,
} from "../../../Pages/Redux/Actions/supliersAction";
import { PutSupplierCurrentDayDeliveriesOutScan } from "../../../Pages/Redux/Actions/supliersAction";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import { GetViewOrderDetails } from "../../../Pages/Redux/Actions/ordersAdminAction";
import CustomConsole from "../../../CustomConsole";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ScannerRoundedIcon from "@mui/icons-material/ScannerRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { PropaneSharp } from "@mui/icons-material";
import { PostQrBarcodeSwapRequest } from "../../../Pages/Redux/Actions/canSwapingAction";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/base";
import ScanningDialog from "../../Custom/ScanningDialog";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Apply styles that you want for all instances of TextField
          "& input": {
            color: "white", // Default input text color
          },
          "& label": {
            color: "white", // Default label color
          },
        },
      },
    },
  },
});
function CameraDialog(props) {
  const SECRET_KEY = "mysecretkey";
  const dispatch = useDispatch();
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);
  const [outScanBtn, setOutScanBtn] = React.useState(false);
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scanCount, setScanCount] = React.useState(0);
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [inScanBtn, setInScanBtn] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");

  const [openSwappedCanDialog, setOpenSwappedCanDialog] = React.useState(false);
  // const [props.inScannedCodes, setInScannedCodes] = React.useState([]);
  // const [outScannedCodes, props.setOutScannedCodes] = React.useState([]);
  // const [outScannedCodes, props.setOutScannedCodes] = React.useState([]);
  // const [props.inScannedNo, props.setInScannedNo] = React.useState("");
  // const [props.outScannedNo, props.setOutScannedNo] = React.useState("");
  const [scannedAlready, setScannedAlready] = React.useState([]);
  // const [scannedCurrent, props.setInScannedCodes] = React.useState([]);

  const [alignment, setAlignment] = React.useState("scanner");

  const [orderQty, setOrderQty] = React.useState(null);
  const [returnQty, setReturnQty] = React.useState(null);

  // Scanning new
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  // const [props.scanQtyMax, props.setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  // const [scannedCount, setScannedCount] = React.useState(0);
  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);

  const handleChangeToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      // setAlignment(newAlignment);
      setScanOption(newAlignment);
    }
  };
  //@Removed/console.log("---props---");
  //@Removed/console.log(props);
  CustomConsole(props);
  CustomConsole(props.decodedResults);
  CustomConsole(props.setScanCount);

  // const [props.currentScanned, props.setCurrentScanned] = React.useState([]);
  const [renderFlag, setRenderFlag] = React.useState(false);
  // const [props.isProperScan, props.setIsProperScan] = React.useState(true);

  React.useEffect(() => {
    //@Removed/console.log("orderProducts");
    //@Removed/console.log(props.orderProducts);
    if (
      props.orderProducts !== undefined &&
      props.orderProducts !== null &&
      props.orderProducts.length > 0
    ) {
      props.orderProducts.map((data) => {
        if (props.isInScan) {
          //@Removed/console.log("check12");
          //@Removed/console.log(data.return ? data.return - data.received_empty : 0);
          // props.setScanQtyMax(data.return ? data.return - data.received_empty : 0);
        } else {
          //@Removed/console.log("check123");
          //@Removed/console.log(
          //   data.order_qty * data.sku_qty
          //     ? data.order_qty * data.sku_qty - data.submitted_filled
          //     : 0
          // );
          // props.setScanQtyMax(
          //   data.order_qty * data.sku_qty
          //     ? data.order_qty * data.sku_qty - data.submitted_filled
          //     : 0
          // );
        }
        setOrderQty(
          data.order_qty * data.sku_qty
            ? data.order_qty * data.sku_qty - data.submitted_filled
            : 0
        );
        setReturnQty(data.return ? data.return - data.received_empty : 0);
      });
    }
  }, [props.orderProducts]);

  React.useEffect(() => {
    //Scanned Qr Code
    CustomConsole(props.orderid);
    CustomConsole(`scannedQrCode ${scannedQrCode}`);
    if (scannedQrCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    //Scanned Bar Code
    CustomConsole(`scannedBarCode ${scannedBarCode}`);
    if (scannedBarCode !== "") {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode]);

  React.useEffect(() => {
    CustomConsole(props.orderid);
    // const reqObj = {
    //   order_id: props.orderid,
    //   qr_id: scannedQrCodeDetails,
    // };
    const reqObj = {
      qr_code: [scannedQrCodeDetails],
      order_id: props.orderid,
    };
    CustomConsole("out scanned API call");

    CustomConsole(reqObj);
    if (scannedQrCodeDetails !== "" && value === 1) {
      dispatch(OutScanQRBarcode(reqObj));
      // dispatch(PutSupplierCurrentDayDeliveriesOutScan(reqObj));
      props.setOpenCameraDialog(false);
      props.setOutScanBtn(true);
      setOpenOutScanCamera(false);
      setScanQrCodeDetailBtn(false);
      setScannedQrCodeDetails("");
      setTimeout(() => {
        dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
      }, 1000);
    }
  }, [scannedQrCodeDetails]);

  React.useEffect(() => {
    CustomConsole("---------------InSCan-----");
    CustomConsole(props.orderid);
    if (scannedQrCode !== "" || scannedBarCode !== "") {
      // setOpenQrBarCodeCamera(false);
      if (value === 1) {
        // const reqObj = {
        //   order_id: props.orderid,
        //   qr_barcode_id1: scannedQrCode || scannedBarCode,
        //   qr_barcode_id2: "",
        // };
        // CustomConsole(reqObj);
        // dispatch(PutSupplierCurrentDayDeliveriesInScan(reqObj));
        const reqObj = {
          qr_code: [scannedQrCode || scannedBarCode],
          order_id: props.orderid,
        };
        CustomConsole("inscan api call");
        CustomConsole(reqObj);
        dispatch(InscanQRBarcode(reqObj));
        props.setOpenCameraDialog(false);
        setOpenQrBarCodeCamera(false);
        props.setInScanBtn(true);
        setScannedQrCode("");
        setScannedBarCode("");
        // setLinkingDialogOpen(false);
        // setScanCount(0);
        setDecodedResults([]);
        // setLink(false);
        // setLinkQrBarcodeBtn(false);
        setTimeout(() => {
          dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
        }, 1000);
      }
    }
  }, [scannedQrCode, scannedBarCode]);
  // const onNewScanResult = () => {
  //   CustomConsole("-------------onNewScanResult---------------");
  //   props.onNewScanResult();
  // };
  // const onNewScanResultQrCode = () => {
  //   CustomConsole("-------------onNewScanResultQrCode---------------");
  //   props.onNewScanResultQrCode();
  // };
  const handleClose = () => {
    CustomConsole("-------------onClose------------");
    // props.handleClose();
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    CustomConsole("-------------inScan---------------");
    CustomConsole("App [result]", decodedResult);
    CustomConsole(decodedText);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCode(decodedText);
      CustomConsole(scanCount);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedBarCode(decodedText);
      CustomConsole(scanCount);
    }
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    CustomConsole("-----------outScan--------------");
    CustomConsole(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
  };

  // const [props.cameraScannedCodes, props.setCameraScannedCodes] = React.useState([]);
  const [decodedText, setDecodedText] = React.useState("");
  const onScanAllAndLink = (decodedText, decodedResult) => {
    CustomConsole("----scan all and link-----");
    // alert(decodedText);
    setDecodedText(decodedText);
    // props.setCameraScannedCodes((prev) => [...prev, decodedText]);
  };
  React.useEffect(() => {
    if (decodedText !== "") {
      // alert(decodedText, "2");
      props.setCameraScannedCodes((prev) => [...prev, decodedText]);
      setTimeout(() => {
        setDecodedText("");
      }, 300);
    }
  }, [decodedText]);

  // React.useEffect(() => {
  //    //@Removed/console.log("cameraScanned UseEffect");
  //    //@Removed/console.log(props.cameraScannedCodes);
  //    //@Removed/console.log("scan alert");
  //   // if (props.cameraScannedCodes.length) {
  //   const newOrderId = props.orderid;
  //    //@Removed/console.log("last scanned");
  //    //@Removed/console.log(props.cameraScannedCodes[props.cameraScannedCodes.length - 1]);
  //   const code = props.cameraScannedCodes[props.cameraScannedCodes.length - 1];
  //   alert(code);
  //   const consumerContainers =
  //     localStorage.getItem("consumer_containers") &&
  //     localStorage.getItem("consumer_containers").length
  //       ? JSON.parse(localStorage.getItem("consumer_containers"))
  //       : [];
  //   const distributorQrs =
  //     localStorage.getItem("distributor_filled_containers") &&
  //     localStorage.getItem("distributor_filled_containers").length
  //       ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
  //       : [];
  //   CustomConsole("--------consumer containers inscan----------");
  //   CustomConsole("--------consumer containers inscan ");
  //   CustomConsole(consumerContainers);
  //   CustomConsole(distributorQrs);
  //   if (props.isInScan) {
  //     props.setIsProperScan(true);
  //     props.setIsProperScan(true);
  //      //@Removed/console.log("camera scan 1");
  //     if (
  //       !(newOrderId in props.currentScanned) || // Check if newOrderId is not already a key in the object
  //       (!props.currentScanned[newOrderId].includes(code) &&
  //         props.currentScanned[newOrderId].length < returnQty)
  //     ) {
  //        //@Removed/console.log("camera scan 2");
  //       props.setCurrentScanned((prevScanned) => {
  //         // Check if the order ID has changed
  //         if (
  //           prevScanned[newOrderId] &&
  //           Object.keys(prevScanned)[0] === newOrderId
  //         ) {
  //           // Same order ID, append to existing array
  //           return {
  //             ...prevScanned,
  //             [newOrderId]: [...prevScanned[newOrderId], code],
  //           };
  //         } else {
  //           // Different order ID or new entry, reset array
  //           return {
  //             [newOrderId]: [code],
  //           };
  //         }
  //       });
  //       toast.success("Scanned Success", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } else {
  //       alert("already exists in inscan");
  //       // props.setIsProperScan(false);
  //       toast.error("Can already scanned!", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } else if (props.isInScan === false) {
  //     props.setIsProperScan(true);

  //     if (
  //       !(newOrderId in props.currentScanned) || // Check if newOrderId is not already a key in the object
  //       (!props.currentScanned[newOrderId].includes(code) &&
  //         props.currentScanned[newOrderId].length < orderQty)
  //     ) {
  //       // props.setCurrentScanned((prev) => [...prev, code]);
  //       props.setCurrentScanned((prevScanned) => {
  //         // Check if the order ID has changed
  //         if (
  //           prevScanned[newOrderId] &&
  //           Object.keys(prevScanned)[0] === newOrderId
  //         ) {
  //           // Same order ID, append to existing array
  //           return {
  //             ...prevScanned,
  //             [newOrderId]: [...prevScanned[newOrderId], code],
  //           };
  //         } else {
  //           // Different order ID or new entry, reset array
  //           return {
  //             [newOrderId]: [code],
  //           };
  //         }
  //       });
  //       toast.success("Scanned Success", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       props.setIsProperScan(true);
  //     } else {
  //       CustomConsole("already exists in outscan");
  //       toast.error("Can already scanned!", {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       // props.setIsProperScan(false);
  //     }
  //   }
  //   // if (!props.currentScanned.includes(code)) {
  //   //   props.setCurrentScanned((prev) => [...prev, code]);
  //   // }
  //   // }
  // }, [props.cameraScannedCodes]);

  React.useEffect(() => {
    //@Removed/console.log("props.cameraScannedCodes");
    //@Removed/console.log(props.cameraScannedCodes);
    // alert("in camera scanned useEffect");
    // const newOrderId = skuWfsDetails?.ec_order_id;
    const code = props.cameraScannedCodes[props.cameraScannedCodes.length - 1];
    // const state = skuWfsDetails && skuWfsDetails.ec_order_id ? true : false;
    const state = props.orderid && props.orderid ? true : false;
    if (props.isInScan) {
      props.setIsProperScan(true);
      if (props.cameraScannedCodes.length) {
        if (state === true) {
          //@Removed/console.log("-- Inside main state ---");
          //@Removed/console.log(code);
          const newOrderId = props.orderid;
          // if (currentScanned && Object.keys(currentScanned).length) {
          //@Removed/console.log("currentScanned in scanner: ");
          //@Removed/console.log(props.currentScanned);
          //@Removed/console.log(props.currentScanned[newOrderId]);
          let qrCodeList =
            props.currentScanned &&
            Object.keys(props.currentScanned).length &&
            props.currentScanned[newOrderId]
              ? props.currentScanned[newOrderId]
              : [];
          //@Removed/console.log("qrCodeList: ");
          //@Removed/console.log(qrCodeList);
          if (qrCodeList.length && qrCodeList.includes(code)) {
            toast.error(`Can is scanned already!`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            //@Removed/console.log("qrCodeList.length <= returnQty: ");
            //@Removed/console.log(qrCodeList.length <= returnQty);
            if (qrCodeList.length < returnQty) {
              qrCodeList = [...qrCodeList, code];
              // setScannedCount(qrCodeList.length);
              let localStorageData = {};
              localStorageData[newOrderId] = qrCodeList;
              //@Removed/console.log("localStorageData: ");
              //@Removed/console.log(localStorageData);
              props.setCurrentScanned(() => localStorageData);

              toast.success("Successfully Scanned!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              // alert("WFS Order limit reached!");
              //@Removed/console.log("WFS Order limit reached!");
              toast.error(`Scan limit reached, please click submit button`, {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }
        }
      }
    }
    if (props.isInScan === false) {
      props.setIsProperScan(true);
      if (props.cameraScannedCodes.length) {
        if (state === true) {
          //@Removed/console.log("-- Inside main state ---");
          //@Removed/console.log(code);
          const newOrderId = props.orderid;
          // if (currentScanned && Object.keys(currentScanned).length) {
          //@Removed/console.log("currentScanned in scanner: ");
          //@Removed/console.log(props.currentScanned);
          //@Removed/console.log(props.currentScanned[newOrderId]);
          let qrCodeList =
            props.currentScanned &&
            Object.keys(props.currentScanned).length &&
            props.currentScanned[newOrderId]
              ? props.currentScanned[newOrderId]
              : [];
          //@Removed/console.log("qrCodeList: ");
          //@Removed/console.log(qrCodeList);
          if (qrCodeList.length && qrCodeList.includes(code)) {
            toast.error(`Can is scanned already!`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            //@Removed/console.log("qrCodeList.length <= orderQty: ");
            //@Removed/console.log(qrCodeList.length <= orderQty);
            if (qrCodeList.length < orderQty) {
              qrCodeList = [...qrCodeList, code];
              // setScannedCount(qrCodeList.length);
              let localStorageData = {};
              localStorageData[newOrderId] = qrCodeList;
              //@Removed/console.log("localStorageData: ");
              //@Removed/console.log(localStorageData);
              props.setCurrentScanned(() => localStorageData);

              toast.success("Successfully Scanned!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              // alert("WFS Order limit reached!");
              //@Removed/console.log("WFS Order limit reached!");
              toast.error(`Scan limit reached, please click submit button`, {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }
        }
      }
    }
  }, [props.cameraScannedCodes]);
  // React.useEffect(() => {
  //   const current_scanned_QRs =
  //     localStorage.getItem("current_scanned_codes") || [];
  //   CustomConsole("set locally scanned");
  //   CustomConsole(localStorage.getItem("current_scanned_codes"));
  //   if (current_scanned_QRs.length) {
  //     props.setCurrentScanned(current_scanned_QRs);
  //   }
  // }, []);
  const [inScanFlag, setInScanFlag] = React.useState(false);

  React.useEffect(() => {
    if (props.isDistributor) {
      CustomConsole("current scanned stored");
      CustomConsole(props.currentScanned);
      if (
        (props.currentScanned[props.orderid] &&
          props.currentScanned[props.orderid].length > 0) ||
        inScanFlag
      ) {
        localStorage.setItem(
          "current_scanned_codes",
          JSON.stringify(props.currentScanned)
        );
        setInScanFlag(false);
      }

      // let scanned_in_live = [];
      // if (props.currentScanned.length) {
      const current_scanned =
        localStorage.getItem("current_scanned_codes") &&
        localStorage.getItem("current_scanned_codes").length
          ? JSON.parse(localStorage.getItem("current_scanned_codes"))
          : [];
      CustomConsole(current_scanned);
      props.setInScannedCodes(
        current_scanned ? current_scanned[props.orderid] : []
      );
      props.setOutScannedCodes(
        current_scanned ? current_scanned[props.orderid] : []
      );
      // }

      const scannedForOrder =
        localStorage.getItem("scanned_for_order") &&
        localStorage.getItem("scanned_for_order").length
          ? JSON.parse(localStorage.getItem("scanned_for_order"))
          : [];
      CustomConsole("scanned_for_order");
      CustomConsole(scannedForOrder);
      setScannedAlready(scannedForOrder);
      const consumerContainers =
        localStorage.getItem("consumer_containers") &&
        localStorage.getItem("consumer_containers").length
          ? JSON.parse(localStorage.getItem("consumer_containers"))
          : [];
      CustomConsole("consumer_containers");
      CustomConsole(consumerContainers);
      const distributorFilledContainers =
        localStorage.getItem("distributor_filled_containers") &&
        localStorage.getItem("distributor_filled_containers").length
          ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
          : [];
      CustomConsole("distributor_filled_containers");
      CustomConsole(distributorFilledContainers);
      CustomConsole("-------Retrived from local storage-------");

      if (props.isDistributor === true) {
        if (props.isInScan) {
          inScannedValidatation();
        } else {
          outScannedValidation();
        }
      }
    }
  }, [props.currentScanned]);

  // Function to handle Scanned QR/barcodes validation for InScan
  // Function to handle
  const inScannedValidatation = () => {
    if (props.isDistributor) {
      let requestedQrs =
        localStorage.getItem("current_scanned_codes") &&
        localStorage.getItem("current_scanned_codes").length
          ? JSON.parse(localStorage.getItem("current_scanned_codes"))
          : [];
      //Order Scanned QRs
      let qrScannedForOrder =
        localStorage.getItem("scanned_for_order") &&
        localStorage.getItem("scanned_for_order").length
          ? JSON.parse(localStorage.getItem("scanned_for_order"))
          : [];
      //Consumer QRs
      let consumerQrs =
        localStorage.getItem("consumer_containers") &&
        localStorage.getItem("consumer_containers").length
          ? JSON.parse(localStorage.getItem("consumer_containers"))
          : [];
      // let finalData = [];
      let finalData = props.inScannedCodes;
      CustomConsole("requestedQrs");
      CustomConsole(requestedQrs);

      CustomConsole("qrScannedForOrder");
      CustomConsole(qrScannedForOrder);

      CustomConsole("consumerQrs");
      CustomConsole(consumerQrs);

      if (requestedQrs && requestedQrs.length) {
        requestedQrs.map((reqQR) => {
          // Already Scanned QRs for the Order
          let foundInAlreadyScannedOrder = qrScannedForOrder.find(
            (item) => item.bar_code === reqQR || item.qr_code === reqQR
          );
          //If not found in already scanned for the order
          if (!foundInAlreadyScannedOrder) {
            //Find whether the QR is found in Consumer Stock or not
            let found = consumerQrs.find(
              (item) => item.bar_code === reqQR || item.qr_code === reqQR
            );
            // If the QR found in consumer stock and not updated the final QR array with it
            if (found && !finalData.find((data) => data === found.qr_code)) {
              // Store the Valid QRs which belongs to consumer and Distributor Scanned
              finalData.push(found.qr_code);
            } else {
              CustomConsole("----In else part----");
            }
          } else {
            CustomConsole("Found in already scanned");
          }
        });
      }

      CustomConsole("finalData");
      CustomConsole(finalData);
      // props.setInScannedCodes(finalData);
      // props.setInScannedNo(finalData.length);
    }
  };

  React.useEffect(() => {
    if (props.isDistributor) {
      CustomConsole(`props.outScannedNo: ${props.outScannedNo}`);
      CustomConsole(`props.inScannedNo: ${props.inScannedNo}`);
    }
  }, [props.inScannedNo, props.outScannedNo]);
  React.useEffect(() => {
    if (props.isDistributor === true) {
      CustomConsole("props.inScannedCodes");
      CustomConsole(props?.isInScan);
      CustomConsole(props?.inScannedCodes?.length || 0);
      CustomConsole(props?.outScannedCodes?.length || 0);
      props.setInScannedNo(props?.inScannedCodes?.length || 0);
      props.setOutScannedNo(props?.outScannedCodes?.length || 0);
    }
  }, [props.inScannedCodes, props.outScannedCodes]);

  const [autoLink, setAutoLink] = React.useState(false);
  React.useEffect(() => {
    //@Removed/console.log("auto check");
    //@Removed/console.log(props.inScannedCodes);
    //@Removed/console.log(returnQty);
    if (props.isInScan) {
      if (returnQty !== 5) {
        if (
          parseInt(props.inScannedNo) >= 5 ||
          parseInt(props.outScannedNo) >= 5
        ) {
          // handleBulkLink();
          setAutoLink(true);
        }
      }
    } else {
      if (orderQty !== 5) {
        if (
          parseInt(props.inScannedNo) >= 5 ||
          parseInt(props.outScannedNo) >= 5
        ) {
          // handleBulkLink();
          setAutoLink(true);
        }
      }
    }
  }, [props.inScannedNo, props.outScannedNo]);

  React.useEffect(() => {
    //@Removed/console.log("autoLink");
    //@Removed/console.log(autoLink);
    if (autoLink) {
      handleBulkLink();
      setAutoLink(false);
    }
  }, [autoLink]);

  const outScannedValidation = () => {
    // CustomConsole(
    //   orderDetailsData.orderDetails.packaged_drinking_water
    //     .twentyltr_capacity_qty
    // );
    if (props.isDistributor) {
      let requestedQrs = localStorage.getItem("current_scanned_codes")
        ? JSON.parse(localStorage.getItem("current_scanned_codes"))
        : [];
      const qrScannedForOrder = [];
      // JSON.parse(localStorage.getItem("scanned_for_order")) || [];

      const consumerQrs =
        localStorage.getItem("consumer_containers") &&
        localStorage.getItem("consumer_containers").length
          ? JSON.parse(localStorage.getItem("consumer_containers"))
          : [];

      const distributorQrs =
        localStorage.getItem("distributor_filled_containers") &&
        localStorage.getItem("distributor_filled_containers").length
          ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
          : [];

      CustomConsole("--qrScannedForOrder--");
      CustomConsole(qrScannedForOrder);

      CustomConsole("--consumerQrs--");
      CustomConsole(consumerQrs);

      CustomConsole("--distributorQrs--");
      CustomConsole(distributorQrs);

      let finalQrDataSet = [];

      let alreadyScanned = props.isInScan ? qrScannedForOrder : consumerQrs;

      let existingWithUser = props.isInScan ? consumerQrs : distributorQrs;

      CustomConsole("--alreadyScanned--");

      CustomConsole(alreadyScanned);

      CustomConsole("--existingWithUser--");

      CustomConsole(existingWithUser);

      CustomConsole(requestedQrs);
      CustomConsole(typeof requestedQrs);
      // requestedQrs = JSON.parse(requestedQrs);
      // const abc = JSON.parse(requestedQrs);
      // CustomConsole(abc);
      // CustomConsole(typeof abc);
      // const abcd = JSON.parse(requestedQrs);
      // CustomConsole(abcd);
      // CustomConsole(typeof abcd);
      // CustomConsole(requestedQrs.length);
      if (requestedQrs && requestedQrs.length > 0) {
        requestedQrs.map((reqQR) => {
          //Already Scanned QRs for the Order
          let foundInAlreadyScanned = alreadyScanned.find(
            (item) => item.bar_code === reqQR || item.qr_code === reqQR
          );

          //If not found in already scanned for the order
          if (!foundInAlreadyScanned) {
            //Find whether the QR is found in Consumer Stock or not
            let found = existingWithUser.find(
              (item) => item.bar_code === reqQR || item.qr_code === reqQR
            );

            // If the QR found in consumer stock and not updated the final QR array with it

            if (
              found &&
              !finalQrDataSet.find((data) => data === found.qr_code)
            ) {
              //Store the Valid QRs which belongs to consumer and Distributor Scanned
              finalQrDataSet.push(found.qr_code);
            }
          } else {
            CustomConsole("Found in already scanned");
          }
        });
      }

      CustomConsole("finalQrDataSet");
      CustomConsole(finalQrDataSet);
      if (
        finalQrDataSet.length <=
        orderDetailsData?.orderDetails?.packaged_drinking_water
          ?.twentyltr_capacity_qty
      ) {
        // props.setOutScannedCodes(finalQrDataSet);
      }
    }
  };

  // handleQrBarcodeIdFromScanner
  //
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");

  const [qrBarcodeIdBulk, setQrBarcodeIdBulk] = React.useState("");
  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      CustomConsole("--- qrBarcodeIdNew === qrBarcodeId ---");
      CustomConsole(qrBarcodeIdNew);
      CustomConsole(qrBarcodeId);
      if (props.openQrBarCodeCamera === true) {
        CustomConsole("-- inscan ---");
        setScannedQrCode(qrBarcodeId);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
      } else if (props.openOutScanCamera === true) {
        CustomConsole("-- outscan ---");
        setScanQrCodeDetailBtn(true);
        setScannedQrCodeDetails(qrBarcodeId);
        setDecodedResults([]);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
        setQrBarcodeIdBulk("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew, qrBarcodeIdBulk]);

  const handleQrBarcodeIdFromScanner = (event) => {
    CustomConsole(event.target.value);
    setQrBarcodeId(event.target.value);

    if (value === 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdBulk) {
          CustomConsole("set code for bulk");
          setQrBarcodeIdBulk(event.target.value);
        } else {
          CustomConsole("---code not set for bulk ---");
        }
      }, 500);
    }

    if (value !== 0) {
      setTimeout(() => {
        if (event.target.value !== qrBarcodeIdNew) {
          CustomConsole("-- set data --");
          setQrBarcodeIdNew(event.target.value);
        } else {
          CustomConsole("--- data is set ---");
        }
      }, 500);
    }
  };

  React.useEffect(() => {
    CustomConsole("effect qrBarcodeIdBulk");
    CustomConsole(qrBarcodeIdBulk);
    CustomConsole(props.orderid);
    const newOrderId = props.orderid;

    const consumerContainers =
      localStorage.getItem("consumer_containers") &&
      localStorage.getItem("consumer_containers").length
        ? JSON.parse(localStorage.getItem("consumer_containers"))
        : [];
    const distributorQrs =
      localStorage.getItem("distributor_filled_containers") &&
      localStorage.getItem("distributor_filled_containers").length
        ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
        : [];
    CustomConsole("--------consumer containers inscan----------");
    CustomConsole(consumerContainers);
    CustomConsole("--------distributor containers outscan-------");
    CustomConsole(distributorQrs);
    if (value === 0) {
      CustomConsole("1");
      if (qrBarcodeIdBulk !== "" && props.isInScan) {
        //@Removed/console.log("2");
        props.setIsProperScan(true);
        const matchingContainer = consumerContainers.find(
          (container) =>
            (container.qr_code === qrBarcodeIdBulk &&
              container.bar_code !== qrBarcodeIdBulk) ||
            (container.bar_code === qrBarcodeIdBulk &&
              container.qr_code !== qrBarcodeIdBulk)
        );
        // if (matchingContainer) {
        if (true) {
          CustomConsole("3");
          props.setIsProperScan(true);

          //@Removed/console.log("currentScannedCheck");
          //@Removed/console.log(props.currentScanned);
          if (
            !(newOrderId in props.currentScanned) || // Check if newOrderId is not already a key in the object
            (!props.currentScanned[newOrderId].includes(qrBarcodeIdBulk) &&
              props.currentScanned[newOrderId].length < returnQty)
          ) {
            // if (true) {
            CustomConsole("3");
            // props.setCurrentScanned((prev) => [...prev, qrBarcodeIdBulk]);
            props.setCurrentScanned((prevScanned) => {
              // Check if the order ID has changed
              if (
                prevScanned[newOrderId] &&
                Object.keys(prevScanned)[0] === newOrderId
              ) {
                // Same order ID, append to existing array
                return {
                  ...prevScanned,
                  [newOrderId]: [...prevScanned[newOrderId], qrBarcodeIdBulk],
                };
              } else {
                // Different order ID or new entry, reset array
                return {
                  [newOrderId]: [qrBarcodeIdBulk],
                };
              }
            });
            // props.setCurrentScanned((prev) => {
            //   const orderid = props.orderid;
            //   // Check if the orderid key already exists
            //   const updatedList = prev[orderid]
            //     ? [...prev[orderid], qrBarcodeIdBulk]
            //     : [qrBarcodeIdBulk];
            //   return { ...prev, [orderid]: updatedList };
            // });
            toast.success("Scanned Success", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            props.setIsProperScan(true);
          } else {
            CustomConsole("already exists swap");
            // props.setIsProperScan(false);
            toast.error("This can is already scanned", {
              position: "top-right",
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        } else {
          props.setIsProperScan(false);

          if (!props.availableForSwap.includes(qrBarcodeIdBulk)) {
            props.setAvailableForSwap((prev) => [...prev, qrBarcodeIdBulk]);
          }
        }
      } else if (qrBarcodeIdBulk !== "" && props.isInScan === false) {
        CustomConsole("Out Scan with scanner");
        const matchingContainer = distributorQrs.find(
          (container) =>
            (container.qr_code === qrBarcodeIdBulk &&
              container.bar_code !== qrBarcodeIdBulk) ||
            (container.bar_code === qrBarcodeIdBulk &&
              container.qr_code !== qrBarcodeIdBulk)
        );
        if (true) {
          CustomConsole("5");
          props.setIsProperScan(true);
          //@Removed/console.log("check check");
          if (
            !(newOrderId in props.currentScanned) || // Check if newOrderId is not already a key in the object
            (!props.currentScanned[newOrderId].includes(qrBarcodeIdBulk) &&
              props.currentScanned[newOrderId].length < orderQty)
          ) {
            // if (true) {
            // CustomConsole("6");
            //@Removed/console.log("out scan new");
            // props.setCurrentScanned((prev) => [...prev, qrBarcodeIdBulk]);
            props.setCurrentScanned((prevScanned) => {
              // Check if the order ID has changed
              if (
                prevScanned[newOrderId] &&
                Object.keys(prevScanned)[0] === newOrderId
              ) {
                // Same order ID, append to existing array
                return {
                  ...prevScanned,
                  [newOrderId]: [...prevScanned[newOrderId], qrBarcodeIdBulk],
                };
              } else {
                // Different order ID or new entry, reset array
                return {
                  [newOrderId]: [qrBarcodeIdBulk],
                };
              }
            });
            toast.success("Scanned Success", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            props.setIsProperScan(true);
          } else {
            CustomConsole("already exists");
            toast.error("This can is already scanned", {
              position: "top-right",
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            // props.setIsProperScan(false);
          }
        } else {
          CustomConsole("7");

          props.setIsProperScan(false);
        }
      }
      setTimeout(() => {
        setQrBarcodeIdBulk("");
        setQrBarcodeId("");
      }, 500);
      // setRenderFlag(true);
    }
  }, [qrBarcodeIdBulk]);

  React.useEffect(() => {
    //@Removed/console.log("otherValidScanned");
    //@Removed/console.log(props.otherValidScanned);
    if (props.otherValidScanned?.length > 0) {
      props.setIsProperScan(false);
    } else {
      props.setIsProperScan(true);
    }
  }, [props.otherValidScanned]);

  // React.useEffect(() => {
  //   CustomConsole("effect qrBarcodeIdBulk");
  //   CustomConsole(qrBarcodeIdBulk);
  //   CustomConsole(props.orderid);

  //   const consumerContainers = JSON.parse(
  //     localStorage.getItem("consumer_containers") || "[]"
  //   );
  //   const distributorQrs = JSON.parse(
  //     localStorage.getItem("distributor_filled_containers") || "[]"
  //   );

  //   CustomConsole("--------consumer containers inscan----------");
  //   CustomConsole(consumerContainers);
  //   CustomConsole("--------distributor containers outscan-------");
  //   CustomConsole(distributorQrs);

  //   if (value === 0) {
  //     // Assuming 'value' is defined elsewhere in your component
  //     CustomConsole("1");
  //     if (qrBarcodeIdBulk !== "" && props.isInScan) {
  //       CustomConsole("2");
  //       props.setIsProperScan(true);

  //       const matchingContainer = consumerContainers.find(
  //         (container) =>
  //           (container.qr_code === qrBarcodeIdBulk &&
  //             container.bar_code !== qrBarcodeIdBulk) ||
  //           (container.bar_code === qrBarcodeIdBulk &&
  //             container.qr_code !== qrBarcodeIdBulk)
  //       );

  //       if (!matchingContainer) {
  //         CustomConsole("3");
  //         props.setCurrentScanned((prev) => {
  //           const orderid = props.orderid;
  //           const updatedList = prev[orderid]
  //             ? [...prev[orderid], qrBarcodeIdBulk]
  //             : [qrBarcodeIdBulk];
  //           return { ...prev, [orderid]: updatedList };
  //         });

  //         toast.success("Scanned Success", {
  //           position: "top-right",
  //           autoClose: 2500,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         props.setIsProperScan(true);
  //       } else {
  //         props.setIsProperScan(false);
  //       }
  //     } else if (qrBarcodeIdBulk !== "" && !props.isInScan) {
  //       CustomConsole("Out Scan with scanner");

  //       const matchingContainer = distributorQrs.find(
  //         (container) =>
  //           (container.qr_code === qrBarcodeIdBulk &&
  //             container.bar_code !== qrBarcodeIdBulk) ||
  //           (container.bar_code === qrBarcodeIdBulk &&
  //             container.qr_code !== qrBarcodeIdBulk)
  //       );

  //       if (!matchingContainer) {
  //         CustomConsole("5");
  //         props.setCurrentScanned((prev) => {
  //           const orderid = props.orderid;
  //           const updatedList = prev[orderid]
  //             ? [...prev[orderid], qrBarcodeIdBulk]
  //             : [qrBarcodeIdBulk];
  //           return { ...prev, [orderid]: updatedList };
  //         });

  //         toast.success("Scanned Success", {
  //           position: "top-right",
  //           autoClose: 200,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         props.setIsProperScan(true);
  //       } else {
  //         CustomConsole("7");
  //         props.setIsProperScan(false);
  //       }
  //     }

  //     setTimeout(() => {
  //       setQrBarcodeIdBulk("");
  //       setQrBarcodeId("");
  //     }, 500);
  //   }
  // }, [qrBarcodeIdBulk, props.orderid, props.isInScan]);

  // React.useEffect(() => {
  //   CustomConsole("effect qrBarcodeIdBulk");
  //   CustomConsole(qrBarcodeIdBulk);
  //   CustomConsole(props.orderid);
  //   const consumerContainers =
  //     JSON.parse(localStorage.getItem("consumer_containers")) || [];
  //   const distributorQrs =
  //     JSON.parse(localStorage.getItem("distributor_filled_containers")) || [];
  //   CustomConsole("--------consumer containers inscan----------");
  //   CustomConsole(consumerContainers);
  //   CustomConsole("--------distributor containers outscan-------");
  //   CustomConsole(distributorQrs);
  //   const orderIndex = props.currentScanned.findIndex((order) =>
  //     order.hasOwnProperty(props.orderid)
  //   );

  //   if (value === 0) {
  //     if (qrBarcodeIdBulk !== "" && props.isInScan) {
  //       const matchingContainer = consumerContainers.find(
  //         (container) =>
  //           (container.qr_code === qrBarcodeIdBulk &&
  //             container.bar_code !== qrBarcodeIdBulk) ||
  //           (container.bar_code === qrBarcodeIdBulk &&
  //             container.qr_code !== qrBarcodeIdBulk)
  //       );
  //       if (matchingContainer) {
  //         if (!props.currentScanned.includes(qrBarcodeIdBulk)) {
  //           if (orderIndex === -1) {
  //             // Order ID doesn't exist, add a new entry
  //             props.setCurrentScanned((prev) => [
  //               ...prev,
  //               { [props.orderid]: [qrBarcodeIdBulk] },
  //             ]);
  //           } else {
  //             // Order ID exists, update the existing entry
  //             const updatedData = [...props.currentScanned];
  //             updatedData[orderIndex][props.orderid].push(qrBarcodeIdBulk);
  //             props.setCurrentScanned(updatedData);
  //           }
  //           // props.setCurrentScanned((prev) => [...prev, qrBarcodeIdBulk]);
  //         } else {
  //           CustomConsole("already exists");
  //         }
  //       }
  //     } else if (qrBarcodeIdBulk !== "" && props.isInScan === false) {
  //       const matchingContainer = distributorQrs.find(
  //         (container) =>
  //           (container.qr_code === qrBarcodeIdBulk &&
  //             container.bar_code !== qrBarcodeIdBulk) ||
  //           (container.bar_code === qrBarcodeIdBulk &&
  //             container.qr_code !== qrBarcodeIdBulk)
  //       );
  //       if (matchingContainer) {
  //         if (!props.currentScanned.includes(qrBarcodeIdBulk)) {
  //           props.setCurrentScanned((prev) => [...prev, qrBarcodeIdBulk]);
  //         } else {
  //           CustomConsole("already exists");
  //         }
  //       }
  //     }
  //     setTimeout(() => {
  //       setQrBarcodeIdBulk("");
  //       setQrBarcodeId("");
  //     }, 500);
  //     // setRenderFlag(true);
  //   }
  // }, [qrBarcodeIdBulk]);

  React.useEffect(() => {}, [scanOption]);

  const handleSelectScanOption = (event) => {
    CustomConsole("-- handleSelectScanOption --");
    CustomConsole(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      // setScanQrCodeDetailBtn(true);
      // setScanQrFlag(true);
      // setUnMountFlag(true);
    } else {
      // setScanQrCodeDetailBtn(false);
      // setScanQrFlag(false);
      // setUnMountFlag(false);
    }
  };

  React.useEffect(() => {
    if (!props.isProperScan) {
      setOpenSwappedCanDialog(true);
    }
  }, [props.isProperScan]);

  const handleBulkLink = () => {
    CustomConsole("Bulk Link");
    if (props.isDistributor) {
      // CustomConsole(props.isInScanCompleted);
      if (props.isInScan) {
        const reqObj = {
          qr_code: props.inScannedCodes,
          order_id: props.orderid,
          is_auto_can_swap: true,
        };
        CustomConsole("inscan api call");
        CustomConsole(reqObj);
        dispatch(InscanQRBarcode(reqObj));
        // setTimeout(() => {
        //   dispatch(GetQrBarcodeDetails(props.orderid));
        //   // dispatch(GetViewOrderDetails(props.orderid));
        // }, 1000);

        // if (autoLink == false) {
        props.setOpenCameraDialog(false);
        // }

        //@Removed/console.log("bulkLink");
        if (props.inScannedNo === 0 && returnQty === 0) {
          props.setOpenCameraDialog(false);
        }
        setTimeout(() => {
          CustomConsole("Current scanned empty after inscan link");
          setInScanFlag(true);
          // if (
          //   props.currentScanned.length ===
          //   orderDetailsData.orderDetails.packaged_drinking_water
          //     .empty_container_qty
          // ) {
          props.setCurrentScanned([]);
          props.setCameraScannedCodes([]);
          props.setOtherValidScanned([]);
          CustomConsole("Current scanned is set empty now after inscan link");
          // }
        }, 500);
        setTimeout(() => {
          dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
        }, 1000);
      } else {
        const reqObj = {
          qr_code: props.outScannedCodes,
          order_id: props.orderid,
          is_auto_can_swap: true,
        };
        CustomConsole("out scanned API call");
        dispatch(OutScanQRBarcode(reqObj));
        setTimeout(() => {
          // dispatch(GetQrBarcodeDetails(props.orderid));
          // dispatch(GetViewOrderDetails(props.orderid));
        }, 1000);
        // if (autoLink === false) {
        props.setOpenCameraDialog(false);
        // }
        setTimeout(() => {
          setInScanFlag(true);
          props.setCurrentScanned([]);
          props.setCameraScannedCodes([]);
          props.setOtherValidScanned([]);
          CustomConsole("Current scanned is set empty now after outscan link");
        }, 500);
        setTimeout(() => {
          dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
        }, 1000);

        // setTimeout(() => {
        //   localStorage.removeItem("current_scanned_codes");
        //   localStorage.removeItem("consumer_containers");
        //   localStorage.removeItem("distributor_filled_containers");
        // }, 1000);

        // setTimeout(() => {
        //   localStorage.clear();
        // }, 1000);
      }
    }
  };

  React.useEffect(() => {
    if (Object.keys(requestObject).length) {
      //@Removed/console.log("request Object in parent");
      //@Removed/console.log(requestObject);
      const array = requestObject?.data?.map((item) => item.id);
      const reqObj = {
        qr_code: array,
        order_id: props.orderid,
        is_auto_can_swap: true,
      };
      if (props.isInScan) {
        dispatch(InscanQRBarcode(reqObj));
      } else if (submitBtnFlg === true) {
        console.log("--------out scan---------");
        dispatch(OutScanQRBarcode(reqObj));
        setSubmitBtnFlg(false);
      }
      // dispatch(LinkValidCans(requestObject))

      setTimeout(() => {
        dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
        localStorage.removeItem("current_scanned_codes");
        props.setCurrentScanned({});
        props.setScannedCount(0);
        props.setOpenScanningDialog(false);
        setRequestObject({});
      }, 1500);
    }
  }, [requestObject]);

  React.useEffect(() => {
    if (props.scannedCount === props.scanQtyMax) {
      console.log(requestObject);
      if (Object.keys(requestObject).length) {
        const array = requestObject?.data?.map((item) => item.id);
        const reqObj = {
          qr_code: array,
          order_id: props.orderid,
          is_auto_can_swap: true,
        };
        dispatch(OutScanQRBarcode(reqObj));
        props.setOpenScanningDialog(false);
      }
    }
  }, [requestObject, props.scannedCount, props.scanQtyMax]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    CustomConsole(newValue);
    setValue(newValue);
  };

  React.useEffect(() => {
    setScanOption("scanner");
    if (value === 0) {
      // dispatch(GetQrBarcodeDetails(props.orderid));
    }
  }, [value]);

  const [dialogColor, setDialogColor] = React.useState("");

  React.useEffect(() => {
    //@Removed/console.log("props.isOnline");
    //@Removed/console.log(props.isOnline);
    if (props.isOnline) {
      setDialogColor("#4CAF50");
    } else if (props.isOnline === false) {
      setDialogColor("#F44336");
    }
  }, [props.isOnline]);

  const handleSwapCan = () => {
    //@Removed/console.log("swap can request");
    if (props.isInScan) {
      const reqObj = {
        qr_barcode: {
          empty: props.availableForSwap,
          filled: [],
        },
        order_id: props.orderid,
      };
      dispatch(PostQrBarcodeSwapRequest(reqObj));
    } else if (!props.isInScan) {
      const reqObj = {
        qr_barcode: {
          empty: [],
          filled: props.availableForSwap,
        },
        order_id: props.orderid,
      };
      dispatch(PostQrBarcodeSwapRequest(reqObj));
    }
  };

  return (
    <Material.Box>
      <Material.Dialog
        open={props.openCameraDialog}
        // sx={{ height: "100%" }}
        // onClose={handleClose}
        // fullScreen
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0", // Light gray background for the entire Dialog
            boxShadow: "none", // Optional: removes shadow
            borderRadius: "8px", // Rounded corners for the Dialog
          },
        }}
      >
        <Material.DialogContent
          sx={{
            width: "100%",
            height: "700px",
            // backgroundColor: dialogColor,
            // padding: 20,
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              width: "100%",
              // padding: "10px",
            }}
          >
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Link bulk" />
              {/* <Tab label="Link one by one" /> */}
            </Tabs>
            {/* <Material.FormControl sx={{ marginTop: "15px" }}>
              <Material.FormLabel>Select Scanning Option</Material.FormLabel>
              <Material.RadioGroup
                defaultValue="scanner"
                value={scanOption}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={handleSelectScanOption}
              >
                <Material.FormControlLabel
                  value="scanner"
                  control={<Material.Radio />}
                  label="Scanner Device"
                />

                {value === 1 && (
                  <Material.FormControlLabel
                    value="camera"
                    control={<Material.Radio />}
                    label="Camera"
                  />
                )}
                {value === 0 && (
                  <Material.FormControlLabel
                    value="Scan all and link"
                    control={<Material.Radio />}
                    label="Camera"
                  />
                )}
              </Material.RadioGroup>
            </Material.FormControl> */}

            <Material.Box sx={{ marginTop: "10px" }}>
              <ToggleButtonGroup
                color="primary"
                // value={alignment}
                value={scanOption}
                exclusive
                onChange={handleChangeToggle}
                aria-label="Platform"
              >
                <ToggleButton value="scanner">
                  Scanner <ScannerRoundedIcon />
                </ToggleButton>
                {value === 1 && (
                  <ToggleButton value="camera">
                    Camera <CameraAltRoundedIcon />
                  </ToggleButton>
                )}

                {value === 0 && (
                  <ToggleButton value="Scan all and link">
                    Camera <CameraAltRoundedIcon />
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </Material.Box>
            {props.isDistributor && (
              <>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    padding: "10px",
                  }}
                >
                  {/* <Material.Box>
                    <Material.Typography
                      sx={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      No of cans to be in-scanned : {orderQty}
                    </Material.Typography>
                  </Material.Box> */}
                  {props.isInScan && value === 0 ? (
                    <Material.Chip
                      label={`Scanned Cans: ${props.inScannedNo} / ${returnQty}`}
                      color="success"
                      style={{
                        fontSize: "1.2rem", // Increase font size
                        fontWeight: "bold", // Make font bold
                        padding: "12px 20px", // Increase padding for a larger chip
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {props.isInScan &&
                  value === 0 &&
                  props.isProperScan === false ? (
                    <Material.Chip
                      label={`No of cans swapped: ${props.otherValidScanned}`}
                      color="error"
                      style={{
                        fontSize: "0.8rem", // Increase font size
                        fontWeight: "bold", // Make font bold
                        padding: "12px 20px", // Increase padding for a larger chip
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {/* {props.availableForSwap.length ? (
                    <>
                      {" "}
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        <Material.Card>
                          <Material.CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "10px",
                            }}
                          >
                            <Material.Typography
                              sx={{ fontWeight: "bold", marginBottom: "10px" }}
                            >
                              Do you want to swap can?
                            </Material.Typography>
                            <Material.Button
                              variant="contained"
                              size="small"
                              color="warning"
                              onClick={handleSwapCan}
                            >
                              <SwapHorizRoundedIcon /> Available for swap :{" "}
                              {props.availableForSwap.length}{" "}
                            </Material.Button>
                          </Material.CardContent>
                        </Material.Card>
                      </Material.Box>
                    </>
                  ) : (
                    ""
                  )} */}
                </Material.Box>
              </>
            )}

            {props.isDistributor && (
              <>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  {props.isInScan === false && value === 0 ? (
                    <Material.Chip
                      label={`Scanned Cans: ${props.outScannedNo} / ${orderQty}`}
                      color="success"
                      style={{
                        fontSize: "1.2rem", // Increase font size
                        fontWeight: "bold", // Make font bold
                        padding: "10px 20px", // Increase padding for a larger chip
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {props.isInScan === false &&
                  value === 0 &&
                  props.isProperScan === false ? (
                    <Material.Chip
                      label={`Can is already scanned or invalid`}
                      color="error"
                      style={{
                        fontSize: "1.2rem", // Increase font size
                        fontWeight: "bold", // Make font bold
                        padding: "12px 20px", // Increase padding for a larger chip
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Material.Box>
              </>
            )}

            {scanOption === "scanner" ? (
              <>
                <Material.Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "10px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Material.TextField
                      onChange={handleQrBarcodeIdFromScanner}
                      // label="QR/Barcode:"
                      value={qrBarcodeId}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      color={props.isProperScan ? "success" : "warning"}
                    />
                  </ThemeProvider>
                  {value === 0 && (
                    <Material.Button
                      onClick={handleBulkLink}
                      variant="contained"
                      sx={{ marginTop: "10px" }}
                    >
                      Submit
                    </Material.Button>
                  )}
                </Material.Box>
              </>
            ) : props.openQrBarCodeCamera === true &&
              scanOption !== "Scan all and link" ? (
              <Html5QrcodePlugin1
                fps={1}
                qrbox={200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            ) : props.openOutScanCamera === true &&
              scanOption !== "Scan all and link" ? (
              <Html5QrcodePlugin1
                fps={2}
                qrbox={200}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResultQrCode}
              />
            ) : (
              <>
                {scanOption === "Scan all and link" && (
                  <>
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Html5QrcodePlugin1
                        fps={1}
                        qrbox={200}
                        disableFlip={false}
                        qrCodeSuccessCallback={onScanAllAndLink}
                      />
                      <Material.Button
                        onClick={handleBulkLink}
                        variant="contained"
                      >
                        Submit
                      </Material.Button>
                    </Material.Box>
                  </>
                )}
              </>
            )}
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {" "}
            {/* <Material.Chip
              label={`No of linked: ${scannedAlready.length}`}
              color="success"
            /> */}
          </Material.Box>
          {/* {props.openQrBarCodeCamera === true && scanOption === "camera" ? (
             <Material.Box>
               <Html5QrcodePlugin1
                 fps={1}
                 qrbox={200}
                 disableFlip={false}
                 qrCodeSuccessCallback={onNewScanResult}
               />
             </Material.Box>
           ) : null} 
           <Material.Box>
             {props.openOutScanCamera === true && scanOption === "camera" ? (
               <>
                 {" "}
                 <Html5QrcodePlugin1
                   fps={1}
                   qrbox={200}
                   disableFlip={false}
                   qrCodeSuccessCallback={onNewScanResultQrCode}
                 />
               </>
             ) : (
               ""
             )}
           </Material.Box> */}

          {/* <ResultContainerPlugin1
            results={decodedResults}
            setScanCount={setScanCount}
          /> */}
        </Material.DialogContent>
        <Material.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Material.Button
            onClick={() => props.setOpenCameraDialog(false)}
            variant="outlined"
            size="small"
            color="error"
          >
            Close
          </Material.Button>
        </Material.Box>
      </Material.Dialog>

      {openSwappedCanDialog && (
        <Dialog
          open={openSwappedCanDialog}
          onClose={() => {
            setOpenSwappedCanDialog(false);
            props.setIsProperScan(true);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{
              backgroundColor: "#e86d7b",
              // padding: 20,
            }}
          >
            <Material.Typography sx={{ fontWeight: "bold", color: "white" }}>
              No of cans swapped : {props.otherValidScanned?.length}
            </Material.Typography>
          </DialogContent>
          <DialogActions>
            <Material.Button
              variant="outlined"
              size="small"
              onClick={() => {
                setOpenSwappedCanDialog(false);
                props.setIsProperScan(true);
              }}
              autoFocus
            >
              Close
            </Material.Button>
          </DialogActions>
        </Dialog>
      )}
      {openLinkFromStartDialog && (
        <Material.Box>
          <Material.Dialog
            open={openLinkFromStartDialog}
            onClose={() => setOpenLinkFromStartDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Material.DialogTitle id="alert-dialog-title">
              {"Are you sure you want to link from the beginning?"}
            </Material.DialogTitle>

            <Material.DialogActions>
              <Material.Button
                onClick={() => setOpenLinkFromStartDialog(false)}
                variant="outlined"
                size="small"
              >
                No
              </Material.Button>
              <Material.Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  localStorage.removeItem("current_scanned_codes");
                  props.setCurrentScanned({});
                  props.setScannedCount(0);
                  setOpenLinkFromStartDialog(false);
                }}
                autoFocus
              >
                Yes
              </Material.Button>
            </Material.DialogActions>
          </Material.Dialog>
        </Material.Box>
      )}
      <ScanningDialog
        orderdata={props.data}
        setOpenScanningDialog={props.setOpenScanningDialog}
        openScanningDialog={props.openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={props.currentScanned}
        setCurrentScanned={props.setCurrentScanned}
        scanQtyMax={props.scanQtyMax}
        scanOrderId={props.orderid}
        scannedCount={props.scannedCount}
        setScannedCount={props.setScannedCount}
        setRequestObject={setRequestObject}
        setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
    </Material.Box>
  );
}

export default CameraDialog;
