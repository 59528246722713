import React from "react";
import * as Material from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { Button } from "@mui/base";
import CustomConsole from "../../CustomConsole";
import { toast } from "react-toastify";

function ProductsInScanDialog(props) {
  const REQUEST_QTY = props.is_inscan_disabled;
  CustomConsole("--------props----");
  CustomConsole(props);
  const [count, setCount] = React.useState(props.inScanData.return);
  const [retunCount, setRetunCount] = React.useState([]);
  CustomConsole(retunCount);
  React.useEffect(() => {
    setRetunCount([{ return: props.inScanData.return }]);
  }, [props.inScanData]);

  const hadleSubmitInScan = () => {
    props.hadleSubmitInScan({
      order_id: props.orderid,
      capacity: props.inScanData.capacity,
      material: props.inScanData.material,
      quantity: count,
      is_inscan_disabled: props.is_inscan_disabled,
    });
    setCount("");
  };
  const handleClose = () => {
    props.handleClose();
    setCount("");
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setCount(() => event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (
      event.key === "-" || // Disallow the '-' key
      (event.key === "0" &&
        event.target.selectionStart === 0 &&
        event.target.selectionEnd === 0) // Disallow '0' at the beginning
    ) {
      event.preventDefault();
    }
  };
  return (
    <Material.Dialog open={props.openProductsInScanDialog}>
      {REQUEST_QTY ? (
        <>
          <Material.DialogContent>
            <Material.Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
              <Material.CardContent>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                  }}
                >
                  <Material.Typography
                    sx={{ fontWeight: "bold", color: "blue" }}
                  >
                    {props.orderid}
                  </Material.Typography>
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    Return Qty: {props.inScanData.return}
                  </Material.Typography>
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    Scanned Empty: {props.inScanData.received_empty}
                  </Material.Typography>
                  <Material.Typography sx={{ fontWeight: "bold" }}>
                    Scanned Filled: {props.inScanData.submitted_filled}
                  </Material.Typography>
                </Material.Box>
                <Material.Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "1rem",
                  }}
                >
                  {/* <Material.TextField
                label="Enter Quantity"
                size="small"
                autoFocus
                value={count}
                // onChange={handleChangeQuantity}
                InputLabelProps={{ shrink: true }}
              /> */}
                </Material.Box>
                <Material.Box sx={{ m: 2 }}>
                  <Material.Autocomplete
                    sx={{ width: 220 }}
                    options={retunCount}
                    autoHighlight
                    getOptionLabel={(retunCount) => `${retunCount.return}`}
                    onChange={(_, newValue) => {
                      setCount(newValue ? newValue.return : ""); // Set the selected description as the value
                    }}
                    renderOption={(props, retunCount) => (
                      <Material.Box
                        component="li"
                        key={retunCount.return}
                        // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {retunCount.return}
                      </Material.Box>
                    )}
                    renderInput={(params) => (
                      <Material.TextField
                        {...params}
                        size="small"
                        InputLabelProps={{
                          style: { backgroundColor: "white" },
                        }}
                        label="Enter Or Select Quantity"
                        value={count}
                        key={params.return}
                        onKeyDown={handleInputKeyDown}
                        inputProps={{
                          maxLength: 10, // Optional: You can set the maximum length if needed
                          onInput: handleInputChange, // Attach the input event handler
                          ...params.inputProps,
                          autoComplete: "off",
                          style: {
                            backgroundColor: "lightyellow",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    )}
                  />
                </Material.Box>
              </Material.CardContent>
              <Material.Box
                sx={{
                  width: "100%",
                  bgcolor: "#F0FFFF",
                  height: "30px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Material.Typography sx={{ marginLeft: "1rem" }}>
                  Scanning Qty:{count}
                </Material.Typography>
              </Material.Box>
            </Material.Card>
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button variant="contained" onClick={hadleSubmitInScan}>
              submit
            </Material.Button>
            <Material.Button
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              close
            </Material.Button>
          </Material.DialogActions>
        </>
      ) : (
        <>
          <Material.DialogContent>
            <Material.Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
              <Material.CardContent sx={{ textAlign: "center" }}>
                <Material.Box
                  sx={{
                    fontSize: "16px",
                    color: "#566573",
                    fontWeight: "bold",
                  }}
                >
                  Are you sure, you have received
                  <b> {props.inScanData.return} </b>
                  empty products?
                </Material.Box>
              </Material.CardContent>
            </Material.Card>
          </Material.DialogContent>
          <Material.Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              px: 2,
              py: 1,
            }}
          >
            <Material.Button
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              NO
            </Material.Button>
            <Material.Button
              color="success"
              sx={{ backgroundColor: "078223" }}
              variant="contained"
              onClick={hadleSubmitInScan}
            >
              YES
            </Material.Button>
          </Material.Box>
        </>
      )}
    </Material.Dialog>
  );
}

export default ProductsInScanDialog;
