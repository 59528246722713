import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const DeleteUserDialog = ({ deleteUserDialog, handleClose, handleDelete }) => {
  return (
    <Dialog open={deleteUserDialog} onClose={handleClose}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
        <Typography>Are you sure you want to delete this user?</Typography>
        </DialogContent>
        <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
            No
        </Button>
        <Button variant="outlined" color="error" onClick={handleDelete}>
            Delete
        </Button>
        </DialogActions>
      </Dialog>
  );
};

export default DeleteUserDialog;
