/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Inputs)
 * @date 19-01-2024
 * @version
 */
// Importing the Libraruies abd the
import { TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomConsole from "../../CustomConsole";
function TextFieldReusable(props) {
  const [hint, setHint] = React.useState(false);
  const authenticationDetails = useSelector((state) => state.authReducer);

  //function for validating hint
  const setDataForVariables = (isValid) => {
    CustomConsole(isValid);
    props.setIsValid(isValid);
    if (props.isHintRequired) setHint(!isValid);
  };

  //function for handling validation
  const handleValidation = (regex, data) => {
    if (data.trim() !== "") {
      const isValid = regex.test(data);
      props.setDataForState(data);
      setDataForVariables(isValid);
    } else {
      props.setDataForState("");
      setDataForVariables(false);
    }
  };

  // Function to check if the data is a valid negative number
  const isValidNegativeNumber = (data) => /^-?[0-9]\d*(\.\d+)?$/.test(data);
  //function for handling event changes in text field
  const handelChange = (event) => {
    const data = event.target.value + "";
    const { label } = props;
    switch (label) {
      case "Product Name":
      case "Capacity":
      case "Crate Name":
        handleValidation(/^.{3,}$/, data);
        break;
      case "Material":
        handleValidation(/^.{1,}$/, data);
        break;
      case "HSN Code":
        handleValidation(/^[0-9]{1}/, data);
        break;
      case "Description":
        handleValidation(/^.{16,}$/, data);
        break;
      case "SKU Quantity":
        if (data !== "0") {
          props.setDataForState([parseInt(data)]);
        }
        break;
      case "Life Span":
      case "Price":
      case "Crate Capacity":
      case "CGST %":
      case "SGST %":
      case "IGST %":
      case "Minimum Book Quantity":
      case "Maximum Book Quantity":
      case "OrderType":
      case "Delivery Charges":
        if (isValidNegativeNumber(data) || data !== "0") {
          props.setDataForState(data);
        }
        break;
      case "MRP":
        if (data !== 0) {
          props.setDataForState((prev) => ({
            ...prev,
            mrp: data,
          }));
        }
        break;
      case "Sale Price":
        if (data !== "0") {
          props.setDataForState((prev) => ({
            ...prev,
            sp: data,
          }));
        }
        break;
      case "Deposit":
        if (parseFloat(data || 0) >= 0) {
          props.setDataForState((prev) => ({
            ...prev,
            deposit: data,
          }));
        }
        break;
      case "Refund Amount":
        if (parseFloat(data || 0) >= 0) {
          props.setDataForState((prev) => ({
            ...prev,
            refund_amount: data,
          }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <TextField
      size="small"
      type={props.type}
      onChange={handelChange}
      error={!hint && props.value != "" ? false : !props.isValid}
      label={props.label}
      // variant="filled"
      color="success"
      value={props.value}
      focused
      required={props.isRequired}
      helperText={hint ? "Incorrect entry" : ""}
      sx={{
        backgroundColor: "white",
        fontSize: "18px",
        borderRadius: "5px",
        width: { xs: "100%" },
        marginTop: "2%",
      }}
    />
  );
}

export default TextFieldReusable;
