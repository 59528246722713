/**
 * @author vinay kumar
 * @description Deliveries Details page
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 21-11-2023
 */
// Importing the Libraries and Other Files

import React from "react";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";

import { makeStyles } from "@mui/styles";
import * as IconName from "react-icons/fc";
import CanIcon from "../../Images/CanIcon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import {
  CurrentDayDeliveries,
  GetAssignDeliveryAgent,
  GetQrBarcodeDetails,
  GetSupplierOnGoingAssignedOrderCount,
  GetSupplierOnGoingAssignedOrderStatus,
  GetSupplierOnGoingAssignedSlotAndAgent,
  GetSupplierPincodes,
  PutSupplierCurrentDayCompleteDeliveries,
  PutSupplierCurrentDayVerifyOtp,
} from "../Redux/Actions/supliersAction";

import Badge from "@mui/material/Badge";
import DeliveriesFilterDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesFilterDialog";
import SupplierDeliveriesSearchByOrderIdDialog from "../../Components/DialogBoxes/SupplierDialogBox/SupplierDeliveriesSearchByOrderIdDialog";
import CurrentDayDeliveriesCardNew from "../../Components/Cards/CurrentDayDeliveriesCardNew";
import CurrentDayAllOrdersTable from "./CurrentDayAllOrdersTable";
import CameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/CameraDialog";
import CurrentDayDeliveriesEnterOtpDialog from "../../Components/DialogBoxes/SupplierDialogBox/CurrentDayDeliveriesEnterOtpDialog";
import { ClearFilesData } from "../Redux/Actions/fileHandlingAction";
import { GetNewSearchOrderDetails } from "../Redux/Actions/ordersAdminAction";
import moment from "moment";
import CustomLoaderDialog from "../../Components/Dialog/CustomLoaderDialog";
import DistributorLocation from "../../Components/Maps/distributerOrderLocation";
import AssignDeliverAgentViaMap from "../../Components/DialogBoxes/mapAssignAgent/mapAssignAgent";
import VehicleProductSwapDialog from "./VehicleProductSwapDialog";

function CurrentDayDeliveiesNew({ driver_id }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const supplierPincodes = useSelector(
    (state) => state.supplierDeliveries.supplierPincodes
  );
  CustomConsole(supplierDeliveries);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const useStyles = makeStyles((theme) => ({
    reducedWidth: {
      width: "100px", // Adjust the width as needed
    },
  }));
  const classes = useStyles();
  const [changeRadioOptions, setChangeRadioOptions] = React.useState("Pincode");
  const [openSpiner, setOpenSpiner] = React.useState(false);
  const [changeRadioOptionsOrders, setChangeRadioOptionsOrders] =
    React.useState("Delayed");
  const [alignment, setAlignment] = React.useState("ASC");
  const [pincodesArea, setPincodesArea] = React.useState([]);
  const [selectedPincode, setSelectedPincode] = React.useState("");
  const [area, setArea] = React.useState("");
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [ordersToggle, setOrdersToggle] = React.useState("pincode");
  const SECRET_KEY = "mysecretkey";
  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [openVehicelProductSwap, setOpenVehicelProductSwap] =
    React.useState(false);
  const [submitOtpBtn, setSubmitOtpBtn] = React.useState(false);
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [deliveryData, setDeliveryData] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState("");
  CustomConsole(orderDetails);
  const [searchByOrderId, setSearchByOrderId] = React.useState("");
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [deliveryAgent, setDeliveryAgent] = React.useState("");
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [openRollBackDialog, setOpenRollBackDialog] = React.useState(false);
  const [isProperScan, setIsProperScan] = React.useState(true);
  const [outScanBtn, setOutScanBtn] = React.useState(false);
  const [inScanBtn, setInScanBtn] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState([]);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [assignDeliveryAgentBtn, setAssignDeliveryAgentBtn] =
    React.useState(false);
  const [uploadDriverChallanFile, setUploadDriverChallanFile] =
    React.useState("");
  const [uploadDriverChallanBtn, setUploadDriverChallanBtn] =
    React.useState(false);
  const [driverChallanUrl, setDriverChallanUrl] = React.useState("");
  CustomConsole(driverChallanUrl);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [ordersPincodeSwitch, setOrdersPincodeSwitch] = React.useState(false);

  const [countId, setCountId] = React.useState("");

  const [outScannedCodes, setOutScannedCodes] = React.useState([]);
  const [supplierPinCodes, setSupplierPincodes] = React.useState([]);
  const [countData, setCountData] = React.useState({});
  const [orderId, setOrderId] = React.useState("");
  const [openProgressBar, setOpenProgressBar] = React.useState(false);
  CustomConsole(countData);
  const [adminRole, setAdminRoleId] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [viewType, setViewType] = React.useState("1");
  const [isInScan, setIsInScan] = React.useState(false);
  // const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [assignDeliveryAgentOpen, setAssignAgentDialogOpen] =
    React.useState(false);
  const [selectedOrderId, setSelectedOrderId] = React.useState("");

  CustomConsole("Live scan");
  CustomConsole(localStorage.getItem("current_scanned_codes"));
  React.useEffect(() => {
    if (setDeliveryAgent) {
      setDeliveryAgent(driver_id);
    }
  }, [driver_id]);

  React.useEffect(() => {
    const storeRoleId = parseInt(window.localStorage.getItem("roleId"), 10);
    if (storeRoleId) {
      setAdminRoleId(storeRoleId);
    }
  }, []);
  //once Order is started re-directing to the Single Order Details Page(CurrentDayDeliveriesPerticularDetails.jsx)
  React.useEffect(() => {
    if (
      ADMIN_ROLES.BW_DELIVERYAGENT === 2001 ||
      ADMIN_ROLES.BW_SUPPLIER_LOADERS === 2003
    ) {
      if (
        supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
          undefined &&
        supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
          null &&
        supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.length >
          0
      ) {
        const startedOrder =
          supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.find(
            (data) =>
              data.order_status === 200 &&
              data.packaged_drinking_water?.is_order_started === true
          );
        CustomConsole("---------startedOrder------------");
        CustomConsole(startedOrder);
        if (
          (parseInt(localStorage.getItem("roleId")) ===
            ADMIN_ROLES.BW_DELIVERYAGENT ||
            parseInt(localStorage.getItem("roleId")) ===
              ADMIN_ROLES.BW_SUPPLIER_LOADERS) &&
          startedOrder?.order_id !== undefined &&
          startedOrder?.order_id !== null &&
          startedOrder?.order_id
        ) {
          const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${startedOrder?.order_id}`;
          history.push(url);
          dispatch(ClearFilesData());
        }
      }
    }
  }, [supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries]);
  React.useEffect(() => {
    // dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
    dispatch(GetSupplierOnGoingAssignedOrderCount());
    dispatch(GetSupplierOnGoingAssignedOrderStatus({ status_id: 1 }));
    dispatch(GetSupplierPincodes());
    // dispatch(GetQrBarcodeDetails(orderDetails.order_id));
    // if(supplierDeliveries.error === true){
    //   setOpenSpiner(true)
    // }else{
    //   setOpenSpiner(false)
    // }
  }, []);
  React.useEffect(() => {
    dispatch(GetQrBarcodeDetails(orderDetails.order_id));
  }, [orderDetails.order_id]);
  React.useEffect(() => {
    CustomConsole("-----Supplier pincodes-----");
    CustomConsole(supplierPincodes);
    if (supplierPincodes) {
      setSupplierPincodes(supplierPincodes);
    }
  }, [supplierPincodes]);

  React.useEffect(() => {
    supplierDeliveries.getSupplierOngoingDeliveriesCount !== undefined &&
      supplierDeliveries.getSupplierOngoingDeliveriesCount !== null &&
      supplierDeliveries.getSupplierOngoingDeliveriesCount.length > 0 &&
      supplierDeliveries.getSupplierOngoingDeliveriesCount.filter((data) => {
        CustomConsole(data);
        setCountData(data.status_counts);
      });
  }, [supplierDeliveries]);
  React.useEffect(() => {
    if (selectedPincode) {
      setPincodesArea(() =>
        supplierPinCodes
          .filter((obj) => obj.pincode === selectedPincode)
          .map((filteredObj) => filteredObj.areas)
      );
    }
  }, [selectedPincode, supplierPinCodes]);

  React.useEffect(() => {
    if (countData) {
      const delayedCount = parseInt(countData.Delayed)
        ? parseInt(countData.Delayed)
        : 0;
      const pendingCount = parseInt(countData.Pending)
        ? parseInt(countData.Pending)
        : 0;
      const deliveredCount = parseInt(countData.Delivered)
        ? parseInt(countData.Delivered)
        : 0;
      setTotalCount(() => delayedCount + pendingCount);
    }
  }, [countData]);

  React.useEffect(() => {
    CustomConsole("------pageNumber------pageSize-----");
    let reqObj = {
      delivery_from_date: moment().format("YYYY-MM-DD"),
      delivery_to_date: moment().format("YYYY-MM-DD"),
      page_size: pageSize,
      page_number: pageNumber,
      // order_type: orderType,
      areaname: area || undefined,
      pincode: selectedPincode || undefined,
      // order_status: orderStatusDelivery ? orderStatusDelivery : "",
    };
    dispatch(GetNewSearchOrderDetails(reqObj));
  }, [pageNumber, pageSize]);
  //Spiner
  // React.useEffect(() => {
  //   if (
  //     supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
  //       undefined &&
  //     supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !== null &&
  //     supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.length > 0
  //   ) {
  //     setOpenProgressBar(false);
  //   } else {
  //     setOpenProgressBar(true);
  //   }
  // }, [supplierDeliveries]);

  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
    CustomConsole("----------BeforeAssignDriver-------------------");
    if (
      supplierDeliveries?.qrBarcodeDetails !== undefined &&
      supplierDeliveries?.qrBarcodeDetails !== null
    ) {
      if (Object.keys(supplierDeliveries?.qrBarcodeDetails).length > 0) {
        CustomConsole("------Successfully got QR barcode Details--------");
        CustomConsole(supplierDeliveries?.qrBarcodeDetails);

        // Storing in local storage

        // const encrypted = CryptoJS.AES.encrypt(
        //   supplierDeliveries?.qrBarcodeDetails?.scanned_for_order?.toString(),
        //   SECRET_KEY
        // ).toString();
        // localStorage.setItem("scanned_for_order", encrypted);

        // localStorage.setItem("current_scanned_codes", []);
        const sfo = supplierDeliveries.qrBarcodeDetails.scanned_for_order
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.scanned_for_order
            )
          : [];
        const cc = supplierDeliveries.qrBarcodeDetails.consumer_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.consumer_containers
            )
          : [];
        const dfc = supplierDeliveries.qrBarcodeDetails
          .distributor_filled_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.distributor_filled_containers
            )
          : [];
        localStorage.setItem("scanned_for_order", sfo);
        localStorage.setItem("consumer_containers", cc);
        localStorage.setItem("distributor_filled_containers", dfc);
      }
    }
    if (
      !supplierDeliveries.assigDeliveryAgenterror ||
      !supplierDeliveries.errorCurrentDeliveryVerifyOtp ||
      !supplierDeliveries.errorCurrentDeliveriesInScan
    ) {
      CustomConsole("---View order details API call here----");
      // dispatch(GetViewOrderDetails(orderId));
      // dispatch(GetQrBarcodeDetails(orderId));
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          page_number: pageNumber - 1,
          page_size: pageSize,
          agent_id: driver_id,
        })
      );

      if (assignDeliveryAgentBtn === true) {
        setAssignDeliveryAgentBtn(false);
      }
      if (submitOtpBtn === true) {
        setSubmitOtpBtn(false);
        handleCloseDialog();
      }
      if (outScanBtn === true) {
        handleCloseCameraDialog();
        setOutScanBtn(false);
      }
      if (inScanBtn === true) {
        handleCloseCameraDialog();
        setInScanBtn(false);
      }
    }
  }, [
    // supplierDeliveries,
    assignDeliveryAgentBtn,
    submitOtpBtn,
    outScanBtn,
    inScanBtn,
  ]);
  React.useEffect(() => {
    // Retrieve the arrays from local storage
    const scannedForOrder =
      localStorage.getItem("scanned_for_order") &&
      localStorage.getItem("scanned_for_order").length
        ? JSON.parse(localStorage.getItem("scanned_for_order"))
        : [];
    const consumerContainers =
      localStorage.getItem("consumer_containers") &&
      localStorage.getItem("consumer_containers").length
        ? JSON.parse(localStorage.getItem("consumer_containers"))
        : [];
    const distributorFilledContainers =
      localStorage.getItem("distributor_filled_containers") &&
      localStorage.getItem("distributor_filled_containers").length
        ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
        : [];
    CustomConsole("-------Retrived from local storage-------");
    CustomConsole(scannedForOrder);
    CustomConsole(consumerContainers);
    CustomConsole(distributorFilledContainers);
  }, []);

  // React.useEffect(() => {
  //   dispatch(
  //     CurrentDayDeliveries({
  //       pageNumber: pageNumber -1,
  //       is_today: true,
  //       pageSize: pageSize,
  //       delivery_slot_sort: "ASC",
  //     })
  //   );
  // }, []);
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
    CustomConsole(alignment);
    if (newAlignment === "ASC") {
      CustomConsole("----------------ASC----------------");
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          page_number: pageNumber - 1,
          page_size: pageSize,
          sortBy: "ASC",
          pincode: selectedPincode || undefined,
          area: area || undefined,
          current_status: countId || undefined,
        })
      );
    } else if (newAlignment === "DESC") {
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          page_number: pageNumber - 1,
          page_size: pageSize,
          sortBy: "DESC",
          pincode: selectedPincode || undefined,
          area: area || undefined,
          current_status: countId || undefined,
        })
      );
    }
  };
  const handleLocation = (props) => {
    CustomConsole("------------handleLocation------------------");
    CustomConsole(props.delivery_address.latitude);
    CustomConsole(props.delivery_address.longitude);

    if (
      props.delivery_address.latitude !== undefined &&
      props.delivery_address.latitude !== null &&
      props.delivery_address.longitude !== undefined &&
      props.delivery_address.longitude !== null &&
      parseFloat(props.delivery_address.latitude) > 0 &&
      parseFloat(props.delivery_address.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${props.delivery_address.latitude},${props.delivery_address.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };
  const handleCopyAddress = (props) => {
    const address_compile = ` ${props.delivery_address.contact_name},${props.delivery_address.contact_phone},${props.delivery_address.address_line1},
    ${props.delivery_address.address_line2},
    ${props.delivery_address.address_line3},
    ${props.delivery_address.service_area}-
    ${props.delivery_address.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };

  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleChangeRadio = (event) => {
    setChangeRadioOptions(event.target.value);
    if (event.target.value === "other") {
      setFilterDialogOpen(true);
    }
    if (event.target.value === "OrderId") {
      setOpenDrawer(true);
    }
    if (event.target.value === "Agent") {
      dispatch(GetAssignDeliveryAgent());
    }
  };
  const handleChangeRadioOrders = (event) => {
    setChangeRadioOptionsOrders(event.target.value);
    if (event.target.value === "Delayed") {
      dispatch(GetSupplierOnGoingAssignedOrderStatus({ status_id: 1 }));
    } else if (event.target.value === "Pending") {
      dispatch(GetSupplierOnGoingAssignedOrderStatus({ status_id: 2 }));
    } else if (event.target.value === "Delivered") {
      dispatch(GetSupplierOnGoingAssignedOrderStatus({ status_id: 3 }));
    }
  };
  const handleSearchOrderId = () => {
    CustomConsole("----------search--------------");
    // setOpenDrawer(false);
    if (searchByOrderId !== "") {
      const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${
        "BTORD-" + searchByOrderId
      }`;
      history.push(url);
      dispatch(ClearFilesData());
    } else {
      toast.error("Please Enter Order Id", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleClosefilterDialog = () => {
    setFilterDialogOpen(false);
    setChangeRadioOptions("Pincode");
  };
  const handleScaDetails = (props, event) => {
    CustomConsole(props);
    setOrderDetails(props);
    CustomConsole("------------scaDetails------------------");
    CustomConsole(orderDetails);
    setIsProperScan(true);

    if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.delivery_otp_status !== 200 &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === false
    ) {
      CustomConsole("----Enter OTP----");
      setOpenEnterOtpDialog(true);
      // setSubmitOtpBtn(true);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.delivery_otp_status === 200 &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === false
    ) {
      CustomConsole("----OTPLessDelivery----");

      const reqObj = {
        delivery_id: props.delivery_id,
        delivery_otp: props.deliveryOtp,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setSubmitOtpBtn(true);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === true &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === true
    ) {
      CustomConsole("----OutScan-----");
      setOpenOutScanCamera(true);
      setOpenQrBarCodeCamera(false);
      setOpenCameraDialog(true);
      CustomConsole(openOutScanCamera);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === false &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.delivery_slots !== undefined &&
      props.packaged_drinking_water.is_order_started === true
    ) {
      CustomConsole("----InScan-----");
      setOpenQrBarCodeCamera(true);
      setOpenOutScanCamera(false);
      setOpenCameraDialog(true);
      setIsInScan(true);
    } else if (
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.is_inscan_completed !== undefined &&
      props.packaged_drinking_water.is_inscan_completed === true &&
      props.packaged_drinking_water !== undefined &&
      props.packaged_drinking_water.is_outscan_completed !== undefined &&
      props.packaged_drinking_water.is_outscan_completed === true &&
      parseInt(props.order_status) === 200
    ) {
      CustomConsole("Complete Delivery");
      // let lat = 0;
      // let longit = 0;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            CustomConsole(position);
            // lat = position.coords.latitude;
            // longit = position.coords.longitude;
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
      CustomConsole("latitude:" + latitude);
      CustomConsole("longitude:" + longitude);
      const reqObj = {
        delivery_otp: props.delivery_otp,
        delivery_id: props.delivery_id,
        driver_challan: "",
        latitude: props.delivery_address?.latitude,
        longitude: props.delivery_address?.longitude,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayCompleteDeliveries(reqObj));
      setTimeout(() => {
        localStorage.removeItem("current_scanned_codes");
        localStorage.removeItem("consumer_containers");
        localStorage.removeItem("distributor_filled_containers");
      }, 1000);
      setTimeout(() => {
        dispatch(GetSupplierOnGoingAssignedSlotAndAgent());
      }, 1000);
    }
  };

  const handleChange = (event, nextView) => {
    setOrdersToggle(nextView);
    CustomConsole(ordersToggle);
  };

  const handleCurrentDaySubmitOtp = (props) => {
    CustomConsole(props);
    CustomConsole("---handleCurrentDaySubmitOtp---");
    setOpenEnterOtpDialog(false);
    if (enterOtpTextfied !== "") {
      const reqObj = {
        delivery_id: orderDetails.delivery_id,
        delivery_otp: enterOtpTextfied,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setEnterOtpTextfied("");
      // setSubmitOtpBtn(true);
      setTimeout(() => {
        dispatch(
          GetSupplierOnGoingAssignedSlotAndAgent({
            page_number: pageNumber - 1,
            page_size: pageSize,
            sortBy: alignment || undefined,
            pincode: selectedPincode || undefined,
            area: area || undefined,
            current_status: countId || undefined,
          })
        );
      }, 1000);
    } else {
      toast.error("Please Enter OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenEnterOtpDialog(false);
  };
  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    // setOpenCameraDialog(false);
  };
  const handleOrdersPincodeSwitch = (event) => {
    setOrdersPincodeSwitch(event.target.checked);
  };
  const handleViewDetails = (props) => {
    const url = `/dashboard/CurrentDayDeliveriesPerticularDetails?order_id=${props.order_id}`;
    history.push(url);
    dispatch(ClearFilesData());
  };
  const handleApplyFilter = (props) => {
    dispatch(
      GetSupplierOnGoingAssignedSlotAndAgent({
        page_number: pageNumber - 1,
        page_size: pageSize,
        // agent_id: props.agent_assigned || undefined,
        // phone: props.phone || undefined,
        pincode: props.enter_Pincode || undefined,
        // booking_time: props.select_Booking_Time || undefined,
        // sortBy: props.select_Delivery_slot || undefined,
        // order_type: props.order_Type || undefined,
        // delivery_agent_id: props.delivery_agent_id || undefined,
      })
    );
    setFilterDialogOpen(false);
  };
  const handleSubmitAgent = () => {
    dispatch(
      GetSupplierOnGoingAssignedSlotAndAgent({
        pageNumber: pageNumber - 1,
        page_size: pageSize,
        agent_id: deliveryAgent,
      })
    );
  };
  const handleSubmitPinCodeArea = () => {
    CustomConsole("-----------handleSubmitPinCodeArea-------------");
    dispatch(
      GetSupplierOnGoingAssignedSlotAndAgent({
        pageNumber: pageNumber - 1,
        page_size: pageSize,
        pincode: selectedPincode,
        area: area || undefined,
        current_status: countId || undefined,
      })
    );
  };

  const handleCount = (props) => {
    CustomConsole("-------------handleCount----------");
    CustomConsole(props);
    if (props === 0) {
      setCountId(0);
      CustomConsole("-------------pendoing----------");
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          pageNumber: pageNumber - 1,
          agent_id: driver_id,
          page_size: pageSize,
          current_status: 0,
        })
      );
    }
    if (props === 501) {
      setCountId(501);
      CustomConsole("-------------handleDeliveredCount-------------");
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          pageNumber: pageNumber - 1,
          page_size: pageSize,
          current_status: 501,
        })
      );
    }
    if (props === 503) {
      setCountId(503);
    }
    if (props === 502) {
      setCountId(502);
      CustomConsole("-------------handleDelayedCount--------------");
      dispatch(
        GetSupplierOnGoingAssignedSlotAndAgent({
          pageNumber: pageNumber - 1,
          page_size: pageSize,
          current_status: 502,
        })
      );
    }
  };

  //function to change the view based on toggle
  const handelViewType = (e) => {
    setViewType(e.target.value);
  };

  //dummy function
  const handeldummyfunction = () => {};

  const handleOpenVehicleProductSwapDialog = () => {
    setOpenVehicelProductSwap(true);
  };

  const handleCloseVehicleProdctSwapDialog = () => {
    setOpenVehicelProductSwap(false);
  };
  const handleTransferProducts = () => {
    setOpenVehicelProductSwap(false);
  };
  return (
    <Material.Box
      sx={{
        maxWidth: { xs: "100%", md: "100%", sm: "650px" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Material.Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Material.Typography
            sx={{
              color: ordersPincodeSwitch === false ? "#30A2F3" : "black",
            }}
          >
            Deliveries
          </Material.Typography>
          <AntSwitch
            checked={ordersPincodeSwitch}
            onChange={handleOrdersPincodeSwitch}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Material.Typography
            sx={{ color: ordersPincodeSwitch === true ? "#30A2F3" : "black" }}
          >
            Status
          </Material.Typography>
        </Stack>
      </Material.Box>

      {/* <Material.Box>
        <Material.ToggleButtonGroup
          orientation="horizontal"
          value={ordersToggle}
          exclusive
          onChange={handleChange}
        >
          <Material.ToggleButton value="pincode">pincode</Material.ToggleButton>
          <Material.ToggleButton value="allOrders">
            All Orders
          </Material.ToggleButton>
        </Material.ToggleButtonGroup>
      </Material.Box> */}

      <Material.Paper elevation={5} sx={{ marginBottom: "10px" }}>
        <Material.Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <Material.Box
            sx={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleCount(0)}
          >
            <Material.Typography
              sx={{
                fontWeight: "bold",
                minHeight: "40px",
                maxWidth: "40px",
                bgcolor: "#FFA500",
                borderRadius: "50px",
                paddingTop: "8px",
                color: "white",
                marginLeft: "10px",
              }}
            >
              {totalCount}
            </Material.Typography>
            <Material.Typography sx={{ fontWeight: "bold" }}>
              Pending{" "}
            </Material.Typography>
          </Material.Box>
          <Material.Box
            sx={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleCount(502)}
          >
            <Material.Typography
              sx={{
                fontWeight: "bold",
                minHeight: "40px",
                maxWidth: "40px",
                bgcolor: "#FF0000",
                borderRadius: "50px",
                paddingTop: "8px",
                color: "white",
                marginLeft: "10px",
              }}
            >
              {countData.Delayed ? countData.Delayed : 0}
            </Material.Typography>
            <Material.Typography sx={{ fontWeight: "bold" }}>
              Delayed{" "}
            </Material.Typography>
          </Material.Box>
          <Material.Box
            sx={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleCount(501)}
          >
            <Material.Typography
              sx={{
                fontWeight: "bold",
                minHeight: "40px",
                maxWidth: "40px",
                bgcolor: "#008000",
                borderRadius: "50px",
                paddingTop: "8px",
                color: "white",
                marginLeft: "13px",
              }}
            >
              {countData.Delivered ? countData.Delivered : 0}
            </Material.Typography>
            <Material.Typography sx={{ fontWeight: "bold" }}>
              Delivered{" "}
            </Material.Typography>
          </Material.Box>
        </Material.Box>
      </Material.Paper>
      {ordersPincodeSwitch === false ? (
        <Material.Box>
          <Material.Paper elevation={5} sx={{ marginBottom: "5px" }}>
            <Material.FormControl>
              <Material.RadioGroup
                row
                value={changeRadioOptions}
                onChange={handleChangeRadio}
              >
                <Material.FormControlLabel
                  sx={{ maxWidth: "80px", m: "5px" }}
                  value="Pincode"
                  control={
                    <Material.Radio
                      sx={{ maxWidth: "8px", maxHeight: "8px", m: "3px" }}
                    />
                  }
                  label="Pincode"
                />
                <Material.FormControlLabel
                  sx={{ maxWidth: "80px", m: "5px" }}
                  value="OrderId"
                  control={
                    <Material.Radio
                      sx={{ maxWidth: "8px", maxHeight: "8px", m: "3px" }}
                    />
                  }
                  label="OrderId"
                />
                {(ADMIN_ROLES.BW_DISTRIBUTER === adminRole ||
                  ADMIN_ROLES.BW_SUPPLIERMANAGER === adminRole) &&
                !driver_id ? (
                  <Material.FormControlLabel
                    sx={{ maxWidth: "80px", m: "5px" }}
                    value="Agent"
                    control={
                      <Material.Radio
                        sx={{ maxWidth: "8px", maxHeight: "8px", m: "3px" }}
                      />
                    }
                    label="Agent"
                  />
                ) : (
                  ""
                )}
                {/* <Material.FormControlLabel
                  sx={{ maxWidth: "80px", m: "5px" }}
                  value="other"
                  control={
                    <Material.Radio
                      sx={{ maxWidth: "8px", maxHeight: "8px", m: "3px" }}
                    />
                  }
                  label="other"
                /> */}
              </Material.RadioGroup>
            </Material.FormControl>
          </Material.Paper>
          {changeRadioOptions === "Pincode" ? (
            <Material.Box>
              <Material.Box
                sx={{
                  display: { md: "flex" },
                  justifyContent: { md: "space-evenly" },
                }}
              >
                <Material.Paper elevation={10} sx={{ padding: "15px" }}>
                  <Material.Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-around" },
                    }}
                  >
                    <Material.FormControl
                      variant="filled"
                      size="small"
                      sx={{ marginRight: "10px", minWidth: "90px" }}
                    >
                      <Material.InputLabel>Pincode</Material.InputLabel>
                      <Material.Select
                        value={selectedPincode}
                        defaultValue={null}
                        onChange={(e) => {
                          setArea();
                          setPincodesArea([]);
                          setSelectedPincode(e.target.value);
                        }}
                      >
                        <Material.MenuItem value={null}>All</Material.MenuItem>

                        {supplierPinCodes?.map((obj, key) => {
                          return (
                            <Material.MenuItem value={obj.pincode} key={key}>
                              {obj.pincode}
                            </Material.MenuItem>
                          );
                        })}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.FormControl
                      variant="filled"
                      size="small"
                      sx={{
                        marginRight: "10px",
                        marginLeft: "5px",
                        minWidth: 85,
                      }}
                    >
                      <Material.InputLabel>Area</Material.InputLabel>
                      <Material.Select
                        value={area}
                        defaultValue={null}
                        onChange={(e) => {
                          setArea(e.target.value);
                        }}
                      >
                        <Material.MenuItem
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          value={null}
                        >
                          All
                        </Material.MenuItem>
                        {pincodesArea?.[0]?.map((obj, key) => {
                          return (
                            <Material.MenuItem
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              value={obj}
                              key={key}
                            >
                              {obj}
                            </Material.MenuItem>
                          );
                        })}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Button
                      variant="contained"
                      sx={{ maxHeight: "46px", marginRight: "5px" }}
                      onClick={handleSubmitPinCodeArea}
                    >
                      submit
                    </Material.Button>
                  </Material.Box>
                </Material.Paper>
                <Material.Paper
                  elevation={10}
                  sx={{ padding: "2px", marginTop: "5px", marginBottom: "5px" }}
                >
                  <Material.Typography
                    sx={{
                      textAlign: "start",
                      paddingLeft: "14px",
                      fontSize: "12px",
                    }}
                  >
                    Sort to Delivery Slot Selected
                  </Material.Typography>
                  <Material.Box
                    sx={{
                      display: "flex",
                      // margin: "15px",
                      marginLeft: "14px",
                      justifyContent: { md: "space-around" },
                    }}
                  >
                    {/* <Material.Typography
                sx={{ marginTop: "10px", fontWeight: "bold" }}
              >
                Sort :
              </Material.Typography> */}

                    <Material.Stack
                      direction="row"
                      // spacing={1}

                      sx={{ maxHeight: "30px" }}
                    >
                      <Material.ToggleButtonGroup
                        value={alignment}
                        exclusive
                        size="sm"
                        onChange={handleAlignment}
                        sx={{ bgcolor: "#FDEDEC" }}
                        // className={classes.reducedWidth}
                      >
                        <Material.ToggleButton value="ASC">
                          {/* <ArrowUpward sx={{ fontSize: "20px" }} /> */}
                          Asc
                        </Material.ToggleButton>
                        <Material.ToggleButton value="DESC">
                          {/* <ArrowDownward /> */}
                          Desc
                        </Material.ToggleButton>
                      </Material.ToggleButtonGroup>
                    </Material.Stack>

                    <Material.Box sx={{ maxHeight: "20px" }}>
                      <Material.FormControl sx={{ ml: 2 }} size="small">
                        <Material.Select
                          variant="standard"
                          value={pageSize}
                          label="Page size"
                          onChange={(e) => {
                            setPageSize(e.target.value);
                          }}
                        >
                          <Material.MenuItem value={25}>25</Material.MenuItem>
                          <Material.MenuItem value={50}>50</Material.MenuItem>
                          <Material.MenuItem value={100}>100</Material.MenuItem>
                          <Material.MenuItem value={500}>500</Material.MenuItem>
                          <Material.MenuItem value={1000}>
                            1000
                          </Material.MenuItem>
                        </Material.Select>
                      </Material.FormControl>
                    </Material.Box>
                    <Material.Box>
                      <Material.Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Material.IconButton
                          onClick={() => {
                            if (pageNumber - 1 > 0) {
                              setPageNumber(() => pageNumber - 1);
                            }
                          }}
                        >
                          <MuiIcons.FirstPage
                            sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
                          />
                        </Material.IconButton>
                        <Material.Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {pageNumber}
                        </Material.Typography>
                        <Material.IconButton
                          sx={{ maxHeight: "50px" }}
                          onClick={() => {
                            if (
                              supplierDeliveries
                                ?.getSupplierAssignSlotAgentOngoingDeliveries
                                ?.length === pageSize
                            ) {
                              setPageNumber(() => pageNumber + 1);
                            }
                          }}
                        >
                          <MuiIcons.LastPage
                            sx={{
                              color: supplierDeliveries
                                ?.getSupplierAssignSlotAgentOngoingDeliveries
                                ?.length
                                ? "blue"
                                : "gray",
                            }}
                          />
                        </Material.IconButton>
                      </Material.Box>
                    </Material.Box>
                  </Material.Box>
                </Material.Paper>
                <Material.Paper elevation={10}>
                  <Material.Typography
                    sx={{
                      textAlign: "start",
                      paddingLeft: "14px",
                      fontSize: "12px",
                    }}
                  >
                    Select view
                  </Material.Typography>

                  <Material.Stack
                    direction="row"
                    // spacing={1}

                    sx={{ padding: "0 1rem" }}
                  >
                    <Material.ToggleButtonGroup
                      value={viewType}
                      exclusive
                      size="sm"
                      onChange={handelViewType}
                      sx={{ bgcolor: "#FDEDEC" }}
                      // className={classes.reducedWidth}
                    >
                      <Material.ToggleButton value="1">
                        {/* <ArrowUpward sx={{ fontSize: "20px" }} /> */}
                        Card
                      </Material.ToggleButton>
                      <Material.ToggleButton value="2">
                        {/* <ArrowDownward /> */}
                        Map
                      </Material.ToggleButton>
                    </Material.ToggleButtonGroup>
                  </Material.Stack>
                </Material.Paper>
              </Material.Box>

              {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                undefined &&
              supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                null &&
              supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                .length > 0 ? (
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    maxHeight: "550px",
                    overflow: "auto",
                  }}
                >
                  <>
                    {viewType === "1" ? (
                      <>
                        {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.map(
                          (data) => {
                            return (
                              <CurrentDayDeliveriesCardNew
                                key={data.id} // assuming each data item has a unique id
                                data={data}
                                handleScaDetails={handleScaDetails}
                                handleCopyAddress={handleCopyAddress}
                                handleLocation={handleLocation}
                                handlePhone={handlePhone}
                                handleViewDetails={handleViewDetails}
                              />
                            );
                          }
                        )}
                      </>
                    ) : (
                      <DistributorLocation
                        data={
                          supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                        }
                        setAssignAgentDialogOpen={setAssignAgentDialogOpen}
                        setOrderId={setSelectedOrderId}
                        coordinates={coordinates}
                        setCoordinates={setCoordinates}
                      />
                    )}
                  </>
                </Material.Box>
              ) : (
                <Material.Box sx={{ marginTop: "30px" }}>
                  <Material.Typography sx={{ color: "red" }}>
                    No Order Details Found
                  </Material.Typography>
                </Material.Box>
              )}
            </Material.Box>
          ) : changeRadioOptions === "OrderId" ? (
            <Material.Box>
              <Material.Paper elevation={24} sx={{ padding: "20px" }}>
                {" "}
                <Material.Box sx={{ margin: "0px" }}>
                  <Material.TextField
                    label="Search Order"
                    variant="outlined"
                    value={searchByOrderId}
                    onChange={(event) => setSearchByOrderId(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <Material.InputAdornment position="start">
                          <Material.Typography sx={{ fontWeight: "bold" }}>
                            BTORD-
                          </Material.Typography>
                        </Material.InputAdornment>
                      ),
                      endAdornment: (
                        <Material.InputAdornment position="end">
                          <IconName.FcSearch
                            size="30px"
                            onClick={handleSearchOrderId}
                          />
                        </Material.InputAdornment>
                      ),
                      style: {
                        borderRadius: "30px",
                      },
                    }}
                  />
                </Material.Box>
              </Material.Paper>
            </Material.Box>
          ) : changeRadioOptions === "Agent" ? (
            <Material.Box>
              <Material.Paper>
                <Material.Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Material.Box>
                    <Material.FormControl
                      variant="filled"
                      sx={{ m: 1, minWidth: "200px" }}
                    >
                      <Material.InputLabel>Delivery Agents</Material.InputLabel>
                      <Material.Select
                        value={deliveryAgent}
                        onChange={(event) =>
                          setDeliveryAgent(event.target.value)
                        }
                      >
                        {supplierDeliveries?.getAssignDeliveryAgent?.map(
                          (data) => {
                            return (
                              <Material.MenuItem value={data?.driver_id}>
                                {data?.driver_profile?.fullname}
                              </Material.MenuItem>
                            );
                          }
                        )}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Button
                      variant="contained"
                      sx={{ maxHeight: "50px", marginTop: "10px" }}
                      onClick={handleSubmitAgent}
                    >
                      Submit
                    </Material.Button>
                  </Material.Box>
                  <Material.Paper elevation={10}>
                    <Material.Typography
                      sx={{
                        textAlign: "start",
                        paddingLeft: "14px",
                        fontSize: "12px",
                      }}
                    >
                      Select view
                    </Material.Typography>

                    <Material.Stack
                      direction="row"
                      // spacing={1}

                      sx={{ padding: "0 1rem" }}
                    >
                      <Material.ToggleButtonGroup
                        value={viewType}
                        exclusive
                        size="sm"
                        onChange={handelViewType}
                        sx={{ bgcolor: "#FDEDEC" }}
                        // className={classes.reducedWidth}
                      >
                        <Material.ToggleButton value="1">
                          {/* <ArrowUpward sx={{ fontSize: "20px" }} /> */}
                          Card
                        </Material.ToggleButton>
                        <Material.ToggleButton value="2">
                          {/* <ArrowDownward /> */}
                          Map
                        </Material.ToggleButton>
                      </Material.ToggleButtonGroup>
                    </Material.Stack>
                  </Material.Paper>
                </Material.Box>
                <>
                  {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                    undefined &&
                  supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                    null &&
                  supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                    .length > 0 ? (
                    <Material.Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        maxHeight: "550px",
                        overflow: "auto",
                      }}
                    >
                      <>
                        {viewType === "1" ? (
                          <>
                            {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.map(
                              (data) => {
                                return (
                                  <CurrentDayDeliveriesCardNew
                                    data={data}
                                    handleScaDetails={handleScaDetails}
                                    handleCopyAddress={handleCopyAddress}
                                    handleLocation={handleLocation}
                                    handlePhone={handlePhone}
                                    handleViewDetails={handleViewDetails}
                                  />
                                );
                              }
                            )}
                          </>
                        ) : (
                          <DistributorLocation
                            data={
                              supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                            }
                            setAssignAgentDialogOpen={setAssignAgentDialogOpen}
                            setOrderId={setSelectedOrderId}
                            coordinates={coordinates}
                            setCoordinates={setCoordinates}
                          />
                        )}
                      </>
                    </Material.Box>
                  ) : (
                    <Material.Box sx={{ marginTop: "30px" }}>
                      <Material.Typography sx={{ color: "red" }}>
                        No Order Details Found
                      </Material.Typography>
                    </Material.Box>
                  )}
                </>
              </Material.Paper>
            </Material.Box>
          ) : changeRadioOptions === "other" ? (
            <>
              {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                undefined &&
              supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries !==
                null &&
              supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                .length > 0 ? (
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    maxHeight: "550px",
                    overflow: "auto",
                  }}
                >
                  <>
                    {viewType === "1" ? (
                      <>
                        {supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries.map(
                          (data) => {
                            return (
                              <CurrentDayDeliveriesCardNew
                                data={data}
                                handleScaDetails={handleScaDetails}
                                handleCopyAddress={handleCopyAddress}
                                handleLocation={handleLocation}
                                handlePhone={handlePhone}
                                handleViewDetails={handleViewDetails}
                              />
                            );
                          }
                        )}
                      </>
                    ) : (
                      <DistributorLocation
                        data={
                          supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries
                        }
                        setAssignAgentDialogOpen={setAssignAgentDialogOpen}
                        setOrderId={setSelectedOrderId}
                        coordinates={coordinates}
                        setCoordinates={setCoordinates}
                      />
                    )}
                  </>
                </Material.Box>
              ) : (
                <Material.Box sx={{ marginTop: "30px" }}>
                  <Material.Typography sx={{ color: "red" }}>
                    No Order Details Found
                  </Material.Typography>
                </Material.Box>
              )}
            </>
          ) : (
            ""
          )}
        </Material.Box>
      ) : (
        // ordersPincodeSwitch === true && (
        <Material.Paper elevation={5} sx={{ marginBottom: "5px" }}>
          <Material.FormControl>
            <Material.RadioGroup
              row
              value={changeRadioOptionsOrders}
              onChange={handleChangeRadioOrders}
            >
              <Material.FormControlLabel
                sx={{ maxWidth: "50px" }}
                value="Delayed"
                control={
                  <Material.Radio
                    sx={{ maxWidth: "5px", maxHeight: "4px", mr: "5px" }}
                  />
                }
                label="Delayed"
              />
              <Material.FormControlLabel
                sx={{ maxWidth: "50px", ml: "20px" }}
                value="Pending"
                control={
                  <Material.Radio
                    sx={{ maxWidth: "5px", maxHeight: "4px", mr: "5px" }}
                  />
                }
                label="Pending"
              />
              <Material.FormControlLabel
                sx={{ maxWidth: "50px", ml: "20px" }}
                value="Delivered"
                control={
                  <Material.Radio
                    sx={{ maxWidth: "5px", maxHeight: "4px", mr: "5px" }}
                  />
                }
                label="Delivered"
              />
              {/* <Material.FormControlLabel
                  sx={{ maxWidth: "50px", m: "10px" }}
                  value="TotalOrder"
                  control={
                    <Material.Radio
                      sx={{ maxWidth: "5px", maxHeight: "4px" }}
                    />
                  }
                  label="Total"
                /> */}
            </Material.RadioGroup>
          </Material.FormControl>
          {changeRadioOptionsOrders === "TotalOrder" ? (
            <CurrentDayAllOrdersTable />
          ) : changeRadioOptionsOrders === "Delivered" ? (
            <CurrentDayAllOrdersTable
              data={supplierDeliveries.getSupplierOngoingDeliveriesStatus}
            />
          ) : changeRadioOptionsOrders === "Pending" ? (
            <CurrentDayAllOrdersTable
              data={supplierDeliveries.getSupplierOngoingDeliveriesStatus}
            />
          ) : changeRadioOptionsOrders === "Delayed" ? (
            <CurrentDayAllOrdersTable
              data={supplierDeliveries.getSupplierOngoingDeliveriesStatus}
            />
          ) : (
            ""
          )}
        </Material.Paper>
        // )
        // <CurrentDayAllOrdersTable />
      )}
      <DeliveriesFilterDialog
        filterDialogOpen={filterDialogOpen}
        handleClose={handleClosefilterDialog}
        handleClickApply={handleApplyFilter}

        // agentListata={supplierDeliveries.getAssignDeliveryAgent}
        // currentDayDeliveries={supplierDeliveries.getSupplierAssignSlotAgentOngoingDeliveries}
      />
      {/* <SupplierDeliveriesSearchByOrderIdDialog
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        handleSearchOrderId={handleSearchOrderId}
      /> */}
      <CurrentDayDeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleCurrentDaySubmitOtp}
        handleClose={handleCloseDialog}
      />
      {/* <CameraDialog
        openCameraDialog={openCameraDialog}
        setOpenCameraDialog={setOpenCameraDialog}
        openQrBarCodeCamera={openQrBarCodeCamera}
        openOutScanCamera={openOutScanCamera}
        orderid={orderDetails.order_id}
        setInScanBtn={setInScanBtn}
        setOutScanBtn={setOutScanBtn}
        data={orderDetails}
        // decodedResults={decodedResults}
        // setScanCount={setScanCount}
        // onNewScanResultQrCode={onNewScanResultQrCodes}
        // onNewScanResult={onNewScanResults}
        handleClose={handleCloseCameraDialog}
        isInScan={isInScan}
        // setScannedCurrent={setScannedCurrent}
        setInScannedCodes={setInScannedCodes}
        inScannedCodes={inScannedCodes} 
        setOutScannedCodes={setOutScannedCodes}
        outScannedCodes={outScannedCodes}
        inScannedNo={inScannedNo}
        setInScannedNo={setInScannedNo}
        setOutScannedNo={setOutScannedNo}
        outScannedNo={outScannedNo}
        isDistributor={true}
        setCurrentScanned={setCurrentScanned}
        setAvailableForSwap={setAvailableForSwap}
        availableForSwap={availableForSwap}
        currentScanned={currentScanned}
        cameraScannedCodes={cameraScannedCodes} 
        setCameraScannedCodes={setCameraScannedCodes}
        isProperScan={isProperScan}
        setIsProperScan={setIsProperScan}
      /> */}
      {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )}

      {assignDeliveryAgentOpen && (
        <AssignDeliverAgentViaMap
          open={assignDeliveryAgentOpen}
          onClose={setAssignAgentDialogOpen}
          orderId={selectedOrderId}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          assignOrder={handeldummyfunction}
          handlePhone={handlePhone}
          type="agent"
        />
      )}
      {openVehicelProductSwap && (
        <VehicleProductSwapDialog
          openVehicelProductSwap={openVehicelProductSwap}
          handleCloseDialog={handleCloseVehicleProdctSwapDialog}
          handleTransferProducts={handleTransferProducts}
        />
      )}
    </Material.Box>
  );
}

export default CurrentDayDeliveiesNew;
