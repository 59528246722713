/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Conformation dialog befor some action
 * @date 23-01-2023
 * @version
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConformationDialog(props) {
  const dispatch = useDispatch();

  //useEfeect for handling disagree
  const handelClose = () => {
    props.setConfirmDialog(false);
  };

  return (
    <Dialog
      open={props.confirmDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handelClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.dialogMsg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelClose}>Cancel</Button>
        {props.handelProceed && (
          <Button onClick={props.handelProceed}>Proceed</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
