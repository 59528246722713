import React from "react";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CustomConsole from "../../CustomConsole";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { SettlementPostpaid } from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";
import {
  GetPoTrackDetails,
  GetInvoiceData,
} from "../Redux/Actions/adminPoTrackPostpaid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConsolidatedDialog({
  open,
  setOpen,
  selectedInvoice,
  walletBal,
  payConsId,
  pageNumber,
}) {
  const poDetails = useSelector((state) => state.poTrackDetails);
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const dispatch = useDispatch();
  const [priceBreakUp, setPriceBreakUp] = React.useState([]);
  const [totalAmt, setTotalAmt] = React.useState(0);
  const uploadPoRef = React.useRef(null);
  const [poFile, setPoFIle] = React.useState("");
  const [isPoUploaded, setPoUploaded] = React.useState(false);
  const [postpaidPaymentMethods, setPostpaidPaymentMethods] = React.useState(
    []
  );
  const [paymentMethod, setPaymentMethod] = React.useState(0);
  const [invoiveSetFlag, setInvoiceSetFlag] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);

  const triggerPoRef = () => {
    uploadPoRef.current.click();
  };

  const poUploadChange = (e) => {
    CustomConsole("----upload button clicked---");
    setPoFIle("");
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setPoUploaded(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPriceBreakUp([]);
    setPaymentMethod(0);
  };

  const handleSettlement = () => {
    let reqObj = {
      invoiceId: selectedInvoice,
      payment_reciept: paymentMethod === 600 ? "" : poFile,
      payment_method: paymentMethod,
    };
    if (paymentMethod === 600) {
      reqObj.amount = parseFloat((totalAmt - discount).toFixed(2));
      reqObj.userId = payConsId;
    }

    if (!reqObj.payment_method) {
      return toast.error("Choose a payment method!.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (reqObj.payment_method !== 600 && !reqObj.payment_reciept) {
      return toast.error("Upload the payment reciept!.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (reqObj.invoiceId && reqObj.payment_method && reqObj.payment_reciept) {
      dispatch(SettlementPostpaid(reqObj));
      setInvoiceSetFlag(true);
    } else if (reqObj.payment_method === 600 && !reqObj.payment_reciept) {
      dispatch(SettlementPostpaid(reqObj));
      setInvoiceSetFlag(true);
    }
  };

  React.useEffect(() => {
    if (poDetails.invoicePriceData) {
      setPriceBreakUp(poDetails.invoicePriceData);
    }
  }, [poDetails.invoicePriceData, open]);

  React.useEffect(() => {
    dispatch(GetSetUpConfigAction());
  }, []);

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (fileUploadDetails.isFileUploaded === true && isPoUploaded) {
      setPoFIle(fileUploadDetails.fileName);
      setPoUploaded(false);
      fileUploadDetails.isFileUploaded = false;
    }
  }, [fileUploadDetails]);

  React.useEffect(() => {
    if (setUpConfigData.error === false) {
      if (setUpConfigData.setupConfigData.length) {
        setUpConfigData.setupConfigData.map((data) => {
          if (data.key === "postpaid_payment_types") {
            return setPostpaidPaymentMethods(data.value);
          }
          return false;
        });
      }
    }
  }, [setUpConfigData]);

  React.useEffect(() => {
    CustomConsole("----inside the toast Effect----- ");
    if (poDetails.error === false && invoiveSetFlag === true) {
      CustomConsole("----Inside the toast Effect if condition----- ");
      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      let reqObj = {
        page_size: "",
        page_number: pageNumber,
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));
      dispatch(GetInvoiceData(payConsId));
      setOpen(false);
      setInvoiceSetFlag(false);
      setPoFIle("");
    } else if (poDetails.error === true && invoiveSetFlag === true) {
      toast.error(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setInvoiceSetFlag(false);
    }
    setInvoiceSetFlag(false);
    if (poDetails.discount) {
      setDiscount(poDetails.discount);
    } else {
      setDiscount(0);
    }
  }, [poDetails]);

  React.useEffect(() => {
    if (priceBreakUp) {
      const totalPrice = priceBreakUp.reduce((acc, curr) => {
        acc += Math.round(curr.taxableAmt + curr.cgstAmt + curr.sgstAmt);
        if (curr?.extraCharges && curr?.extraCharges?.totalAmt) {
          acc += curr.extraCharges.totalAmt;
        }
        return acc;
      }, 0);
      setTotalAmt(() => (totalPrice ? totalPrice : 0));
    }
  }, [priceBreakUp]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle textAlign={"center"}>Price Breakup</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          mb={2}
        >
          {priceBreakUp &&
            priceBreakUp.map((item, idx) => {
              return (
                <>
                  <Grid key={idx} container spacing={1}>
                    <Grid item md={6}>
                      <Typography>
                        {item.quantity} X {item.material}-{item.product}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>
                        {Math.round(
                          item.taxableAmt + item.cgstAmt + item.sgstAmt
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>Taxable Amt.</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>{item.taxableAmt}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>CGST Amt.</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>{item.cgstAmt}</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>SGST Amt.</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography>{item.sgstAmt}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              );
            })}
          <Grid container spacing={2} mt={1}>
            <Grid item md={6}>
              <Typography>Discount Amt.</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{discount}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>Total Amt.</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{(totalAmt - discount).toFixed(2)}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Stack mt={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Payment method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={paymentMethod}
              label="Payment method"
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              {postpaidPaymentMethods
                ? postpaidPaymentMethods.map((data, key) => {
                    return (
                      <MenuItem key={key + 1} value={data.k}>
                        {data.v}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Stack>
        {paymentMethod === 600 &&
        walletBal > priceBreakUp?.total_mrp - discount ? (
          <Box sx={{ padding: "10px", width: "300px" }}>
            <Alert severity="info">
              Amount will be deducted from the consumers wallet.
            </Alert>
          </Box>
        ) : paymentMethod === 600 &&
          walletBal < priceBreakUp?.total_mrp - discount ? (
          <Box sx={{ padding: "10px", width: "300px" }}>
            <Alert severity="warning">
              Low wallet balance order will go to payment pending.
            </Alert>
          </Box>
        ) : (
          <Stack mt={2}>
            <input
              style={{ display: "none" }}
              type="file"
              ref={uploadPoRef}
              onChange={poUploadChange}
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.65rem",
                backgroundColor: poFile ? "green" : "herit",
              }}
              onClick={triggerPoRef}
            >
              {poFile ? "PR Uploaded" : "Upload PR"}
            </Button>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={
            paymentMethod === 600 &&
            walletBal < priceBreakUp?.total_mrp - discount
          }
          onClick={handleSettlement}
        >
          Settle invoice
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConsolidatedDialog;
