import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  booknowMsg: {},
  bankdowntimeMsg: {},
  adminSettings: {},
  referralUpdate: {},
  booknowUpdateMsg: "",
  bankdowntimeUpdateMsg: "",
  setUpConfigMsg: "",
  referralMsg: "",
  deliverySlotMsg: "",
  getDeliverySlots: [],
  referralData: [],
  referralError: false,
  updateReferralSettings: { error: false, msg: "", data: [] },
};

const adminSettingsReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.GET_BOOKNOW_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
        booknowMsg: action.payload.data,
      };
    case actionType.UPDATE_REFERRAL_SETTINGS:
      return {
        ...state,
        error: action.payload.error,
        updateReferralSettings: action.payload,
      };
    case actionType.GET_BANKDOWNTIME_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
        bankdowntimeMsg: action.payload.data,
      };
    case actionType.GET_SETUP_SETTINGS:
      return {
        ...state,
        error: action.payload.error,
        adminSettings: action.payload.admin_settings,
      };
    case actionType.UPDATE_BOOKNOW_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
        booknowUpdateMsg: action.payload.msg,
      };
    case actionType.UPDATE_BANKDOWNTIME_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
        bankdowntimeUpdateMsg: action.payload.msg,
      };
    case actionType.UPDATE_SETUP_CONFIG:
      return {
        ...state,
        error: action.payload.error,
        setUpConfigMsg: action.payload.msg,
      };
    case actionType.UPDATE_REFERRAL_CODE:
      return {
        ...state,
        error: action.payload.error,
        referralMsg: action.payload.msg,
        referralUpdate: action.payload.data,
      };
    case actionType.DELIVERY_SLOT:
      return {
        ...state,
        error: action.payload.error,
        deliverySlotMsg: action.payload.msg,
      };
    case actionType.GET_REFERRAL_SETTINGS:
      return {
        ...state,
        referralError: action.payload.error,
        msg: action.payload.msg,
        referralData: action.payload.data,
      };
    case actionType.GET_DELIVERY_SLOT:
      return {
        ...state,
        error: action.payload.error,
        getDeliverySlotmsg: action.payload.msg,
        getDeliverySlots: action.payload.data,
      };
    case actionType.POST_FORCE_CONSUMER_APPS_UPDATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PUT_BLOCK_INSCAN_SETTINGS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default adminSettingsReducer;
