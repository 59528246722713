import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { ReportbasedonType } from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import WalletTypeTable from "./walletTable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TranscationTabel({
  allReportData,
  date,
  reportType,
  setDate,
  setTypeReportPgNo,
  reportTypePgNo,
  pageNumber,
  setPageNumber,
}) {
  const dispatch = useDispatch();
  const [reportFilterDialog, setReportFilterDialog] = React.useState(false);
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(25);
  const handelSubmit = (e) => {
    e.preventDefault();
    dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1,pageSize));
    setReportFilterDialog(false);
  };
  // React.useEffect(() => {
  //   dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1,pageSize));
  // }, [reportTypePgNo]);
  const handleClose = (e) => {
    setReportFilterDialog(false);
  };
  const csvLink = {
    filename: "Report",
    data: allReportData.reportBasedonType,
  };
  const pageChangeForward = () => {
    CustomConsole(pageNumber);
    if (allReportData.reportBasedonType.length > 0) {
      setPageNumber(pageNumber + 1);
      CustomConsole(pageNumber);
      setTypeReportPgNo(pageNumber + 1);
    }

    if (pageNumber - 1 >= 1) {
      setEnableBakBtn(false);
    }
  };

  const pageChangeBackward = () => {
    CustomConsole(pageNumber);
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
      setTypeReportPgNo(pageNumber - 1);
    }
    if (pageNumber - 1 <= 1) {
      setEnableBakBtn(true);
    }
  };
  const handleSearchPageSize = () => {
    dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1, pageSize));
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  return (
    <>
      <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
        <Box sx={{ margin: "1em" }}>
          <span style={{ fontSize: "1em" }}>
            <strong>All Transaction Report</strong>
          </span>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() =>
              reportFilterDialog
                ? setReportFilterDialog(false)
                : setReportFilterDialog(true)
            }
            sx={{ marginLeft: "1em" }}
          >
            Filter
          </Button>
        </Box>
        <Dialog
          open={reportFilterDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Order by
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(event) => setDate(event.target.value)}
                >
                  <FormControlLabel
                    value="DESC"
                    control={<Radio />}
                    label="Descending"
                  />
                  <FormControlLabel
                    value="ASC"
                    control={<Radio />}
                    label="Ascending"
                  />
                </RadioGroup>
              </FormControl>
              <Button variant="contained" onClick={handelSubmit}>
                Submit
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}
        >
          <Box sx={{ maxWidth: "120px", maxHeight: "10px", m: 1 }}>
            <TextField
              size="small"
              value={pageSize}
              label="Page size"
              // onChange={(e) => {
              //   setPageSize(e.target.value);
              //   // setSearchOrderFlag(true);
              // }}

              InputProps={{
                maxLength: 10, // Optional: You can set the maximum length if needed
                onInput: handleChangePageSize, // Attach the input event handler
                endAdornment: (
                  <Material.InputAdornment position="end">
                    <MuiIcons.Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleSearchPageSize}
                    />
                  </Material.InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // gap: "15px",
              // padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageChangeBackward}
                // disabled={enableBakBtn}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                // marginLeft: "10px",
                // marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageChangeForward}
                disabled={enableForBtn}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
          <Box sx={{ m: 2}}>
            <CSVLink {...csvLink}>Download</CSVLink>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Order Id</StyledTableCell>
                <StyledTableCell>Transaction Id</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Payment Method</StyledTableCell>
                <StyledTableCell>Payment Type</StyledTableCell>
                <StyledTableCell>Payment Status</StyledTableCell>
                <StyledTableCell>Reason</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allReportData.reportBasedonType.map((row) => (
                <StyledTableRow key={row.order_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.order_id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.transaction_id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.transaction_ts}
                  </StyledTableCell>
                  <StyledTableCell>{row.amount}</StyledTableCell>
                  <StyledTableCell>{row.payment_method}</StyledTableCell>
                  <StyledTableCell>{row.payment_type}</StyledTableCell>
                  <StyledTableCell>{row.payment_status}</StyledTableCell>
                  <StyledTableCell>{row.reason}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
