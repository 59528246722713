import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import { Typography, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { ReportbasedonType } from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaidCancelTable({
  allReportData,
  date,
  reportType,
  setDate,
  setTypeReportPgNo,
  reportTypePgNo,
  pageNumber,
  setPageNumber,
}) {
  const dispatch = useDispatch();
  const [reportFilterDialog, setReportFilterDialog] = React.useState(false);
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(25);
  // const handelSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(ReportbasedonType(reportType, date));
  //   setReportFilterDialog(false);
  // };
  // const handleClose = (e) => {
  //   setReportFilterDialog(false);
  // };
  const csvLink = {
    filename: "Report",
    data: allReportData.reportBasedonType,
  };
  // CustomConsole(reportType);
  // React.useEffect(() => {
  //   dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1, pageSize));
  // }, [reportTypePgNo]);
  const pageChangeForward = () => {
    CustomConsole(pageNumber);
    if (allReportData.reportBasedonType.length > 0) {
      setPageNumber(pageNumber + 1);
      CustomConsole(pageNumber);
      setTypeReportPgNo(pageNumber + 1);
    }

    if (pageNumber - 1 >= 1) {
      setEnableBakBtn(false);
    }
  };

  const pageChangeBackward = () => {
    CustomConsole(pageNumber);
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
      setTypeReportPgNo(pageNumber - 1);
    }
    if (pageNumber - 1 <= 1) {
      setEnableBakBtn(true);
    }
  };
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD-MM-YYYY");
    return momentEndDate;
  };
  const handleSearchPageSize = () => {
    dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1, pageSize));
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };
  return (
    <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
      {reportType == 1001 || reportType == 1002 || reportType == 1004 || reportType == 1006 || reportType == 1007  ? (
        <>
          <Box sx={{ margin: "1em" }}>
            <span style={{ fontSize: "1em" }}>
              <strong>
                {reportType == 1001
                  ? "Financial Year paid but cancelled Table"
                  : "Paid but cancelled table"}
              </strong>
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ maxWidth: "120px", maxHeight: "10px", mt: 2 }}>
              <TextField
                size="small"
                value={pageSize}
                label="Page size"
                // onChange={(e) => {
                //   setPageSize(e.target.value);
                //   // setSearchOrderFlag(true);
                // }}

                InputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleChangePageSize, // Attach the input event handler
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <MuiIcons.Search
                        cursor="pointer"
                        size="30px"
                        onClick={handleSearchPageSize}
                      />
                    </Material.InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeBackward}
                  // disabled={enableBakBtn}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeForward}
                  disabled={enableForBtn}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box>
            <Box>
              <CSVLink {...csvLink}>Download</CSVLink>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Order Id</StyledTableCell>
                  <StyledTableCell>Consumer Name</StyledTableCell>
                  <StyledTableCell>Supplier Name</StyledTableCell>
                  <StyledTableCell>Ordered Date</StyledTableCell>
                  <StyledTableCell>Delivery Date</StyledTableCell>
                  <StyledTableCell>Order type</StyledTableCell>
                  <StyledTableCell>Tanker type</StyledTableCell>
                  <StyledTableCell>Order status</StyledTableCell>
                  <StyledTableCell>Payment status</StyledTableCell>
                  <StyledTableCell>Payment method</StyledTableCell>
                  <StyledTableCell>Order price</StyledTableCell>
                  <StyledTableCell>Discount</StyledTableCell>
                  <StyledTableCell>Conv fee</StyledTableCell>
                  <StyledTableCell>Gateway fee</StyledTableCell>
                  <StyledTableCell>Transaction ts</StyledTableCell>
                  <StyledTableCell>Final price</StyledTableCell>
                  <StyledTableCell>Transaction id</StyledTableCell>
                  <StyledTableCell>Transaction type</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReportData.reportBasedonType.map((row) => (
                  <StyledTableRow key={row.order_id}>
                    <StyledTableCell component="th" scope="row">
                      {row.order_id}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.consumer_name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.supplier_name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {endDateConverter(row.start_date)}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {endDateConverter(row.end_date)}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.order_type}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.tanker_type}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.order_status}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.payment_status}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.payment_method}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.order_price}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.discount}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.conv_fee}
                    </StyledTableCell>
                    <StyledTableCell>{row.gateway_fee}</StyledTableCell>
                    <StyledTableCell>{row.transaction_ts}</StyledTableCell>
                    <StyledTableCell>{row.final_price}</StyledTableCell>
                    <StyledTableCell>{row.transaction_id}</StyledTableCell>
                    <StyledTableCell>{row.transaction_type}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </>
      ) : (
        " "
      )}
    </Box>
  );
}
