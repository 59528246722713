import React, { useEffect, useState } from "react";
// import * as Material from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Refresh from "@mui/icons-material/Refresh";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomConsole from "../../CustomConsole";
import FilledCanIcon from "../../Images/FilledCanIcon.svg";
import DocumentScanner from "@mui/icons-material/DocumentScanner";
import WaterCanImg from "../../Images/WaterCanImg.png";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import * as MuiIcons from "@mui/icons-material";
import Rotate90DegreesCcw from "@mui/icons-material/Rotate90DegreesCcw";
import FilledGlassBottleIcon2 from "../../Images/FilledGlassBottleIcon2.webp";

import Place from "@mui/icons-material/Place";
import glasbottle from "../../Images/glassbottle.jpg";
import EmptyGlassBottle from "../../Images/EmptyGlassBottle.jpg";
import {
  ClearDataRefersh,
  CrateWithdarawalRequest,
  CreateWithdarawalRequest,
  ExtraEmpty,
  ExtraEmptyCallBack,
  GetAssignDeliveryAgent,
  GetCanAgeDetails,
  GetCanAgeDetailsSendEmail,
  GetQrBarcodeDetails,
  InscanQRBarcode,
  LinkProductsOrderToOrder,
  OutScanQRBarcode,
  PostSupplierAssignDeliveryAgent,
  PostSupplierProductInscan,
  PostSupplierProductQrLessOutScan,
  PostSupplierRollBack,
  PutSupplierCurrentDayCancelOtp,
  PutSupplierCurrentDayCompleteDeliveries,
  PutSupplierCurrentDayVerifyOtp,
} from "../Redux/Actions/supliersAction";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { GetViewOrderDetails } from "../Redux/Actions/ordersAdminAction";
import CurrentDayDeliveriesEnterOtpDialog from "../../Components/DialogBoxes/SupplierDialogBox/CurrentDayDeliveriesEnterOtpDialog";
import CameraDialog from "../../Components/DialogBoxes/SupplierDialogBox/CameraDialog";
import AssignDeliveryAgentDialog from "../../Components/DialogBoxes/SupplierDialogBox/AssignDeliveryAgentDialog";
import RollbackCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/RollbackCansDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import {
  CheckCircle,
  DoneOutline,
  DriveFileRenameOutline,
  HighlightOff,
  Info,
  KeyboardReturn,
  ProductionQuantityLimits,
  QrCodeScanner,
} from "@mui/icons-material";
import CanIcon from "../../Images/CanIcon.svg";
import {
  ClearFilesData,
  UploadFile,
} from "../Redux/Actions/fileHandlingAction";
import CryptoJS from "crypto-js";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import DeliveriesScanInValidCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/DeliveriesScanInValidCansDialog";
import RequestForScanInValidCans from "../../Components/DialogBoxes/SupplierDialogBox/RequestForScanInValidCans";
import ApprovedOrRejectedCansCanSwapDialog from "../../Components/DialogBoxes/SupplierDialogBox/ApprovedOrRejectedCansCanSwapDialog";
import { GetQrBarcodeSwapList } from "../Redux/Actions/canSwapingAction";
import CanSwapOrderRequestDetailsDialog from "../../Components/DialogBoxes/CanSwapOrderRequestDetailsDialog";
import { OutScanFilledCans } from "../Redux/Actions/adminQrBarcodeAction";
import QrLessDialog from "./QrLessDialog";
import OutScanQrProductsCameraDialog from "./OutScanQrProductsCameraDialog";
import ProductsInScanDialog from "./ProductsInScanDialog";
import useProductImages from "../../Components/Custom/useProductImages";
import ImageLoader from "./ImageLoader";
import CustomLoaderDialog from "../../Components/Dialog/CustomLoaderDialog";
import WithdrawalRequestBehalfConsumerDialog from "./WithdrawalRequestBehalfConsumerDialog";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Compressor from "compressorjs";
import StockDetailsDialog from "./StockDetailsDialog";
import MarkingDamage from "../../Components/DialogBoxes/SupplierDialogBox/MarkingDamage";
import LinkOrderToOrderScanningProcessDialog from "../../Components/Dialog/LinkOrderToOrderScanningProcessDialog";
import ExtraEmptyReturn from "./ExtraEmptyReturn";
import { Add, Remove } from "@mui/icons-material";

function CurrentDayDeliveriesPerticularDetails() {
  const SECRET_KEY = "mysecretkey";
  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [
    openOutScanQrProductsCameraDialog,
    setOpenOutScanQrProductsCameraDialog,
  ] = React.useState(false);
  const [openProgressBar, setOpenProgressBar] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  console.log(scannedCount);
  const [qrlessData, setQrlessData] = React.useState("");
  const [productData, setProductData] = React.useState({});
  CustomConsole(qrlessData);
  const [openDamageDialog, setOpenDamageDialog] = useState();
  const [editingProduct, setEditingProduct] = useState();
  const [openQrlessDialog, setOpenQrlessDialog] = React.useState(false);
  const [material, setMaterial] = React.useState("");
  const [capcity, setCapcity] = React.useState("");
  const [productorderDetails, setProductorderDetails] = React.useState({});

  const [openProductsInScanDialog, setOpenProductsInScanDialog] =
    React.useState(false);
  const [submitOtpBtn, setSubmitOtpBtn] = React.useState(false);
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [deliveryData, setDeliveryData] = React.useState([]);
  const [openScanInValidCsnsDialog, setOpenScanInValidCsnsDialog] =
    React.useState(false);
  const [productDetails, setProductDetails] = React.useState([]);
  CustomConsole(productDetails);
  const [alignmentCustomers, setAlignmentCustomers] =
    React.useState("GenralCustomers");
  const [openQrBarCodeCamera, setOpenQrBarCodeCamera] = React.useState(false);
  const [orderIdStartingLetters, setOrderIdStartingLetters] =
    React.useState("");
  const [openOrderToOrderScanningDialog, setOpenOrderToOrderScanningDialog] =
    React.useState("");
  const [openWithdrawalRequestDialog, setOpenWithdrawalRequestDialog] =
    React.useState(false);
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [openOutScanCamera, setOpenOutScanCamera] = React.useState(false);
  const [openRollBackDialog, setOpenRollBackDialog] = React.useState(false);
  const [outScanCount, setOutScanCount] = React.useState(0);
  const [scanOrderId, setScanOrderId] = React.useState("");
  const [addressId, setAddressId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [outScanBtn, setOutScanBtn] = React.useState(false);
  const [inScanBtn, setInScanBtn] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAssignDeliveryAgentDialog, setOpenAssignDeliveryAgentDialog] =
    React.useState(false);
  const [assignDeliveryAgentBtn, setAssignDeliveryAgentBtn] =
    React.useState(false);
  const [uploadDriverChallanFile, setUploadDriverChallanFile] =
    React.useState("");
  const [uploadDriverChallanBtn, setUploadDriverChallanBtn] =
    React.useState(false);
  const [driverChallanUrl, setDriverChallanUrl] = React.useState("");
  CustomConsole(driverChallanUrl);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [openApprovedRejectedCans, setOpenApprovedRejectedCans] =
    React.useState(false);

  // const [currentScannedCodes, setCurrentScannedCodes] = React.useState([]);
  const [scannedCurrent, setScannedCurrent] = React.useState([]);
  const [inScannedCodes, setInScannedCodes] = React.useState([]);
  const [cameraScannedCodes, setCameraScannedCodes] = React.useState([]);

  const [outScannedCodes, setOutScannedCodes] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [isScaningCompleted, setIsScaningCompleted] = React.useState(false);
  const [inScannedNo, setInScannedNo] = React.useState("");
  const [outScannedNo, setOutScannedNo] = React.useState("");
  const [expiredCansWithConsumer, setExpiredCansWithConsumer] =
    React.useState(0);
  const [nonExpiredCans, setNonExpiredCans] = React.useState(0);
  const [isInScan, setIsInScan] = React.useState(false);
  CustomConsole("Live scan");
  CustomConsole(localStorage.getItem("current_scanned_codes"));
  // const initialScannedCodes = localStorage.getItem("current_scanned_codes");
  const [data, setData] = React.useState({});
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [openExtraRet, setOpenExtraRet] = React.useState(false);
  const [extraReturnObj, setExtraObj] = React.useState({
    order_id: "",
    capacity: "",
    material: "",
    quantity: 0,
    is_crate: false,
  });
  const [extraReturn, setExtraReturn] = React.useState(0);
  const [extraReturnProducts, setExtraReturnProducts] = useState();
  const [minReturn, setMinReturn] = React.useState(0);

  // const defaultOrderId = data.order_id; // You should dynamically fetch this if possible

  // const [currentScanned, setCurrentScanned] = React.useState(() =>
  //   localStorage.getItem("current_scanned_codes") &&
  //   localStorage.getItem("current_scanned_codes").length
  //     ? JSON.parse(localStorage.getItem("current_scanned_codes"))
  //     : []
  // );
  // console.log(initialScannedCodes);
  // console.log("initialScannedCodes");
  // const [currentScanned, setCurrentScanned] = React.useState(() => {
  //   if (initialScannedCodes && initialScannedCodes.length) {
  //     const scannedArray = JSON.parse(initialScannedCodes);
  //     return { [defaultOrderId]: scannedArray };
  //   }
  //   return {};
  // });
  const [currentScanned, setCurrentScanned] = React.useState([]);
  const [currentOrderId, setCurrentOrderId] = React.useState("");

  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);

  React.useEffect(() => {
    const loadData = () => {
      //@Removed/console.log("Change in order id");
      // Assume `props.orderId` is the current order ID we need to check against
      const storedData = localStorage.getItem("current_scanned_codes");
      const data = storedData ? JSON.parse(storedData) : {};

      //@Removed/console.log(data.order_id);
      //@Removed/console.log(data);
      //@Removed/console.log(data[data.order_id]);
      // Check if the current order ID from props matches the one stored in local storage
      if (data && data[data.order_id]) {
        //@Removed/console.log("matching");
        setCurrentScanned(data[data.order_id]);
        // setCurrentOrderId(data.order_id);
      } else {
        // If not matching or not existing, reset the scanned codes
        //@Removed/console.log("not matching");
        setCurrentScanned([]);
        // localStorage.setItem("current_scanned_codes", {});
        if (localStorage.getItem("current_scanned_codes")) {
          localStorage.removeItem("current_scanned_codes");
        }

        // setCurrentOrderId(data.order_id);
      }
    };

    loadData();
  }, [data.order_id]);
  const [availableForSwap, setAvailableForSwap] = React.useState(() =>
    localStorage.getItem("available_for_swap") &&
    localStorage.getItem("available_for_swap").length
      ? JSON.parse(localStorage.getItem("available_for_swap"))
      : []
  );
  const [isProperScan, setIsProperScan] = React.useState(true);

  // Checking for internet connection
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    //@Removed/console.log("isOnline");
    //@Removed/console.log(isOnline);
  }, [isOnline]);

  const history = useHistory();
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const orderDetailsData = useSelector((state) => state.orderDetailsAdmin);
  const fileHandler = useSelector((state) => state.fileHandler);
  const canSwapDeatails = useSelector((state) => state.canSwapingDetails);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");

  const uploadDriverCgallan = React.useRef(null);

  const handleUploadDriverDriverChallan = () => {
    uploadDriverCgallan.current.click();
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  React.useEffect(() => {}, [scannedCount]);

  const [userValidScanned, setUserValidScanned] = React.useState([]);
  const [otherValidScanned, setOtherValidScanned] = React.useState([]);
  const [inValidScanned, setInValidScanned] = React.useState([]);
  React.useEffect(() => {
    CustomConsole(supplierDeliveries);
    CustomConsole("----------BeforeAssignDriver-------------------");
    setAddressId(orderDetailsData.orderDetails?.delivery_address?.address_id);
    setUserId(orderDetailsData.orderDetails?.delivery_address?.user_id);
    if (
      supplierDeliveries?.qrBarcodeDetails !== undefined &&
      supplierDeliveries?.qrBarcodeDetails !== null
    ) {
      if (Object.keys(supplierDeliveries?.qrBarcodeDetails).length > 0) {
        CustomConsole("------Successfully got QR barcode Details--------");
        CustomConsole(supplierDeliveries?.qrBarcodeDetails);

        // Storing in local storage

        // const encrypted = CryptoJS.AES.encrypt(
        //   supplierDeliveries?.qrBarcodeDetails?.scanned_for_order?.toString(),
        //   SECRET_KEY
        // ).toString();
        // localStorage.setItem("scanned_for_order", encrypted);

        // localStorage.setItem("current_scanned_codes", []);
        const sfo = supplierDeliveries.qrBarcodeDetails.scanned_for_order
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.scanned_for_order
            )
          : [];
        const cc = supplierDeliveries.qrBarcodeDetails.consumer_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.consumer_containers
            )
          : [];
        const dfc = supplierDeliveries.qrBarcodeDetails
          .distributor_filled_containers
          ? JSON.stringify(
              supplierDeliveries.qrBarcodeDetails.distributor_filled_containers
            )
          : [];
        localStorage.setItem("scanned_for_order", sfo);
        localStorage.setItem("consumer_containers", cc);
        localStorage.setItem("distributor_filled_containers", dfc);
      }
    }
    if (
      !supplierDeliveries.assigDeliveryAgenterror ||
      !supplierDeliveries.errorCurrentDeliveryVerifyOtp ||
      !supplierDeliveries.errorCurrentDeliveriesInScan
    ) {
      CustomConsole("---View order details API call here----");
      dispatch(GetViewOrderDetails(orderId));
      // dispatch(GetQrBarcodeDetails(orderId));
      if (assignDeliveryAgentBtn === true) {
        setAssignDeliveryAgentBtn(false);
      }
      if (submitOtpBtn === true) {
        setSubmitOtpBtn(false);
        setIsProductScanCompleted(false);
        handleCloseDialog();
      }
      if (outScanBtn === true) {
        handleCloseCameraDialog();
        setOutScanBtn(false);
      }
      if (inScanBtn === true) {
        handleCloseCameraDialog();
        setInScanBtn(false);
      }
    }

    // setting uservalid and otherValid

    if (supplierDeliveries?.validatedData) {
      //@Removed/console.log("validatedData");
      //@Removed/console.log(supplierDeliveries.validatedData);
      setUserValidScanned(
        supplierDeliveries.validatedData?.qr_details?.user_valid
      );
      setOtherValidScanned(
        supplierDeliveries.validatedData?.qr_details?.other_valid
      );
      setInValidScanned(supplierDeliveries.validatedData?.qr_details?.invalid);
    }
  }, [
    supplierDeliveries,
    assignDeliveryAgentBtn,
    submitOtpBtn,
    outScanBtn,
    inScanBtn,
  ]);

  React.useEffect(() => {
    if (supplierDeliveries?.getCanAgeDetails) {
      //@Removed/console.log(supplierDeliveries?.getCanAgeDetails);
      setExpiredCansWithConsumer(
        supplierDeliveries?.getCanAgeDetails?.expired_Cans_With_Consumer
      );
      setNonExpiredCans(supplierDeliveries?.getCanAgeDetails?.non_expired_cans);
    }
  }, [supplierDeliveries.getCanAgeDetails]);

  React.useEffect(() => {
    // Retrieve the arrays from local storage
    const scannedForOrder =
      localStorage.getItem("scanned_for_order") &&
      localStorage.getItem("scanned_for_order").length
        ? JSON.parse(localStorage.getItem("scanned_for_order"))
        : [];
    const consumerContainers =
      localStorage.getItem("consumer_containers") &&
      localStorage.getItem("consumer_containers").length
        ? JSON.parse(localStorage.getItem("consumer_containers"))
        : [];
    const distributorFilledContainers =
      localStorage.getItem("distributor_filled_containers") &&
      localStorage.getItem("distributor_filled_containers").length
        ? JSON.parse(localStorage.getItem("distributor_filled_containers"))
        : [];
    CustomConsole("-------Retrived from local storage-------");
    CustomConsole(scannedForOrder);
    CustomConsole(consumerContainers);
    CustomConsole(distributorFilledContainers);
  }, []);
  React.useEffect(() => {
    if (uploadDriverChallanFile !== "") {
      // dispatch(UploadFile(uploadDriverChallanFile));
      new Compressor(uploadDriverChallanFile, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          //@Removed/console.log(uploadDriverChallanFile);
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          //@Removed/console.log(compressedResult);
          //@Removed/console.log(compressedResult.name);
          dispatch(UploadFile(compressedResult));
          setUploadDriverChallanBtn(true);
          setUploadDriverChallanFile("");
          setUploadFlags(true);
        },
      });
    }
  }, [uploadDriverChallanFile]);
  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (
      fileHandler.isFileUploaded === true &&
      uploadDriverChallanBtn === true
    ) {
      CustomConsole("Delivery Challan file uploaded file here");
      setDriverChallanUrl(fileHandler.fileName);
      setUploadDriverChallanBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Delivery Challan uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);
  const [userLocation, setUserLocation] = React.useState({
    latitude: "",
    longitude: "",
  });

  const [deliverAgentName, setDeliverAgentName] = React.useState("");
  const [orderProducts, setOrderProducts] = React.useState([]);
  const [extraReturnQty, setExtraReturnQty] = useState([]);
  const [isOrderStarted, setIsOrderStarted] = React.useState(false);
  const [openStockDetailsDialog, setOpenStockDetailsDialog] =
    React.useState(false);
  const [isProductScanCompleted, setIsProductScanCompleted] =
    React.useState(false);

  const { images, isLoading, error } = useProductImages(
    orderDetailsData?.orderDetails
  );
  //click on Start button scroll to scaning products
  const scrollDownRef = React.useRef(null);
  const scrollDownToCard = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {}, [deliverAgentName]);
  React.useEffect(() => {
    if (
      orderDetailsData.orderDetails &&
      orderDetailsData.orderDetails.order_id &&
      orderId === orderDetailsData.orderDetails.order_id
    ) {
      setCurrentOrderId(orderDetailsData.orderDetails.order_id);
      setData(orderDetailsData.orderDetails);
      if (Object.keys(orderDetailsData.orderDetails).length) {
        CustomConsole("--- orderDetailsData.orderDetails ---");
        CustomConsole(orderDetailsData.orderDetails);
        setAddressId(
          orderDetailsData.orderDetails?.delivery_address?.address_id
        );
        setUserId(orderDetailsData.orderDetails?.delivery_address?.user_id);
        const orderType =
          orderDetailsData.orderDetails.payment_type === 300
            ? "POSTPAID"
            : orderDetailsData.orderDetails.packaged_drinking_water
                .order_type === 700
            ? "INSTA"
            : orderDetailsData.orderDetails.packaged_drinking_water
                .is_subscribed_order === true
            ? "SUBSCRIBED"
            : "REGULAR";
        const deliverySlot = orderDetailsData.orderDetails
          .packaged_drinking_water.delivery_slots
          ? orderDetailsData.orderDetails.packaged_drinking_water.delivery_slots
              .admin_selected_delivery_slot &&
            orderDetailsData.orderDetails.packaged_drinking_water.delivery_slots
              .admin_selected_delivery_slot.length
            ? orderDetailsData.orderDetails.packaged_drinking_water
                .delivery_slots.admin_selected_delivery_slot
            : orderDetailsData.orderDetails.packaged_drinking_water
                .delivery_slots.supplier_selected_delivery_slot &&
              orderDetailsData.orderDetails.packaged_drinking_water
                .delivery_slots.supplier_selected_delivery_slot.length
            ? orderDetailsData.orderDetails.packaged_drinking_water
                .delivery_slots.supplier_selected_delivery_slot
            : "-"
          : "-";
        let data = [
          { type: "Order id", payload: orderDetailsData.orderDetails.order_id },
          {
            type: "Order qty",
            payload:
              orderDetailsData.orderDetails.packaged_drinking_water
                .twentyltr_capacity_qty,
          },
          {
            type: "Return qty",
            payload:
              orderDetailsData.orderDetails.packaged_drinking_water
                .empty_container_qty,
          },
          { type: "Order type", payload: orderType },
          {
            type: "Order price",
            payload:
              orderDetailsData.orderDetails.packaged_drinking_water
                .twenty_ltr_capacity_totamt,
          },
          {
            type: "Delivery slot",
            payload: deliverySlot,
          },
          {
            type: "Customer name",
            payload:
              orderDetailsData.orderDetails.delivery_address.contact_name,
          },
          {
            type: "Contact phone",
            payload:
              orderDetailsData.orderDetails.delivery_address.contact_phone,
          },
          {
            type: "Delivery address",
            payload: `${orderDetailsData.orderDetails.delivery_address.address_line1}, 
          ${orderDetailsData.orderDetails.delivery_address.address_line2},
          ${orderDetailsData.orderDetails.delivery_address.address_line3}`,
          },
          {
            type: "Pincode",
            payload: orderDetailsData.orderDetails.delivery_address.pincode,
          },
          {
            type: "Service area",
            payload:
              orderDetailsData.orderDetails.delivery_address.service_area,
          },
          {
            type: "Received empty qty",
            payload:
              orderDetailsData.orderDetails.packaged_drinking_water
                .received_empty_cans,
          },
          {
            type: "Provided filled qty",
            payload:
              orderDetailsData.orderDetails.packaged_drinking_water
                .submitted_filled_cans,
          },

          {
            type: "Location",
            payload: (
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
                onClick={() => handleCopyLocation()}
              >
                {orderDetailsData.orderDetails.delivery_address.latitude},
                {orderDetailsData.orderDetails.delivery_address.longitude}
              </Typography>
            ),
          },
        ];
        setUserLocation({
          latitude: orderDetailsData.orderDetails.delivery_address.latitude,
          longitude: orderDetailsData.orderDetails.delivery_address.longitude,
        });
        setDeliveryData(data);
        setOpenProgressBar(false);
      } else {
        setDeliveryData([]);
        setOpenProgressBar(true);
      }
      CustomConsole("--------------setProductDetails--------------------");
      if (
        orderDetailsData.orderDetails?.packaged_drinking_water?.is_invalid ===
        false
      ) {
        CustomConsole("--------------setProductDetails--------------------");
        const data = [{ orderdetails: orderDetailsData.orderDetails }];
        // data.push({pdw:orderDetails.packaged_drinking_water})
        setProductDetails(data);
      }

      CustomConsole("--------------Product Details--------------------");
      CustomConsole(scannedCount);
      let localProducts = [];
      let isScaning = 0;
      let productsData = [];
      let scannedProductCount = 0;
      let isOrderStartedStatus = orderDetailsData.orderDetails
        ?.packaged_drinking_water?.is_order_started
        ? true
        : false;
      let isinscandisabled =
        orderDetailsData?.orderDetails?.packaged_drinking_water
          ?.is_inscan_disabled !== undefined &&
        orderDetailsData?.orderDetails?.packaged_drinking_water
          ?.is_inscan_disabled !== null
          ? orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_inscan_disabled
          : true;
      let oustScanCount = scannedCount;
      let order_statuss = orderDetailsData.orderDetails.order_status;
      CustomConsole("isOrderStartedStatus: " + isOrderStartedStatus);
      CustomConsole(orderDetailsData.orderDetails);
      if (!orderDetailsData.orderDetails?.packaged_drinking_water?.is_invalid) {
        productsData.push({
          out_scan_count: oustScanCount,
          order_status: order_statuss,
          is_inscan_disabled: isinscandisabled,
          capacity:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.selected_container_capacity,
          image: orderDetailsData.orderDetails?.packaged_drinking_water?.image,
          name: orderDetailsData.orderDetails?.packaged_drinking_water?.name,
          material:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.selected_container_material,
          is_qr: true,
          booking_type:
            orderDetailsData.orderDetails?.packaged_drinking_water
              ?.booking_type,
          order_qty:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.twentyltr_capacity_qty,
          return:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.empty_container_qty,
          received_empty: orderDetailsData?.orderDetails
            ?.packaged_drinking_water?.received_empty_cans
            ? orderDetailsData?.orderDetails?.packaged_drinking_water
                ?.received_empty_cans
            : 0,
          submitted_filled: orderDetailsData?.orderDetails
            ?.packaged_drinking_water?.submitted_filled_cans
            ? orderDetailsData?.orderDetails?.packaged_drinking_water
                ?.submitted_filled_cans
            : 0,
          sku_qty: 1,
          is_invalid: data.packaged_drinking_water?.is_invalid,
          order_price:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.twenty_ltr_capacity_totamt,
          isInScanCompleted:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_inscan_completed,
          isOutScanCompleted:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_outscan_completed,
          is_inscan_disabled:
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_inscan_disabled !== undefined &&
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_inscan_disabled !== null
              ? orderDetailsData?.orderDetails?.packaged_drinking_water
                  ?.is_inscan_disabled
              : true,
          in_scan:
            isOrderStartedStatus &&
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_inscan_completed === false &&
            isinscandisabled === false,
          // scan_type:
          //   isOrderStartedStatus &&
          //   orderDetailsData?.orderDetails?.packaged_drinking_water
          //     ?.is_inscan_completed == false
          //     ? 1
          //     : isOrderStartedStatus &&
          //       orderDetailsData?.orderDetails?.packaged_drinking_water
          //         ?.is_inscan_completed == true &&
          //       orderDetailsData?.orderDetails?.packaged_drinking_water
          //         ?.is_outscan_completed == false
          //     ? 2
          //     : 0,
          scan_type:
            isOrderStartedStatus &&
            orderDetailsData?.orderDetails?.packaged_drinking_water
              ?.is_outscan_completed == false
              ? 2
              : 0,
        });
        if (
          orderDetailsData?.orderDetails?.packaged_drinking_water
            ?.is_inscan_completed &&
          orderDetailsData?.orderDetails?.packaged_drinking_water
            ?.is_outscan_completed
        ) {
          scannedProductCount = scannedProductCount + 1;
        }
        localProducts.push({ data: orderDetailsData.orderDetails });
        CustomConsole("-----------localProducts-------------------");
        CustomConsole(localProducts);
        if (
          localProducts?.packaged_drinking_water?.is_inscan_completed ===
            true &&
          localProducts?.packaged_drinking_water?.is_outscan_completed === true
        ) {
          isScaning = 1;
        }
      } else {
        isScaning = 1;
      }
      if (orderDetailsData?.orderDetails?.products?.length > 0) {
        for (const data of orderDetailsData?.orderDetails?.products) {
          CustomConsole("--- data ---");
          CustomConsole(data);
          const orderQty = data.quantity * data.sku_qty;
          const retQty = data.return;
          const isInscan = retQty > data.consumer_submitted_empty;
          const isOutScan = orderQty > data.consumer_received_filled;
          CustomConsole("orderQty: " + orderQty);
          CustomConsole("retQty: " + retQty);
          CustomConsole("isInScan: " + isInScan);
          CustomConsole("isOutScan: " + isOutScan);
          CustomConsole(
            "data.consumer_submitted_empty: " + data.consumer_submitted_empty
          );
          CustomConsole(
            "data.consumer_received_filled: " + data.consumer_received_filled
          );
          productsData.push({
            capacity: data.capacity,
            material: data.material,
            is_qr: data.is_qr,
            order_qty: data.quantity,
            name: data.name,
            booking_type: data.booking_type,
            return: data.return,
            is_crate: data.is_crate,
            image: data.image,
            is_invalid: true,
            received_empty: data.consumer_submitted_empty
              ? data.consumer_submitted_empty
              : 0,
            submitted_filled: data.consumer_received_filled
              ? data.consumer_received_filled
              : 0,
            sku_qty: data.sku_qty,
            order_price: data.product_price,
            is_inscan_disabled: isinscandisabled,
            order_status: order_statuss,
            out_scan_count: oustScanCount,
            in_scan:
              isOrderStartedStatus && isInscan && isinscandisabled === false,
            // scan_type:
            //   isOrderStartedStatus && isInscan
            //     ? 1
            //     : isOrderStartedStatus && !isInscan && isOutScan
            //     ? 2
            //     : 0,
            scan_type: isOrderStartedStatus && isOutScan ? 2 : 0,
          });
          if (
            orderQty === data.consumer_received_filled &&
            retQty === data.consumer_submitted_empty
          ) {
            scannedProductCount = scannedProductCount + 1;
          }
          localProducts.push({
            data: data,
          });
        }
        if (
          localProducts.return * localProducts.sku_qty !==
            localProducts.consumer_submitted_empty &&
          localProducts.quantity * localProducts.sku_qty !==
            localProducts.consumer_received_filled
        ) {
          isScaning = isScaning + 1;
        }
      }
      CustomConsole(localProducts);
      if (localProducts === isScaning) {
        setIsScaningCompleted(true);
      }
      if (scannedProductCount === productsData.length) {
        setIsProductScanCompleted(true);
      }
      CustomConsole("productsData: ===");
      CustomConsole(productsData);
      setOrderProducts(productsData);
      setExtraReturnProducts(
        orderDetailsData?.orderDetails?.extra_return_products || []
      );
      setEditingProduct(null);
      setProducts(localProducts);
    }
    // setOrderProducts,setIsProductScanCompleted
  }, [orderDetailsData.orderDetails, scannedCount]);

  React.useEffect(() => {
    CustomConsole(orderId);
    // Hit tghe API for the Order ID and Get the Data
    dispatch(GetViewOrderDetails(orderId));
    // dispatch(GetQrBarcodeDetails(orderId));
    // setData(orderDetailsData.orderDetails);
  }, [orderId]);

  React.useEffect(() => {
    if (data.delivery_address?.user_id && data.delivery_address?.address_id) {
      dispatch(
        GetCanAgeDetails({
          address_id: data.delivery_address?.address_id,
          user_id: data.delivery_address?.user_id,
        })
      );
    }
  }, [data.delivery_address?.user_id, data.delivery_address?.address_id]);

  React.useEffect(() => {
    if (!supplierDeliveries.linkOrderToOrdererror) {
      dispatch(GetViewOrderDetails(orderId));
      dispatch(ClearDataRefersh());
    }
  }, [supplierDeliveries.linkOrderToOrdererror]);

  const handleCloseAddress = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseCameraDialog = () => {
    CustomConsole("---handleCloseDialog---");
    // setOpenCameraDialog(false);
  };

  const handleCloseDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenEnterOtpDialog(false);
  };
  React.useEffect(() => {
    if (navigator.geolocation) {
      CustomConsole("--- Getting the Current Location of the User ---");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          CustomConsole(position);
          // lat = position.coords.latitude;
          // longit = position.coords.longitude;
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  React.useEffect(() => {
    CustomConsole(isProductScanCompleted);
  }, [isProductScanCompleted]);

  const handleOpenEnterOtpDialog = (event) => {
    CustomConsole("Button name");
    setIsProperScan(true);
    const buttonName = event.currentTarget.innerText;
    if (buttonName === "IN-SCAN") {
      setIsInScan(true);
    } else {
      setIsInScan(false);
    }
    if (
      data.packaged_drinking_water !== undefined &&
      data.packaged_drinking_water.delivery_slots !== undefined &&
      data.packaged_drinking_water.delivery_otp_status !== 200 &&
      data.packaged_drinking_water !== undefined &&
      data.packaged_drinking_water.delivery_slots !== undefined &&
      data.packaged_drinking_water.is_order_started === false
    ) {
      CustomConsole("----Enter OTP----");
      setOpenEnterOtpDialog(true);
      //   setSubmitOtpBtn(true);
    } else if (
      data.packaged_drinking_water !== undefined &&
      data.packaged_drinking_water.delivery_slots !== undefined &&
      data.packaged_drinking_water.delivery_otp_status === 200 &&
      data.packaged_drinking_water !== undefined &&
      data.packaged_drinking_water.delivery_slots !== undefined &&
      data.packaged_drinking_water.is_order_started === false
    ) {
      CustomConsole("----OTPLessDelivery----");

      const reqObj = {
        delivery_id: data.delivery_id,
        delivery_otp: data.deliveryOtp,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setSubmitOtpBtn(true);
      // setTimeout(() => {
      scrollDownToCard();
      // }, 500);
    } else {
      CustomConsole("Complete Delivery");
      // let lat = 0;
      // let longit = 0;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            CustomConsole(position);
            // lat = position.coords.latitude;
            // longit = position.coords.longitude;
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
      CustomConsole("latitude:" + latitude);
      CustomConsole("longitude:" + longitude);
      // const reqObj = {
      //   delivery_otp: data.delivery_otp,
      //   delivery_id: data.delivery_id,
      //   driver_challan: fileHandler?.fileName || "",
      //   latitude: latitude,
      //   longitude: longitude,
      // };
      const reqObj = {
        delivery_id: data.delivery_id,
        delivery_challan: fileHandler?.fileName || "",
        latitude: latitude || undefined,
        longitude: longitude || undefined,
      };

      CustomConsole(reqObj);

      dispatch(PutSupplierCurrentDayCompleteDeliveries(reqObj));
      setTimeout(() => {
        localStorage.removeItem("current_scanned_codes");
        localStorage.removeItem("consumer_containers");
        localStorage.removeItem("distributor_filled_containers");
      }, 1000);
      setTimeout(() => {
        dispatch(GetViewOrderDetails(orderId));
      }, 500);
    }
  };

  const refreshOrderDetails = () => {
    dispatch(GetViewOrderDetails(orderId));
    toast.dark("Order details refreshed!", {
      position: "bottom-right",
      autoClose: 2000,
      type: "success",
    });
  };

  const handleCurrentDaySubmitOtp = () => {
    // CustomConsole(props);
    CustomConsole("---handleCurrentDaySubmitOtp---");
    setOpenEnterOtpDialog(false);
    if (enterOtpTextfied !== "") {
      const reqObj = {
        delivery_id: data.delivery_id,
        delivery_otp: enterOtpTextfied,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setEnterOtpTextfied("");
      setSubmitOtpBtn(true);
      setTimeout(() => {
        scrollDownToCard();
      }, 500);
    } else {
      toast.error("Please Enter OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleAssignDeliveryAgent = (props) => {
    CustomConsole("------handleAssignDeliveryAgent--------");
    CustomConsole(props);
    setDeliverAgentName(props.driver_profile.fullname);
    const reqObj = {
      delivery_agent_id: props.driver_id,
      delivery_id: data.delivery_id,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierAssignDeliveryAgent(reqObj));
    setAssignDeliveryAgentBtn(true);
    setOpenAssignDeliveryAgentDialog(false);
    // setSelectedDeliveryId("");
  };
  const handleCloses = () => {
    CustomConsole("---handleCloses--");
    setOpenAssignDeliveryAgentDialog(false);
    // setSelectedDeliveryId("");
  };
  const handleCopyLocation = () => {
    CustomConsole("------------handleCopyLocation--------------");
    navigator.clipboard.writeText(
      orderDetailsData.orderDetails.delivery_address.latitude +
        "," +
        orderDetailsData.orderDetails.delivery_address.longitude
    );
    // alert(
    //   orderDetailsData.orderDetails.delivery_address.latitude +
    //     "," +
    //     orderDetailsData.orderDetails.delivery_address.longitude
    // );
  };

  const handleClickYes = (props) => {
    // CustomConsole(props);
    CustomConsole("------handleClickYes--------");

    CustomConsole(props);

    const reqObj = {
      order_id: orderDetailsData?.orderDetails?.order_id,
      products: [
        {
          capacity: props.productorderDetails.capacity,
          material: props.productorderDetails.material,
          is_crate: props.productorderDetails.is_crate,
        },
      ],
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierRollBack(reqObj));
    setOpenRollBackDialog(false);
    setIsProductScanCompleted(false);
    // const reqObj = {
    //   order_id: data.order_id,
    // };
    // CustomConsole(reqObj);
    // dispatch(PostSupplierRollBack(reqObj));
    // setOpenRollBackDialog(false);
    setTimeout(() => {
      localStorage.removeItem("current_scanned_codes");
      // dispatch(GetQrBarcodeDetails(orderId));
      setInScannedCodes([]);
      setCurrentScanned([]);
      setCameraScannedCodes([]);
      // localStorage.removeItem("consumer_containers");
      // localStorage.removeItem("distributor_filled_containers");
    }, 1000);
  };
  const handleClickNo = () => {
    CustomConsole("------handleClickNo--------");
    setOpenRollBackDialog(false);
  };
  const hnadleRollBackCons = (data) => {
    CustomConsole(data);
    CustomConsole("------handleRollBackCansSupplier--------");
    // setOrderIds(props.singleData.order_id);
    setProductorderDetails(data);
    setOpenRollBackDialog(true);
  };
  const handleBackToDeliveries = (status) => {
    CustomConsole("--- handleBackToDeliveries ---");
    window.history.back();
    dispatch(ClearFilesData());
  };
  // Function to handle the Google map location tracking button click
  const handleGMapButtonClick = () => {
    CustomConsole(userLocation);
    if (
      userLocation.latitude !== undefined &&
      userLocation.latitude !== null &&
      userLocation.longitude !== undefined &&
      userLocation.longitude !== null &&
      parseFloat(userLocation.latitude) > 0 &&
      parseFloat(userLocation.longitude) > 0
    ) {
      // Construct the map URL with the coordinates (Google Maps in this example)
      const mapUrl = `https://www.google.com/maps?q=${userLocation.latitude},${userLocation.longitude}`;
      // Redirect to the map URL
      window.location.href = mapUrl;
    } else {
      toast.dark("User location not found!", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const handleScanInValidCans = () => {
    CustomConsole("-----------handleScanInValidCans----------");
    setOpenScanInValidCsnsDialog(true);
  };
  const handleSendRequest = (props) => {
    setOpenScanInValidCsnsDialog(false);
    const reqObj = {
      scan_type: props.scan_type,
      qr_code: props.qr_code,
      order_id: orderId,
    };
    CustomConsole(reqObj);
  };
  const handleCloseDialogInvalidCans = () => {
    setOpenScanInValidCsnsDialog(false);
  };

  const handleApprovedOrRejectedCans = () => {
    if (
      orderDetailsData.orderDetails &&
      orderDetailsData.orderDetails.order_id &&
      orderDetailsData.orderDetails.order_id.length
    ) {
      setOpenApprovedRejectedCans(true);
    }
  };

  const handleCloseApprovedRejectedDialog = () => {
    setOpenApprovedRejectedCans(false);
  };

  const handleLinkEmptyCans = (props) => {
    CustomConsole("------------handleLinkEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedEmpty,
      order_id: orderDetailsData.orderDetails.order_id,
    };
    CustomConsole(reqObj);
    // dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedEmptyCans = (props) => {
    CustomConsole("------------handleLinkApprovedEmptyCans-------------");
    CustomConsole(props);
    const reqObj = {
      qr_code: props.approvedFilledids,
      order_id: orderDetailsData.orderDetails.order_id,
    };
    CustomConsole(reqObj);
    // dispatch(InscanQRBarcode(reqObj));
  };
  const handleLinkApprovedQrs = (props) => {
    CustomConsole("-- handleLinkApprovedQrs --");
    CustomConsole(props);
    if (props.approved_empty.length) {
      //Link the approved empty
      const reqObj = {
        order_id: props.order_id,
        qr_code: props.approved_empty,
      };
      CustomConsole(reqObj);
      dispatch(InscanQRBarcode(reqObj));
    }
    if (props.approved_filled.length) {
      //Link the approved filled
      const reqObj = {
        order_id: props.order_id,
        qr_code: props.approved_filled,
      };
      CustomConsole(reqObj);
      dispatch(OutScanQRBarcode(reqObj));
    }
  };
  const handleChangeToggleCustomers = (event, newAlignment) => {
    setAlignmentCustomers(newAlignment);
  };

  const handleOpenQrlessDialog = (qrless) => {
    console.log("---------qrless-----------");
    CustomConsole(qrless);

    const reqObj = {
      order_id: orderId,
      capacity: qrless.capacity,
      material: qrless.material,
      quantity: qrless.return,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierProductInscan(reqObj));
    setOpenQrlessDialog(true);
    setQrlessData(qrless);
  };

  const handleCloseQrless = () => {
    setOpenQrlessDialog(false);
  };
  const hadleSubmitQrless = (data) => {
    CustomConsole(data);
    const reqObj = {
      order_id: data.order_id,
      capacity: data.capacity,
      material: data.material,
      quantity: data.quantity,
      is_crate: data.is_crate,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierProductQrLessOutScan(reqObj));
    setOpenQrlessDialog(false);
    setTimeout(() => {
      dispatch(GetViewOrderDetails(orderId));
    }, 1000);
  };
  const handleProductInScan = (data) => {
    CustomConsole(data);
    const reqObj = {
      order_id: data.order_id,
      capacity: data.capacity,
      material: data.material,
      quantity: data.quantity,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierProductInscan(reqObj));
    setOpenProductsInScanDialog(false);
  };
  const handleInScanningProcess = (data) => {
    console.log("-------In SCAn-----------");
    console.log(data);
    //@Removed/console.log("data scan");
    //@Removed/console.log(data);
    setScanQtyMax(data.return ? data.return - data.received_empty : 0);
    CustomConsole("   ");
    setIsProperScan(true);
    CustomConsole(data);
    setProductData(data);
    setIsInScan(true);

    // dispatch(GetViewOrderDetails(orderId));
    if (
      data.is_inscan_disabled ||
      (data.is_invalid === true && data.return !== data.received_empty)
    ) {
      CustomConsole("----InScan product-----");
      CustomConsole(data.return);
      CustomConsole(data.received_empty);
      // dispatch(GetQrBarcodeDetails(orderId));
      setOpenProductsInScanDialog(true);
      // const reqObj = {
      //   order_id: orderId,
      //   capacity: data.capacity,
      //   material: data.material,
      //   quantity: data.return,
      // };
      // CustomConsole(reqObj);
      // dispatch(PostSupplierProductInscan(reqObj));
    } else if (
      data.is_invalid === false &&
      data.isOutScanCompleted === false &&
      data.isInScanCompleted === false
    ) {
      CustomConsole("----In Scan- 20 L----");
      // dispatch(GetQrBarcodeDetails(orderId));
      setOpenQrBarCodeCamera(true);
      setOpenOutScanCamera(false);
      // setOpenCameraDialog(true);
      setOpenScanningDialog(true);
    }
  };
  const handleCloseInScan = () => {
    setOpenProductsInScanDialog(false);
  };

  const handleOutScanningProcess = (data) => {
    CustomConsole("---------------handleOutScanningProcess-----------------");
    CustomConsole(data);
    setScanQtyMax(
      data.order_qty * data.sku_qty
        ? data.order_qty * data.sku_qty - data.submitted_filled
        : 0
    );
    setProductData(data);
    setIsInScan(false);
    // dispatch(GetViewOrderDetails(orderId));
    // if (data.isInScanCompleted === false) {
    //   const reqObj = {
    //     order_id: orderId,
    //     capacity: data.capacity,
    //     material: data.material,
    //     quantity: data.return,
    //   };
    //   CustomConsole(reqObj);
    //   dispatch(PostSupplierProductInscan(reqObj));
    // }

    if (data.isOutScanCompleted === false) {
      // dispatch(GetQrBarcodeDetails(orderId));
      if (data.isInScanCompleted === false) {
        const reqObj = {
          order_id: orderId,
          capacity: data.capacity,
          material: data.material,
          quantity: data.return,
        };
        CustomConsole(reqObj);
        dispatch(PostSupplierProductInscan(reqObj));
      }
      CustomConsole("----Out Scan- 20 L----");
      setOpenOutScanCamera(true);
      setOpenQrBarCodeCamera(false);
      // setOpenCameraDialog(true);
      setOpenScanningDialog(true);
    } else if (data.order_qty * data.sku_qty !== data.submitted_filled) {
      const reqObj = {
        order_id: orderId,
        capacity: data.capacity,
        material: data.material,
        quantity: data.return,
      };
      CustomConsole(reqObj);
      dispatch(PostSupplierProductInscan(reqObj));
      CustomConsole("----Out Scan- product----");
      setOpenOutScanQrProductsCameraDialog(true);
    }
  };

  const handleCopyAddress = (data) => {
    const address_compile = ` ${data.delivery_address?.address_line1},
    ${data.delivery_address?.address_line2},
    ${data.delivery_address?.address_line3},
    ${data.delivery_address?.service_area}- 
    ${data.delivery_address?.pincode}`;
    navigator.clipboard
      .writeText(address_compile)
      .then(() => {
        toast.success("Address copied sucessfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      })
      .catch((error) => {
        toast.error("Failed to copy address. Please try again", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //theme: "colored",
        });
      });
  };

  const handlePhone = (props) => {
    CustomConsole("------------handlePhone------------------");
    if (props.delivery_address.contact_phone !== "") {
      CustomConsole(props.delivery_address.contact_phone);
      const telUrl = `tel:${props.delivery_address.contact_phone}`;
      window.location.href = telUrl;
    } else {
      toast.error("Consumer Number Is Not Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleCanceOtp = () => {
    CustomConsole("------------handleCanceOtp----------------");
    const reqObj = {
      delivery_id: data.delivery_id,
    };
    CustomConsole(reqObj);
    dispatch(PutSupplierCurrentDayCancelOtp(reqObj));
  };

  //This function works Click on Send Email button send mail to support
  const handleSendEmailExpiredOrNonExpired = () => {
    dispatch(
      GetCanAgeDetailsSendEmail({ address_id: addressId, user_id: userId })
    );
  };
  // Open Dialog Return Can Request Behalf Consumer
  const handleOpenReturnRequest = () => {
    setOpenWithdrawalRequestDialog(true);
  };

  //Open Close Return Can Request Behalf Consumer
  const handleCloseDialogReturnRequest = () => {
    setOpenWithdrawalRequestDialog(false);
  };
  //Submit & close  Return Can Request Behalf Consumer
  const handleSubmitDialogReturnRequest = (props) => {
    setOpenWithdrawalRequestDialog(false);
    const reqObj = {
      order_id: data.order_id,
      container_capacity: "20_Liters",
      container_material: "PET",
      container_qty: parseInt(props),
      user_id: userId,
      address_id: addressId,
      for_consumer: true,
    };
    dispatch(CreateWithdarawalRequest(reqObj));
  };
  const handleOpenStockDialog = () => {
    setOpenStockDetailsDialog(true);
  };
  const handleCloseStockDialog = () => {
    setOpenStockDetailsDialog(false);
  };

  const handleOpenLinkOrderToOrderDialog = (props) => {
    //@Removed/console.log(props);
    setOrderIdStartingLetters(props.orderId);
    setCapcity(props.capacity);
    setMaterial(props.material);
    setOpenOrderToOrderScanningDialog(true);
  };

  const handleLinkOrder = (props) => {
    const reqObj = {
      from_order_id: orderIdStartingLetters + props.scanOrderId,
      to_order_id: orderDetailsData?.orderDetails?.order_id,
      capacity: capcity,
      material: material,
    };
    CustomConsole(reqObj);
    dispatch(LinkProductsOrderToOrder(reqObj));
    setOpenOrderToOrderScanningDialog(false);
  };

  const openExtraReturnDialog = (material, capacity, order_id, is_crate) => {
    setExtraObj((prev) => ({
      ...prev,
      order_id: order_id,
      capacity: capacity,
      material: material,
      quantity: 0,
      is_crate: is_crate,
    }));

    setOpenExtraRet(true);
  };

  const handleIncrement = (material, capacity, orderId, crate) => {
    // const updatedReturn = prev + 1;
    // setExtraReturn(extraReturn + 1);
    setExtraReturn((prev) => {
      const updatedReturn = prev + 1;

      let bodyParam = {
        order_id: orderId,
        capacity: capacity,
        material: material,
        quantity:
          updatedReturn - minReturn >= 0 ? updatedReturn - minReturn : 0,
        is_crate: crate,
      };

      dispatch(ExtraEmpty(bodyParam));

      return updatedReturn;
    });
  };

  const handleDecrement = async (
    material,
    capacity,
    orderId,
    crate,
    returnQty
  ) => {
    let bodyParam = {
      order_id: orderId,
      capacity: capacity,
      material: material,
      quantity: returnQty,
    };
    // setExtraReturn((prev) => {
    //   const updatedReturn = prev > minReturn ? prev - 1 : minReturn;

    //   // dispatch(ExtraEmpty(bodyParam));

    //   return updatedReturn;
    // });
    await ExtraEmptyCallBack(bodyParam);
    dispatch(GetViewOrderDetails(orderId));
  };
  const handleReturnChange = async (
    material,
    capacity,
    orderId,
    crate,
    returnQty,
    index,
    qty
  ) => {
    let bodyParam = {
      order_id: orderId,
      capacity: capacity,
      material: material,
      is_crate: crate ? true : false,
      quantity: qty > 0 ? qty : 0,
    };
    // setExtraReturn((prev) => {
    //   const updatedReturn = prev > minReturn ? prev - 1 : minReturn;

    //   // dispatch(ExtraEmpty(bodyParam));

    //   return updatedReturn;
    // });
    await ExtraEmptyCallBack(bodyParam);
    dispatch(GetViewOrderDetails(orderId));
  };

  const handleChange = (event, material, capacity, orderId, crate) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= minReturn) {
      setExtraReturn(newValue);
      let bodyParam = {
        order_id: orderId,
        capacity: capacity,
        material: material,
        quantity: newValue,
      };

      dispatch(ExtraEmpty(bodyParam));
    } else {
      setExtraReturn(minReturn);
    }
  };

  // React.useEffect(() => {
  //   if (data.return) {
  //     setExtraReturn(data.return);
  //     setMinReturn(data.return);
  //   }
  // }, [data]);

  return (
    <Box sx={{ marginTop: "60px" }}>
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // gap: "10px",
            mt: 2,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleBackToDeliveries(data.order_status)}
                sx={{
                  // backgroundColor: "#34f7b3",
                  color: "black",
                  borderRadius: "20px",
                }}
              >
                <ArrowBack />
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={refreshOrderDetails}
                sx={{
                  // backgroundColor: "#d8f59f",
                  color: "white",
                  borderRadius: "100px",
                }}
              >
                <Refresh />
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={handleGMapButtonClick}
                sx={{ color: "blue", borderRadius: "50px" }}
              >
                <Place sx={{ color: "red" }} /> Maps
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={() => handlePhone(orderDetailsData?.orderDetails)}
                sx={{
                  color: "white",
                  borderRadius: "50px",
                  bgcolor: "#239B56",
                }}
              >
                <MuiIcons.Call />
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ maxHeight: "780px", overflow: "auto" }}
        >
          <Grid item md={4} xs={12}>
            <Paper sx={{ padding: "10px", borderRadius: "10px" }} elevation={6}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {parseInt(data.order_status) === 200 &&
                data.packaged_drinking_water?.is_order_started === true ? (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#D35400",
                      color: "white",
                      borderRadius: "10px",
                      // mt: "10px",
                      minWidth: "10px",
                      height: "20px",
                    }}
                    onClick={handleCanceOtp}
                  >
                    <MuiIcons.Close />
                  </Button>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  m: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  ORDER DETAILS
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#D0D3D4",
                    p: "5px",
                    pr: "5px",
                  }}
                >
                  <Typography sx={{ color: "#17202A", fontWeight: "bold" }}>
                    {orderDetailsData?.orderDetails?.order_id}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      textAlign: "end",
                      fontWeight: "bold",
                      color:
                        orderDetailsData?.orderDetails?.payment_type === 300
                          ? "#6d147d"
                          : orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.order_type === 700
                          ? "red"
                          : orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.is_subscribed_order ===
                            true
                          ? "green"
                          : "black",
                    }}
                  >
                    {orderDetailsData?.orderDetails?.payment_type === 300
                      ? "POSTPAID"
                      : orderDetailsData?.orderDetails?.packaged_drinking_water
                          ?.order_type === 700
                      ? "INSTA"
                      : orderDetailsData?.orderDetails?.packaged_drinking_water
                          ?.is_subscribed_order === true
                      ? "SUBSCRIBED"
                      : "REGULAR"}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mt: "10px",
                    // ml:"10px"
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#566573",
                        fontWeight: "bold",
                      }}
                    >
                      <MuiIcons.Person
                        sx={{ fontSize: "16px", color: "#21618C" }}
                      />{" "}
                      Customer Name
                    </Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      {
                        orderDetailsData?.orderDetails?.delivery_address
                          ?.contact_name
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ display: "flex", gap: "3px" }}>
                      <MuiIcons.Phone
                        sx={{ fontSize: "14px", color: "#21618C", mt: "3px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#566573",
                          fontWeight: "bold",
                        }}
                      >
                        Customer Phone
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      {
                        orderDetailsData?.orderDetails?.delivery_address
                          ?.contact_phone
                      }{" "}
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mt: "10px",
                    mr: "40px",
                  }}
                >
                  <Box sx={{ mr: "50px" }}>
                    <Box sx={{ display: "flex", gap: "2px" }}>
                      <MuiIcons.AccessTime
                        sx={{ fontSize: "17px", color: "#21618C", mt: "4px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#566573",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Slot
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                      {orderDetailsData?.orderDetails?.packaged_drinking_water
                        ?.delivery_slots
                        ? orderDetailsData?.orderDetails
                            ?.packaged_drinking_water?.delivery_slots
                            ?.admin_selected_delivery_slot &&
                          orderDetailsData?.orderDetails
                            ?.packaged_drinking_water?.delivery_slots
                            ?.admin_selected_delivery_slot.length
                          ? orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.delivery_slots
                              ?.admin_selected_delivery_slot
                          : orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.delivery_slots
                              ?.supplier_selected_delivery_slot &&
                            orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.delivery_slots
                              ?.supplier_selected_delivery_slot.length
                          ? orderDetailsData?.orderDetails
                              ?.packaged_drinking_water?.delivery_slots
                              ?.supplier_selected_delivery_slot
                          : "-"
                        : "-"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#566573",
                        fontWeight: "bold",
                      }}
                    >
                      <MuiIcons.Place
                        sx={{ fontSize: "15px", color: "#21618C", mr: "2px" }}
                      />
                      PinCode
                    </Typography>
                    <Typography
                      sx={{ fontSize: "15px", fontWeight: "bold", ml: "15px" }}
                    >
                      {
                        orderDetailsData?.orderDetails?.delivery_address
                          ?.pincode
                      }
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mt: "10px",
                    // ml: "30px",
                  }}
                >
                  <Box sx={{ mr: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#566573",
                        fontWeight: "bold",
                      }}
                    >
                      <MuiIcons.Place
                        sx={{ fontSize: "15px", color: "#21618C" }}
                      />{" "}
                      Service Area
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      {
                        orderDetailsData?.orderDetails?.delivery_address
                          ?.service_area
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ display: "flex", gap: "2px", ml: "44px" }}>
                      <MuiIcons.Place
                        sx={{ fontSize: "15px", color: "#21618C" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#566573",
                          fontWeight: "bold",
                        }}
                      >
                        Location
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: "bold" }}
                      // onClick={() => handleCopyLocation()}
                    >
                      {`${
                        orderDetailsData?.orderDetails?.delivery_address
                          ?.latitude
                          ? orderDetailsData?.orderDetails?.delivery_address
                              ?.latitude
                          : "---"
                      },
              ${
                orderDetailsData?.orderDetails?.delivery_address?.longitude
                  ? orderDetailsData?.orderDetails?.delivery_address?.longitude
                  : "---"
              }`}{" "}
                      <MuiIcons.ContentCopy
                        onClick={() => handleCopyLocation()}
                        sx={{ fontSize: "17px", color: "#E74C3C" }}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${1}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "10px",
                    // ml:"10px"
                  }}
                >
                  <Box sx={{ mr: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#566573",
                        fontWeight: "bold",
                        ml: "23px",
                      }}
                    >
                      <MuiIcons.Home
                        sx={{ fontSize: "16px", color: "#21618C" }}
                      />
                      Address
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      {`${orderDetailsData?.orderDetails?.delivery_address?.address_line1}, 
          ${orderDetailsData?.orderDetails?.delivery_address?.address_line2},
          ${orderDetailsData?.orderDetails?.delivery_address?.address_line3}`}{" "}
                      <MuiIcons.ContentCopy
                        onClick={() =>
                          handleCopyAddress(orderDetailsData?.orderDetails)
                        }
                        sx={{ fontSize: "17px", color: "#E74C3C" }}
                      />
                      {/* <Button
                        onClick={handleOpenStockDialog}
                        variant="contained"
                        size="small"
                        sx={{ width: "30px", m: 1 }}
                      >
                        stock
                      </Button> */}
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: `${0.5}px`, // Adjust the thickness as needed
                    borderStyle: "dashed",
                    border: "1px solid black",
                  }}
                />
                {/* <Box
                  sx={{
                    display: "flex",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "black",
                    pt: 1,
                    pl: 1,
                  }}
                >
                  <Typography>Cans In Location :</Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "black",
                      p: "5px",
                    }}
                  >
                    {nonExpiredCans + expiredCansWithConsumer}
                  </Typography>
                </Box> */}
              </Box>

              <Divider
                sx={{
                  height: `${1}px`, // Adjust the thickness as needed
                  borderStyle: "dashed",
                  border: "1px solid black",
                }}
              />
              {data && parseInt(data.order_status) === 200 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    // mr: "20px",
                  }}
                >
                  <Box>
                    {orderDetailsData.orderDetails.payment_type === 300 &&
                    orderDetailsData.orderDetails.order_status === 200 &&
                    isProductScanCompleted ? (
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        md={12}
                        xs={12}
                        padding="10px"
                      >
                        <Button
                          onClick={handleUploadDriverDriverChallan}
                          variant="outlined"
                          disabled={
                            fileHandler.isFileUploaded !== undefined &&
                            fileHandler.isFileUploaded === false
                              ? false
                              : true
                          }
                        >
                          {fileHandler.isFileUploaded !== undefined &&
                          fileHandler.isFileUploaded === false
                            ? "Upload Delivery Challan"
                            : "Uploaded Successfully"}
                          <input
                            type="file"
                            accept="image/*"
                            // capture="”camera"
                            ref={uploadDriverCgallan}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setUploadDriverChallanFile(e.target.files[0])
                            }
                          />
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {(data.packaged_drinking_water !== undefined &&
                      data.packaged_drinking_water.delivery_slots !==
                        undefined &&
                      data.packaged_drinking_water.delivery_otp_status !==
                        200 &&
                      data.packaged_drinking_water !== undefined &&
                      data.packaged_drinking_water.delivery_slots !==
                        undefined &&
                      data.packaged_drinking_water.is_order_started ===
                        false) ||
                    (data.packaged_drinking_water !== undefined &&
                      data.packaged_drinking_water.delivery_slots !==
                        undefined &&
                      data.packaged_drinking_water.delivery_otp_status ===
                        200 &&
                      data.packaged_drinking_water !== undefined &&
                      data.packaged_drinking_water.delivery_slots !==
                        undefined &&
                      data.packaged_drinking_water.is_order_started ===
                        false) ||
                    isProductScanCompleted ? (
                      <Button
                        variant="contained"
                        sx={{
                          background: appColors.commonBtnColor.background,
                          color: "white",
                          borderRadius: "10px",
                          mt: "10px",
                          height: "30px",
                          mr: 1,
                        }}
                        onClick={handleOpenEnterOtpDialog}
                      >
                        {data.packaged_drinking_water !== undefined &&
                        data.packaged_drinking_water.delivery_slots !==
                          undefined &&
                        data.packaged_drinking_water.delivery_otp_status !==
                          200 &&
                        data.packaged_drinking_water !== undefined &&
                        data.packaged_drinking_water.delivery_slots !==
                          undefined &&
                        data.packaged_drinking_water.is_order_started === false
                          ? "start"
                          : data.packaged_drinking_water !== undefined &&
                            data.packaged_drinking_water.delivery_slots !==
                              undefined &&
                            data.packaged_drinking_water.delivery_otp_status ===
                              200 &&
                            data.packaged_drinking_water !== undefined &&
                            data.packaged_drinking_water.delivery_slots !==
                              undefined &&
                            data.packaged_drinking_water.is_order_started ===
                              false
                          ? "Start"
                          : isProductScanCompleted
                          ? "Complete"
                          : ""}
                      </Button>
                    ) : (
                      ""
                    )}

                    {/* {data.packaged_drinking_water.is_order_started === true ? (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#D35400",
                            color: "white",
                            borderRadius: "10px",
                            mt: "10px",
                            height: "30px",
                          }}
                          onClick={handleCanceOtp}
                        >
                          cancel Otp
                        </Button>
                        <Button
                          // hidden
                          variant="contained"
                          sx={{
                            background: "red",
                            color: "white",
                            borderRadius: "10px",
                            mt: "10px",
                            height: "30px",
                            mr: 1,
                            // display: "none",
                          }}
                          onClick={() => setOpenDamageDialog(true)}
                        >
                          Mark Damaged
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )} */}
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {/* Swap and Swap info buttons */}

              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleScanInValidCans}
                  sx={{
                    height: "30px",
                    borderRadius: "20px",
                    // bgcolor: "#52BE80",
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  <DocumentScanner sx={{ fontSize: "17px" }} /> swap
                </Button>
                <Button
                  variant="contained"
                  onClick={handleApprovedOrRejectedCans}
                  sx={{
                    height: "30px",
                    borderRadius: "20px",
                    // bgcolor: "#52BE80",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  <Info />
                  Swap Info
                </Button>
              </Box> */}
            </Paper>
          </Grid>
          <Grid item md={8} xs={12} ref={scrollDownRef}>
            <Box>
              <Paper
                sx={{
                  padding: "10px",
                  borderRadius: "20px",
                  border: isProductScanCompleted ? "4px solid green" : "white",
                }}
                elevation={6}
              >
                <Box sx={{ p: 1 }}>
                  <Typography
                    sx={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    PRODUCT DETAILS
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "",
                    gap: "10px",
                  }}
                >
                  {orderProducts !== undefined &&
                  orderProducts !== null &&
                  orderProducts.length > 0 ? (
                    <>
                      {orderProducts?.map((data, index) => {
                        let extra_qty = extraReturnProducts?.filter(
                          (extra_product) =>
                            extra_product.capacity === data.capacity &&
                            extra_product.material === data.material
                        )?.[0]?.quantity;
                        return (
                          <Paper
                            sx={{
                              padding: "10px",
                              width: { xs: "100%", md: "50%" },
                              borderRadius: "10px",
                              border:
                                data.order_qty * data.sku_qty ===
                                data.submitted_filled
                                  ? "4px solid green"
                                  : data.return === data.received_empty
                                  ? "4px solid #F4D03F"
                                  : "4px solid red",
                            }}
                            elevation={5}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                // bgcolor: "#CCD1D1",
                                bgcolor: "#ECF0F1",
                                pl: "5px",
                                // pr: "5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#21618C",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.name}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "flex",
                                  textAlign: "end",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                ({data.capacity} - {data.material})
                              </Typography>
                              {/* <IconButton
                                size="large"
                                onClick={() =>
                                  handleOpenLinkOrderToOrderDialog({
                                    orderId: "ECON",
                                    capacity: data.capacity,
                                    material: data.material,
                                  })
                                }
                                sx={{ padding: "0px" }}
                              >
                                i
                              </IconButton> */}
                              {data.is_qr === true && data.scan_type == 2 ? (
                                // <Button
                                //   size="small"
                                //   sx={{
                                //     minWidth: "10px",
                                //     minHeight: "10px",
                                //     borderRadius: "50px",
                                //     // padding: "0px",
                                //     fontFamily: "'Montserrat', sans-serif",
                                //     textTransform: "none",
                                //   }}
                                //   variant="contained"
                                //   onClick={() =>
                                //     handleOpenLinkOrderToOrderDialog({
                                //       orderId: "ECON",
                                //       capacity: data.capacity,
                                //       material: data.material,
                                //     })
                                //   }
                                // >
                                <Tooltip title="Link Order Id">
                                  <Info
                                    onClick={() =>
                                      handleOpenLinkOrderToOrderDialog({
                                        orderId: "ECON",
                                        capacity: data.capacity,
                                        material: data.material,
                                      })
                                    }
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </Tooltip>
                              ) : // </Button>
                              null}
                            </Box>
                            <Divider />
                            <Box
                              sx={{
                                display: "flex",
                                // flexDirection: "column",
                                justifyContent: "space-around",
                                gap: "10px",
                              }}
                            >
                              <Box>
                                <ImageLoader filename={data.image} />
                                {data.order_status === 200 ? (
                                  <>
                                    {data.received_empty > 0 ||
                                    data.submitted_filled > 0 ? (
                                      <Box>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          color="error"
                                          disabled={
                                            parseInt(
                                              localStorage.getItem("roleId")
                                            ) ===
                                              ADMIN_ROLES.BW_DELIVERYAGENT ||
                                            parseInt(
                                              localStorage.getItem("roleId")
                                            ) ===
                                              ADMIN_ROLES.BW_SUPPLIER_LOADERS
                                              ? true
                                              : false
                                          }
                                          sx={{
                                            height: "30px",
                                            borderRadius: "20px",
                                            // bgcolor: "#52BE80",
                                          }}
                                          onClick={() =>
                                            hnadleRollBackCons(data)
                                          }
                                        >
                                          <Rotate90DegreesCcw
                                            sx={{ fontSize: "14px" }}
                                          />
                                          RollBack
                                        </Button>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {data.booking_type === 1 ? (
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      <Typography sx={{ mt: "8px" }}>
                                        {" "}
                                        {data.is_invalid === false ? (
                                          <img
                                            src={CanIcon}
                                            alt="icon"
                                            style={{
                                              height: "25px",
                                              width: "20px",
                                              // marginTop: "3px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={EmptyGlassBottle}
                                            alt="icon"
                                            style={{
                                              height: "30px",
                                              width: "30px",
                                              // marginTop: "3px",
                                            }}
                                          />
                                        )}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          mt: "8px",
                                        }}
                                      >
                                        Empty :
                                      </Typography>
                                    </Box>
                                    <Box>
                                      {" "}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          // gap: "5px",
                                          border: "1px solid black",
                                          height: "30px",
                                        }}
                                      >
                                        <Box>
                                          <IconButton
                                            onClick={() =>
                                              handleReturnChange(
                                                data.material,
                                                data.capacity,
                                                orderId,
                                                false,
                                                data?.return,
                                                index,
                                                (extra_qty || 1) - 1 //extra_return_products
                                              )
                                            }
                                            disabled={extra_qty ? false : true}
                                          >
                                            <Remove />
                                          </IconButton>
                                        </Box>
                                        <Divider
                                          orientation="vertical"
                                          flexItem
                                          sx={{ border: "1px solid black" }}
                                        />
                                        <Box>
                                          {editingProduct == index ? (
                                            <TextField
                                              size="small"
                                              type="number"
                                              autoFocus={true}
                                              onChange={(event) => {
                                                let qty = parseInt(
                                                  event.target.value || 0
                                                );
                                                let extra =
                                                  qty - parseInt(data?.return);
                                                setExtraReturnQty(qty);
                                                console.log(qty, extra, "OUT");
                                                handleReturnChange(
                                                  data.material,
                                                  data.capacity,
                                                  orderId,
                                                  false,
                                                  data?.return,
                                                  index,
                                                  extra //extra_return_products
                                                );
                                                // setTimeout(() => {
                                                //   console.log(
                                                //     qty,
                                                //     extraReturnQty,
                                                //     "IN"
                                                //   );
                                                //   if (qty == extraReturnQty) {
                                                //     let extra =
                                                //       parseInt(data?.return) -
                                                //       qty;
                                                //     handleReturnChange(
                                                //       data.material,
                                                //       data.capacity,
                                                //       orderId,
                                                //       false,
                                                //       data?.return,
                                                //       index,
                                                //       extra //extra_return_products
                                                //     );
                                                //   }
                                                // }, 500);
                                              }}
                                              inputProps={{
                                                min: minReturn,
                                                style: {
                                                  height: "10px",
                                                  width: "20px",
                                                },
                                              }}
                                            />
                                          ) : (
                                            <Box
                                              sx={{ padding: "20px" }}
                                              onClick={() =>
                                                setEditingProduct(index)
                                              }
                                            >
                                              {parseInt(data.return || 0) +
                                                parseInt(extra_qty || 0)}
                                            </Box>
                                          )}
                                        </Box>
                                        <Divider
                                          orientation="vertical"
                                          flexItem
                                          sx={{ border: "1px solid black" }}
                                        />
                                        <Box>
                                          <IconButton
                                            onClick={() =>
                                              handleReturnChange(
                                                data.material,
                                                data.capacity,
                                                orderId,
                                                false,
                                                data.return,
                                                index,
                                                (extra_qty || 0) + 1 //extra_return_products
                                              )
                                            }
                                          >
                                            <Add />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : (
                                  ""
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: "5px",
                                  }}
                                >
                                  <Box sx={{ display: "flex", gap: "10px" }}>
                                    <Typography>
                                      {" "}
                                      {data.is_invalid === false ? (
                                        <img
                                          src={FilledCanIcon}
                                          alt="icon"
                                          style={{
                                            height: "30px",
                                            width: "26px",
                                            // marginTop: "3px",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={FilledGlassBottleIcon2}
                                          alt="icon"
                                          style={{
                                            height: "32px",
                                            width: "36px",
                                            // marginTop: "3px",
                                          }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography
                                      sx={{ mt: "5px", fontWeight: "bold" }}
                                    >
                                      Filled :
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        mt: "2px",
                                      }}
                                    >
                                      {data.is_invalid === false
                                        ? data.out_scan_count +
                                          data.submitted_filled
                                        : data.submitted_filled
                                        ? data.submitted_filled
                                        : 0}
                                      /{" "}
                                      {data.order_qty * data.sku_qty
                                        ? data.order_qty * data.sku_qty
                                        : 0}
                                    </Typography>
                                  </Box>
                                  {/* <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {data.is_invalid === false
                                        ? data.out_scan_count
                                          ? data.out_scan_count
                                          : 0
                                        : data.received_empty
                                        ? data.received_empty
                                        : 0}
                                      /{" "}
                                      {data.order_qty * data.sku_qty
                                        ? data.order_qty * data.sku_qty
                                        : 0}
                                    </Typography>
                                  </Box> */}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "10px",
                                    mt: "10px",
                                  }}
                                >
                                  <Box>
                                    {data.in_scan ? (
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="success"
                                        sx={{
                                          height: "26px",
                                          borderRadius: "20px",
                                          // bgcolor: "#52BE80",
                                        }}
                                        onClick={() =>
                                          handleInScanningProcess(data)
                                        }
                                      >
                                        <DocumentScanner
                                          sx={{ fontSize: "17px" }}
                                        />
                                        INSCAN
                                      </Button>
                                    ) : data.scan_type == 2 ? (
                                      <>
                                        {data.is_qr === true ? (
                                          <Button
                                            variant="contained"
                                            size="small"
                                            color="secondary"
                                            sx={{
                                              height: "26px",
                                              borderRadius: "20px",
                                              // bgcolor: "#52BE80",
                                            }}
                                            onClick={() =>
                                              handleOutScanningProcess(data)
                                            }
                                          >
                                            <DocumentScanner
                                              sx={{ fontSize: "17px" }}
                                            />
                                            OUTSCAN
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                              height: "26px",
                                              borderRadius: "20px",
                                            }}
                                            size="small"
                                            onClick={() =>
                                              handleOpenQrlessDialog(data)
                                            }
                                          >
                                            <DocumentScanner
                                              sx={{ fontSize: "17px" }}
                                            />
                                            Outscan
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Paper>
                        );
                      })}
                    </>
                  ) : (
                    "No Order Details Found"
                  )}
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </>
      {openOrderToOrderScanningDialog && (
        <LinkOrderToOrderScanningProcessDialog
          openOrderToOrderScanningDialog={openOrderToOrderScanningDialog}
          handlCloseDialogOrdertoOrder={() =>
            setOpenOrderToOrderScanningDialog(false)
          }
          handleLinkOrder={handleLinkOrder}
          orderIdStartingLetters={orderIdStartingLetters}
        />
      )}
      {openStockDetailsDialog && (
        <StockDetailsDialog
          openStockDetailsDialog={openStockDetailsDialog}
          handleOpenReturnRequest={handleOpenReturnRequest}
          handleSendEmailExpiredOrNonExpired={
            handleSendEmailExpiredOrNonExpired
          }
          expiredCansWithConsumer={expiredCansWithConsumer}
          nonExpiredCans={nonExpiredCans}
          handleCloseDialog={handleCloseStockDialog}
        />
      )}
      <CurrentDayDeliveriesEnterOtpDialog
        openEnterOtpDialog={openEnterOtpDialog}
        enterOtpTextfied={enterOtpTextfied}
        otpValidationError={otpValidationError}
        setOtpValidationError={setOtpValidationError}
        setEnterOtpTextfied={setEnterOtpTextfied}
        handleSubmitOTP={handleCurrentDaySubmitOtp}
        handleClose={handleCloseDialog}
      />

      <CameraDialog
        openCameraDialog={openCameraDialog}
        setOpenCameraDialog={setOpenCameraDialog}
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        openQrBarCodeCamera={openQrBarCodeCamera}
        openOutScanCamera={openOutScanCamera}
        orderid={data.order_id}
        setInScanBtn={setInScanBtn}
        setOutScanBtn={setOutScanBtn}
        data={data}
        // decodedResults={decodedResults}
        // setScanCount={setScanCount}
        // onNewScanResultQrCode={onNewScanResultQrCodes}
        // onNewScanResult={onNewScanResults}
        handleClose={handleCloseCameraDialog}
        isInScan={isInScan}
        // setScannedCurrent={setScannedCurrent}
        setInScannedCodes={setInScannedCodes}
        inScannedCodes={inScannedCodes}
        setOutScannedCodes={setOutScannedCodes}
        outScannedCodes={outScannedCodes}
        inScannedNo={inScannedNo}
        setInScannedNo={setInScannedNo}
        setOutScannedNo={setOutScannedNo}
        outScannedNo={outScannedNo}
        isDistributor={true}
        setCurrentScanned={setCurrentScanned}
        setAvailableForSwap={setAvailableForSwap}
        availableForSwap={availableForSwap}
        currentScanned={currentScanned}
        cameraScannedCodes={cameraScannedCodes}
        setCameraScannedCodes={setCameraScannedCodes}
        isProperScan={isProperScan}
        setIsProperScan={setIsProperScan}
        orderProducts={orderProducts}
        isOnline={isOnline}
        userValidScanned={userValidScanned}
        otherValidScanned={otherValidScanned}
        setOtherValidScanned={setOtherValidScanned}
        inValidScanned={inValidScanned}
        setScanQtyMax={setScanQtyMax}
        scanQtyMax={scanQtyMax}
        setScannedCount={setScannedCount}
        scannedCount={scannedCount}
      />
      <AssignDeliveryAgentDialog
        openAssignDeliveryAgentDialog={openAssignDeliveryAgentDialog}
        agentListata={supplierDeliveries.getAssignDeliveryAgent}
        handleClickAssignDeliveryAgent={handleAssignDeliveryAgent}
        hadleClose={handleCloses}
      />
      <RollbackCansDialog
        openRollBackDialog={openRollBackDialog}
        handleNo={handleClickNo}
        handleYes={handleClickYes}
        productorderDetails={productorderDetails}
      />
      <DeliveriesScanInValidCansDialog
        openScanInValidCsnsDialog={openScanInValidCsnsDialog}
        setOpenScanInValidCsnsDialog={setOpenScanInValidCsnsDialog}
        // handleSendRequest={handleSendRequest}
        handleCloseDialog={handleCloseDialogInvalidCans}
        orderid={data.order_id}
      />

      {orderDetailsData.orderDetails &&
      orderDetailsData.orderDetails.order_id &&
      orderDetailsData.orderDetails.order_id.length &&
      openApprovedRejectedCans ? (
        <CanSwapOrderRequestDetailsDialog
          open={openApprovedRejectedCans}
          handleClose={handleCloseApprovedRejectedDialog}
          order_id={orderDetailsData.orderDetails.order_id}
          data={canSwapDeatails.getQrBarcodeSwapList}
          handleLink={handleLinkApprovedQrs}
          handleLinkApprovedEmptyCans={handleLinkApprovedEmptyCans}
          handleLinkEmptyCans={handleLinkEmptyCans}
        />
      ) : null}
      {openQrlessDialog && (
        <QrLessDialog
          openQrlessDialog={openQrlessDialog}
          handleClose={handleCloseQrless}
          hadleSubmit={hadleSubmitQrless}
          qrlessData={qrlessData}
          orderid={data.order_id}
        />
      )}
      {openOutScanQrProductsCameraDialog && (
        <OutScanQrProductsCameraDialog
          openOutScanQrProductsCameraDialog={openOutScanQrProductsCameraDialog}
          setOpenOutScanQrProductsCameraDialog={
            setOpenOutScanQrProductsCameraDialog
          }
          // handleClose={handleCloseOutScanCamera}
          orderid={data.order_id}
          productData={productData}
        />
      )}
      {openProductsInScanDialog && (
        <ProductsInScanDialog
          openProductsInScanDialog={openProductsInScanDialog}
          handleClose={handleCloseInScan}
          hadleSubmitInScan={handleProductInScan}
          inScanData={productData}
          orderid={data.order_id}
        />
      )}
      {/* {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )} */}
      {openWithdrawalRequestDialog && (
        <WithdrawalRequestBehalfConsumerDialog
          openWithdrawalRequestDialog={openWithdrawalRequestDialog}
          handleSubmitDialogReturnRequest={handleSubmitDialogReturnRequest}
          handleCloseDialogReturnRequest={handleCloseDialogReturnRequest}
          expiredCansWithConsumer={expiredCansWithConsumer}
        />
      )}
      {openDamageDialog && (
        <MarkingDamage
          user_id={""}
          order_id={data?.order_id}
          open={openDamageDialog}
          setOpen={setOpenDamageDialog}
          products={orderProducts}
        />
      )}

      {openExtraRet && (
        <ExtraEmptyReturn
          open={openExtraRet}
          setOpen={setOpenExtraRet}
          setReq={setExtraObj}
          reqObj={extraReturnObj}
        />
      )}
    </Box>
  );
}

export default CurrentDayDeliveriesPerticularDetails;
