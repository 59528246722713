import {
  Box,
  Button,
  createTheme,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { CameraAltRounded, ScannerRounded } from "@mui/icons-material";
import { useState } from "react";
import Html5QrcodePlugin1 from "../../Pages/wfsHome/HtmlQrcodePlugin1";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Apply styles that you want for all instances of TextField
          "& input": {
            color: "white", // Default input text color
          },
          "& label": {
            color: "white", // Default label color
          },
        },
      },
    },
  },
});

const QrScanners = ({ handleChange, isProperScan }) => {
  const [qrBarcodeId, setQrBarcodeId] = useState("");
  const [scannedValue, setScannedValue] = useState("");

  const [scanOption, setScanOption] = useState("scanner");
  const onNewScanResult = async (result) => {
    if (result.trim().length < 5) {
      return;
    }
    setQrBarcodeId(result);
    handleChange(result);
    setTimeout(() => {
      setScannedValue("");
      setQrBarcodeId("");
    }, 100);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          // padding: "10px",
        }}
      >
        <Box sx={{ marginTop: "10px" }}>
          <ToggleButtonGroup
            color="primary"
            // value={alignment}
            value={scanOption}
            exclusive
            onChange={(e) => setScanOption(e.target.value)}
            aria-label="Platform"
          >
            <ToggleButton value="scanner">
              Scanner <ScannerRounded />
            </ToggleButton>

            <ToggleButton value="camera">
              Camera <CameraAltRounded />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {scanOption === "scanner" ? (
          <>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <form
                  onSubmit={(event) => {
                    event.preventDefault(); // Prevent default form submission
                    onNewScanResult(scannedValue);
                  }}
                >
                  {/* Form element wrapping the TextField */}
                  {!qrBarcodeId && (
                    <TextField
                      type="text"
                      name="qrCode"
                      disabled={qrBarcodeId ? true : false}
                      autoFocus
                      onChange={(e) => setScannedValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ color: "text.secondary" }}
                      value={scannedValue}
                    />
                  )}
                  <Button type="submit" style={{ display: "none" }} />{" "}
                  {/* Hidden submit button */}
                </form>
              </ThemeProvider>
            </Box>
          </>
        ) : (
          <>
            {!scannedValue && (
              <Html5QrcodePlugin1
                fps={5}
                qrbox={80}
                disableFlip={false}
                qrCodeSuccessCallback={(decodedText, decodedResult) => {
                  setScannedValue(decodedText);
                  onNewScanResult(decodedText);
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default QrScanners;
