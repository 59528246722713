/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/material";

const SkuPincodeFilterDialog = ({
  open,
  onClose,
  handleApplyFilter,
  intialParamsState,
  queryParams,
  setQueryParams,
  sortByKey,
  setSortByKey,
}) => {
  const [sortBy, setSortBy] = React.useState("created_at_desc");
  //@Removed/console.log(sortBy);
  const sorKeys = [
    { key: "created_at_asc", value: "sort_created_at", order: "ASC" },
    { key: "created_at_desc", value: "sort_created_at", order: "DESC" },
    { key: "updated_at_asc", value: "sort_updated_at", order: "ASC" },
    { key: "updated_at_desc", value: "sort_updated_at", order: "DESC" },
    { key: "pincode_asc", value: "sort_pincode", order: "ASC" },
    { key: "pincode_desc", value: "sort_pincode", order: "DESC" },
    { key: "locality_asc", value: "sort_locality", order: "ASC" },
    { key: "locality_desc", value: "sort_locality", order: "DESC" },
    { key: "district_asc", value: "sort_district", order: "ASC" },
    { key: "district_desc", value: "sort_district", order: "DESC" },
  ];
  React.useEffect(() => {
    //@Removed/console.log("sortByKey: ", sortByKey);
    for (const data of sorKeys) {
      //@Removed/console.log("-- hgfhg -1--");
      //@Removed/console.log(data);
      //@Removed/console.log(sortByKey);
      //@Removed/console.log(sortByKey[data.value]);
      if (sortByKey[data.value] && data.order === sortByKey[data.value]) {
        //@Removed/console.log("--TESTT UPODATE --");
        //@Removed/console.log(data);
        setSortBy(data.key);
        break;
      }
    }
  }, []);

  const handleChange = (field, value) => {
    setQueryParams({ ...queryParams, [field]: value });
  };

  const handleFilter = () => {
    let tempSortBy = {};
    for (const data of sorKeys) {
      //@Removed/console.log("-- hgfhg --");
      //@Removed/console.log(data);
      //@Removed/console.log(sortBy);
      if (sortBy === data.key) {
        //@Removed/console.log("--TESTT UPODATE -1 --");
        //@Removed/console.log(data);
        tempSortBy[data.value] = data.order;
        //@Removed/console.log("tempSortBy: ", tempSortBy);
        setSortByKey(tempSortBy);
        break;
      }
    }
    handleApplyFilter(queryParams, tempSortBy);
    onClose();
  };

  const handelClearFilter = () => {
    setSortBy("created_at_desc");
    setSortByKey({ created_at: "DESC" });
    // setQueryParams(intialParamsState);
    if (queryParams.pincode) {
      setQueryParams({
        ...queryParams,
        pincode: "",
        locality: "",
        district: "",
        state: "",
      });
    }
  };

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="Pincode"
            size="small"
            type="number"
            value={queryParams.pincode}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            onChange={(e) => handleChange("pincode", e.target.value)}
          />
          <TextField
            label="Locality"
            size="small"
            value={queryParams.locality}
            onChange={(e) => handleChange("locality", e.target.value)}
          />
          <TextField
            label="District"
            size="small"
            value={queryParams.district}
            onChange={(e) => handleChange("district", e.target.value)}
          />
          <TextField
            label="Country"
            size="small"
            value={queryParams.country}
            onChange={(e) => handleChange("country", e.target.value)}
          />
          <TextField
            label="State"
            size="small"
            value={queryParams.state}
            onChange={(e) => handleChange("state", e.target.value)}
          />
          <FormControl variant="standard" size="small">
            <InputLabel>Sort by</InputLabel>
            <Select
              name="sort_by"
              size="small"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="created_at_asc">Created at (A-Z) ASC</MenuItem>
              <MenuItem value="created_at_desc">Created at (Z-A) DESC</MenuItem>
              <MenuItem value="updated_at_asc">Updated At (A-Z) ASC</MenuItem>
              <MenuItem value="updated_at_desc">Updated At (Z-A) DESC</MenuItem>
              <MenuItem value="pincode_asc">Pincode (A-Z) ASC</MenuItem>
              <MenuItem value="pincode_desc">Pincode (Z-A) DESC</MenuItem>
              <MenuItem value="locality_asc">Locality (A-Z) ASC</MenuItem>
              <MenuItem value="locality_desc">Locality (Z-A) DESC</MenuItem>
              <MenuItem value="district_asc">Disctrict (A-Z) ASC</MenuItem>
              <MenuItem value="district_desc">Disctrict (Z-A) DESC</MenuItem>
            </Select>
          </FormControl>

          {/* <FormControl variant="filled" sx={{ minWidth: "220px" }}>
            <InputLabel>Sort by </InputLabel>
            <Select
              name="sort_by"
              size="small"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              {sorKeys.map((data, key) => {
                return (
                  <MenuItem value={data.value} key={key}>
                    {data.value} - {data.order}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          {/* <FormControl variant="filled" fullWidth>
            <InputLabel>Sort by Created At</InputLabel>
            <Select
              name="sort_created_at"
              size="small"
              value={queryParams.sort_created_at}
              onChange={(e) => handleChange("sort_created_at", e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort by Updated At</InputLabel>
            <Select
              name="sort_updated_at"
              lable="Sort by Updated At"
              size="small"
              value={queryParams.sort_updated_at}
              onChange={(e) => handleChange("sort_updated_at", e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort by Picode</InputLabel>
            <Select
              name="sort_pincode"
              size="small"
              value={queryParams.sort_pincode}
              onChange={(e) => handleChange("sort_pincode", e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort by Locality</InputLabel>
            <Select
              name="sort_locality"
              size="small"
              value={queryParams.sort_locality}
              onChange={(e) => handleChange("sort_locality", e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort by District</InputLabel>
            <Select
              name="sort_district"
              size="small"
              value={queryParams.sort_district}
              onChange={(e) => handleChange("sort_district", e.target.value)}
            >
              <MenuItem value="ASC">Ascending</MenuItem>
              <MenuItem value="DESC">Descending</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
        {/* Add similar Select components for other sort options */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handelClearFilter}>
          Clear Filter
        </Button>
        <Button
          onClick={handleFilter}
          variant="contained"
          color="primary"
          size="small"
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkuPincodeFilterDialog;
